import { Link } from "react-router-dom";
import SxForm from "../../../forms/SxForm";
import { boolean, object, string } from "yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import BackButton from "../../../components/BackButton";
import ValidationErrors from "../../../forms/ValidationErrors";
import SxCheckbox from "../../../forms/fields/SxCheckbox";
import { RegisterData } from "../../../services/auth/authModel";
import {
  letterIncludingAccentsRegex,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";

function RegisterForm({
  onRegister,
  onRedirectToLogin,
  initialValues,
}: {
  onRegister: (values: RegisterData) => Promise<void> | void;
  onRedirectToLogin?: () => void;
  initialValues?: Pick<RegisterData, "firstname" | "lastname" | "email">;
}) {
  return (
    <SxForm
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        tel: "",
        cguAccepted: false,
        ...initialValues,
      }}
      onSubmit={onRegister}
      validationSchema={object({
        firstname: string()
          .label("Prénom")
          .required()
          .matches(
            letterIncludingAccentsRegex,
            "Le prénom ne doit contenir que des lettres",
          )
          .min(2)
          .trim(),
        lastname: string()
          .label("Nom")
          .required()
          .matches(
            letterIncludingAccentsRegex,
            "Le nom ne doit contenir que des lettres",
          )
          .min(2)
          .trim(),
        email: string().label("Adresse email").email().required(),
        tel: string()
          .label("Numéro de téléphone")
          .phoneNumber("Le numéro de téléphone est invalide")
          .transform(transformEmptyToUndefined)
          .required("Le numéro de téléphone est requis"),
        password: string().label("Mot de passe").password().required(),
        cguAccepted: boolean()
          .transform((v) => !!v)
          .oneOf([true]),
      })}
    >
      <SxField name="firstname" placeholder="Saisissez votre prénom" />
      <SxField name="lastname" placeholder="Saisissez votre nom de famille" />
      <SxField name="email" placeholder="me@mail.com" />
      <div className="cblock">
        <SxField name="tel" />
        <div className="field-tip">
          Ce numéro ne sera utilisé que pour vous envoyer les notifications de
          rendez-vous par SMS
        </div>
      </div>
      <div className="cblock">
        <SxField name="password" placeholder="************" />
      </div>

      <div className="lblock">
        <SxCheckbox
          name="cguAccepted"
          label={
            <>
              J'ai lu et j'accepte les{" "}
              <Link to="/general-conditions-of-use" target="_blank">
                conditions générales d’utilisation
              </Link>{" "}
              de la plateforme.
            </>
          }
        />
      </div>

      <div className="form_footer">
        <ValidationErrors />
        <div className="cblock">
          <SubmitButton className="submit-btn" type="submit">
            Inscription
          </SubmitButton>
        </div>

        <div className="cblock body--30">
          Déjà inscrit sur Tisio ?{" "}
          {onRedirectToLogin ? (
            <button
              type="button"
              className="redirect-link"
              onClick={onRedirectToLogin}
            >
              Me connecter
            </button>
          ) : (
            <Link className="redirect-link" to="/login">
              Me connecter
            </Link>
          )}
        </div>
        {!onRedirectToLogin && (
          <div className="lblock">
            <BackButton />
          </div>
        )}
      </div>
    </SxForm>
  );
}

export default RegisterForm;
