import { object, string, array, number, boolean } from "yup";
import SxForm from "../../forms/SxForm";
import SxField from "../../forms/fields/SxField";
import { transformEmptyToUndefined } from "../../react-helpers/yup";
import { MappedConfigData } from "../../services/data/dataModel";
import { useSearchParams } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import SubmitButton from "../../components/forms/SubmitButton";
import FormResetButton from "../../components/forms/FormResetButton";
import Icon from "../../components/Icon";
import BookingProfileAdvancedSearch from "./BookingProfileAdvancedSearch";
import { cx } from "../../react-helpers/css";

interface InitialValues {
  search: string;
  companyActivities: string[];
  languages: string[];
  expertises: string[];
  skills: string[];
  hierarchyLevels: string[];
  minPrice: number | null;
  maxPrice: number | null;
  probono: boolean;
}

const BookingProfileSearch = ({
  filtersOptions,
}: {
  filtersOptions: MappedConfigData;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const initialValues: InitialValues = useMemo(
    () => ({
      search: searchParams.get("search") ?? "",
      companyActivities: searchParams.get("activities")?.split(",") ?? [],
      languages: searchParams.get("langs")?.split(",") ?? [],
      expertises: searchParams.get("exps")?.split(",") ?? [],
      skills: searchParams.get("skills")?.split(",") ?? [],
      hierarchyLevels: searchParams.get("levels")?.split(",") ?? [],
      companyHeadcounts: searchParams.get("headcounts")?.split(",") ?? [],
      minPrice: searchParams.get("minPrice")
        ? Number(searchParams.get("minPrice"))
        : null,
      maxPrice: searchParams.get("maxPrice")
        ? Number(searchParams.get("maxPrice"))
        : null,
      probono: searchParams.get("probono") === "true",
    }),
    [searchParams],
  );

  const handleSubmit = useCallback(
    (values: InitialValues) => {
      const mappedParamsKeys = [
        "activities",
        "langs",
        "exps",
        "levels",
        "headcounts",
      ];
      setSearchParams((prev) => {
        //First, drop all the query params (except limit and sort)
        Array.from(prev.keys()).forEach((key) => {
          if (key !== "limit" && key !== "sort") prev.delete(key);
        });

        //Second, set the query params for the other fields
        Object.entries(values)
          .map(([key, value]) => {
            const paramKey = mappedParamsKeys.find((paramKey) => {
              return key.toLowerCase().includes(paramKey.slice(0, -1));
            });
            return paramKey ? [paramKey, value] : [key, value];
          })
          .forEach(([key, value]) => {
            if (key === "probono" && !value) return;
            prev.set(key, Array.isArray(value) ? value.join(",") : value);
          });

        return prev;
      });

      return Promise.resolve();
    },
    [setSearchParams],
  );
  return (
    <SxForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={object({
        search: string().transform(transformEmptyToUndefined).clearable(),
        companyActivities: array(string())
          .label("Secteurs d'activité")
          .transform(transformEmptyToUndefined),
        languages: array(string())
          .label("Langues")
          .transform(transformEmptyToUndefined),
        expertises: array(string())
          .label("Domaines d'expertise")
          .transform(transformEmptyToUndefined),
        skills: array(string())
          .label("Compétences clés")
          .transform(transformEmptyToUndefined),
        hierarchyLevels: array(string())
          .label("Niveaux hiérarchiques")
          .transform(transformEmptyToUndefined),
        companyHeadcounts: array(string())
          .label("Effectifs")
          .transform(transformEmptyToUndefined),
        minPrice: number()
          .transform(transformEmptyToUndefined)
          .positive()
          .label("Prix min."),
        maxPrice: number()
          .transform(transformEmptyToUndefined)
          .positive()
          .label("Prix max."),
        probono: boolean().label("Profils 100% don"),
      })}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ submitForm }) => (
        <div className="lblocks">
          <div className="input--search">
            <SxField
              name="search"
              placeholder={"Rechercher des profils"}
              onReset={() => {
                void submitForm();
              }}
            />
            <SubmitButton type="submit" className="--addon btn--1">
              <Icon name="search" />
            </SubmitButton>
          </div>
          <div className="lrow --distribute--h --center--v --gap--v--xs">
            <button
              type="button"
              className={cx(["btn--collapse", showAdvancedSearch && "--open"])}
              onClick={() => setShowAdvancedSearch((prev) => !prev)}
            >
              Recherche avancée
            </button>
            {/* <div className="ui-row --center body--30">
            Recherche avancée{" "}
            <button
              type="button"
              className={cx([
                showAdvancedSearch ? "icon--caret-up" : "icon--caret-down",
                "--blue",
              ])}
              onClick={() => setShowAdvancedSearch((prev) => !prev)}
            />
          </div> */}

            <FormResetButton
              className="link--error --with-icon --s"
              onClick={(_, { resetForm }) => {
                // we must to reset search params before reseting the form because of the initialValues depending on the search params
                setSearchParams((prev) => {
                  for (const key of Array.from(prev.keys())) {
                    if (key !== "limit" && key !== "sort" && key !== "search")
                      prev.delete(key);
                  }
                  return prev;
                });
                resetForm();
              }}
              ignoredFields={["search"]}
            >
              <Icon name="reset" />
              Réinitialiser les filtres
            </FormResetButton>
          </div>
          {showAdvancedSearch && (
            <BookingProfileAdvancedSearch filtersOptions={filtersOptions} />
          )}
        </div>
      )}
    </SxForm>
  );
};
export default BookingProfileSearch;
