import { isValid, parseISO } from "date-fns";
import { modulo } from "./number";
import { array, mixed } from "yup";
import { readFile } from "./files";

export const transformFileToString = async (
  value: File | string | null | undefined,
) => {
  if (!value || typeof value === "string") return value;
  let file;
  try {
    file = await readFile(value, "data");
    return file;
  } catch (e) {
    return undefined;
  }
};

export const files = () =>
  array(mixed<(string | File)[]>()).transform(transformEmptyToUndefined);
export const file = () =>
  mixed<string | File>()
    .transform((values: (File | string)[]) => values[0])
    .transform(transformEmptyToUndefined);

export const transformEmptyToUndefined = (
  currentValue: any,
  originalValue: any,
) => (!originalValue || originalValue?.length === 0 ? undefined : currentValue);

export const transformNumericStringToNumberOrNull = (
  currentValue: any,
  originalValue: any,
) =>
  originalValue !== undefined && originalValue !== null && !isNaN(currentValue)
    ? Number(currentValue)
    : null;

export const transformInvalidDateToNull = (
  currentValue: any,
  originalValue: any,
) =>
  isValid(
    typeof originalValue === "string" ? parseISO(originalValue) : originalValue,
  )
    ? currentValue
    : null;

export const transformEmptyToNull = (currentValue: any, originalValue: any) =>
  !originalValue || originalValue?.length === 0 ? null : currentValue;

// NOTE: Valid iban: DE89370400440532013000
export const checkIban = (iban: string | undefined | null) => {
  if (!iban || iban === null) return true;

  const regexIban = new RegExp("[A-Z]{2}[0-9]{2}[A-Z0-9 ]{10,28}");
  iban = iban.replace(/ /g, "");
  if (!regexIban.test(iban)) return false;

  const countryCode = iban.slice(0, 2);
  const key = iban.slice(2, 4);
  const trail = iban.slice(4);
  let convertedIban = trail + countryCode + "00";

  convertedIban = convertedIban.replace(/[A-Z]/g, (m) =>
    String(m.charCodeAt(0) - 64 + 9),
  );

  const mod = modulo(convertedIban, 97);
  const checksum = 98 - mod;

  return checksum === Number(key);
};

export const checkSiret = (value: string | undefined | null) => {
  return !value || /^[0-9]{6}([0-9]{8})?$/g.test(value);
};

export const letterIncludingAccentsRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ -]+$/;
export const checkLinkedinUrl = (linkedinUrl: string | undefined | null) => {
  if (!linkedinUrl || linkedinUrl === null) return true;

  return /^(https?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9-%]+(\/[a-zA-Z0-9-%]+)?\/?$/.test(
    linkedinUrl,
  );
};
