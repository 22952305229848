const NotFound = () => {
  return (
    <div className="container">
      <div className="title--70">403</div>
      <div className="title--50">Accès refusé</div>
    </div>
  );
};

export default NotFound;
