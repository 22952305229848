import { useOutletContext } from "react-router-dom";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import RequestsTable from "./RequestTable";

const RequestsSections = () => {
  const { unfinishedBookingRequests } = useOutletContext<{
    unfinishedBookingRequests: BookingRequest[];
  }>();

  return <RequestsTable bookingRequests={unfinishedBookingRequests} />;
};
export default RequestsSections;
