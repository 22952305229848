import { isAfter, isBefore, parseISO } from "date-fns";
import {
  addPaymentMethodToBusinessAccountRequest,
  cancelSubscriptionRequest,
  getBusinessAccountByIdRequest,
  updateBusinessAccountRequest,
} from "./businessAccountApi";
import { BusinessAccount, Plan } from "./businessAccountModel";
import { authService } from "../auth/authService";
import { toastsWithIntl } from "../toastService";

interface BusinessAccountService {
  getBusinessAccount(
    businessAccountId: BusinessAccount["id"],
  ): Promise<BusinessAccount>;
  addPaymentMethodToBusinessAccount(
    businessAccountId: BusinessAccount["id"],
    paymentMethodId: string,
  ): Promise<void>;
  updateBusinessAccount(
    businessAccountId: BusinessAccount["id"],
    businessAccount: Partial<BusinessAccount>,
  ): Promise<void>;
  cancelSubscription(
    businessAccountId: BusinessAccount["id"],
    planId: Plan["id"],
  ): Promise<void>;
}

const { getLoggedUserId } = authService();

export function businessAccountService(): BusinessAccountService {
  const { toastSuccess, toastError } = toastsWithIntl([
    "global",
    "businessAccount",
  ]);

  const getBusinessAccount: BusinessAccountService["getBusinessAccount"] = (
    businessAccountId,
  ) => {
    return getBusinessAccountByIdRequest(businessAccountId).then(
      (rawBusinessAccount) => {
        const loggedUserId = getLoggedUserId();

        return {
          ...rawBusinessAccount,
          validSubscriptions: rawBusinessAccount.plans
            .filter((plan: any) => {
              const today = new Date();
              const startDate = parseISO(plan.Subscription.startDate);
              const endDate = parseISO(plan.Subscription.endDate);

              return isAfter(today, startDate) && isBefore(today, endDate);
            })
            .map((plan: any) => plan.type),
          expiredSubscriptions: rawBusinessAccount.plans
            .filter((plan: any) => {
              const today = new Date();
              const endDate = parseISO(plan.Subscription.endDate);

              return isAfter(today, endDate);
            })
            .map((plan: any) => plan.type),
          isCurrentUserAdmin: rawBusinessAccount.users.some(
            (user: any) =>
              user.id === loggedUserId && user.BusinessAccountUser.admin,
          ),
        };
      },
    );
  };

  const addPaymentMethodToBusinessAccount: BusinessAccountService["addPaymentMethodToBusinessAccount"] =
    (businessAccountId, paymentMethodId) => {
      return addPaymentMethodToBusinessAccountRequest(
        businessAccountId,
        paymentMethodId,
      );
    };

  const updateBusinessAccount: BusinessAccountService["updateBusinessAccount"] =
    (businessAccountId, businessAccount) => {
      return updateBusinessAccountRequest(
        businessAccountId,
        businessAccount,
      ).then(
        () => {
          toastSuccess("businessAccount:update.SUCCESS");
          return Promise.resolve();
        },
        (err) => {
          toastError("businessAccount:update.ERROR");
          return Promise.reject(err);
        },
      );
    };

  const cancelSubscription: BusinessAccountService["cancelSubscription"] = (
    businessAccountId,
    planId,
  ) => {
    return cancelSubscriptionRequest(businessAccountId, planId).then(
      () => {
        toastSuccess("businessAccount:cancel-subscription.SUCCESS");
      },
      () => {
        toastError("businessAccount:cancel-subscription.ERROR");
      },
    );
  };

  return {
    getBusinessAccount,
    addPaymentMethodToBusinessAccount,
    updateBusinessAccount,
    cancelSubscription,
  };
}
