import { User } from "../../../services/user/userModel";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

import { CAL_BASE_URL } from "../../../services/config";

import OpenCalIframeButton from "./OpenCalIframeButton";

function CalendarWidgetComponent({
  loggedUser,
  displayTitle = true,
}: {
  loggedUser: User;
  displayTitle?: boolean;
}) {
  // Access the environment variable
  const calUrl = CAL_BASE_URL;

  return (
    <div className="widget">
      {displayTitle && <h2 className="widget_title">Votre calendrier</h2>}

      <div className="widget_content">
        {!loggedUser.completedOnboarding && (
          <>
            <p className="info --warning">
              Paramétrez votre calendrier pour profiter pleinement de toutes les
              fonctionnalités de la plateforme !
            </p>
            <OpenCalIframeButton
              loggedUser={loggedUser}
              className={"btn--2 --block"}
            />
          </>
        )}

        {loggedUser.completedOnboarding && (
          <>
            <Icon name={"calendar_check"} />
            <div className="info --success">Votre calendrier est configuré</div>
            <div>
              <Link
                to={`${calUrl}/availability`}
                target="_blank"
                className="btn --s --block"
              >
                Mettre à jour mes disponibilités ou mes préférences
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default CalendarWidgetComponent;
