import "../styles/main.less";
import "../internationalization/i18n.ts";
import "../forms/validation/yup-init.ts";

import React, { ReactNode } from "react";

if (typeof document === "undefined") {
  React.useLayoutEffect = React.useEffect;
}

function SsrRoot({ children }: { children: ReactNode }) {
  return (
    <>
      {/* NOTE: Place here around the Router all the Providers that need to persist during navigation */}
      {children}
    </>
  );
}

export default SsrRoot;
