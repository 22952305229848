import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import { TISIO_FEE_PERCENTAGE } from "../../services/config";
import DisplayPrice from "../DisplayPrice";

const ProfilePrice = ({
  profile,
  publicPrice = false,
}: {
  profile: BookingProfile;
  publicPrice?: boolean;
}) => {
  if (!profile.price) return;

  return (
    <div className="profile_price-display">
      <DisplayPrice
        amount={
          publicPrice
            ? profile.price * (1 + TISIO_FEE_PERCENTAGE / 100)
            : profile.price
        }
        className="price"
        addon={<span className="timeslot">/ 30 minutes</span>}
      />
    </div>
  );
};

export default ProfilePrice;
