import { boolean, object, string } from "yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import SxCheckbox from "../../../forms/fields/SxCheckbox";
import ValidationErrors from "../../../forms/ValidationErrors";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import { User } from "../../../services/user/userModel";

const CampaignUserDialogContent = ({
  onClose,
  nextStep,
  currentUser,
}: {
  onClose(): void;
  nextStep(
    externalUser: Pick<
      BookingRequest,
      "extFirstname" | "extLastname" | "extEmail"
    >,
  ): void;
  currentUser?: User;
}) => {
  return (
    <SxForm
      initialValues={{
        extLastname: currentUser?.lastname,
        extFirstname: currentUser?.firstname,
        extEmail: currentUser?.email,
        extConsent: false,
      }}
      onSubmit={(values) => {
        nextStep(values);
      }}
      validationSchema={object({
        extConsent: boolean()
          .label(
            "J’atteste sur l’honneur être en mesure de répondre à la sollicitation",
          )
          .isTrue(),
        extLastname: string().label("Nom").required(),
        extFirstname: string().label("Prénom").required(),
        extEmail: string().label("Adresse email").email().required(),
      })}
    >
      <div className="popup_head">
        <h1 className="popup_title">Vos coordonnées</h1>
      </div>
      <div className="popup_body cblocks">
        <div className="card">
          <div className="card_body">
            <SxCheckbox name="extConsent" />
          </div>
        </div>

        <div>
          <SxField name="extLastname" />
          <SxField name="extFirstname" />
          <SxField name="extEmail" />
        </div>

        <ValidationErrors />
      </div>
      <div className="popup_footer">
        <button className="btn--grey" type="button" onClick={onClose}>
          Annuler
        </button>
        <SubmitButton className="btn" type="submit">
          Suivant
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default CampaignUserDialogContent;
