import { promiseAllObject } from "../react-helpers/promise";
import { businessAccountService } from "../services/businessAccount/businessAccountService";
import { campaignService } from "../services/campaign/campaignService";
import { userService } from "../services/user/userService";
import CampaignsErrorPage from "../views/pro/campaigns/CampaignsErrorPage";
import CampaignsListPage from "../views/pro/campaigns/CampaignsListPage";
import CreateCampaignPage from "../views/pro/campaigns/campaign/CreateCampaignPage";
import EditCampaignPage from "../views/pro/campaigns/campaign/EditCampaignPage";
import ContactsImportTab from "../views/pro/campaigns/campaign/contacts/import/ContactsImportTab";
import ContactsListTab from "../views/pro/campaigns/campaign/contacts/ContactsListTab";
import CampaignContactsPage from "../views/pro/campaigns/campaign/contacts/CampaignContactsPage";
import { RouteObjectWithCondition } from "./routerConditions.tsx";
import CampaignBookingsPage from "../views/pro/campaigns/campaign/bookings/CampaignBookingsPage";
import BusinessAccountManagementPage from "../views/pro/management/BusinessAccountManagementPage.tsx";
import { getAllCountriesRequest } from "../services/country/countryApi.ts";

const { getAllCampaigns, getCampaign } = campaignService();
const { getBusinessAccount } = businessAccountService();
const { getLoggedUser } = userService();

export const proRoutes: RouteObjectWithCondition = {
  path: "pro",
  async sxRedirectTo() {
    const user = await getLoggedUser().catch(() => Promise.resolve(null));
    const isUserPro = (user?.businessAccounts?.length ?? 0) > 0;

    return isUserPro ? null : "/app";
  },
  children: [
    {
      index: true,
      async sxRedirectTo() {
        const user = await getLoggedUser();
        return `${user.businessAccounts![0].id}/campaigns`;
      },
    },
    {
      path: ":businessAccountId/manage",
      loader({ params }) {
        return promiseAllObject({
          businessAccount: getBusinessAccount(Number(params.businessAccountId)),
          countries: getAllCountriesRequest(),
        });
      },
      element: <BusinessAccountManagementPage />,
    },
    {
      path: ":businessAccountId/campaigns",
      errorElement: <CampaignsErrorPage />,
      async loader({ params }) {
        await getBusinessAccount(Number(params.businessAccountId));

        return null;
      },
      children: [
        {
          index: true,
          loader({ params }) {
            return promiseAllObject({
              campaigns: getAllCampaigns(Number(params.businessAccountId)),
              businessAccount: getBusinessAccount(
                Number(params.businessAccountId),
              ),
            });
          },
          element: <CampaignsListPage />,
        },
        {
          path: "new",
          async sxRedirectTo({ params }) {
            const businessAccount = await getBusinessAccount(
              Number(params.businessAccountId),
            );

            return businessAccount.validSubscriptions.includes("CAMPAIGN")
              ? null
              : `/subscribe?businessAccountId=${params.businessAccountId}`;
          },
          loader({ params }) {
            return promiseAllObject({
              currentUser: getLoggedUser(),
              businessAccount: getBusinessAccount(
                Number(params.businessAccountId),
              ),
            });
          },
          element: <CreateCampaignPage />,
        },
        {
          path: ":campaignId",
          loader({ params }) {
            return promiseAllObject({
              currentUser: getLoggedUser(),
              campaign: getCampaign(Number(params.campaignId)),
            });
          },
          element: <EditCampaignPage />,
        },
        {
          path: ":campaignId/contacts",
          loader({ params }) {
            return promiseAllObject({
              campaign: getCampaign(Number(params.campaignId)),
            });
          },
          element: <CampaignContactsPage />,
          children: [
            {
              index: true,
              element: <ContactsListTab />,
            },
            {
              path: "import",
              element: <ContactsImportTab />,
            },
          ],
        },
        {
          path: ":campaignId/bookings",
          loader({ params }) {
            return promiseAllObject({
              campaign: getCampaign(Number(params.campaignId)),
              businessAccount: getBusinessAccount(
                Number(params.businessAccountId),
              ),
            });
          },
          element: <CampaignBookingsPage />,
        },
      ],
    },
  ],
};
