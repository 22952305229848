import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";

const ProfilePosition = ({ profile }: { profile: BookingProfile }) => {
  if (!profile.position && !profile.companyName) return;
  return (
    <div>
      {profile.position && (
        <div className="profile_position">{profile.position}</div>
      )}

      {profile.companyName && (
        <div className="profile_company-name">
          {profile.companyName}
          {profile.companyActivity && (
            <small> ({profile.companyActivity.labelFr})</small>
          )}
        </div>
      )}
    </div>
  );
};

export default ProfilePosition;
