import { RouteObjectWithCondition } from "./routerConditions";
import LoginPage from "../views/auth/login/LoginPage";
import LostPasswordPage from "../views/auth/lostPassword/LostPasswordPage";
import ResetPasswordPage from "../views/auth/lostPassword/ResetPasswordPage";
import CreatePasswordPage from "../views/auth/createPassword/CreatePasswordPage";
import { authService } from "../services/auth/authService";
import RegisterPage from "../views/auth/register/RegisterPage";
import SiteLayout from "../components/layouts/SiteLayout";
import { PromiseResult } from "../react-helpers/type";

const { isLoggedIn, validateUser } = authService();

// NOTE: These routes are all the routes that will no longer be accessible once logged in
export const authRoutes: RouteObjectWithCondition = {
  path: "", // public
  sxRedirectTo() {
    return isLoggedIn() ? "/app" : null;
  },
  //empty loader because of the useLoaderData call in SiteLayout
  loader: () => ({}),
  element: <SiteLayout className="--sticky-header --centered" />,
  children: [
    {
      path: "login",
      element: <LoginPage />,
      async loader({ request }) {
        const url = new URL(request.url);
        const uuid = url.searchParams.get("uuid");
        // null => no uuid validation
        let validated: null | PromiseResult<ReturnType<typeof validateUser>> =
          null;
        if (uuid && uuid.length > 0) {
          validated = await validateUser(uuid);
        }
        return Promise.resolve({
          validated,
        });
      },
    },
    {
      path: "register",
      element: <RegisterPage />,
    },
    {
      path: "lost-password",
      element: <LostPasswordPage />,
    },
    {
      path: "reset-password/:uuid",
      element: <ResetPasswordPage />,
    },
    {
      path: "create-password/:uuid",
      element: <CreatePasswordPage />,
    },
  ],
};
