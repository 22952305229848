import { LoginData } from "../services/auth/authModel";
import LoginForm from "../views/auth/login/LoginForm";
import Dialog from "./Dialog";

const LoginDialog = ({
  initialValues,
  onClose,
  onRedirectToRegister,
}: {
  initialValues?: Pick<LoginData, "email">;
  onClose: () => void;
  onRedirectToRegister: () => void;
}) => {
  return (
    <Dialog onClose={onClose} className="auth-card">
      <>
        <div className="popup_head">
          <div className="popup_title">Connexion</div>
        </div>
        <div className="popup_body">
          <LoginForm
            initialValues={initialValues}
            onRedirectToRegister={onRedirectToRegister}
          />
        </div>
      </>
    </Dialog>
  );
};

export default LoginDialog;
