import { toastsWithIntl } from "../toastService";
import {
  createBookingRequestRequest,
  deleteBookingRequestRequest,
  getBookingRequestByUuidRequest,
  getPublicBookingRequestByUuidRequest,
  updateBookingRequestByIdRequest,
} from "./bookingRequestApi";
import { BookingRequest } from "./bookingRequestModel";

interface BookingRequestService {
  createBookingRequest(bookingRequest: BookingRequest): Promise<BookingRequest>;
  getBookingRequestByUuid(
    bookingRequestUuid: BookingRequest["uuid"],
  ): Promise<BookingRequest>;
  getPublicBookingRequestByUuid(
    bookingRequestUuid: BookingRequest["uuid"],
  ): Promise<BookingRequest>;
  updateBookingRequestById(
    bookingRequestIdOrUuid: BookingRequest["id"] | BookingRequest["uuid"],
    bookingRequest: BookingRequest,
  ): Promise<BookingRequest>;
  deleteBookingRequest(bookingRequestId: BookingRequest["id"]): Promise<void>;
}

export function bookingRequestService(): BookingRequestService {
  const { toastError } = toastsWithIntl(["global"]);

  const createBookingRequest: BookingRequestService["createBookingRequest"] = (
    bookingRequest,
  ) => {
    return createBookingRequestRequest(bookingRequest).catch((err) => {
      toastError("global:GENERIC_ERROR", {
        id: "generic-error",
      });
      return Promise.reject(err);
    });
  };

  const getBookingRequestByUuid: BookingRequestService["getBookingRequestByUuid"] =
    (uuid) => {
      return getBookingRequestByUuidRequest(uuid).catch((err) => {
        toastError("global:GENERIC_ERROR", {
          id: "generic-error",
        });
        return Promise.reject(err);
      });
    };

  const getPublicBookingRequestByUuid: BookingRequestService["getPublicBookingRequestByUuid"] =
    (uuid) => {
      return getPublicBookingRequestByUuidRequest(uuid).catch((err) => {
        toastError("global:GENERIC_ERROR", {
          id: "generic-error",
        });
        return Promise.reject(err);
      });
    };

  const updateBookingRequestById: BookingRequestService["updateBookingRequestById"] =
    (bookingRequestIdOrUuid, bookingRequest) => {
      return updateBookingRequestByIdRequest(
        bookingRequestIdOrUuid,
        bookingRequest,
      ).catch((err) => {
        toastError("global:GENERIC_ERROR", {
          id: "generic-error",
        });
        return Promise.reject(err);
      });
    };

  const deleteBookingRequest: BookingRequestService["deleteBookingRequest"] = (
    bookingRequestId,
  ) => {
    return deleteBookingRequestRequest(bookingRequestId).catch((err) => {
      toastError("global:GENERIC_ERROR", {
        id: "generic-error",
      });
      return Promise.reject(err);
    });
  };

  return {
    createBookingRequest,
    getBookingRequestByUuid,
    getPublicBookingRequestByUuid,
    updateBookingRequestById,
    deleteBookingRequest,
  };
}
