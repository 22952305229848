import { useLoaderData, useNavigate } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import CampaignForm from "./CampaignForm";
import { User } from "../../../../services/user/userModel";
import { campaignService } from "../../../../services/campaign/campaignService";
import { toastsWithIntl } from "../../../../services/toastService";
import { BusinessAccount } from "../../../../services/businessAccount/businessAccountModel";

const { createCampaign } = campaignService();

const CreateCampaignPage = () => {
  const navigate = useNavigate();
  const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);
  const { currentUser, businessAccount } = useLoaderData() as {
    currentUser: User;
    businessAccount: BusinessAccount;
  };

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton forcedUrl={`/app/pro/${businessAccount.id}/campaigns`} />
          <h1 className="page_title">Nouvelle campagne</h1>
        </div>
      </div>
      <div className="page-content">
        <CampaignForm
          currentUser={currentUser}
          businessAccount={businessAccount}
          onSubmit={async (values) => {
            return createCampaign(values).then(
              (campaign) => {
                toastSuccess("campaign:create.SUCCESS");
                navigate(`./../${campaign.id}`);
              },
              () => {
                toastError("campaign:create.ERROR");
              },
            );
          }}
        />
      </div>
    </div>
  );
};

export default CreateCampaignPage;
