import { object, string } from "yup";
import Dialog from "../../../components/Dialog";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import { dataService } from "../../../services/data/dataService";
import {
  checkIban,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import useReload from "../../../hooks/useReload";

function BookingProfileCreateAssociationDialog({
  onClose,
}: {
  onClose(): void;
}) {
  const reload = useReload();
  const { createAssociation } = dataService();

  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <h1 className="popup_title">Proposer une nouvelle association</h1>
        </div>
        <SxForm
          initialValues={{ labelFr: "", iban: "" }}
          onSubmit={async (values) => {
            await createAssociation(values.labelFr, values.iban);
            reload();
            return onClose();
          }}
          validationSchema={object({
            labelFr: string()
              .label("Nom de l'association")
              .transform(transformEmptyToUndefined)
              .required(),
            iban: string()
              .label("IBAN")
              .transform(transformEmptyToUndefined)
              .test("checkIbanFormat", "IBAN invalide", checkIban),
          })}
        >
          <div className="popup_body">
            <SxField name="labelFr" />
            <SxField name="iban" />
          </div>
          <div className="popup_footer">
            <div className="ui-row">
              <button className="btn--cancel" type="button" onClick={onClose}>
                Annuler
              </button>
              <SubmitButton className="btn--1" type="submit">
                Envoyer
              </SubmitButton>
            </div>
          </div>
        </SxForm>
      </>
    </Dialog>
  );
}

export default BookingProfileCreateAssociationDialog;
