import { Fragment, ReactNode, createElement, useEffect, useState } from "react";

export const ClientOnly = ({
  children,
}: {
  children: ReactNode | (() => ReactNode);
}) => {
  const [mounted, setMounted] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setMounted(true));

  return mounted
    ? createElement(
        Fragment,
        {},
        typeof children === "function" ? children() : children,
      )
    : null;
};
