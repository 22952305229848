const NotFound = () => {
  return (
    <div className="container">
      <div className="title--70">404</div>
      <div className="title--50">Page non trouvée</div>
    </div>
  );
};

export default NotFound;
