import { Elements } from "@stripe/react-stripe-js";
import { stripe } from "../../../../services/payment/stripeConfig";
import BookingRequestPaymentForm from "./BookingRequestPaymentForm";

interface Props {
  clientSecret: string;
  amount: number;
  returnUrl: string;
}

const BookingProfilePaymentDialog = ({
  clientSecret,
  amount,
  returnUrl,
}: Props) => {
  return (
    <div className="cblocks">
      <Elements
        stripe={stripe}
        options={{
          mode: "payment",
          amount,
          currency: "eur",
          paymentMethodTypes: ["card"],
        }}
      >
        <BookingRequestPaymentForm
          clientSecret={clientSecret}
          returnUrl={returnUrl}
        />
      </Elements>
    </div>
  );
};

export default BookingProfilePaymentDialog;
