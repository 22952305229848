import { NavLink } from "react-router-dom";
import Sidebar from "./Sidebar";
import { User } from "../../services/user/userModel";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import Icon from "../Icon";

function PrivateSidebar({
  onCloseMenu,
  loggedUser,
  bookingProfile,
}: {
  onCloseMenu?: () => void;
  loggedUser: User;
  bookingProfile: BookingProfile;
}) {
  const firstBusinessAccount = loggedUser.businessAccounts?.[0];

  return (
    <Sidebar onCloseMenu={onCloseMenu} loggedUser={loggedUser}>
      <nav className="sidebar_nav" onClick={onCloseMenu}>
        <NavLink className="nav_item" to="dashboard">
          <div className="lrow --gap--xs --center--v">
            <Icon name="dashboard" />
            Mon tableau de bord
          </div>
        </NavLink>
        <NavLink className="nav_item" to="my-account">
          <div className="lrow --gap--xs --center--v">
            <Icon name="user" />
            Mon compte
          </div>
        </NavLink>
        <NavLink className="nav_item" to="profiles/tisio">
          <div className="lrow --gap--xs --center--v">
            <Icon name="id" />
            Mon profil Tisio
          </div>
        </NavLink>
        <NavLink className="nav_item" to="my-calendar">
          <div className="lrow --gap--xs --center--v">
            <Icon name="calendar" />
            Mon calendrier
            {bookingProfile.published && !loggedUser.completedOnboarding && (
              <div className="dot --warning --pos--r" />
            )}
          </div>
        </NavLink>
        <NavLink className="nav_item" to="bookings">
          <div className="lrow --gap--xs --center--v">
            <Icon name="planning" />
            Mes sollicitations et RDVs
          </div>
        </NavLink>
        <NavLink className="nav_item" to="e-wallet">
          <div className="lrow --gap--xs --center--v">
            <Icon name="wallet" />
            Crédits &amp; bons d'achat
          </div>
        </NavLink>
        {firstBusinessAccount && (
          <div className="sidebar_block">
            <div className="sidebar_block_title">Abonnement PRO</div>
            {firstBusinessAccount.BusinessAccountUser?.admin && (
              <NavLink
                className="nav_item"
                to={`pro/${firstBusinessAccount.id}/manage`}
              >
                <div className="lrow --gap--xs --center--v">
                  <Icon name="pro" />
                  Mon compte pro
                </div>
              </NavLink>
            )}
            <NavLink
              className="nav_item"
              to={`pro/${firstBusinessAccount.id}/campaigns`}
            >
              <div className="lrow --gap--xs --center--v">
                <Icon name="campaigns" />
                Mes campagnes
              </div>
            </NavLink>
          </div>
        )}
        <div className="sidebar_section">
          <NavLink
            className="btn--2 --l --btn--block --rounded"
            to="/app/profiles-list"
          >
            <Icon name="search" />
            Rechercher un profil
          </NavLink>
        </div>
      </nav>
    </Sidebar>
  );
}

export default PrivateSidebar;
