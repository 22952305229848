import { array, object } from "yup";
import SxForm from "../../../forms/SxForm";
import { User } from "../../../services/user/userModel";
import { string } from "yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import { userService } from "../../../services/user/userService";
import { useLoaderData } from "react-router-dom";
import useReload from "../../../hooks/useReload";
import { Country } from "../../../services/country/countryModel";
import { useMemo } from "react";
import { orderByField } from "../../../react-helpers/array";
import SxFileInput from "../../../forms/fields/SxFileInput";
import { readFile } from "../../../react-helpers/files";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import {
  letterIncludingAccentsRegex,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";
import { authService } from "../../../services/auth/authService";

const UserIdentityForm = () => {
  const { loggedUser, countries } = useLoaderData() as {
    loggedUser: User;
    countries: Country[];
  };
  const { logout } = authService();
  const { updateUserById, sendDeleteUserRequest } = userService();
  const reload = useReload();
  const { confirm } = useConfirmationWithIntl("accounts");

  const mappedCountries = useMemo(() => {
    return countries.sort(orderByField("countryName")).map((country) => ({
      value: country.countryCode,
      label: country.countryName,
    }));
  }, [countries]);

  return (
    <SxForm
      initialValues={{
        lastname: loggedUser.lastname,
        firstname: loggedUser.firstname,
        tel: loggedUser.tel,
        city: loggedUser.city,
        countryCode: loggedUser.countryCode,
        profilePicture: loggedUser.profilePicture
          ? [loggedUser.profilePicture]
          : [],
      }}
      onSubmit={async (values) => {
        let profilePicture = loggedUser.profilePicture;

        if (values.profilePicture && values.profilePicture.length > 0) {
          const profilePictureFile = values.profilePicture[0];
          // If the picture is an url, we keep it (undefined will ignore it in the backend)
          if (
            typeof profilePictureFile === "string" &&
            /^htt(s)?:\/\/.+/.test(profilePictureFile)
          ) {
            profilePicture = loggedUser.profilePicture;
          } else if (typeof profilePictureFile === "object") {
            try {
              // We try to read the file to an url
              profilePicture = await readFile(
                values.profilePicture[0] as unknown as File,
              );
            } catch {
              // Else as a fallback we do nothing
              profilePicture = loggedUser.profilePicture;
            }
          }
        } else {
          profilePicture = null;
        }

        return updateUserById({
          ...values,
          profilePicture,
          id: loggedUser.id,
        }).then(() => reload());
      }}
      validationSchema={object({
        profilePicture: array().label("Photo de profil"),
        firstname: string()
          .label("Prénom")
          .required()
          .matches(
            letterIncludingAccentsRegex,
            "Le prénom ne doit contenir que des lettres",
          )
          .min(2)
          .trim(),
        lastname: string()
          .label("Nom")
          .required()
          .matches(
            letterIncludingAccentsRegex,
            "Le nom ne doit contenir que des lettres",
          )
          .min(2)
          .trim(),
        tel: string()
          .label("Numéro de téléphone")
          .phoneNumber("Le numéro de téléphone est invalide")
          .transform(transformEmptyToUndefined)
          .required("Le numéro de téléphone est requis"),
        city: string().label("Ville").nullable(),
        countryCode: string().label("Pays").nullable(),
      })}
    >
      <div className="grid--1a-2">
        <div>
          <label htmlFor="" className="field-label">
            Photo de profil
          </label>
          {/* TODO : inclure la prévisu dans le composant, après spec/design des différents cas possibles (photo, pdf, etc...) */}
          <SxFileInput name="profilePicture" className="--profile-pic">
            {(files) => (
              <canvas
                width="1"
                height="1"
                style={{
                  backgroundImage: `url(${typeof files[0] === "string" ? files[0] : URL.createObjectURL(files[0])})`,
                }}
              />
            )}
          </SxFileInput>
          <div className="field-tip">
            La photo n’est pas obligatoire, mais elle peut considérablement
            augmenter vos chances d’être sollicité(e) !
          </div>
        </div>
        <div>
          <div className="grid--2">
            <div>
              <div className="form-block">
                <label htmlFor="" className="field-label">
                  Adresse email
                </label>
                <input
                  className="input"
                  type="text"
                  disabled={true}
                  value={loggedUser.email}
                />
              </div>
              <SxField name="lastname" data-testid="user-identity-lastname" />
              <SxField name="firstname" data-testid="user-identity-firstname" />
            </div>
            <div>
              <div className="form-block">
                <SxField name="tel" data-testid="user-identity-tel" />
                <div className="field-tip">
                  Ce numéro ne sera utilisé que pour vous envoyer les
                  notifications de rendez-vous par SMS
                </div>
              </div>
              <SxField name="city" data-testid="user-identity-city" />
              <SxField
                name="countryCode"
                as="autocomplete"
                options={mappedCountries}
                placeholder={"Sélectionnez un pays"}
                data-testid="user-identity-country-code"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="lblock --txt--right">
        <button
          className="link--danger --cta --s"
          type="button"
          onClick={() => {
            confirm(
              "delete-request.CONFIRM",
              async () => {
                await sendDeleteUserRequest();
                await logout();
              },
              true,
            );
          }}
        >
          Demander la suppression de mon compte
        </button>
      </div>
      <div className="form_footer">
        <SubmitButton
          className="btn"
          type="submit"
          data-testid="user-identity-submit"
        >
          Enregistrer
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default UserIdentityForm;
