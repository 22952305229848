import { t } from "i18next";
import { cx } from "../../../../react-helpers/css";
import { Campaign } from "../../../../services/campaign/campaignModel";

function CampaignStatusChip({ campaign }: { campaign: Campaign }) {
  return (
    <span
      className={cx([
        "chip",
        campaign.status === "DRAFT" && "--draft",
        campaign.status === "ACTIVE" && "--active",
        campaign.status === "SUSPENDED" && "--suspended",
        campaign.status === "CLOSED" && "--closed",
      ])}
    >
      {t(
        `campaign:status.${
          campaign.status === "ACTIVE" && campaign.endDate
            ? new Date(campaign.endDate) > new Date()
              ? "ACTIVE_ACTIVE"
              : "ACTIVE_ENDED"
            : campaign.status
        }`,
      )}
    </span>
  );
}

export default CampaignStatusChip;
