import { useLoaderData } from "react-router-dom";
import CalendarWidgetComponent from "./calendarWidgetComponent/CalendarWidgetComponent";
import { User } from "../../services/user/userModel";
import BackButton from "../../components/BackButton";
import BookingProfileWidgetComponent from "./BookingProfileWidgetComponent";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import ProfileSearch from "../../components/ProfileSearch";
import Icon from "../../components/Icon";
import { BookingRequest } from "../../services/bookingRequest/bookingRequestModel";
import BookingRequestWidgets from "./BookingRequestWidgetsComponent";
import SupportWidgetComponent from "./SupportWidgetComponent";
import StepByStepOnBoardingWidget from "./StepByStepOnBoardingWidget";

const UserDashboardPage = () => {
  const { loggedUser, tisioProfile, dataForUserDashboard } =
    useLoaderData() as {
      loggedUser: User;
      tisioProfile?: BookingProfile;
      dataForUserDashboard: {
        bookingRequestsToCome: BookingRequest[];
        bookingRequestsToClose: BookingRequest[];
        nbUnbookedBookingRequests: number;
      };
    };

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton />
          <div className="page_title">Bienvenue sur votre espace Tisio</div>
        </div>
      </div>
      <div className="page-content lblocks">
        <StepByStepOnBoardingWidget user={loggedUser} profile={tisioProfile} />
        <div className="dashboard_grid">
          <div className="lblocks">
            <BookingProfileWidgetComponent
              loggedUser={loggedUser}
              profile={tisioProfile}
            />
            {!loggedUser.completedOnboarding && (
              <CalendarWidgetComponent loggedUser={loggedUser} />
            )}
            <SupportWidgetComponent />
          </div>
          <div className="lblocks">
            <div className="dashboard_welcome-block">
              <Icon name="dashboard" />
              <h2 className="welcome_title">
                Bienvenue sur votre tableau de bord Tisio !
              </h2>
              <p className="body--30">
                Retrouvez ici vos rendez-vous à venir, vos sollicitations
                envoyées et reçues, et autres informations importantes pour la
                gestion de votre compte.
              </p>
              <div className="section container --wrap">
                <ProfileSearch />
              </div>
            </div>
            <BookingRequestWidgets
              bookingRequestsToCome={dataForUserDashboard.bookingRequestsToCome}
              bookingRequestsToClose={
                dataForUserDashboard.bookingRequestsToClose
              }
              nbUnbookedBookingRequests={
                dataForUserDashboard.nbUnbookedBookingRequests
              }
              loggedUserId={loggedUser.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDashboardPage;
