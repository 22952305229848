import CalendarWidgetComponent from "../dashboard/calendarWidgetComponent/CalendarWidgetComponent";
import { User } from "../../services/user/userModel";
import { useLoaderData } from "react-router-dom";
import BackButton from "../../components/BackButton";

function UserCalendarPage() {
  const { loggedUser } = useLoaderData() as {
    loggedUser: User;
  };
  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton />
          <h1 className="page_title">Votre calendrier</h1>
        </div>
      </div>
      <div className="page-content">
        <div className="grid--1-2">
          <CalendarWidgetComponent
            loggedUser={loggedUser}
            displayTitle={false}
          />
        </div>
      </div>
    </div>
  );
}

export default UserCalendarPage;
