import { Outlet, useLoaderData } from "react-router-dom";
import Header from "./Header";
import { cx } from "../../react-helpers/css";
import SiteFooter from "./SiteFooter";
import { useState } from "react";
import Sidebar from "./Sidebar";
import { User } from "../../services/user/userModel";
import ScrollToTopOnNav from "../utilities/ScrollToTopOnNav";

function SiteLayout({ className }: { className?: string }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { loggedUser } = useLoaderData() as {
    loggedUser?: User | null;
  };
  return (
    <div
      className={cx([
        "app-layout --scroll",
        className,
        isMenuOpened && "--menu-opened",
      ])}
    >
      <div className="screen-overlay" onClick={() => setIsMenuOpened(false)} />
      <Header
        onOpenMenu={() => setIsMenuOpened(true)}
        loggedUser={loggedUser}
        area={"public"}
      />
      <Sidebar
        area="public"
        className="--lp_sidebar --mobile"
        onCloseMenu={() => setIsMenuOpened(false)}
        loggedUser={loggedUser}
      />
      <div className="layout_content">
        <ScrollToTopOnNav />
        <Outlet />
      </div>
      <SiteFooter />
    </div>
  );
}

export default SiteLayout;
