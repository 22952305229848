import { Link } from "react-router-dom";
import Icon from "../../components/Icon";
import { User } from "../../services/user/userModel";
import { cx } from "../../react-helpers/css";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import OpenCalIframeButton from "./calendarWidgetComponent/OpenCalIframeButton";

function StepByStepOnBoardingWidget({
  user,
  profile,
}: {
  user: User;
  profile: BookingProfile | undefined;
}) {
  return profile?.isComplete && user.completedOnboarding ? (
    <></>
  ) : (
    <div className="cblocks">
      <h2 className="section_title">Bien commencer</h2>
      <div className="step-by-step">
        <Link
          to="/app/my-account"
          className={cx(["step", user.profilePicture && "--done"])}
        >
          <div className="step_content">
            <div className="step_number">
              {user.profilePicture ? (
                <Icon name="success" className="--white" />
              ) : (
                "1"
              )}
            </div>
            <div>Ajoutez votre photo de profil</div>
          </div>
        </Link>
        <Link
          className={cx(["step", profile?.isComplete && "--done"])}
          to="/app/profiles/tisio"
        >
          <div className="step_content">
            <div className="step_number">
              {profile?.isComplete ? (
                <Icon name="success" className="--white" />
              ) : (
                "2"
              )}
            </div>
            <div>Complétez votre profil</div>
          </div>
        </Link>
        <OpenCalIframeButton
          loggedUser={user}
          className={cx([
            "step link --soft",
            user.completedOnboarding && "--done",
          ])}
        >
          <div className="step_content">
            {" "}
            <div className="step_number">
              {user.completedOnboarding ? (
                <Icon name="success" className="--white" />
              ) : (
                "3"
              )}
            </div>
            <div>Paramétrez votre calendrier</div>
          </div>
        </OpenCalIframeButton>
      </div>
    </div>
  );
}

export default StepByStepOnBoardingWidget;
