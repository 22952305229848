import { Link, useNavigate } from "react-router-dom";
import SxForm from "../../../forms/SxForm";
import { object, string } from "yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import BackButton from "../../../components/BackButton";
import { LoginData } from "../../../services/auth/authModel";
import { authService } from "../../../services/auth/authService";
import useReload from "../../../hooks/useReload";
import { AxiosError } from "axios";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";

function LoginForm({
  onRedirectToRegister,
  initialValues,
  redirectTo,
}: {
  onRedirectToRegister?: () => void;
  initialValues?: Pick<LoginData, "email">;
  redirectTo?: string;
}) {
  const auth = authService();
  const reload = useReload();
  const navigate = useNavigate();
  const { confirm } = useConfirmationWithIntl(["auth"]);

  return (
    <SxForm
      initialValues={{
        email: "",
        password: "",
        ...initialValues,
      }}
      onSubmit={(loginData) => {
        return auth.login(loginData).then(
          () => {
            if (redirectTo) {
              navigate(redirectTo);
            } else {
              reload();
            }
          },
          (err: AxiosError) => {
            if (err.response?.status === 424) {
              confirm(
                "auth:create-password.UPDATE_PASSWORD_REQUIRED",
                true,
                false,
                {
                  title: "Vous devez mettre à jour votre mot de passe",
                  confirmText: "C'est compris !",
                },
              );
            }
          },
        );
      }}
      validationSchema={object({
        email: string().label("Adresse email").required(),
        password: string().label("Mot de passe").required(),
      })}
    >
      <SxField as="email" name="email" placeholder="me@mail.com" />
      <SxField as="password" name="password" placeholder="••••••••••••" />
      <div className="--txt--right">
        <Link className="link --xs" to="/lost-password">
          J'ai oublié mon mot de passe
        </Link>
      </div>

      <div className="form_footer">
        <SubmitButton className="submit-btn" type="submit">
          Connexion
        </SubmitButton>
        <div className="cblock">
          {onRedirectToRegister ? (
            <button
              type="button"
              className="redirect-link"
              onClick={onRedirectToRegister}
            >
              Je n'ai pas encore de compte
            </button>
          ) : (
            <Link className="redirect-link" to="/register">
              Je n'ai pas encore de compte
            </Link>
          )}
        </div>
        {!onRedirectToRegister && (
          <div className="lblock">
            <BackButton />
          </div>
        )}
      </div>
    </SxForm>
  );
}

export default LoginForm;
