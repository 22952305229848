import { authService } from "../auth/authService";
import { toastsWithIntl } from "../toastService";
import {
  getVouchersByUserIdRequest,
  getWalletTransactionsByUserIdRequest,
  setPaymentIntentStatusRequest,
  cashoutRequest,
} from "./paymentApi";
import { Voucher, WalletTransaction } from "./paymentModel";

interface PaymentService {
  setPaymentIntentStatus(
    bookingRequestId: number,
    status: string,
  ): Promise<void>;
  getVouchersForCurrentUser(): Promise<Voucher[]>;
  getWalletTransactionsForCurrentUser(): Promise<WalletTransaction[]>;
  cashoutForCurrentUser(): Promise<void>;
}

export function paymentService(): PaymentService {
  const { toastError } = toastsWithIntl(["global"]);
  const { getLoggedUserId } = authService();

  const setPaymentIntentStatus: PaymentService["setPaymentIntentStatus"] = (
    bookingRequestId,
    status,
  ) => {
    return setPaymentIntentStatusRequest(bookingRequestId, status).catch(
      (err) => {
        toastError("global:GENERIC_ERROR");
        return Promise.reject(err);
      },
    );
  };

  const getVouchersForCurrentUser: PaymentService["getVouchersForCurrentUser"] =
    () => {
      return getVouchersByUserIdRequest(getLoggedUserId()!).catch((err) => {
        toastError("global:GENERIC_ERROR");
        return Promise.reject(err);
      });
    };

  const getWalletTransactionsForCurrentUser: PaymentService["getWalletTransactionsForCurrentUser"] =
    () => {
      return getWalletTransactionsByUserIdRequest(getLoggedUserId()!).catch(
        (err) => {
          toastError("global:GENERIC_ERROR");
          return Promise.reject(err);
        },
      );
    };

  const cashoutForCurrentUser: PaymentService["cashoutForCurrentUser"] = () => {
    return cashoutRequest(getLoggedUserId()!).catch((err) => {
      toastError("global:GENERIC_ERROR");
      return Promise.reject(err);
    });
  };

  return {
    setPaymentIntentStatus,
    getVouchersForCurrentUser,
    getWalletTransactionsForCurrentUser,
    cashoutForCurrentUser,
  };
}
