import CreatePasswordForm from "./CreatePasswordForm";

function CreatePasswordPage() {
  return (
    <>
      <div className="auth-card">
        <div className="card_head">
          <h1 className="card_title">Finalisez la création de votre compte</h1>
          <p>en créant votre mot de passe</p>
        </div>
        <div className="card_body">
          <CreatePasswordForm />
        </div>
      </div>
    </>
  );
}

export default CreatePasswordPage;
