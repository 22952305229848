import { NavLink } from "react-router-dom";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import { TISIO_FEE_PERCENTAGE } from "../../services/config";
import ProfilePic from "../../components/profileDisplay/ProfilePic";
import { authService } from "../../services/auth/authService";
import Icon from "../Icon";
import DisplayPrice from "../DisplayPrice";

const BookingProfileCard = ({
  bookingProfile,
  minimal = false,
}: {
  bookingProfile: BookingProfile;
  minimal?: boolean;
}) => {
  const { isLoggedIn } = authService();
  return (
    <NavLink
      to={`${isLoggedIn() ? "/app" : ""}/c/${bookingProfile.community.slug}/${bookingProfile.user.username}`}
      className="booking-profile-list_item"
    >
      <div className="profile_row">
        <ProfilePic src={bookingProfile.user.profilePicture} />
        <div className="profile_info-col">
          <div>
            <div className="lrow --distribute--h --center--v">
              <div className="profile_name">
                {bookingProfile.user.displayName}
              </div>
              {bookingProfile.probono && <div className="chip">100% don</div>}
            </div>
            {bookingProfile.linkedin && (
              <button
                className="link profile_linkedin-link"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(bookingProfile.linkedin!, "_blank");
                }}
              >
                <Icon name="linkedin" className="--linkedin-blue" />
              </button>
            )}
            {/* TODO: create component to display rating */}
            {/* this tests are because the component is used in bookingRequest detail page, so we need to test if the bookingProfile is the sender or the recipient */}
            {/* the back-end is responsible for returning the correct data according to the role of the profile displayed */}
            {bookingProfile.ratingAsRecipient !== null &&
            bookingProfile.ratingAsRecipient !== undefined ? (
              <div className="rating-display">
                <Icon name="star" />{" "}
                <span>
                  <strong>
                    {Math.round(bookingProfile.ratingAsRecipient * 10) / 10}
                  </strong>
                  /5
                </span>
              </div>
            ) : (
              bookingProfile.ratingAsSender !== null &&
              bookingProfile.ratingAsSender !== undefined && (
                <div className="rating-display">
                  <Icon name="star" />{" "}
                  <span>
                    <strong>
                      {" "}
                      {Math.round(bookingProfile.ratingAsSender * 10) / 10}
                    </strong>
                    /5
                  </span>
                </div>
              )
            )}
          </div>

          <div>
            <div className="profile_position">{bookingProfile.position}</div>
            <div className="profile_company-name">
              {bookingProfile.companyName}
              {bookingProfile.companyActivity && (
                <small> ({bookingProfile.companyActivity.labelFr})</small>
              )}
            </div>
          </div>
          <div className="profile_tags">
            <div className="ui-row --s">
              {bookingProfile.expertises.map((expertise) => (
                <span key={expertise.id} className="tag">
                  {expertise.labelFr}
                </span>
              ))}
            </div>
          </div>

          {!minimal && (
            <div className="profile_description">
              <div
                className="description_preview"
                dangerouslySetInnerHTML={{
                  __html:
                    bookingProfile.description ??
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh est, porta a ipsum eu, tempus euismod felis. Sed eu lobortis neque.", // TODO remove temp lorem
                }}
              />
            </div>
          )}
        </div>
      </div>
      {!minimal && (
        <div className="profile_price-display">
          <DisplayPrice
            amount={
              bookingProfile.price *
              (1 + parseInt(TISIO_FEE_PERCENTAGE, 10) / 100)
            }
            className="price"
            addon={<span className="timeslot">/ 30 minutes</span>}
          />
        </div>
      )}
    </NavLink>
  );
};

export default BookingProfileCard;
