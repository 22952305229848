import { Link, useNavigate } from "react-router-dom";
import { Booking } from "../../../services/bookingRequest/bookingRequestModel";
import { CAL_BASE_URL } from "../../../services/config";
import { formatDate } from "../../../react-helpers/date";

const CampaignConfirmationDialogContent = ({
  booking,
  isLoggedIn,
}: {
  booking: Booking;
  isLoggedIn: boolean;
}) => {
  const navigate = useNavigate();
  const visioLink = `${CAL_BASE_URL}/video/${booking?.uid}`;

  return (
    <>
      <div className="popup_head">
        <h1 className="popup_title">Confirmation de l'interview</h1>
      </div>
      <div className="popup_body lblocks">
        <div className="info --success">
          <div>
            Votre visio est confirmée le
            <div>
              <strong>{formatDate(booking.startTime, "PPp")}</strong>
            </div>
          </div>
          <div>
            lien de la visio:
            <br />
            <Link to={visioLink}>{visioLink}</Link>
          </div>
        </div>
        <div className="info">
          Vous allez recevoir un mail de confirmation contenant toutes les
          informations nécessaires.
        </div>

        {!isLoggedIn && (
          <div className="card">
            <div className="card_body">
              <div className="--txt--center cblocks">
                <div className="section_title">
                  Vous souhaitez recevoir d’autres d’opportunités
                  <br />
                  comme celle-ci ?
                </div>

                <div className="ui-row --center">
                  <Link className="btn--2" to="/register">
                    Oui, je crée mon profil Tisio
                  </Link>
                  <button
                    className="btn--cancel"
                    type="button"
                    onClick={() => navigate("/")}
                  >
                    Non merci
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CampaignConfirmationDialogContent;
