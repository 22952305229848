import ProfilePic from "../../../../components/profileDisplay/ProfilePic";
import { BookingProfile } from "../../../../services/bookingProfile/bookingProfileModel";
import { User } from "../../../../services/user/userModel";
import BookingProfileStatuses from "../BookingProfileStatuses";

import ProfilePosition from "../../../../components/profileDisplay/ProfilePosition";
import ProfileTags from "../../../../components/profileDisplay/ProfileTags";
import ProfileDescription from "../../../../components/profileDisplay/ProfileDescription";
import ProfilePrice from "../../../../components/profileDisplay/ProfilePrice";
import LinkedinLink from "../../../../components/profileDisplay/LinkedinLink";
import Icon from "../../../../components/Icon";

import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";

function ProfileDisplay({
  user,
  profile,
  publicDisplay = false,
}: {
  user: User;
  profile: BookingProfile;
  publicDisplay?: boolean;
}) {
  return (
    <div className="profile-display">
      <div className="profile_row">
        {publicDisplay ? (
          <ProfilePic src={user.profilePicture} />
        ) : (
          <Link to="/app/my-account" target="_blank" className="link --soft">
            <ProfilePic
              src={user.profilePicture}
              className={!user.profilePicture ? "--editable" : ""}
            />
          </Link>
        )}

        <div className="profile_info-col">
          <div>
            <div className="lrow --center--v --distribute --gap--v--xxs">
              <div className="profile_name">
                {publicDisplay
                  ? user.displayName
                  : `${user.firstname} ${user.lastname}`}
              </div>
              <div className="ui-row --stretch">
                <BookingProfileStatuses profile={profile} />
                {publicDisplay && (
                  <>
                    {profile.association?.validated && (
                      <span className="status --dark">
                        {profile.association.labelFr}
                        <span
                          data-tooltip-id="asso-tooltip"
                          data-tooltip-html={
                            profile.probono
                              ? `Ce profil reverse l’ensemble<br/>de ses gains à l’association<br/>${profile.association.labelFr}`
                              : `10% des gains de ce profil sont<br/>reversés à l’association<br/>${profile.association.labelFr}`
                          }
                        >
                          <Icon name="info" />
                        </span>
                      </span>
                    )}
                    <Tooltip id="asso-tooltip" />
                  </>
                )}
              </div>
            </div>

            {/* TODO: create component to display rating */}
            {profile.ratingAsRecipient !== null &&
              profile.ratingAsRecipient !== undefined && (
                <div className="rating-display">
                  <Icon name="star" />
                  <span>
                    <strong>
                      {Math.round(profile.ratingAsRecipient * 10) / 10}
                    </strong>
                    /5
                  </span>
                </div>
              )}
            {/* TODO: add nb opinion*/}
            <LinkedinLink profile={profile} />
          </div>

          <ProfilePosition profile={profile} />

          <ProfileTags profile={profile} />

          <ProfileDescription
            profile={profile}
            publicDescription={publicDisplay}
          />
        </div>
      </div>

      <ProfilePrice profile={profile} publicPrice={true} />
    </div>
  );
}

export default ProfileDisplay;
