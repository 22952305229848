import { useContext, useMemo, useState } from "react";
import { FormikContextType, FormikContext } from "formik";

type SubmitButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "onClick"
> & {
  onClick?(ev: MouseEvent): void | Promise<any>;
};

const SubmitButton = (props: SubmitButtonProps) => {
  const formik: FormikContextType<any> | undefined = useContext(FormikContext);

  const [isHookedSubmitting, setIsHookedSubmitting] = useState(false);

  const isSubmitting = useMemo(
    () => (formik?.isSubmitting && !props.onClick) || isHookedSubmitting,
    [formik?.isSubmitting, isHookedSubmitting, props.onClick],
  );

  return (
    <button
      type={props.onClick ? "button" : "submit"}
      {...props}
      disabled={isSubmitting || props.disabled}
      onClick={(ev) => {
        if (props.onClick) {
          ev.stopPropagation();
          const onSubmit = Promise.resolve(props.onClick(ev as any));

          setIsHookedSubmitting(true);
          onSubmit
            .catch((e) => Promise.reject(e))
            .finally(() => setIsHookedSubmitting(false));
        }
      }}
    >
      {isSubmitting ? "Chargement..." : props.children}
    </button>
  );
};

export default SubmitButton;
