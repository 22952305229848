import Mime from "mime";

/**
 * This method is used to handle wildcards
 * @param type
 * @param mime
 */
export function isMimeTypeApplicableToType(
  type: string,
  mime: string,
): boolean {
  return type.endsWith("/*")
    ? mime.toLowerCase().startsWith(type.slice(0, -2).toLowerCase())
    : mime.toLowerCase() === type.toLowerCase();
}

/**
 * Check if a mime type is accepted in an accept attribute (See https://html.spec.whatwg.org/multipage/input.html#file-upload-state-(type=file))
 * @param accept
 * @param mime
 */
export function isMimeTypeAccepted(accept: string, mime: string): boolean {
  const acceptedTypes = accept.split(",").map((at) => at.trim());

  const acceptedMimeTypes = acceptedTypes
    .map((at) => (at.startsWith(".") ? Mime.getType(at) : at))
    .filter((at): at is string => at !== null);

  return acceptedMimeTypes.some((at) => isMimeTypeApplicableToType(at, mime));
}

export function readFile<
  Result extends As extends "binary" ? ArrayBuffer : string,
  const As extends "data" | "plain" | "binary" = "data",
>(file: File, as: As = "data" as As): Promise<Result> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as Result);
    };
    reader.onerror = reject;

    if (as === "data") reader.readAsDataURL(file);
    else if (as === "plain") reader.readAsText(file);
    else if (as === "binary") reader.readAsArrayBuffer(file);
  });
}

export function downloadFile(file: Blob, name: string): void {
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
}
