const ConformityGuidePage = () => {
  return (
    <div className="container page-content">
      <div className="page_head">
        <h1 className="page_title">Guide de conformité des échanges visios</h1>
      </div>
      <div className="page-content lblocks">
        <div className="cblocks --s">
          <h2 className="section_title">Introduction</h2>
          <p>
            Ce guide de conformité des échanges visios & informations
            confidentielles vise à vous fournir des informations supplémentaires
            sur les exigences auxquelles vous devez adhérer avant, pendant et
            après une visio avec un utilisateur Tisio.
            <br />
            Veuillez vous assurer d'avoir lu et compris toutes les précisions
            apportées dans ce guide avant votre interaction.
          </p>
          <p>
            Veuillez noter : Rien dans ce guide ne constitue un avis juridique
            ou ne devrait être utilisé par vous de quelque manière que ce soit.
            Si vous avez des doutes concernant vos droits, obligations ou
            devoirs liés à votre participation à toute interaction, vous devriez
            obtenir un avis juridique indépendant.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            1- Les profils Tisio doivent respecter leurs obligations vis-à-vis
            de leur employeur actuel
          </h3>
          <ul>
            <li>
              Vous ne devez pas fournir d'informations confidentielles sur votre
              employeur actuel.
            </li>
            <li>
              Vous certifiez que votre participation en tant qu'individu est
              autorisée et n'entre pas en contradiction avec vos obligations
              contractuelles spécifiques.
            </li>
            <li>
              Vous devez refuser les échanges en visio qui enfreindrait une de
              vos obligations envers une tierce partie (par exemple, une
              personne, un employeur, un ancien employeur ou autre).
            </li>
          </ul>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            2- Les profils Tisio ne doivent pas révéler d'informations
            matérielles non publiques ou confidentielles/propriétaires
          </h3>
          <p>
            Vous ne devez jamais divulguer d'informations matérielles non
            publiques ou confidentielles/propriétaires.
          </p>
        </div>

        <div className="cblocks --s">
          <h4 className="block_title">
            Les informations non publiques importantes peuvent être définies
            comme des informations :
          </h4>
          <ul>
            <li>qui ne sont pas généralement connues du public ;</li>
            <li>
              qui concernent une sécurité spécifique cotée en bourse (dette ou
              capitaux propres) ;
            </li>
            <li>
              qu'un investisseur raisonnable considérerait comme importantes
              dans le cadre d'une décision d'investissement.
            </li>
            <li>
              Si elles étaient rendues publiques, elles pourraient
              vraisemblablement avoir un impact sur le prix d'une sécurité.
            </li>
          </ul>
          <p>voir plus bas des exemples, et comment les traiter</p>
        </div>

        <div className="cblocks --s">
          <h4 className="block_title">
            En règle générale, l'information sera confidentielle et/ou
            propriétaire si :
          </h4>
          <ul>
            <li>vous l'avez reçue en confiance ;</li>
            <li>
              vous avez accepté par écrit ou verbalement de la garder
              confidentielle ; ou
            </li>
            <li>elle concerne votre employeur.</li>
          </ul>
          <p>voir plus bas des exemples, et comment les traiter</p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            3- Votre participation à une visio Tisio ne doit créer aucun conflit
            d'intérêts
          </h3>
          <p>
            Vous devez refuser une visio pour laquelle vous êtes sollicité(e)
            si, selon vos connaissances, elle pourrait créer un conflit
            d'intérêts basé sur :
          </p>
          <ul>
            <li>la portée du projet ; ou</li>
            <li>l'identité de l’utilisateur qui vous sollicite.</li>
          </ul>
          <p>
            Vous devez refuser toute visio Tisio qui pourrait entraîner un
            conflit professionnel, réglementaire ou éthique, ou enfreindre tout
            accord ou obligation envers une tierce partie.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            4- Les discussions entre un profil et un utilisateur Tisio doivent
            rester confidentielles
          </h3>
          <p>
            Vous ne devez jamais divulguer ce qui suit à une tierce partie :
          </p>
          <ul>
            <li>l'existence ou le sujet des échanges de la visio ;</li>
            <li>
              l'identité et les détails concernant l’utilisateur Tisio vous
              ayant sollicité ; et
            </li>
            <li>
              toute autre information confidentielle et/ou propriétaire ou
              propriété intellectuelle de Tisio ou de son utilisateur (y compris
              les informations relatives à tout investissement, échange et/ou
              décision commerciale réelle ou potentielle du client).
            </li>
          </ul>
          <p>
            Vous ne devez jamais utiliser ou divulguer les informations d'un
            utilisateur Tisio vous ayant sollicité à votre avantage personnel, y
            compris toute opération sur des titres qui pourraient en être
            affectés.
          </p>
        </div>

        <div className="cblocks --s">
          <h2 className="section_title">
            Accord de confidentialité relatif aux informations non publiques
            importantes
          </h2>
          <h3 className="paragraph_title">Définition</h3>
          <p>
            Les informations non publiques importantes peuvent être définies
            comme des informations :
          </p>
          <ul>
            <li>qui ne sont pas généralement connues du public ;</li>
            <li>
              qui concernent une sécurité spécifique cotée en bourse (dette ou
              capitaux propres) ; et qu'un investisseur raisonnable
              considérerait comme importantes dans le cadre d'une décision
              d'investissement.
            </li>
            <li>
              Si elles étaient rendues publiques, elles pourraient
              vraisemblablement avoir un impact sur le prix d'une sécurité.
            </li>
          </ul>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            Pourrait-il s'agir d’informations non publiques importantes soumises
            à accord de confidentialité ?
          </h3>
          <p>
            Voici une liste non exhaustive de situations pouvant donner lieu à
            des informations non publiques soumises à accord de confidentialité.
            Vous ne devez partager aucune information sur ces sujets qui ne soit
            pas largement accessible au public :
          </p>
          <ul>
            <li>
              Changements de contrôle, y compris fusions, scissions et scissions
            </li>
            <li>
              Vente, cession, destruction ou changements de valorisation
              d'actifs majeurs
            </li>
            <li>Changements dans les bénéfices ou pertes prévus</li>
            <li>Changements de direction</li>
            <li>Changements dans les clients ou les commandes d'achat</li>
            <li>Entrée ou retrait de nouveaux marchés/territoires</li>
            <li>Activités d'un auditeur</li>
            <li>
              Changements dans le capital social et/ou les droits de dividende
            </li>
            <li>
              Mesures d'insolvabilité ou procédures d'une entreprise ou de ses
              débiteurs
            </li>
            <li>
              Litiges juridiques et réclamations en responsabilité
              civile/publique
            </li>
            <li>
              Changements dans le portefeuille de propriété intellectuelle, y
              compris nouveaux contrats de licence, brevets et marques de
              commerce
            </li>
            <li>Attribution ou résiliation de contrats clés</li>
            <li>
              Tout autre élément susceptible d'avoir un impact sur le prix du
              marché d'une sécurité cotée en bourse
            </li>
          </ul>
          <p>
            Vous ne devez donc en aucun cas divulguer des informations non
            publiques importantes soumises à accord de confidentialité lors d’un
            échange visio Tisio.{" "}
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            Informations confidentielles et propriétaires
          </h3>
          <p>
            En plus des informations non publiques importantes soumises à accord
            de confidentialité, il peut y avoir d'autres informations en votre
            possession pour lesquelles vous avez le devoir de garder une
            confidentialité, par exemple en raison d'un accord d'emploi, de
            consultation ou de rupture de contrat que vous pourriez avoir.
          </p>
        </div>

        <div className="cblocks --s">
          <h4 className="block_title">Définition</h4>
          <p>
            En règle générale, les informations seront considérées comme
            confidentielles et/ou propriétaires si :
          </p>
          <ul>
            <li>vous les avez reçues en toute confidentialité ;</li>
            <li>
              vous avez accepté par écrit ou verbalement de les garder
              confidentielles ; ou
            </li>
            <li>elles se rapportent à votre employeur.</li>
          </ul>
          <p>
            Seule vous pouvez évaluer de manière définitive si une information
            est confidentielle et/ou propriétaire, mais il est probable qu'elle
            inclura des secrets commerciaux, des listes de clients, des
            informations spécifiques sur le marketing, les ventes et les
            produits, ainsi que des détails sur les processus commerciaux et la
            stratégie d'entreprise.{" "}
          </p>
          <p>Elle n'inclura généralement pas : </p>
          <ul>
            <li>vos compétences globales</li>
            <li>
              votre expérience et vos perspectives basées sur votre carrière à
              ce jour{" "}
            </li>
            <li>des informations qui sont dans le domaine public</li>
          </ul>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            Comment traiter les informations confidentielles et/ou propriétaires
          </h3>
          <p>
            Lorsque vous participez à des interactions avec nos clients, vous
            devez limiter vos discussions aux catégories d'informations
            autorisées uniquement, à savoir : (i) vos compétences générales,
            votre expérience et vos perspectives ; et (ii) les informations
            publiques.
            <br />
            Si on vous pose une question pendant une interaction qui
            nécessiterait la divulgation d'informations confidentielles et/ou
            propriétaires, vous devez vous abstenir de répondre à cette
            question. En tant que condition d'utilisation de notre service, nos
            utilisateurs sont interdits de solliciter délibérément des
            informations confidentielles et/ou propriétaires, donc ils
            comprendront s'il y a des sujets que vous ne pouvez pas discuter.
            <br />
            Si vous avez le moindre doute quant au caractère confidentiel et/ou
            propriétaire d'une information, vous devez obtenir des
            éclaircissements de la part du propriétaire de cette information
            avant de la divulguer à un utilisateur Tisio. En l'absence
            d'indication contraire, un utilisateur Tisio supposera que vous
            étiez autorisé à divulguer toute information qu'il recevrait de
            votre part.
          </p>
          <p>
            Une autre catégorie d'informations confidentielles et/ou
            propriétaires est celle qui appartient à l’utilisateur Tisio qui
            vous sollicite. Vous devez supposer que toutes les informations que
            vous recevez de l’utilisateur Tisio qui vous sollicite sont
            confidentielles. Conformément à vos conditions d'engagement, vous
            êtes interdit de divulguer les informations confidentielles et/ou
            propriétaires de l’utilisateur Tisio à un tiers après une
            interaction. Vous ne devez pas non plus utiliser ces informations à
            votre avantage.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConformityGuidePage;
