import { baseAPI } from "../api";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { User } from "../user/userModel";
import { Voucher, WalletTransaction } from "./paymentModel";

export function setPaymentIntentStatusRequest(
  paymentIntentId: NonNullable<BookingRequest["id"]>,
  status: string,
): Promise<void> {
  return baseAPI
    .post(`/payments/${paymentIntentId}/status`, {
      paymentIntentId,
      status,
    })
    .then(({ data }) => data);
}

export function getVouchersByUserIdRequest(
  userId: User["id"],
): Promise<Voucher[]> {
  return baseAPI
    .get(`/payments/vouchers/users/${userId}`)
    .then(({ data }) => data);
}

export function getWalletTransactionsByUserIdRequest(
  userId: User["id"],
): Promise<WalletTransaction[]> {
  return baseAPI
    .get(`/payments/wallet-transactions/users/${userId}`)
    .then(({ data }) => data);
}

export function cashoutRequest(userId: User["id"]): Promise<void> {
  return baseAPI.post(`/payments/cashout/${userId}`).then(({ data }) => data);
}
