import { baseAPI } from "../api";
import { BusinessAccount, Plan } from "./businessAccountModel";

export function getBusinessAccountByIdRequest(
  businessAccountId: BusinessAccount["id"],
) {
  return baseAPI
    .get(`/business-accounts/${businessAccountId}`)
    .then(({ data }) => data);
}

export function updateBusinessAccountRequest(
  businessAccountId: BusinessAccount["id"],
  businessAccount: Partial<BusinessAccount>,
) {
  return baseAPI
    .put(`/business-accounts/${businessAccountId}`, businessAccount)
    .then(({ data }) => data);
}

export function addPaymentMethodToBusinessAccountRequest(
  businessAccountId: BusinessAccount["id"],
  paymentMethodId: string,
) {
  return baseAPI
    .post(`/business-accounts/${businessAccountId}/add-payment-method`, {
      paymentMethodId,
    })
    .then(({ data }) => data);
}

export function cancelSubscriptionRequest(
  businessAccountId: BusinessAccount["id"],
  planId: Plan["id"],
) {
  return baseAPI
    .delete(`/business-accounts/${businessAccountId}/plans/${planId}`)
    .then(({ data }) => data);
}
