import { ReactNode } from "react";
import useReload from "../../hooks/useReload";
import { authService } from "../../services/auth/authService";
import { cx } from "../../react-helpers/css";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Logo from "../../assets/img/logo-tisio-blue.png";
import { User } from "../../services/user/userModel";
import ProfilePic from "../profileDisplay/ProfilePic";

function Sidebar({
  onCloseMenu,
  area,
  children,
  className,
  loggedUser,
}: {
  onCloseMenu?: () => void;
  area?: "public" | "private";
  children?: ReactNode;
  className?: string;
  loggedUser?: User | null;
}) {
  const { logout } = authService();
  const reload = useReload();
  return (
    <div className={cx(["layout_sidebar", className])}>
      <div className="sidebar_head">
        <button
          className="close-sidebar-btn icon--close"
          onClick={onCloseMenu}
        />
        {area === "public" && (
          <Link to="/" className="logo" onClick={onCloseMenu}>
            <img src={Logo} alt="Logo Tisio" />
          </Link>
        )}

        {loggedUser && (
          <Link to="/app/my-account" className="link --soft --block">
            <div className="welcome-user-block">
              <ProfilePic
                src={loggedUser?.profilePicture}
                className={!loggedUser?.profilePicture ? "--editable" : ""}
              />
              <div>
                <p className="welcome">Bienvenue</p>
                <p className="user-name">{loggedUser?.firstname}</p>
                <button
                  className="logout-button"
                  onClick={() => {
                    void logout().then(() => {
                      reload();
                    });
                  }}
                >
                  Déconnexion
                </button>
              </div>
            </div>
          </Link>
        )}
        {area === "public" && loggedUser && (
          <div className="cblock" onClick={onCloseMenu}>
            <Link className="btn --block" to="/app">
              Mon espace <Icon name="arrow-right" />
            </Link>
          </div>
        )}
      </div>
      {area === "public" && (
        <div onClick={onCloseMenu}>
          <div className="sidebar_section">
            <Link to="/business" className="lblock btn--soft --block">
              Lancer une campagne de sollicitations
            </Link>
            <Link
              to="/profiles-list"
              className="lp_header_cta btn--soft --block"
            >
              Recherche un profil
            </Link>
          </div>

          {!loggedUser && (
            <div className="sidebar_section cblocks">
              <Link className="btn--2 --block" to="/register">
                Créer mon compte
              </Link>

              <Link className="btn--1 --block" to="/login">
                Me connecter
              </Link>
            </div>
          )}
        </div>
      )}

      {children}
    </div>
  );
}

export default Sidebar;
