import { useFormikContext } from "formik";
import { deepEqual } from "../react-helpers/any";
import { debounce } from "../react-helpers/function";
import { useCallback, useEffect, useState } from "react";

const FormAutoSubmit = ({
  minDebounce = 300,
  maxDebounce = Infinity,
}: {
  minDebounce?: number;
  maxDebounce?: number;
}): null => {
  const formik = useFormikContext();
  const [lastValues, updateState] = useState(formik.values);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const submitForm = useCallback(
    debounce(
      () => {
        return formik.submitForm();
      },
      minDebounce,
      maxDebounce,
    ),
    [],
  );

  useEffect(() => {
    const valuesEqualLastValues = deepEqual(lastValues, formik.values);

    const valuesEqualInitialValues = deepEqual(
      formik.values,
      formik.initialValues,
    );

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (!valuesEqualLastValues && !valuesEqualInitialValues && formik.isValid) {
      void submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.isValid]);

  return null;
};

export default FormAutoSubmit;
