import { useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import ValidationErrors from "../../../forms/ValidationErrors";
import SxField from "../../../forms/fields/SxField";
import {
  BookingRequest,
  BookingRequestAttendanceDeclaration,
} from "../../../services/bookingRequest/bookingRequestModel";
import { toastsWithIntl } from "../../../services/toastService";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { number, object, string } from "yup";
import {
  transformEmptyToNull,
  transformNumericStringToNumberOrNull,
} from "../../../react-helpers/yup";
import { LONG_TOAST_DURATION_IN_MS } from "../../../services/config";

const BookingClosureBookingHappenedForm = ({
  isAlreadyCompleted,
  bookingRequest,
  isCurrentUserSender,
}: {
  isAlreadyCompleted: boolean;
  bookingRequest: BookingRequest;
  isCurrentUserSender: boolean;
}) => {
  const navigate = useNavigate();
  const { toastSuccess, toastError } = toastsWithIntl([
    "global",
    "bookingRequest",
  ]);
  const { updateBookingRequestById } = bookingRequestService();

  const validationSchema = isCurrentUserSender
    ? object({
        senderRating: number()
          .transform(transformNumericStringToNumberOrNull)
          .nullable()
          .required(),
        senderComment: string()
          .label("Laissez un avis à votre interlocuteur")
          .transform(transformEmptyToNull)
          .nullable(),
        senderAdminComment: string()
          .label("Commentaire optionnel à destination de l’équipe Tisio")
          .transform(transformEmptyToNull)
          .nullable(),
      })
    : object({
        recipientRating: number()
          .transform(transformNumericStringToNumberOrNull)
          .nullable()
          .required(),
        recipientComment: string()
          .label("Laissez un avis à votre interlocuteur")
          .transform(transformEmptyToNull)
          .nullable(),
        recipientAdminComment: string()
          .label("Commentaire optionnel à destination de l’équipe Tisio")
          .transform(transformEmptyToNull)
          .nullable(),
      });

  return (
    <SxForm
      initialValues={bookingRequest}
      onSubmit={(values: Partial<BookingRequest>) => {
        return updateBookingRequestById(bookingRequest.id, {
          ...bookingRequest,
          ...validationSchema.cast(values),
          ...(isCurrentUserSender
            ? {
                senderAttendanceDeclaration:
                  BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE,
                senderClosureDate: new Date().toISOString(),
              }
            : {
                recipientAttendanceDeclaration:
                  BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE,
                recipientClosureDate: new Date().toISOString(),
              }),
        }).then(
          () => {
            if (!isCurrentUserSender) {
              toastSuccess("bookingRequest:recipient-cloture.BOTH_WHERE_HERE", {
                duration: LONG_TOAST_DURATION_IN_MS,
              });
            } else {
              toastSuccess("bookingRequest:sender-cloture.SUCCESS");
            }
            navigate(`/app/bookings/${bookingRequest.uuid}`);
          },
          () => {
            toastError("global:GENERIC_ERROR");
          },
        );
      }}
      validationSchema={validationSchema}
      disabled={isAlreadyCompleted}
    >
      <div className="lblocks">
        <div className="card">
          <div className="card_body --txt--center">
            <h2 className="section_title">Évaluez votre interlocuteur *</h2>
            <div className="lblock star-rating --center --l">
              <SxField
                name={isCurrentUserSender ? "senderRating" : "recipientRating"}
                as="radio"
                radios={[
                  { label: "★", value: "5" },
                  { label: "★", value: "4" },
                  { label: "★", value: "3" },
                  { label: "★", value: "2" },
                  { label: "★", value: "1" },
                ]}
              />
              {/* Values order is reversed because of technical display constraint (direction rtl) */}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card_body cblocks">
            <SxField
              name={isCurrentUserSender ? "senderComment" : "recipientComment"}
              as="textarea"
            />

            <div>
              <SxField
                name={
                  isCurrentUserSender
                    ? "senderAdminComment"
                    : "recipientAdminComment"
                }
                as="textarea"
              />
              <div className="field-tip">
                Votre interlocuteur n’en aura pas connaissance
              </div>
            </div>
          </div>
        </div>

        {!isAlreadyCompleted && (
          <div className="form_footer --txt--center">
            <ValidationErrors />
            <SubmitButton className="btn" type="submit">
              Envoyer et clôturer le RDV
            </SubmitButton>
          </div>
        )}
      </div>
    </SxForm>
  );
};

export default BookingClosureBookingHappenedForm;
