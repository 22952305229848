import Dialog from "../../../../components/Dialog";
import DisplayPrice from "../../../../components/DisplayPrice";
import SubmitButton from "../../../../components/forms/SubmitButton";
import { Campaign } from "../../../../services/campaign/campaignModel";

const ActivateCampaignDialog = ({
  onClose,
  onConfirm,
  onUpdatePayment,
  campaign,
}: {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onUpdatePayment: () => void;
  campaign: Campaign;
}) => {
  const card = campaign.businessAccount?.stripeCustomer?.card;

  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <h2 className="popup_title">Confirmer l'activation de la campagne</h2>
        </div>
        <div className="popup_body cblocks">
          <p>
            En activant cette campagne, un lien sera généré vers une page
            d’inscription. Vous pourrez partager librement ce lien (par email,
            sur LinkedIn, etc.) pour permettre aux personnes de réserver une
            visio dans l'agenda du référent de la campagne.
          </p>
          <p>
            Pour chaque rendez-vous confirmé, un montant de{" "}
            <DisplayPrice
              amount={campaign.price! * 1.2}
              currency="EUR"
              taxFree={false}
            />{" "}
            (
            <DisplayPrice amount={campaign.price!} currency="EUR" taxFree />
            ), sera débité du moyen de paiement enregistré sur votre compte pro
            Tisio.
            {card && (
              <>
                <br />({card.brand.toUpperCase()} #### #### #### {card.last4}{" "}
                exp. {card.exp_month}/{card.exp_year})
              </>
            )}
            <br />
            <button className="link--accent" onClick={onUpdatePayment}>
              mettre à jour le moyen de paiement
            </button>
          </p>

          {campaign.maxVisio && (
            <p className="body--30">
              La campagne prendra fin dès que le nombre maximum de{" "}
              {campaign.maxVisio} rendez-vous souhaités sera atteint.
            </p>
          )}
        </div>
        <div className="popup_footer">
          <button type="button" className="btn--grey" onClick={onClose}>
            Annuler
          </button>
          <SubmitButton type="button" className="btn" onClick={onConfirm}>
            Activer la campagne
          </SubmitButton>
        </div>
      </>
    </Dialog>
  );
};

export default ActivateCampaignDialog;
