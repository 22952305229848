import BackButton from "../../components/BackButton";
import ImgExample1 from "../../assets/img/help/profile-advice/profile-example-1.jpeg";
import ImgExample2 from "../../assets/img/help/profile-advice/profile-example-2.jpeg";
import ImgExample3 from "../../assets/img/help/profile-advice/profile-example-3.jpeg";
import ImgExample4 from "../../assets/img/help/profile-advice/profile-example-4.jpeg";

function ProfileAdvicePage() {
  return (
    <div className="container --wrap">
      <div className="page_head">
        <div>
          <BackButton />
          <h1 className="page_title">
            Nos conseils pour la création de votre profil
          </h1>
        </div>
      </div>
      <div className="page-content">
        <div className="lblocks">
          <h2 className="section_title">
            Bien renseigner son profil Tisio c’est optimiser sa visibilité !
          </h2>
          <p className="paragraph_title">
            Suivez nos conseils pratiques pour bien mettre en avant votre
            profil.
          </p>
          <p>
            La première chose à faire pour gagner en visibilité sur Tisio est de
            renseigner entièrement votre profil :
          </p>
          <ul className="checklist">
            <li>Une photo de profil professionnelle</li>
            <li>Un intitulé de poste clair</li>
            <li>
              Une description de votre activité principale, secteur d’activité,
              de vos compétences
            </li>
            <li>
              Une courte explication de ce que vous pouvez apporter lors d'une
              visio
            </li>
            <li>Un calendrier connecté !</li>
          </ul>
          <p className="paragraph_title">
            Nos recommandations pour une description claire et rapide à écrire !
          </p>
          <p>Décrivez-vous !</p>
          <ul className="checklist">
            <li>
              En intro : décrivez votre parcours, votre poste actuel et vos
              missions.
            </li>
            <li>
              Listez vos domaines d'expertise, vos expériences et vos
              compétences
            </li>
            <li>
              Expliquez ce que vous pouvez apporter lors d'une visio pour
              maximiser votre visibilité et vos réservations en visio.
            </li>
          </ul>
          <p className="paragraph_title">💡 Pensez à utiliser des mots-clé</p>
          <p>
            Plus vous renseignez des mots-clés pertinents et plus les résultats
            de recherche vous concernant seront précis.
          </p>
          <p className="paragraph_title">
            Voici quelques profils qui peuvent vous inspirer :
          </p>
          <div>
            <img src={ImgExample1} alt="example de profil 1" />
            <img src={ImgExample2} alt="example de profil 2" />
            <img src={ImgExample3} alt="example de profil 3" />
            <img src={ImgExample4} alt="example de profil 4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileAdvicePage;
