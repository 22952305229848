import { createRef, useLayoutEffect, useRef } from "react";
import { useNavigation } from "react-router-dom";
import { ClientOnly } from "../../react-helpers/react";

function ScrollToTopOnNavInner() {
  const navigation = useNavigation();
  const topAnchor = createRef<HTMLDivElement>();
  const prevState = useRef(navigation.state);

  /// Using useLayoutEffect to ensure the scroll happens after the DOM updates but before the paint
  /// Using navigation.state we can ensure to scroll after the navigation is done
  useLayoutEffect(() => {
    if (!import.meta.env.SSR && navigation.state === "idle") {
      topAnchor.current?.scrollIntoView();
    }

    prevState.current = navigation.state;
  }, [navigation.state, topAnchor]);

  return <div ref={topAnchor}></div>;
}

function ScrollToTopOnNav() {
  return (
    <ClientOnly>
      <ScrollToTopOnNavInner />
    </ClientOnly>
  );
}

export default ScrollToTopOnNav;
