import { useMemo } from "react";
import { isAfter, parseISO } from "date-fns";
import { compareDate, orderByField } from "../../../react-helpers/array";
import DisplayPrice from "../../../components/DisplayPrice";
import { formatDate } from "../../../react-helpers/date";
import { Link } from "react-router-dom";
import { Voucher } from "../../../services/payment/paymentModel";
import Icon from "../../../components/Icon";

const EWalletVouchersColumnComponent = ({
  vouchers,
}: {
  vouchers: Voucher[];
}) => {
  const sortedUnusedVouchers = useMemo(() => {
    const today = new Date();
    return vouchers
      .filter((v) => !v.usageDate && isAfter(parseISO(v.expirationDate), today))
      .sort(orderByField("expirationDate", "desc", compareDate));
  }, [vouchers]);

  const unusedVouchersTotal = useMemo(
    () =>
      sortedUnusedVouchers.reduce(
        (total, voucher) => voucher.amount + total,
        0,
      ),
    [sortedUnusedVouchers],
  );

  const sortedUsedVouchers = useMemo(
    () =>
      vouchers
        .filter((v) => !!v.usageDate)
        .sort(orderByField("usageDate", "asc", (a, b) => compareDate(a!, b!))),
    [vouchers],
  );

  const sortedExpiredVouchers = useMemo(() => {
    const today = new Date();
    return vouchers
      .filter((v) => !v.usageDate && isAfter(today, parseISO(v.expirationDate)))
      .sort(orderByField("expirationDate", "asc", compareDate));
  }, [vouchers]);
  return (
    <div className="lblocks">
      <div className="scorecard">
        <Icon name="voucher" />
        <div>
          <div className="scorecard_label">Vos bons d'achat</div>
          <div className="scorecard_value">
            <DisplayPrice amount={unusedVouchersTotal} />
          </div>
        </div>
      </div>

      {sortedUnusedVouchers.length > 0 && (
        <div>
          <h2 className="block_title">Bons en cours de validité</h2>
          <div className="cblock--s table-scroll">
            <table className="table --framed">
              <tbody>
                {sortedUnusedVouchers.map((voucher) => (
                  <tr key={voucher.id}>
                    <td className="row-title">{voucher.label}</td>
                    <td className="--shrink">
                      <small>validité :</small>{" "}
                      {formatDate(voucher.expirationDate, "P")}{" "}
                    </td>
                    <td className="--shrink">
                      <span className="tag">{voucher.community.labelFr}</span>
                    </td>
                    <td className="row-value --shrink --txt--right">
                      <DisplayPrice amount={voucher.amount} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {sortedUsedVouchers.length > 0 && (
        <div>
          <h2 className="block_title">Bons utilisés</h2>
          <div className="cblock--s table-scroll">
            <table className="table --framed">
              <tbody>
                {sortedUsedVouchers.map((voucher) => (
                  <tr key={voucher.id}>
                    <td className="row-title">{voucher.label}</td>
                    <td className="--shrink">
                      <small>utilisé le :</small>{" "}
                      <Link
                        to={`/app/bookings/${voucher.bookingRequests[0]?.uuid}`}
                        className="link --s"
                      >
                        {formatDate(voucher.usageDate!, "P")}
                      </Link>
                    </td>
                    <td className="row-value --shrink --txt--right">
                      <DisplayPrice amount={voucher.amount} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {sortedExpiredVouchers.length > 0 && (
        <div>
          <h2 className="block_title">Bons expirés</h2>
          <div className="cblock--s table-scroll">
            <table className="table --framed">
              <tbody>
                {sortedExpiredVouchers.map((voucher) => (
                  <tr key={voucher.id}>
                    <td className="row-title">{voucher.label}</td>
                    <td className="--shrink">
                      <small>Expiré le :</small>{" "}
                      {formatDate(voucher.expirationDate, "P")}
                    </td>
                    <td className="row-value --shrink --txt--right">
                      <DisplayPrice amount={voucher.amount} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default EWalletVouchersColumnComponent;
