import { useMemo, useState } from "react";
import EWalletRefundDialog from "./EWalletRefundDialog";
import { WalletTransaction } from "../../../services/payment/paymentModel";
import DisplayPrice from "../../../components/DisplayPrice";
import { User } from "../../../services/user/userModel";
import Icon from "../../../components/Icon";

const EWalletTotalComponent = ({
  walletTransactions,
  loggedUser,
}: {
  walletTransactions: WalletTransaction[];
  loggedUser: User;
}) => {
  const [showRefundDialog, setShowRefundDialog] = useState(false);

  const balance = useMemo(
    () =>
      walletTransactions.reduce(
        (total, walletTransaction) =>
          walletTransaction.validated
            ? total + walletTransaction.amount
            : total,
        0,
      ),
    [walletTransactions],
  );

  return (
    <>
      <div className="scorecard">
        <Icon name="wallet" />
        <div>
          <div className="scorecard_label">
            Dans votre porte-monnaie électronique
          </div>
          <div className="scorecard_value">
            <DisplayPrice amount={balance} />
          </div>
        </div>
        {balance > 0 && (
          <div className="scorecard_actions">
            <button
              className="btn"
              type="button"
              onClick={() => setShowRefundDialog(true)}
            >
              Retirer
            </button>
          </div>
        )}
      </div>

      {showRefundDialog && (
        <EWalletRefundDialog
          loggedUser={loggedUser}
          onClose={() => setShowRefundDialog(false)}
        />
      )}
    </>
  );
};

export default EWalletTotalComponent;
