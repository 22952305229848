import SubmitButton from "../../components/forms/SubmitButton";
import SxCheckbox from "../../forms/fields/SxCheckbox";
import SxField from "../../forms/fields/SxField";
import { MappedConfigData } from "../../services/data/dataModel";

const BookingProfileAdvancedSearch = ({
  filtersOptions,
}: {
  filtersOptions: MappedConfigData;
}) => {
  return (
    <div className="card --bg --light slide-left">
      <div className="card_body">
        <div className="grid--3">
          <SxField
            as="autocomplete"
            name="companyActivities"
            placeholder={"- Sélectionnez des secteurs -"}
            options={filtersOptions.companyActivities}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="languages"
            placeholder={"- Sélectionnez des langues -"}
            options={filtersOptions.languages}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="expertises"
            options={filtersOptions.expertises}
            placeholder={"- Sélectionnez des domaines -"}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="skills"
            placeholder={"- Sélectionnez des compétences -"}
            options={filtersOptions.skills}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="hierarchyLevels"
            placeholder={"- Sélectionnez des niveaux -"}
            options={filtersOptions.hierarchyLevels}
            isMulti
          />
          <SxField
            as="autocomplete"
            name="companyHeadcounts"
            placeholder={"- Sélectionnez des tranches -"}
            options={filtersOptions.companyHeadcounts}
            isMulti
          />
          <div className="grid--2">
            <SxField name="minPrice" />
            <SxField name="maxPrice" />
          </div>
          <div className="card">
            <div className="card_body">
              <SxCheckbox name="probono" />
            </div>
          </div>
        </div>
        <div className="lblock --txt-center">
          <SubmitButton type="submit" className="btn">
            Rechercher
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default BookingProfileAdvancedSearch;
