import { LoggedUserData } from "./authModel";
import * as Sentry from "@sentry/react";

export const LOGGED_USER_LOCALSTORAGE_ID = "loggedUser";

export function nativeGetLoggedUser(): LoggedUserData | null {
  if (import.meta.env.SSR) {
    if (globalThis.__USER)
      return {
        id: globalThis.__USER.user_id,
        xsrfToken: globalThis.__USER.access_token,
      };
    else return null;
  }

  const rawUser = localStorage.getItem(LOGGED_USER_LOCALSTORAGE_ID);
  const user = rawUser ? (JSON.parse(rawUser) as LoggedUserData) : null;

  return user;
}

export function nativeSetLoggedUser(loggedUser: LoggedUserData | null) {
  if (import.meta.env.SSR) return;

  if (loggedUser !== null) {
    Sentry.setUser({ id: loggedUser.id });
    localStorage.setItem(
      LOGGED_USER_LOCALSTORAGE_ID,
      JSON.stringify(loggedUser),
    );
  } else {
    Sentry.setUser(null);
    localStorage.removeItem(LOGGED_USER_LOCALSTORAGE_ID);
  }
}
