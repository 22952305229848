import { useField } from "formik";
import { useMemo, useState } from "react";
import { cx } from "../../react-helpers/css";
import useYupField from "../validation/useYupField";
import { ValidationError } from "yup";
import { PasswordTest } from "../validation/passwordValidation";
import { useTranslation } from "react-i18next";
import { ValueOf } from "../../react-helpers/type";
import Icon from "../../components/Icon";

const SxPassword = ({ showTogglePassword, errored, ...props }: any) => {
  const [field, , helper] = useField(props);
  const fieldSchema = useYupField(field.name);
  const { t } = useTranslation(["validation"]);

  const meta = useMemo(() => fieldSchema?.meta(), [fieldSchema]);

  const testsStatus = useMemo(() => {
    const testsStatusObject = Object.fromEntries(
      Object.entries(PasswordTest).map(([, test]) => {
        let count;
        if (test === PasswordTest.Lower)
          count = meta?.passwordConfig?.minLowerCount;
        else if (test === PasswordTest.Upper)
          count = meta?.passwordConfig?.minUpperCount;
        else if (test === PasswordTest.Number)
          count = meta?.passwordConfig?.minNumberCount;
        else if (test === PasswordTest.Length)
          count = meta?.passwordConfig?.minLength;
        else if (test === PasswordTest.Specials)
          count = meta?.passwordConfig?.minSpecials;

        return [test, { valid: !!field.value, count }];
      }),
    ) as Record<
      ValueOf<typeof PasswordTest>,
      { valid: boolean; count?: number }
    >;

    try {
      fieldSchema?.validateSync(field.value, {
        abortEarly: false,
      });
    } catch (e: any) {
      e.inner?.forEach((validationError: ValidationError) => {
        const key = validationError.type as ValueOf<typeof PasswordTest>;
        if (validationError.type && testsStatusObject[key]) {
          testsStatusObject[key].valid = false;
        }
      });
    }

    return Object.entries(testsStatusObject) as [
      ValueOf<typeof PasswordTest>,
      { valid: boolean; count?: number },
    ][];
  }, [field.value, fieldSchema, meta]);

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="input-with-btn">
        <input
          {...field}
          {...props}
          onChange={(e) => {
            void helper.setValue(
              e.currentTarget.value === "" ? null : e.currentTarget.value,
            );
          }}
          type={showPassword ? "text" : "password"}
          value={field.value ?? ""}
        />
        {showTogglePassword && (
          <button
            type="button"
            className="btn--grey --addon"
            onClick={() => setShowPassword(!showPassword)}
          >
            <Icon name={showPassword ? "eye_off" : "eye"} />
          </button>
        )}
      </div>
      {!!meta?.passwordConfig && (
        <div className="field_step-validation">
          {testsStatus.map(([test, { valid, count }]) => (
            <div
              className={cx([
                "step",
                valid && "--valid",
                !valid && errored && "--unvalid",
              ])}
              key={test}
            >
              {t(`validation:password.tip.${test}`, {
                count,
              })}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SxPassword;
