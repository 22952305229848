import {
  Form as FormikForm,
  FormikConfig,
  FormikContext,
  FormikValues,
  useFormik,
  FormikHelpers,
} from "formik";
import { createContext, useEffect, useState } from "react";
import { AnySchema, InferType } from "yup";

interface YupSchemaAPI<Schema extends AnySchema = AnySchema> {
  schema: Schema;
}
export const YupSchemaContext = createContext<YupSchemaAPI | null>(null);

type Props<
  Values,
  Schema extends AnySchema | undefined,
  SchemaValues extends
    | Values
    | NonNullable<AnySchema> = Schema extends AnySchema
    ? InferType<Schema>
    : Values,
> = Omit<
  FormikConfig<Values & SchemaValues>,
  "initialValues" | "validationSchema" | "onSubmit" | "onReset"
> & {
  initialValues: Values;
  validationSchema?: Schema | (() => Schema);
  onSubmit: (
    values: Values & SchemaValues,
    formikHelpers: FormikHelpers<Values & SchemaValues>,
  ) => void | Promise<any>;
  onReset?: (
    values: Values & SchemaValues,
    formikHelpers: FormikHelpers<Values & SchemaValues>,
  ) => void;
  disabled?: boolean;
  "data-testid"?: string;
};

function SxForm<
  Values extends FormikValues,
  Schema extends AnySchema | undefined = undefined,
  SchemaValues extends
    | Values
    | NonNullable<AnySchema> = Schema extends AnySchema
    ? InferType<Schema>
    : Values,
>({ disabled, ...rawProps }: Props<Values, Schema, SchemaValues>) {
  // BEGIN: Disable during SSR
  const [isDisabledBySSR, setDisabledBySSR] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setDisabledBySSR(false));
  // END: Disable during SSR

  const props = rawProps as unknown as FormikConfig<Values & SchemaValues>;
  const { children } = props;

  const schema =
    typeof props.validationSchema === "function"
      ? props.validationSchema()
      : props.validationSchema;

  const formikStateAndHelpers = useFormik({
    ...props,
    validate: (values) => {
      try {
        if (schema)
          schema.validateSync(values, {
            abortEarly: false,
          });
      } catch (err: any) {
        return err.inner?.reduce(
          (obj: any, validationError: any) => ({
            ...obj,
            [validationError.params.path]: (
              obj[validationError.params.path] || []
            ).concat([
              { type: validationError.type, message: validationError.message },
            ]),
          }),
          {},
        );
      }
    },
    onSubmit(values, formikHelpers) {
      const parsedValues = schema ? schema.cast(values) : values;
      return props.onSubmit(parsedValues, formikHelpers);
    },
    onReset(values, formikHelpers) {
      if (props.onReset) {
        const parsedValues = schema ? schema.cast(values) : values;
        return props.onReset(parsedValues, formikHelpers);
      }
    },
  });
  return (
    <FormikContext.Provider value={formikStateAndHelpers}>
      <YupSchemaContext.Provider
        value={
          schema
            ? {
                schema,
              }
            : null
        }
      >
        <FormikForm>
          <fieldset
            disabled={(isDisabledBySSR ?? import.meta.env.SSR) || disabled}
          >
            {typeof children === "function"
              ? children(formikStateAndHelpers)
              : children}
          </fieldset>
        </FormikForm>
      </YupSchemaContext.Provider>
    </FormikContext.Provider>
  );
}

export default SxForm;
