import { useLoaderData, useSearchParams } from "react-router-dom";
import LoginForm from "./LoginForm";
import { PromiseResult } from "../../../react-helpers/type";
import { AuthService } from "../../../services/auth/authService";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { BASE_URL } from "../../../services/config";

function LoginPage() {
  const [searchParams] = useSearchParams();
  const { validated } = useLoaderData() as {
    validated: null | PromiseResult<ReturnType<AuthService["validateUser"]>>;
  };
  const { t } = useTranslation(["auth", "global"]);

  const redirectTo = useMemo(() => {
    const redirectToParam = searchParams.get("redirectTo");
    if (redirectToParam) {
      try {
        new URL(BASE_URL + redirectToParam);
        return redirectToParam;
      } catch {
        return null;
      }
    } else {
      return null;
    }
  }, [searchParams]);

  return (
    <div className="auth-card">
      <div className="card_head">
        <h1 className="card_title">Bienvenue sur Tisio !</h1>
        <p>Connectez-vous pour accéder à votre espace</p>
      </div>
      <div className="card_body cblocks">
        {validated === "validated" && (
          <div className="info --success">{t("auth:validate.SUCCESS")}</div>
        )}
        {validated === "invalid-link" && (
          <div className="info --warning">
            {t("auth:validate.INVALID_LINK")}
          </div>
        )}
        {validated === "error" && (
          <div className="info --error">{t("global:GENERIC_ERROR")}</div>
        )}
        {redirectTo && (
          <div className="info">
            Vous serez redirigé vers la page à laquelle vous essayez d’accéder
            dès que vous serez connecté
          </div>
        )}
        <LoginForm redirectTo={redirectTo ?? undefined} />
      </div>
    </div>
  );
}

export default LoginPage;
