import { mapNonNull } from "./any";
import { Nullability } from "./type";

export function mapMultiply<Num extends number | undefined | null>(
  num: Num,
  other: number,
): number | Nullability<Num> {
  return mapNonNull(num, (num) => num * other);
}

export const modulo = (
  divident: number | string,
  divisor: number,
  // this value is used to split the divident into smaller parts
  partLength = 10,
) => {
  while (String(divident).length > partLength) {
    const part = Number(String(divident).substring(0, partLength));
    divident = String(part % divisor) + String(divident).substring(partLength);
  }
  return Number(divident) % divisor;
};
