import { Link } from "react-router-dom";

const GlobalError = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className="container cblocks">
      <div className="title--70">
        Oups... une erreur inconnue est survenue...
      </div>
      {errorMessage && <p>Message : {errorMessage}</p>}
      <div className="body--50 --strong">
        Si le problème persiste, n'hésitez pas à{" "}
        <Link to={"/contact"} className="link --cta --underlined">
          nous contacter
        </Link>{" "}
        !
      </div>
    </div>
  );
};

export default GlobalError;
