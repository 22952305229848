import { useState } from "react";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import SxForm from "../../../../forms/SxForm";
import SubmitButton from "../../../../components/forms/SubmitButton";

interface Props {
  clientSecret: string;
  returnUrl: string;
}

const BookingRequestPaymentForm = ({ clientSecret, returnUrl }: Props) => {
  const elements = useElements();
  const stripe = useStripe();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showErrors, setShowErrors] = useState(false);

  const handleSubmit = async () => {
    setShowErrors(true);
    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message ?? null);
      return;
    }

    const { error } = await stripe!.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: clientSecret,
      confirmParams: {
        return_url: returnUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message ?? null);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <SxForm initialValues={{}} onSubmit={handleSubmit} enableReinitialize>
      <PaymentElement onChange={() => setShowErrors(false)} />
      <div className="form_footer cblocks">
        {errorMessage && showErrors && (
          <div className="info --error">{errorMessage}</div>
        )}
        <SubmitButton
          className="btn"
          type="submit"
          disabled={!stripe || !elements}
        >
          Payer
        </SubmitButton>
        <div className="info --centered">
          Vous pourrez choisir la date et l’heure de votre RDV à la prochaine
          étape !
        </div>
      </div>
    </SxForm>
  );
};

export default BookingRequestPaymentForm;
