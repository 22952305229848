/*
Context:
  getUser,
  checkUserValidity,
  updateUser,
  et toutes méthodes de manipulation d'un user existant en DB
*/

import { NewUserData, User } from "./userModel";
import {
  createUserRequest,
  getAllUsersRequest,
  getUserRequest,
  sendDeleteUserRequestRequest,
  updateUserRequest,
} from "./userApi";
import { toastsWithIntl } from "../toastService";
import { authService } from "../auth/authService";
import * as Sentry from "@sentry/react";

interface UserService {
  getLoggedUser: () => Promise<User>;
  getAllUsers: () => Promise<User[]>;
  createUser: (newUserData: NewUserData) => Promise<User>;
  updateUserById: (user: User) => Promise<User>;
  sendDeleteUserRequest: () => Promise<void>;
}
export const userService = () => {
  const { getLoggedUserId } = authService();
  const { toastSuccess, toastError } = toastsWithIntl(["accounts"]);

  const getLoggedUser: UserService["getLoggedUser"] = () => {
    const loggedUserId = getLoggedUserId();

    if (!loggedUserId) return Promise.reject(new Error("Not logged in"));
    return getUserRequest(loggedUserId).then((u) => {
      if (!import.meta.env.SSR)
        Sentry.setUser({
          id: u.id,
          email: u.email,
          username: `${u.firstname} ${u.lastname}`,
        });
      return u;
    });
  };

  const createUser: UserService["createUser"] = (newUser) => {
    return createUserRequest(newUser).then(
      (user) => {
        toastSuccess("accounts:create-account.SUCCESS");
        return Promise.resolve(user);
      },
      (err) => {
        if (err.response.status === 403) {
          toastError("accounts:create-account.FORBIDDEN");
        } else if (err.response.status === 404) {
          toastError("accounts:create-account.NOT_FOUND");
        } else if (err.response.status === 409) {
          toastError("accounts:create-account.EXISTING_USER");
        } else {
          toastError("accounts:create-account.ERROR");
        }
        return Promise.reject(err);
      },
    );
  };

  const updateUserById: UserService["updateUserById"] = (user) => {
    return updateUserRequest(user).then(
      (updateUser) => {
        toastSuccess("accounts:update.SUCCESS");
        return Promise.resolve(updateUser);
      },
      (err) => {
        toastError("accounts:update.ERROR");
        return Promise.reject(err);
      },
    );
  };

  const sendDeleteUserRequest: UserService["sendDeleteUserRequest"] = () => {
    return sendDeleteUserRequestRequest().then(
      () => {
        toastSuccess("accounts:delete-request.SUCCESS");
        return Promise.resolve();
      },
      (err) => {
        toastError("global:GENERIC_ERROR");
        return Promise.reject(err);
      },
    );
  };

  return {
    getLoggedUser,
    getAllUsers: getAllUsersRequest,
    createUser: createUser,
    updateUserById,
    sendDeleteUserRequest,
  };
};
