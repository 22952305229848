import { Link } from "react-router-dom";
import FaqHome from "./FaqHome";

function HomeFAQSection() {
  return (
    <div className="section --white">
      <h2 className="section_title ">Question et réponses</h2>

      <FaqHome />
      <div className="--txt--center lblock--l">
        <Link to="/faq" className="btn--2" target="_blank">
          Voir toute la FAQ
        </Link>
      </div>
      <div className="body--30 --txt--center">
        Vous ne trouvez pas la réponse que vous cherchez ?<br />
        N'hésitez pas à <Link to="/contact">nous contacter</Link>
      </div>
    </div>
  );
}

export default HomeFAQSection;
