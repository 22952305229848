import { AnyObject, AnySchema, number, object, string } from "yup";
import Dialog from "../../../components/Dialog";
import ProfilePic from "../../../components/profileDisplay/ProfilePic";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import SxField from "../../../forms/fields/SxField";
import BookingProfilePaymentTableComponent from "./BookingProfilePaymentTableComponent";
import { Request } from "./BookingProfilePage";
import { useMemo } from "react";
import { User } from "../../../services/user/userModel";
import BookingProfileContextExempleSection from "./BookingProfileContextExempleSection";

const BookingProfileBookingDialog = ({
  onClose,
  currentUser,
  recipientBookingProfile,
  currentUserBookingProfile,
  currentUserCredits,
  onSubmit,
}: {
  onClose(): void;
  currentUser?: User;
  recipientBookingProfile: BookingProfile;
  currentUserBookingProfile?: BookingProfile;
  currentUserCredits?: {
    eCredit: User["eCredit"];
    vouchers: User["vouchers"];
  };
  onSubmit(
    values: Request & {
      senderId?: BookingProfile["id"];
      recipientId: BookingProfile["id"];
    },
  ): Promise<void>;
}) => {
  const initialValues: Request = useMemo(() => {
    const initialValues = {
      context: "",
      eCredit: null,
      voucherId: null,
    };
    return currentUser
      ? initialValues
      : { ...initialValues, email: "", firstname: "", lastname: "" };
  }, [currentUser]);

  const validationSchema = useMemo(() => {
    let schema: Record<string, AnySchema<any, AnyObject, undefined, "">> = {
      context: string().transform(transformEmptyToUndefined).required(),
      eCredit: number().min(0).nullable(),
      voucherId: number().nullable().transform(transformEmptyToUndefined),
    };

    if (!currentUserBookingProfile) {
      schema = {
        ...schema,
        email: string().email().required().label("Email"),
        firstname: string().required().label("Prénom"),
        lastname: string().required().label("Nom"),
      };
    }
    return object(schema);
  }, [currentUserBookingProfile]);

  return (
    <Dialog onClose={onClose} className="--fullw">
      <>
        <div className="popup_head">
          <h1 className="popup_title">Envoyer une sollicitation</h1>
        </div>

        <SxForm
          initialValues={initialValues}
          onSubmit={(values) => {
            return onSubmit({
              ...values,
              senderId: currentUserBookingProfile?.id,
              recipientId: recipientBookingProfile.id,
            });
          }}
          validationSchema={validationSchema}
        >
          <div className="popup_body">
            <div className="lrow --center--v">
              <ProfilePic src={recipientBookingProfile.user.profilePicture} />

              <div className="section_title">
                {recipientBookingProfile.user.displayName}
              </div>
            </div>

            <div className="lblock">
              <h2 className="section_title">Rédigez votre demande</h2>
              <div className="body--30">
                Soyez précis, c'est ce que votre interlocuteur lira !
              </div>
              <div className="cblock">
                <SxField
                  name="context"
                  as="textarea"
                  placeholder="Je suis [votre fonction] chez [votre entreprise]. Je veux échanger avec vous pour [décrivez ce que vous attendez de l’échange] dans l’objectif de [décrivez votre objectif]."
                />
              </div>

              <div className="cblock--xs">
                <BookingProfileContextExempleSection />
              </div>

              {!currentUser && (
                <div className="lblock card --bg">
                  <div className="card_body">
                    <h2 className="section_title">Vos informations</h2>
                    <div className="cblock grid">
                      <SxField name="firstname" placeholder="eg. John" />
                      <SxField name="lastname" placeholder="eg. Smith" />
                      <SxField
                        name="email"
                        placeholder="eg. john.smith@gmail.com"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="lblock">
                <BookingProfilePaymentTableComponent
                  vouchersFieldName="voucherId"
                  eCreditFieldName="eCredit"
                  bookingPrice={recipientBookingProfile.price}
                  userCredits={currentUserCredits}
                />
              </div>
            </div>
          </div>

          <div className="popup_footer">
            <div className="ui-row">
              <button className="btn--cancel" type="button" onClick={onClose}>
                Annuler
              </button>
              <SubmitButton className="btn--1" type="submit">
                Confirmer
              </SubmitButton>
            </div>
          </div>
        </SxForm>
      </>
    </Dialog>
  );
};

export default BookingProfileBookingDialog;
