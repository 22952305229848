import { User } from "../../../services/user/userModel";
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState, useRef, ReactNode } from "react";
import { userService } from "../../../services/user/userService";
import Dialog from "../../../components/Dialog";
import { CAL_BASE_URL } from "../../../services/config";
import useReload from "../../../hooks/useReload";

function OpenCalIframeButton({
  loggedUser,
  className,
  label = "Paramétrer mon calendrier",
  children,
}: {
  loggedUser: User;
  className: string;
  displayTitle?: boolean;
  label?: string;
  children?: ReactNode;
}) {
  const reload = useReload();

  // Access the environment variable
  const calUrl = CAL_BASE_URL;

  // Reference to the opened window
  const windowRef = useRef<Window | null>(null);

  const [user, setUser] = useState<User>(loggedUser);
  const [polling, setPolling] = useState<boolean>(false);
  const [showAdblockerPopup, setShowAdblockerPopup] = useState<boolean>(false);
  const { getLoggedUser } = userService();

  const handleSetupCalClick = () => {
    if (user.completedOnboarding) {
      window.open(`${calUrl}/availability`, "_blank");
      return;
    }

    const newWindow = window.open(
      `${calUrl}/getting-started`,
      "Cal",
      `left=40,top=40,width=${window.innerWidth - 80},height=${window.innerHeight - 80},directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no`,
    );

    if (
      !newWindow ||
      newWindow.closed ||
      typeof newWindow.closed === "undefined"
    ) {
      // If the window couldn't be opened, show the adblocker popup
      setShowAdblockerPopup(true);
    } else {
      windowRef.current = newWindow;
      setShowAdblockerPopup(false);
      startPolling();
    }
  };

  const fetchAndUpdateUserData = useCallback(async () => {
    try {
      const updatedUser = await getLoggedUser(); // Assuming you have a function to fetch user data
      setUser(updatedUser);
    } catch (error) {
      // do nothing, if it doesn't work user will eventually refresh the page
    }
  }, [getLoggedUser]);

  useEffect(() => {
    if (!polling) return;

    const intervalId = setInterval(() => {
      if (!user.completedOnboarding) {
        void fetchAndUpdateUserData();
      }

      // Check if the window is closed
      if (windowRef.current && windowRef.current.closed) {
        setPolling(false);
        windowRef.current = null;
      }

      // Close the window if onboarding is completed
      if (user.completedOnboarding && windowRef.current) {
        windowRef.current.close();
        windowRef.current = null;
        setPolling(false);
        reload();
      }
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [polling, user.completedOnboarding, fetchAndUpdateUserData, reload]);

  const startPolling = useCallback(() => {
    setPolling(true);
  }, []);

  return (
    <>
      <button onClick={handleSetupCalClick} className={className}>
        {children ?? label}
      </button>

      {showAdblockerPopup && (
        <Dialog onClose={() => setShowAdblockerPopup(false)}>
          <>
            <div className="popup_head">
              <h2 className="popup_title">Fenêtre bloquée</h2>
            </div>
            <div className="popup_body">
              <p className="info --warning">
                Il semble que l'ouverture de la fenêtre ait été bloquée,
                probablement à cause d'un bloqueur de publicité. Essayez de le
                désactiver puis de renouveler l'opération.
              </p>
              <div className="cblock">
                <button
                  onClick={handleSetupCalClick}
                  className="btn--2 --block"
                >
                  Réessayer
                </button>
              </div>

              <p className="lblock">
                Vous pouvez également ouvrir manuellement la fenêtre en cliquant
                sur le lien ci-dessous, puis en revenant sur cette page quand
                vous aurez terminé :
              </p>
              <div className="cblock --txt--center">
                <Link
                  to={`${calUrl}/getting-started`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link--accent"
                >
                  Ouvrir dans un nouvel onglet
                </Link>
              </div>
            </div>
            <div className="popup_footer">
              <button
                className="btn--cancel"
                onClick={() => setShowAdblockerPopup(false)}
              >
                Fermer
              </button>
            </div>
          </>
        </Dialog>
      )}
    </>
  );
}

export default OpenCalIframeButton;
