import {
  addMethod,
  date,
  Schema,
  string,
  SchemaRefDescription,
  mixed,
} from "yup";
import { passwordValidation } from "./passwordValidation";
import { isValidPhoneNumber } from "libphonenumber-js";

/**
 * // NOTE: Add the validation method to yup here and its type in yup.d.ts.
 * If you need an example of validation with multiple errors, refer to passwordValidation.tsx.
 */

/* STRING METHODS */
addMethod(string, "password", passwordValidation);

addMethod(
  string,
  "passwordConfirmation",
  function passwordConfirmation(ref: SchemaRefDescription) {
    return this.required().meta({ password: true }).equals([ref]) as any;
  },
);

addMethod(string, "multiline", function () {
  return this.meta({ multiline: true });
});

addMethod(string, "phoneNumber", function (message) {
  return this.meta({ phoneNumber: true }).test({
    name: "phoneNumber",
    test: (originalValue) =>
      !!(!originalValue || isValidPhoneNumber(originalValue, "FR")),
    message,
  });
});

/* DATE METHODS */
addMethod(date, "time", function date() {
  return this.meta({ time: true });
});

/* MIXED METHODS */
// HACK: `Schema as unknown as typeof mixed` till issue is fixed
// Because common methods must be added to Schema but Schema is broken in Typescript
// 19/08/2024 still broken
// SOURCE: https://github.com/jquense/yup/issues/2068
addMethod(
  Schema as unknown as typeof mixed,
  "disabled",
  function disabled(disabled = true) {
    return this.meta({ disabled });
  },
);

addMethod(
  Schema as unknown as typeof mixed,
  "notEditable",
  function notEditable(isNotEditable = true) {
    return isNotEditable
      ? this.optional()
          .meta({ disabled: true })
          .transform(() => undefined)
      : this;
  },
);

addMethod(
  Schema as unknown as typeof mixed,
  "notVisible",
  function notVisible(isNotVisible = true) {
    return isNotVisible
      ? this.optional()
          .meta({ notVisible: true })
          .transform(() => null)
      : this;
  },
);

addMethod(
  Schema as unknown as typeof mixed,
  "clearable",
  function clearable(isClearable = true) {
    return this.meta({ clearable: isClearable });
  },
);
