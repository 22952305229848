import { NavLink, Outlet } from "react-router-dom";
import Tabs from "../../../components/Tabs";
import BackButton from "../../../components/BackButton";

const AccountPage = () => {
  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
          <h1 className="page_title">Mon compte</h1>
        </div>
      </div>
      <Tabs>
        <NavLink className="tab_item" to="identity">
          Identité
        </NavLink>
        <NavLink className="tab_item" to="password">
          Mot de passe
        </NavLink>
      </Tabs>
      <div className="section">
        <Outlet />
      </div>
    </div>
  );
};

export default AccountPage;
