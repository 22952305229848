import { baseAPI } from "../api";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { BusinessAccount } from "../businessAccount/businessAccountModel";
import { User } from "../user/userModel";
import { Campaign } from "./campaignModel";

export function getCampaignsByUserAndBaIdRequest(
  userId: User["id"],
  baId: BusinessAccount["id"],
) {
  return baseAPI
    .get(`/campaigns/users/${userId}/business-accounts/${baId}`)
    .then(({ data }) => data);
}

export function getCampaignByIdRequest(
  campaignId: Campaign["id"] | Campaign["uuid"],
) {
  return baseAPI.get(`/campaigns/${campaignId}`).then(({ data }) => data);
}

export function getPublicCampaignByUuidRequest(campaignUuid: Campaign["uuid"]) {
  return baseAPI
    .get(`/campaigns/${campaignUuid}/public`)
    .then(({ data }) => data);
}

export function createCampaignRequest(campaign: Campaign) {
  return baseAPI.post(`/campaigns`, campaign).then(({ data }) => data);
}

export function updateCampaignRequest(
  campaign: Partial<Campaign> & Pick<Campaign, "id">,
) {
  return baseAPI
    .put(`/campaigns/${campaign.id}`, campaign)
    .then(({ data }) => data);
}

export function createBookingRequestRequest(
  campaignUuid: Campaign["uuid"],
  externalUser: Pick<
    BookingRequest,
    "extFirstname" | "extLastname" | "extEmail" | "extConsent"
  >,
) {
  return baseAPI
    .post(`/campaigns/${campaignUuid}/booking-requests`, externalUser)
    .then(({ data }) => data);
}

export function addContactsToCampaignRequest(
  campaignId: Campaign["id"],
  contacts: Pick<User, "firstname" | "lastname" | "email">[],
  sendMail: "send" | "dontSend",
) {
  return baseAPI
    .post(
      `/campaigns/${campaignId}/contacts?sendMail=${sendMail === "send"}`,
      contacts,
    )
    .then(({ data }) => data);
}
