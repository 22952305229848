import { authService } from "../../../services/auth/authService";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import { object, string } from "yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import BackButton from "../../../components/BackButton";

function LostPasswordForm() {
  const auth = authService();

  return (
    <SxForm
      initialValues={{
        email: "",
      }}
      onSubmit={({ email }) => {
        return auth.lostPassword(email);
      }}
      validationSchema={object({
        email: string().label("Adresse email").email().required(),
      })}
    >
      <p className="info">
        Saisissez votre adresse email de connexion pour recevoir un lien de
        réinitialisation de votre mot de passe.
      </p>

      <div className="lblock">
        <SxField name="email" placeholder="your.email@email.com" />
      </div>

      <div className="form_footer">
        <SubmitButton className="submit-btn">Envoyer</SubmitButton>
        <div className="lblock">
          <BackButton forcedUrl="/login">Retour à la connexion</BackButton>
        </div>
      </div>
    </SxForm>
  );
}

export default LostPasswordForm;
