// eslint-disable-next-line no-restricted-imports
import Select from "react-select";
// eslint-disable-next-line no-restricted-imports
import Creatable from "react-select/creatable";
import { ClientOnly } from "../../react-helpers/react";
import { useField } from "formik";
import { useCallback, useMemo } from "react";
import { loggerBuilder } from "../../services/logger";

const logger = loggerBuilder("sx-autocomplete");

export interface Value {
  label: string;
  value: string;
}
type SelectProps = Parameters<Select>[0];
type CreatableProps = Parameters<Creatable>[0];

export type SxAutocompleteProps =
  | (SelectProps & {
      creatable?: false;
      name: string;
      options: Value[];
      value?: Value | Value[];
      onCreateOption?: never;
      "data-testid"?: string;
    })
  | (Omit<CreatableProps, "onCreateOption"> & {
      creatable: true;
      name: string;
      options: Value[];
      value?: Value | Value[];
      onCreateOption(v: string): Promise<Value>;
      "data-testid"?: string;
    });

export const SxAutocomplete = ({
  creatable,
  name,
  onCreateOption,
  ...props
}: SxAutocompleteProps) => {
  const [field, , helpers] = useField(name);

  const value = useMemo(() => {
    const findValue = (v: Value["value"]) =>
      v !== null
        ? (props.options ?? []).find((opt: Value) => opt.value === String(v))
        : null;

    if (Array.isArray(field.value)) return field.value.map(findValue);
    else return findValue(field.value);
  }, [field.value, props.options]);

  const setValue = useCallback(
    (v: Value | Value[]) => {
      if (Array.isArray(v)) return helpers.setValue(v.map((v) => v.value));
      else return helpers.setValue(v.value);
    },
    [helpers],
  );

  if (creatable && !props.isMulti) {
    logger.error(
      "Creatable only works with multi select please implement the create for single values",
    );
    return null;
  }

  return (
    <ClientOnly>
      <div data-testid={props["data-testid"]}>
        {creatable ? (
          <Creatable
            value={value}
            onCreateOption={(v) => {
              if (onCreateOption)
                void onCreateOption(v).then((createdValue) => {
                  // BUG: it does not support creatable that is not multi
                  // prev is assumed to be an array here
                  void setValue([...(value as Value[]), createdValue]);
                });
            }}
            onChange={(v: any) => {
              void setValue(v);
            }}
            {...props}
          />
        ) : (
          <Select
            value={value}
            onChange={(v: any) => {
              void setValue(v);
            }}
            {...props}
          />
        )}
      </div>
    </ClientOnly>
  );
};
