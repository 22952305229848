import { useMemo } from "react";
import { formatDate } from "../../../react-helpers/date";
import {
  BusinessAccount,
  Plan,
  Subscription,
} from "../../../services/businessAccount/businessAccountModel";
import { isAfter, parseISO } from "date-fns";
import DisplayPrice from "../../../components/DisplayPrice";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import { businessAccountService } from "../../../services/businessAccount/businessAccountService";
import useReload from "../../../hooks/useReload";

const ProSubscriptionForm = ({
  businessAccount,
  subscription,
  plan,
}: {
  businessAccount: BusinessAccount;
  subscription?: Subscription;
  plan?: Plan;
}) => {
  const reload = useReload();
  const { confirm } = useConfirmationWithIntl(["businessAccount"]);
  const { cancelSubscription } = businessAccountService();

  const freeTrial = useMemo(
    () =>
      !!subscription?.stripeSubscription?.trialEnd &&
      isAfter(parseISO(subscription.stripeSubscription.trialEnd), new Date()),
    [subscription],
  );
  const cancelAt = useMemo(
    () =>
      subscription?.stripeSubscription?.cancelAt
        ? parseISO(subscription.stripeSubscription.cancelAt)
        : null,
    [subscription],
  );
  const cancelled = useMemo(
    () =>
      (!!cancelAt && isAfter(new Date(), cancelAt)) ||
      subscription?.stripeSubscription?.status === "canceled",
    [cancelAt, subscription],
  );

  return subscription?.stripeSubscription && plan && !cancelled ? (
    <div className="cblocks">
      {(freeTrial || cancelAt) && (
        <div className="lrow --gap--xs --center">
          <div>
            {freeTrial && (
              <span className="chip --active">Période d'essai</span>
            )}
          </div>
          {cancelAt && (
            <div className="ui-row --center--v">
              <span className="chip --danger">Résilié</span>
              <span className="--txt--warning body--30 --strong">
                Actif jusqu'au {formatDate(cancelAt)}
              </span>
            </div>
          )}
        </div>
      )}

      <div className="--txt--center">
        <h3 className="title--30">{plan.labelFr}</h3>
        <div className="plan-price cblock">
          <DisplayPrice
            amount={subscription.stripeSubscription.amount}
            currency={subscription.stripeSubscription.currency}
            taxFree
            addon={
              subscription.stripeSubscription.interval === "month"
                ? "par mois"
                : "par an"
            }
          />
        </div>
        {subscription.stripeSubscription.currentPeriodEnd && (
          <div className="body--30">
            Fin de période d'engagement :{" "}
            <strong>
              {formatDate(subscription.stripeSubscription.currentPeriodEnd)}
            </strong>
          </div>
        )}
      </div>

      {!cancelAt && (
        <div className="cblock --txt--center">
          <button
            type="button"
            className="link--danger"
            onClick={() =>
              confirm(
                "businessAccount:cancel-subscription.CONFIRM",
                () => {
                  return cancelSubscription(businessAccount.id, plan.id).then(
                    () => {
                      reload();
                    },
                  );
                },
                true,
              )
            }
          >
            résilier
          </button>
        </div>
      )}
    </div>
  ) : null;
};

export default ProSubscriptionForm;
