import { Link } from "react-router-dom";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import BookingProfileStatuses from "../user/BookingProfile/BookingProfileStatuses";
import CopyToClipboard from "../../components/utilities/CopyToClipboard";
import ProfilePic from "../../components/profileDisplay/ProfilePic";
import DisplayPrice from "../../components/DisplayPrice";
import { User } from "../../services/user/userModel";
import OpenCalIframeButton from "./calendarWidgetComponent/OpenCalIframeButton";
import { ClientOnly } from "../../react-helpers/react";

function BookingProfileWidgetComponent({
  profile,
  loggedUser,
}: {
  profile?: BookingProfile;
  loggedUser: User;
}) {
  if (!profile) return null;

  return (
    <div className="widget">
      <h2 className="widget_title">Votre profil Tisio</h2>

      <div className="widget_content --bg">
        {profile.isComplete &&
          profile.published &&
          !loggedUser.completedOnboarding && (
            <div className="info --warning">
              Votre profil est complet, mais vous devez{" "}
              <OpenCalIframeButton
                className="link--accent --s"
                loggedUser={loggedUser}
                label="paramétrer votre calendrier"
              />{" "}
              pour apparaître dans la liste de profils Tisio et pouvoir être
              sollicité pour un RDV.
            </div>
          )}
        {loggedUser.migrated &&
          !profile.isComplete &&
          profile.published &&
          !loggedUser.completedOnboarding && (
            <div className="info --warning">
              Vous devez encore{" "}
              <OpenCalIframeButton
                className="link--accent --s"
                loggedUser={loggedUser}
                label="paramétrer votre calendrier"
              />{" "}
              pour apparaître dans la liste de profils Tisio et pouvoir être
              sollicité pour un RDV.
            </div>
          )}
        {!profile.isComplete && (
          <>
            {profile.published && loggedUser.migrated ? (
              <>
                <div className="info --warning">
                  Suite à la migration de votre compte, votre profil est
                  désormais en ligne, mais il n'est pas encore associé à vos
                  domaines d'expertise et compétences clés. Renseignez-les pour
                  augmenter vos chances d'apparaître dans les résultats de
                  recherche et d'être sollicité !
                </div>
                <Link to={"/app/profiles/tisio"} className="btn--2 --block">
                  Compléter mon profil
                </Link>
              </>
            ) : (
              <>
                <div className="info --warning">
                  Vous n’avez pas encore finalisé la création de votre profil,
                  et ne pourrez donc pas apparaître dans les résultats de
                  recherche, ni être sollicité pour un RDV.
                </div>
                <Link to={"/app/profiles/tisio"} className="btn--2 --block">
                  Mettre mon profil en ligne
                </Link>
              </>
            )}
          </>
        )}
        {profile.isComplete && !profile.published && (
          <>
            <div className="info --warning">
              Votre profil est désactivé, vous ne pouvez actuellement pas être
              sollicité.
            </div>
            <Link
              to={"/app/profiles/tisio/preferences"}
              className="btn--2 --block"
            >
              Activer mon profil
            </Link>
          </>
        )}

        {profile.published && (
          <div className="widget_profile-display">
            <Link to="/app/my-account" className="link --soft">
              <ProfilePic
                src={profile.user.profilePicture}
                className={
                  !profile.user.profilePicture ? "--s --editable" : "--s"
                }
              />
            </Link>
            <div className="profile_data">
              <div className="lrow --center--v --distribute --gap--v--xs">
                <div className="section_title">{profile.user.displayName}</div>
                <div>
                  <BookingProfileStatuses profile={profile} />
                </div>
              </div>

              <div className="profile_price-display cblock--xs">
                {/* TODO : centraliser le calcul */}
                <DisplayPrice
                  amount={profile.price}
                  className="price"
                  addon={<span className="timeslot">/ 30 minutes</span>}
                />
              </div>

              {/* TODO : indication rému exacte (45% ou 0 si 100% don) */}
              {/* <p className="body--20">
                Vous percevez <DisplayPrice amount={profile.price} /> par visio
              </p> */}

              <div className="cblock--s">
                <Link to="/app/profiles/tisio" className="profile_edit-btn" />
              </div>
            </div>
          </div>
        )}
        {profile.published && loggedUser.completedOnboarding && (
          <ClientOnly>
            {() => {
              const profileUrl = `${location.origin}/c/${profile.community.slug}/${profile.user.username}`;

              return (
                <div className="cblock">
                  <CopyToClipboard value={profileUrl}>
                    <Link
                      to={profileUrl}
                      target="_blank"
                      className="link--accent --s --with-icon"
                    >
                      <div className="ui-row --center">
                        <div>Votre page de réservation</div>
                      </div>
                    </Link>
                  </CopyToClipboard>
                </div>
              );
            }}
          </ClientOnly>
        )}
      </div>
    </div>
  );
}

export default BookingProfileWidgetComponent;
