import { BookingProfile } from "../bookingProfile/bookingProfileModel";
import { Campaign } from "../campaign/campaignModel";
import { Voucher } from "../payment/paymentModel";

export interface BookingRequest {
  senderId: BookingProfile["id"];
  sender?: BookingProfile;
  context: string;
  eCredit: number | null;
  recipientProbono?: boolean;
  // normal solicitation
  recipientId?: BookingProfile["id"];
  recipient?: BookingProfile;
  // external solicitation
  businessAccountId?: number;
  extFirstname?: string;
  extLastname?: string;
  extEmail?: string;
  extConsent?: boolean;
  price?: number;

  // Properties after creation
  fee?: number;
  status?:
    | "PAID"
    | "CREATED"
    | "ACCEPTED"
    | "DECLINED"
    | "PENDING"
    | "BOOKED"
    | "CLOSED"
    | "CONFLICT";
  id?: number;
  uuid?: string;
  paymentSecret?: string;
  paidPrice?: number;
  booking?: Booking;
  senderAttendanceDeclaration?: BookingRequestAttendanceDeclaration;
  senderComment?: string | null;
  senderAdminComment?: string | null;
  senderRating?: number;
  senderClosureDate?: string | null;
  recipientAttendanceDeclaration?: BookingRequestAttendanceDeclaration;
  recipientComment?: string | null;
  recipientAdminComment?: string | null;
  recipientRating?: number;
  recipientClosureDate?: string | null;
  createdAt?: string;
  voucherId?: number | null;
  voucher?: Voucher;
  requestMailSent?: string;
  campaignId?: Campaign["id"];
  declineReasonId?: number;
  otherReason?: string;
  external?: boolean;

  campaign?: Campaign;
}

export enum BookingRequestAttendanceDeclaration {
  BOTH_WHERE_HERE = "BOTH_WHERE_HERE",
  SENDER_WAS_NOT_HERE = "SENDER_WAS_NOT_HERE",
  RECIPIENT_WAS_NOT_HERE = "RECIPIENT_WAS_NOT_HERE",
}
export const bookingRequestAttendanceDeclarationList = Object.values(
  BookingRequestAttendanceDeclaration,
);

export interface Booking {
  startTime: string;
  endTime: string;
  status: "cancelled" | "accepted" | "pending" | "rejected" | "awaiting_host";
  uid: string;
  responses: {
    name: string;
    email: string;
    tisioBookingId: BookingRequest["uuid"];
  };
}

export enum AppointmentsStatus {
  TO_COME = "à venir",
  ONGOING = "en cours",
  TO_CLOSE = "à cloturer",
  CLOSED = "terminés",
}
