import React, { useMemo } from "react";
import i18n from "../internationalization/i18n";

interface DisplayPriceProps {
  amount: number;
  // if undefined, no taxFree display, if true, display HT, if false, display TTC
  taxFree?: boolean;
  addon?: string | React.ReactNode;
  currency?: string;
  className?: string;
  withSign?: boolean;
  textOnly?: boolean;
}
// TODO: @CG integration
const DisplayPrice = ({
  amount,
  taxFree,
  addon,
  currency = "EUR",
  className,
  withSign,
  textOnly,
}: DisplayPriceProps) => {
  // this variable is an object : {formatedPrice: string[], currency: {value: string, position: "before" | "after"}}
  const formatedPriceWithCurrency = useMemo(() => {
    // get current language of the app
    const language = i18n.language;
    // Format the price with the currency and return the formated price and the currency in an array of object : { value: string, type: "currency" | "group" | "integer" | "decimal" }
    const formatedPrice = Intl.NumberFormat(language, {
      style: "currency",
      currency: currency,
    }).formatToParts(amount / 100);

    return {
      // filter the formated price to get only the integer and decimal part and join them to get the price as a string
      formatedPrice: formatedPrice
        .filter((part) => part.type !== "currency")
        .map((part) => part.value)
        .join("")
        // NOTE: if they are another method to check the good separator of the price, change this line
        .split(language === "fr" ? "," : "."),

      // get the currency and its position (depends on the language)
      currency: {
        value:
          formatedPrice.find((part) => part.type === "currency")?.value ?? "€",
        position:
          formatedPrice.findIndex((part) => part.type === "currency") === 0
            ? "before"
            : "after",
      },
    };
  }, [amount, currency]);

  if (textOnly) {
    let priceAsText = "";

    if (formatedPriceWithCurrency.currency.position === "before")
      priceAsText += formatedPriceWithCurrency.currency.value;
    if (withSign && amount > 0) priceAsText += "+";
    priceAsText += formatedPriceWithCurrency.formatedPrice[0];
    if (
      formatedPriceWithCurrency.formatedPrice[1] &&
      Number(formatedPriceWithCurrency.formatedPrice[1]) > 0
    )
      priceAsText += `,${formatedPriceWithCurrency.formatedPrice[1]}`;
    if (formatedPriceWithCurrency.currency.position === "after")
      priceAsText += `${formatedPriceWithCurrency.currency.value} `;
    if (taxFree === true) priceAsText += "HT";
    else if (taxFree === false) priceAsText += "TTC";

    return priceAsText;
  }

  return (
    <>
      <span className={className}>
        {formatedPriceWithCurrency.currency.position === "before" &&
          formatedPriceWithCurrency.currency.value}
        {withSign && amount > 0 && <span>+</span>}
        <span>{formatedPriceWithCurrency.formatedPrice[0]}</span>
        {formatedPriceWithCurrency.formatedPrice[1] &&
          Number(formatedPriceWithCurrency.formatedPrice[1]) > 0 && (
            <span>,{formatedPriceWithCurrency.formatedPrice[1]}</span>
          )}
        {formatedPriceWithCurrency.currency.position === "after" &&
          formatedPriceWithCurrency.currency.value}{" "}
        {taxFree === true ? "HT" : taxFree === false && "TTC"}
      </span>
      {addon}
    </>
  );
};

export default DisplayPrice;
