import { Link } from "react-router-dom";

function CodeOfConductPage() {
  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">
          Code de conduite professionnelle des profils Tisio
        </h1>
      </div>
      <div className="page-content lblocks">
        {/* Introduction Section */}
        <div className="cblocks">
          <h2 className="section_title">Introduction</h2>
          <p>
            La mission de Tisio est de faciliter la mise en relation entre les
            professionnels en poste pour alimenter le progrès des
            professionnels, des entreprises et de la société. Afin de réaliser
            notre mission, nous maintenons et appliquons un ensemble de normes
            pour protéger et respecter toutes les parties impliquées. Ce code de
            conduite énonce nos attentes envers les profils Tisio à l'égard des
            utilisateurs de la plateforme Tisio.
          </p>
          <p>
            Veuillez noter que rien dans ce code de conduite n'a pour intention
            de remplacer une obligation ou une responsabilité acceptée par vous
            dans les Conditions d'engagement de Tisio et les engagements de
            conformité. Toute violation de ce code de conduite peut entraîner la
            suppression immédiate et définitive de votre profil sur la
            plateforme Tisio.
          </p>
        </div>

        {/* Engagements de conformité Section */}
        <div className="cblocks">
          <h2 className="section_title">Engagements de conformité</h2>
          <ul>
            <li>
              Vous ne devez pas divulguer d'informations spécifiques à une
              entreprise pour laquelle vous êtes actuellement employé, officier,
              directeur, consultant, agent ou représentant.
            </li>
            <li>
              Vous ne devez pas divulguer d'informations confidentielles ou
              propriétaires, ni de renseignements non publics, sur un sujet
              quelconque tout en travaillant avec Tisio et l'un de ses
              utilisateurs.
            </li>
            <li>
              Vous ne devez pas participer à une visio si elle crée un conflit
              d'intérêts pour vous personnellement ou par rapport à toute
              obligation que vous avez envers un employeur actuel ou ancien ou
              un tiers.
            </li>
          </ul>
          <p>
            Le contenu des échanges d’une visio doit rester confidentiel, y
            compris son sujet et l'identité des parties participant.
          </p>
          <ul>
            <li>
              Tisio n'autorise pas la participation de toute personne qui, à
              titre personnel, (a) a été reconnue coupable d'un crime ; (b) a
              fait l'objet de sanctions administrées par une juridiction
              quelconque ; (c) a fait l'objet d'une enquête menée par un
              régulateur des valeurs mobilières dans une juridiction quelconque
              ; (d) a été défendeur dans une procédure où la fraude ou la
              violation de lois est alléguée ; et/ou (e) a été soumise à de
              telles condamnations, sanctions, listes, enquêtes ou procédures.
            </li>
          </ul>
        </div>

        {/* Représentation précise Section */}
        <div className="cblocks">
          <h2 className="section_title">Représentation précise</h2>
          <h3 className="paragraph_title">
            Vos informations et expérience professionnelles :
          </h3>
          <p>
            Vous ne devez pas induire en erreur ou tromper les utilisateurs de
            Tisio concernant votre expertise et votre expérience. Vous devez
            fournir des informations précises, à jour et vérifiables concernant
            votre historique professionnel, votre nom légal, vos affiliations et
            votre expérience professionnelle. Par conséquent, vous devez mettre
            à jour votre profil Tisio si de telles informations changent avant
            une visio.
          </p>
          <h3 className="paragraph_title">Plagiat :</h3>
          <p>
            Vous devez fournir vos propres idées et réflexions basées sur vos
            connaissances et votre expertise. Vous ne devez pas utiliser le
            travail, les idées ou la thèse d'un tiers et les faire passer pour
            les vôtres lors de votre participation à une visio, y compris tout
            enregistrement d'engagement d'expert. Cela inclut l'utilisation de
            l'IA pour produire des idées et des réflexions. Vous ne pouvez
            partager des informations tierces que si vous avez l'autorisation
            requise et si vous avez clairement indiqué que ces informations ne
            sont pas les vôtres.
          </p>
        </div>

        {/* Comportement professionnel Section */}
        <div className="cblocks">
          <h2 className="section_title">
            Comportement professionnel envers Tisio et ses utilisateurs
          </h2>
          <h3 className="paragraph_title">Discrimination et harcèlement :</h3>
          <p>
            Vous devez traiter tout le monde de manière égale et avec respect
            dans chaque visio à laquelle vous participez par le biais de Tisio.
            Tisio ne tolère pas les traitements différenciés en fonction de la
            race, de l'ethnicité, de l'origine naturelle, de l'affiliation
            religieuse, de l'âge, de l'état matrimonial, de l'orientation
            sexuelle, du genre ou du handicap.
          </p>
          <h3 className="paragraph_title">Fiabilité :</h3>
          <p>
            Vous devez être disponible et ponctuel pour les visios Tisio
            planifiées. Surtout, veuillez être prêt à partager vos perspectives
            et vos connaissances concernant l’objet de la visio. Si vous avez
            besoin de reporter ou réalisez que vous serez en retard, vous devez
            contacter immédiatement votre point de contact Tisio afin que nous
            puissions reporter avec l’utilisateur l’horaire et/ou la date de la
            visio.
          </p>
        </div>

        {/* Respect des engagements Section */}
        <div className="cblocks">
          <h2 className="section_title">Respect des engagements</h2>
          <p>
            Tisio peut, à sa seule discrétion, supprimer votre profil Tisio à
            tout moment, y compris en raison de votre non-respect de ce code de
            conduite. Si approprié et autorisé, nous pouvons choisir de fournir
            des commentaires et une explication pour toute violation de ce code
            de conduite.
          </p>
        </div>
        <div className="--txt--center">
          <Link className="btn--2" to="/faq">
            Consulter la FAQ
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CodeOfConductPage;
