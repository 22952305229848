import { baseAPI } from "../api";
import { User } from "../user/userModel";
import { BookingRequest } from "./bookingRequestModel";

export function createBookingRequestRequest(
  bookingRequest: BookingRequest,
): Promise<BookingRequest> {
  return baseAPI
    .post("/booking-requests", bookingRequest)
    .then(({ data }) => data);
}

export function getBookingRequestByUuidRequest(
  uuid: BookingRequest["uuid"],
): Promise<BookingRequest> {
  return baseAPI.get(`/booking-requests/${uuid}`).then(({ data }) => data);
}

export function getPublicBookingRequestByUuidRequest(
  uuid: BookingRequest["uuid"],
): Promise<BookingRequest> {
  return baseAPI
    .get(`/booking-requests/${uuid}/public`)
    .then(({ data }) => data);
}

export function updateBookingRequestByIdRequest(
  bookingRequestIdOrUuid: BookingRequest["id"] | BookingRequest["uuid"],
  bookingRequest: BookingRequest,
): Promise<BookingRequest> {
  return baseAPI
    .put(`/booking-requests/${bookingRequestIdOrUuid}`, bookingRequest)
    .then(({ data }) => data);
}

export function getCurrentUserBookingRequestsRequest(
  userId: User["id"],
): Promise<BookingRequest[]> {
  return baseAPI
    .get(`/booking-requests/user/${userId}`)
    .then(({ data }) => data);
}

export function deleteBookingRequestRequest(
  bookingRequestId: BookingRequest["id"],
): Promise<void> {
  return baseAPI.delete(`/booking-requests/${bookingRequestId}`);
}
