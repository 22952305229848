import { useMemo, useState } from "react";
import BusinessAccountPaymentMethodDialog from "../campaigns/campaign/BusinessAccountPaymentMethodDialog";
import { BusinessAccount } from "../../../services/businessAccount/businessAccountModel";

const ProPaymentMethodForm = ({
  businessAccount,
}: {
  businessAccount: BusinessAccount;
}) => {
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);

  const card = useMemo(
    () => businessAccount.stripeCustomer?.card,
    [businessAccount],
  );
  const isBusinessAccountCardExpired = useMemo(() => {
    if (!businessAccount?.stripeCustomer?.card) return true;

    const card = businessAccount.stripeCustomer.card;
    const today = new Date();
    if (card.exp_year < today.getFullYear()) return true;
    if (
      card.exp_month < today.getMonth() + 1 &&
      card.exp_year == today.getFullYear()
    )
      return true;

    return false;
  }, [businessAccount]);

  return (
    <div>
      {!card ? (
        <div className="info --error">
          <div>
            Vous devez{" "}
            <button
              type="button"
              className="link --s"
              onClick={() => setShowPaymentMethod(true)}
            >
              enregistrer un moyen de paiement
            </button>{" "}
            sur votre compte pro Tisio
          </div>
        </div>
      ) : (
        <div className="cblocks--s">
          {isBusinessAccountCardExpired && (
            <div>
              <span className="chip --error">Expiré</span>
            </div>
          )}

          <div className="info --no-icon --strong">
            {card.display_brand.toLocaleUpperCase()} #### #### #### {card.last4}{" "}
            [exp. {card.exp_month}/{card.exp_year}]
          </div>
          <div className="--txt--right">
            <button
              className="link --s"
              type="button"
              onClick={() => setShowPaymentMethod(true)}
            >
              mettre à jour le moyen de paiement
            </button>
          </div>
        </div>
      )}

      {showPaymentMethod && (
        <BusinessAccountPaymentMethodDialog
          businessAccount={businessAccount}
          onClose={() => setShowPaymentMethod(false)}
        />
      )}
    </div>
  );
};

export default ProPaymentMethodForm;
