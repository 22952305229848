export const BASE_URL = import.meta.env.VITE_API_HOST;
export const DEFAULT_LOG_LEVEL = import.meta.env.VITE_LOG_LEVEL;
export const IS_PROD = import.meta.env.PROD;
export const IS_DEV = import.meta.env.DEV;
export const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
export const CAL_BASE_URL = import.meta.env.VITE_CAL_URL;
export const TISIO_FEE_PERCENTAGE = import.meta.env.VITE_FEE_PERCENTAGE;
export const ASSOCIATION_PERCENTAGE = 10;
export const MINIMUM_VISIO_PRICE = 30;
export const LONG_TOAST_DURATION_IN_MS = 30 * 1000;
export const MAXIMUM_VISIO_PRICE = 5000;
