import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { cx } from "../../react-helpers/css";
import Icon from "../../components/Icon";
import { isMimeTypeAccepted } from "../../react-helpers/files";
import { useFormikContext } from "formik";
import { randomUUID } from "../../react-helpers/crypto";
import { ClientOnly } from "../../react-helpers/react";

type SxFileList = (File | string)[];

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "onChange" | "children"
  > {
  onChange?(list: SxFileList): void;
  dragLabel?: string;
  className?: string;
  children?:
    | ReactNode
    | ((list: SxFileList, remove: (list: SxFileList) => void) => ReactNode);
}

function hasUnacceptedFiles(items: DataTransferItemList, accept: string) {
  for (const item of items) {
    if (!isMimeTypeAccepted(accept, item.type)) {
      return true;
    }
  }
}

const SxFileInputInner = (props: Props) => {
  const formik = useFormikContext();
  const fileZone = useRef<HTMLDivElement>(null);
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);
  const dragCountRef = useRef<number>(0);
  const [impossible, setImpossible] = useState<
    false | "not-multiple" | "invalid-mime"
  >(false);

  const {
    onChange,
    accept,
    multiple,
    dragLabel,
    value,
    name,
    children,
    ...otherProps
  } = props;

  const fieldValue: (File | string)[] = useMemo(
    () => value ?? (name && (formik?.values as any)[name]) ?? [],
    [value, name, formik?.values],
  );
  const inputUuid = useMemo(() => randomUUID(), []);

  const handleChange = useCallback(
    (files: SxFileList | null) => {
      if (onChange) onChange(files ?? []);
      else if (formik?.setFieldValue && name)
        void formik.setFieldValue(name, files ?? []);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, formik?.setFieldValue, name],
  );

  const handleDelete = useCallback(
    (filesToDelete: SxFileList) => {
      const newFiles = fieldValue.filter((f) => !filesToDelete.includes(f));
      handleChange(newFiles);
    },
    [handleChange, fieldValue],
  );

  // Handle input change
  useEffect(() => {
    function listener() {
      const inputFile = inputFileRef.current!;
      return handleChange(inputFile.files ? [...inputFile.files] : null);
    }

    const inputFile = inputFileRef.current!;
    inputFile.addEventListener("change", listener);
    return () => {
      inputFile.removeEventListener("change", listener);
    };
  }, [inputFileRef, handleChange]);

  // Handle drag and drop
  useEffect(() => {
    const div = fileZone.current!;
    const handlers = {
      dragenter(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        dragCountRef.current++;
        if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
          setDragging(true);

          if (accept && hasUnacceptedFiles(e.dataTransfer.items, accept))
            setImpossible("invalid-mime");
          else if (!multiple && e.dataTransfer.items.length > 1)
            setImpossible("not-multiple");
        }
      },
      dragleave(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        dragCountRef.current--;
        if (dragCountRef.current === 0) {
          setDragging(false);
          setImpossible(false);
        }
      },
      dragover(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
      },
      drop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        setImpossible(false);
        if (
          e.dataTransfer?.files &&
          e.dataTransfer.files.length > 0 &&
          (!accept || !hasUnacceptedFiles(e.dataTransfer.items, accept)) &&
          (multiple ?? e.dataTransfer.files.length === 1)
        ) {
          handleChange([...e.dataTransfer.files]);
        }
        dragCountRef.current = 0;
      },
    };
    Object.entries(handlers).map(([key, value]) =>
      div.addEventListener(
        key as "dragenter" | "dragleave" | "dragover" | "drop",
        value,
      ),
    );

    return () => {
      if (div) {
        Object.entries(handlers).map(([key, value]) =>
          div.removeEventListener(
            key as "dragenter" | "dragleave" | "dragover" | "drop",
            value,
          ),
        );
      }
    };
  }, [fileZone, handleChange, accept, multiple]);

  const showChildren = useMemo(
    () => fieldValue && fieldValue.length > 0 && children,
    [fieldValue, children],
  );

  // We memoize children so if other formik fields are updated, it will not be re-rendered
  const childrenMemoized = useMemo(
    () =>
      showChildren && typeof children === "function"
        ? children(fieldValue, handleDelete)
        : children,
    [showChildren, children, fieldValue, handleDelete],
  );

  return (
    <div
      ref={fileZone}
      className={cx(["file-drop", props.className, dragging && "active"])}
    >
      <input
        id={inputUuid}
        accept={accept}
        multiple={multiple}
        type={"file"}
        hidden
        {...otherProps}
        ref={inputFileRef}
      />
      {showChildren ? (
        <>
          {childrenMemoized}
          <button
            className="file-drop_delete-btn"
            type="button"
            onClick={() => handleDelete(fieldValue)}
          />
        </>
      ) : (
        <>
          <Icon name={"upload"} className="--blue" />
          {dragLabel
            ? dragLabel
            : `Déposez ${multiple ? "vos fichiers" : "votre fichier"} ici`}
          <div>ou</div>
          <label className={"upload-btn"} htmlFor={inputUuid}>
            <Icon name={"search_computer"} />
            <div>Recherchez sur votre ordinateur</div>
          </label>
        </>
      )}
      {impossible === "invalid-mime" ? (
        <p>Impossible de placer ce type de fichier</p>
      ) : (
        impossible === "not-multiple" && (
          <p>Ce champ ne peut contenir qu'un seul fichier</p>
        )
      )}
    </div>
  );
};

const SxFileInput = (props: Props) => {
  // We use the ClientOnly with a function so that the props are evaluated lazily
  return <ClientOnly>{() => <SxFileInputInner {...props} />}</ClientOnly>;
};

export default SxFileInput;
