import SxForm from "../../../forms/SxForm";
import useReload from "../../../hooks/useReload";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import ValidationErrors from "../../../forms/ValidationErrors";
import {
  Link,
  useLoaderData,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { Expertise, Language, Skill } from "../../../services/data/dataModel";
import { useMemo, useState } from "react";
import { BookingProfileInterface } from "./bookingProfileManagementPage/BookingProfileManagementPage";
import {
  bookingProfileDescriptionSchema,
  bookingProfileService,
} from "../../../services/bookingProfile/bookingProfileService";
import { dataService } from "../../../services/data/dataService";

function BookingProfileDescriptionForm() {
  const reload = useReload();
  const navigate = useNavigate();

  const { skills, expertises, languages } = useLoaderData() as {
    skills: Skill[];
    expertises: Expertise[];
    languages: Language[];
  };
  const profileInterface = useOutletContext<BookingProfileInterface>();
  const { createSkill } = dataService();
  const { updateBookingProfile } = bookingProfileService();

  const [mappedSkills, setMappedSkills] = useState(
    skills.map((s) => ({ label: s.labelFr!, value: String(s.id) })),
  );
  const mappedExpertises = useMemo(
    () => expertises.map((e) => ({ label: e.labelFr!, value: String(e.id) })),
    [expertises],
  );
  const mappedLanguages = useMemo(
    () => languages.map((l) => ({ label: l.labelFr!, value: String(l.id) })),
    [languages],
  );

  return (
    <SxForm
      enableReinitialize
      initialValues={profileInterface.profile}
      onSubmit={async (values) => {
        const updatedProfile = {
          ...profileInterface.profile,
          ...bookingProfileDescriptionSchema.cast(values),
          price: profileInterface.profile.price * 100,
        };

        await updateBookingProfile({
          ...updatedProfile,
          languages: updatedProfile.languages?.map((l) => ({ id: Number(l) })),
          expertises: updatedProfile.expertises?.map((e) => ({
            id: Number(e),
          })),
          skills: updatedProfile.skills?.map((s) => ({ id: Number(s) })),
        });
        reload();

        return profileInterface.isPositionComplete
          ? profileInterface.isPreferencesComplete
            ? []
            : navigate("../preferences")
          : navigate("../position");
      }}
      validationSchema={bookingProfileDescriptionSchema}
    >
      <div className="lblocks">
        <div>
          <SxField name="description" as="rte" data-testid="description" />
          <div className="field-tip">
            Décrivez vos compétences, expertises et expériences pour maximiser
            votre visibilité et vos réservations de visio.{" "}
            <Link className="link" to="/help/profile-advice" target="blank">
              Voir nos conseils
            </Link>
          </div>
        </div>
        <div className="grid--2">
          <SxField
            name={"expertises"}
            as={"autocomplete"}
            options={mappedExpertises}
            isMulti
            placeholder="Sélectionnez jusqu'à 3 domaines"
            data-testid="expertises"
          />

          <SxField
            name={"skills"}
            as={"autocomplete"}
            creatable
            options={mappedSkills}
            isMulti
            placeholder="Sélectionnez jusqu'à 10 compétences"
            formatCreateLabel={(userInput) =>
              `Créer la compétence : ${userInput}`
            }
            isValidNewOption={(option) => option.length <= 35}
            onCreateOption={async (option) => {
              const skill = await createSkill(option);
              const newSkillEntry = {
                label: skill.labelFr!,
                value: String(skill.id),
              };
              setMappedSkills((prev) => [...prev, newSkillEntry]);
              return newSkillEntry;
            }}
            data-testid="skills"
          />

          <div>
            <SxField
              name="linkedin"
              placeholder="https://www.linkedin.com/in/..."
              data-testid="linkedin"
            />
            <div className="field-tip">
              Votre profil LinkedIn n'est pas obligatoire, mais peut
              considérablement augmenter vos chances d’être sollicité(e) !
            </div>
          </div>

          <SxField
            name={"languages"}
            as={"autocomplete"}
            options={mappedLanguages}
            isMulti
            className="react-select"
            classNamePrefix="react-select"
            placeholder="Sélectionnez au moins une langue"
            data-testid="languages"
          />
        </div>
      </div>

      <div className="form_footer">
        <ValidationErrors />
        <div className="cblock">
          <SubmitButton
            className="btn--1"
            type="submit"
            data-testid="booking-profile-description-submit"
          >
            Enregistrer
          </SubmitButton>
        </div>
      </div>
    </SxForm>
  );
}

export default BookingProfileDescriptionForm;
