import { useFormikContext } from "formik";
import { useCallback, useContext, useMemo } from "react";
import { AnySchema, getIn as yupGetIn } from "yup";
import { YupSchemaContext } from "../SxForm";
import { loggerBuilder } from "../../services/logger";

const logger = loggerBuilder("yup-field");

function useYupField(name: string): AnySchema | null {
  const schema = useContext(YupSchemaContext)?.schema ?? null;
  const { values } = useFormikContext();

  return useMemo(() => {
    try {
      const reached = yupGetIn(schema, name, values);

      return reached.schema.resolve({
        parent: reached.parent,
        value: reached.parent ? reached.parent[reached.parentPath] : values,
      }) as AnySchema | null;
    } catch {
      logger.info(`Field ${name} does not exist in yup schema`);
      return null;
    }
  }, [name, schema, values]);
}

export function useYupFields(): (
  names: string[],
) => Record<string, AnySchema | null> {
  const { schema } = useContext(YupSchemaContext)!;
  const { values } = useFormikContext();

  return useCallback(
    (names) => {
      const reached_names: Record<string, AnySchema | null> = {};
      for (const name of names) {
        try {
          const reached = yupGetIn(schema, name, values);
          reached_names[name] = reached.schema.resolve({
            parent: reached.parent,
            value: reached.parent ? reached.parent[reached.parentPath] : values,
          }) as AnySchema | null;
        } catch {
          logger.info(`Field ${name} does not exist in yup schema`);
          continue;
        }
      }
      return reached_names;
    },
    [schema, values],
  );
}

export default useYupField;
