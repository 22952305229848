import { useMemo } from "react";
import { authService } from "../../services/auth/authService";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";

const { getLoggedUserId } = authService();

const ProfileTags = ({ profile }: { profile: BookingProfile }) => {
  const loggedUserId = useMemo(() => getLoggedUserId(), []);

  if (profile.expertises.length + profile.skills.length === 0) return;
  return (
    <div className="profile_tags">
      <div className="ui-row --s">
        {profile.expertises.map((expertise) => (
          <span key={expertise.id} className="tag">
            {expertise.labelFr}
          </span>
        ))}
      </div>
      <div className="ui-row --s">
        {profile.skills
          .filter((skill) => profile.userId === loggedUserId || skill.validated)
          .map((skill) => (
            <span key={skill.id} className="tag--3">
              {skill.labelFr}
            </span>
          ))}
      </div>
    </div>
  );
};

export default ProfileTags;
