import { useLoaderData } from "react-router-dom";
import BackButton from "../../components/BackButton";
import ContactForm from "./ContactForm";
import { User } from "../../services/user/userModel";

function ContactPage() {
  const { user } = useLoaderData() as {
    user: User | null;
  };

  return (
    <div className="container --wrap--s">
      <div className="page_head">
        <div>
          <BackButton />
          <h1 className="page_title">Une question ? Un problème ?</h1>
        </div>
      </div>

      <div className="section">
        <div className="info --msg">
          <p>Vous pouvez :</p>
          <div>
            nous joindre au{" "}
            <a className="link--accent" href="callto:+33757954068">
              07 57 95 40 68
            </a>
            ,
          </div>
          <div>
            <a
              className="link--accent"
              href="https://zcal.co/robinson/15min"
              target="_blank"
              rel="noreferrer"
            >
              réserver un call avec Robinson
            </a>
          </div>
          <div>ou nous écrire via le formulaire ci-dessous :</div>
        </div>

        <div className="lblock">
          <ContactForm
            initialValues={{
              email: user?.email ?? "",
              firstname: user?.firstname ?? "",
              lastname: user?.lastname ?? "",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
