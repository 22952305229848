import React from "react";
import ReactDOM from "react-dom/client";

import SsrRoot from "./views/SsrRoot.tsx";

import "anylogger-loglevel";
import "./services/payment/stripeConfig.ts";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { routes } from "./router/routes.tsx";
import * as Sentry from "@sentry/react";

void hydrate();

async function hydrate() {
  // Determine if any of the initial routes are lazy
  const lazyMatches = matchRoutes(routes, window.location)?.filter(
    (m) => m.route.lazy,
  );

  // Load the lazy matches and update the routes before creating your router
  // so we can hydrate the SSR-rendered content synchronously
  if (lazyMatches && lazyMatches?.length > 0) {
    await Promise.all(
      lazyMatches.map(async (m) => {
        const routeModule = await m.route.lazy!();
        Object.assign(m.route, { ...routeModule, lazy: undefined });
      }),
    );
  }

  Sentry.init({
    dsn: "https://9f440acd6f785fd55b94f4f0017c8ab3@sentry.simplx.fr/3",
    // This enables automatic instrumentation (highly recommended)
    // If you only want to use custom instrumentation:
    // * Remove the BrowserTracing integration
    // * add Sentry.addTracingExtensions() above your Sentry.init() call
    integrations: [
      // Or, if you are using react router, use the appropriate integration
      // See docs for support for different versions of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // For finer control of sent transactions you can adjust this value, or
    // use tracesSampler
    tracesSampleRate: 1.0,

    environment: import.meta.env.PROD ? "production" : "local",
  });
  const sentryCreateBrowserRouter =
    Sentry.wrapCreateBrowserRouter(createBrowserRouter);

  // Create the browser router
  const router = sentryCreateBrowserRouter(routes);

  // NOTE: If you use hydrateRoot, double-check for hydrate={true} in entry-server.tsx
  ReactDOM.hydrateRoot(
    document.getElementById("root")!,
    <React.StrictMode>
      <SsrRoot>
        <RouterProvider router={router} />
      </SsrRoot>
    </React.StrictMode>,
  );
}
