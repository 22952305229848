import { Outlet, useLoaderData } from "react-router-dom";
import Header from "./Header";
import { cx } from "../../react-helpers/css";
import { useState } from "react";
import PrivateSidebar from "./PrivateSidebar";
import { User } from "../../services/user/userModel";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import ScrollToTopOnNav from "../utilities/ScrollToTopOnNav";

function AppLayout({ className }: { className?: string }) {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { loggedUser, bookingProfile } = useLoaderData() as {
    loggedUser: User;
    bookingProfile: BookingProfile;
  };
  return (
    <div
      className={cx(["app-layout", className, isMenuOpened && "--menu-opened"])}
    >
      <div className="screen-overlay" onClick={() => setIsMenuOpened(false)} />
      <Header
        onOpenMenu={() => setIsMenuOpened(true)}
        loggedUser={loggedUser}
        area="private"
      />

      <div className="app-layout --h">
        <PrivateSidebar
          onCloseMenu={() => setIsMenuOpened(false)}
          loggedUser={loggedUser}
          bookingProfile={bookingProfile}
        />
        <div className="layout_content">
          <ScrollToTopOnNav />
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AppLayout;
