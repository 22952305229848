import axios from "axios";
import { Contact } from "../../views/contact/ContactForm";
import { TargetedProfile } from "../../views/profilesList/NoProfileFound/TargetingProfilesHelperDialog";
import { ErrorReport } from "./supportModel";
import { BASE_URL } from "../config";
import { baseAPI } from "../api";

export function contactRequest(contact: Contact) {
  return baseAPI.post("/support/contact", contact);
}

export function targetingProfileRequest(targetingProfile: TargetedProfile) {
  return baseAPI.post("/support/targeting-profile", targetingProfile);
}

export function errorReportingRequest(error: ErrorReport) {
  return axios.post(`${BASE_URL}/support/error-reporting`, error);
}
