import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../../react-helpers/date";
import { BookingRequest } from "../../../../../services/bookingRequest/bookingRequestModel";
import { mapBookingRequestToContactStatus } from "../../../../../services/campaign/campaignService";
import { CAL_BASE_URL } from "../../../../../services/config";
import DisplayPrice from "../../../../../components/DisplayPrice";
import { Link } from "react-router-dom";
import { isAfter, parseISO } from "date-fns";

const BookingsTable = ({
  date,
  bookingRequests,
  showVisioLink,
}: {
  date: "start" | "end";
  bookingRequests: BookingRequest[];
  showVisioLink?: boolean;
}) => {
  const { t } = useTranslation(["campaign"]);

  function mapBookingRequestToVisioLink(bookingRequest: BookingRequest) {
    return `${CAL_BASE_URL}/video/${bookingRequest.booking?.uid}`;
  }

  return (
    <div className="table-scroll">
      <table className="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Email</th>
            {!!showVisioLink && <th>Lien visio</th>}
            <th />
            <th>Prix</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {bookingRequests.map((bookingRequest) => (
            <tr key={bookingRequest.id}>
              <td className="--shrink">
                <strong>
                  {formatDate(
                    date === "start"
                      ? bookingRequest.booking!.startTime
                      : bookingRequest.booking!.endTime,
                    "PPPp",
                  )}
                </strong>
              </td>
              <td className="--shrink">{bookingRequest.extFirstname}</td>
              <td className="--shrink">{bookingRequest.extLastname}</td>
              <td className="--shrink">{bookingRequest.extEmail}</td>
              {!!showVisioLink && (
                <td>
                  <a href={mapBookingRequestToVisioLink(bookingRequest)}>
                    {mapBookingRequestToVisioLink(bookingRequest)}
                  </a>
                </td>
              )}
              <td className="--shrink">
                <span className="chip">
                  {t(
                    `campaign:contact-status-short.${mapBookingRequestToContactStatus(bookingRequest)}`,
                  )}
                </span>
              </td>
              <td className="--shrink --txt--right">
                {bookingRequest.paidPrice ? (
                  <DisplayPrice
                    amount={bookingRequest.paidPrice}
                    currency="EUR"
                  />
                ) : (
                  "-"
                )}
              </td>
              <td>
                <div className="ui-row --right">
                  {!bookingRequest.senderClosureDate &&
                    bookingRequest.booking?.endTime &&
                    isAfter(
                      new Date(),
                      parseISO(bookingRequest.booking.endTime),
                    ) && (
                      <Link
                        className="btn--success --s"
                        to={`/app/bookings/${bookingRequest.uuid}/closure`}
                      >
                        clôturer
                      </Link>
                    )}
                  <Link
                    className="btn --btn--icon --chevron-right"
                    to={`/app/bookings/${bookingRequest.uuid}`}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default BookingsTable;
