function PrivacyPage() {
  return (
    <div className="container page-content">
      <div className="page_head">
        <h1 className="page_title">
          Données personnelles et politique de confidentialité{" "}
        </h1>
      </div>
      <div className="page-content lblocks">
        <div className="cblocks --s">
          <p>
            De manière générale, vous n’êtes pas tenu de nous communiquer vos
            données personnelles lorsque vous visitez notre site Internet
            www.tisio.fr.
          </p>
          <p>
            Cependant, ce principe comporte certaines exceptions. En effet, pour
            certains services proposés par notre site, vous pouvez être amenés à
            nous communiquer certaines données telles que : votre nom, votre
            fonction, le nom de votre société, votre adresse électronique, et
            votre numéro de téléphone. Tel est le cas lorsque vous remplissez le
            formulaire qui vous est proposé en ligne, dans les rubriques
            “Contact”, “Faites appel à quelqu’un”, “Créez votre profil Tisio”.
            Dans tous les cas, vous pouvez refuser de fournir vos données
            personnelles. Dans ce cas, vous ne pourrez pas utiliser les services
            du site, notamment celui de solliciter des renseignements sur notre
            société, accepter une visio, faire appel à quelqu’un ou créer votre
            profil.
          </p>
          <p>
            Enfin, nous pouvons collecter de manière automatique certaines
            informations vous concernant lors d’une simple navigation sur notre
            site internet, notamment : des informations concernant l’utilisation
            de notre site, comme les zones que vous visitez et les services
            auxquels vous accédez, votre adresse IP, le type de votre
            navigateur, vos temps d’accès. De telles informations sont utilisées
            exclusivement à des fins de statistiques internes, de manière à
            améliorer la qualité des services qui vous sont proposés. Les bases
            de données sont protégées par les dispositions de la loi du 1er
            juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative
            à la protection juridique des bases de données.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            1- Objectif et champ d'application de la Politique
          </h3>
          <p>
            La Société Tisio accorde la plus grande importance et le plus grand
            soin à la protection de la vie privée et des Données à caractère
            personnel, ainsi qu’au respect des dispositions de la Législation
            applicable. Le Règlement (UE) 2016/679 du 27 avril 2016 relatif à la
            protection des personnes physiques à l’égard du Traitement des
            Données à caractère personnel et à la libre circulation de ces
            Données (ci- après « RGPD ») affirme que les Données à caractère
            personnel doivent être traitées de façon licite, loyale, et
            transparente. Ainsi, cette politique de confidentialité (ci-après la
            « Politique ») a pour objectif de vous fournir une information
            simple, claire sur les Traitements de Données à caractère personnel
            vous concernant, dans le cadre de votre navigation et des opérations
            réalisées sur nos sites Internet.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">2- Définitions</h3>
          <p>
            Données à caractère personnel/Données personnelles : Toute
            information se rapportant à une personne concernée notamment par
            référence à un identifiant tel qu’un nom, un numéro de sécurité
            sociale, un numéro de téléphone, des données de santé, des habitudes
            de consommation, des données de localisation, un identifiant en
            ligne, etc. Le terme « Données personnelles » inclut les Données à
            caractère personnel sensibles.
          </p>
          <p>
            Données à caractère personnel sensibles/Données personnelles
            sensibles : Les catégories particulières de données ou données dites
            « sensibles » sont les données à caractère personnel révélant ou
            reposant sur :
          </p>
          <ul>
            <li>
              L’origine raciale ou ethnique, les opinions politiques,
              religieuses ou philosophiques ;
            </li>
            <li>L’appartenance à une organisation syndicale ;</li>
            <li>La santé physique ou mentale ;</li>
            <li>L’orientation sexuelle ou la vie sexuelle ;</li>
            <li>Les Données génétiques et biométriques ;</li>
            <li>
              Des Données relatives aux condamnations pénales, aux infractions
              ou aux mesures de sûreté connexes.
            </li>
          </ul>
          <p>
            Traitement de données : Toute opération ou ensemble d’opérations
            effectuées ou non à l’aide de procédés automatisés et appliqués à
            des données personnelles telles que la collecte, l’accès,
            l’enregistrement, la copie, le transfert, la conservation, le
            stockage, le croisement, la modification, la structuration, la mise
            à disposition, la communication, l’enregistrement, la destruction,
            que ce soit de manière automatique, semi-automatique ou autre. Cette
            liste n’étant pas exhaustive.
          </p>
          <p>
            Responsable de traitement : Personne physique ou morale qui,
            individuellement ou conjointement, décide quelles données à
            caractère personnel sont collectées, pourquoi et comment elles sont
            collectées et traitées.
          </p>
          <p>
            Sous-traitant : Toute personne physique ou morale, l’autorité
            publique, le service ou un autre organisme qui traite des Données à
            caractère personnel au nom du Responsable de Traitement et selon ses
            instructions (par exemple des prestataires ou fournisseurs).
          </p>
          <p>
            Consentement : Toute manifestation de volonté, libre, spécifique,
            éclairée et univoque par laquelle la Personne concernée accepte, par
            une déclaration ou par un acte positif clair, que des données à
            caractère personnel la concernant fassent l’objet d’un traitement.
          </p>
          <p>
            Transfert de données : Toute communication, toute copie ou
            déplacement de données par l’intermédiaire d’un réseau, ou toute
            communication, toute copie ou déplacement de ces données d’un
            support à un autre, quel que soit ce support, de données
            personnelles vers un pays tiers à l’Union européenne ou à une
            organisation internationale qui font ou sont destinées à faire
            l’objet d’un traitement après ce transfert.
          </p>
          <p>
            Cookies : Un cookie (ou « témoin de connexion ») est un petit
            fichier stocké sur votre ordinateur qui permet le passage d’une page
            Internet à une autre en conservant vos paramètres de navigation.
          </p>
          <p>
            Utilisateur : Il s’agit de la personne naviguant sur les sites
            internet de la Société Tisio.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">3- Litiges</h3>
          <p>
            Pour l’ensemble des Traitements de Données liés à la collecte des
            cookies, la Société Tisio, inscrite au RCS de Lyon sous le n° 949
            115 018 et dont le siège social est situé 49 rue Servient 69003
            Lyon, détermine les moyens et les finalités du Traitement. Ainsi, la
            Société Tisio agit en tant que Responsable de Traitement, au sens de
            la Réglementation relative aux Données à caractère personnel, et
            notamment du Règlement (UE) 2016/679 relatif à la protection des
            personnes physiques à l’égard du Traitement des Données à caractère
            personnel et à la libre circulation de ces Données.
          </p>
          <p>
            La Société Tisio accorde la plus grande importance et le plus grand
            soin à la protection de la vie privée et des Données à caractère
            personnel, ainsi qu’au respect des dispositions de la Législation
            applicable.
          </p>
          <p>
            La présente politique Cookies (ci-après la « Politique ») a pour
            objectif de vous fournir une information simple, claire sur la
            manière dont vos Données sont collectées à travers les cookies
            déposés sur les sites internet suivants : www.tisio.fr
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            4- Quelles Données à caractère personnel collectons-nous et comment
            ?
          </h3>
          <p>
            En utilisant nos sites internet, vous nous transmettez un certain
            nombre d’informations à votre sujet dont certaines sont de nature à
            vous identifier (« Données à caractère personnel »). C’est le cas
            lorsque vous naviguez sur notre site ou lorsque vous remplissez des
            formulaires en ligne.
          </p>
          <p>
            La nature et la qualité des Données à caractère personnel collectées
            à votre sujet varient selon les relations que vous entreprenez avec
            la Société TISIO dont les principales sont :
          </p>
          <ul>
            <li>
              Données d’identification : cela comprend toutes les informations
              qui nous permettraient de vous identifier, telles que votre nom,
              prénom ou numéro de téléphone. Nous sommes également amenés à
              collecter votre adresse mail.
            </li>
            <li>
              Données de connexion : Nous pouvons collecter votre adresse IP à
              des fins de maintenance et de statistiques. Nous pouvons également
              collecter votre mot de passe de compte professionnel Linkedin,
              avec votre consentement, dans le cadre des prestations de
              prospection commerciale effectuées pour vous.
            </li>
            <li>
              Données financières : cela correspond aux Données bancaires tel
              qu’un RIB.
            </li>
            <li>
              Données relatives à votre vie professionnelle : Ces informations
              nous permettent de mieux vous connaître et de cerner davantage vos
              besoins. Exemples : Fonction, société, secteur d’activité, lien
              URL de votre profil Linkedin.
            </li>
            <li>
              Données commerciales : Ces informations nous permettent de gérer
              vos commandes et le suivi de vos commandes. Ces informations
              peuvent comprendre les prestations contractualisées, les dates de
              vos prestations, les numéros de vos commandes et l’historique de
              vos commandes.
            </li>
            <li>
              Documents de natures différentes (PDF, format Office, Image) avec
              des titres, des contenus, des noms de dossier, ou des informations
              liées à un document, telles que les justificatifs de vos commandes
              ou des échanges d’e-mails.
            </li>
            <li>
              Informations relatives à votre navigation : en navigant sur nos
              sites web, vous interagissez avec lui. Par conséquent, certaines
              informations relatives à votre navigation sont collectées.
            </li>
            <li>
              Données recueillies auprès des Tiers : les Données à caractère
              personnel que vous avez accepté de partager avec nous ou sur les
              réseaux sociaux accessibles au public et/ou que nous pourrions
              collecter à partir d’autres bases des Données accessibles au
              public.
            </li>
          </ul>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            5- Partageons-nous vos Données à caractère personnel ?
          </h3>
          <p>
            Vos Données sont destinées aux collaborateurs de la Société Tisio
            habilités en charge de la gestion et l’exécution des contrats et des
            obligations légales, en fonction des finalités de la collecte et
            dans la limite de leurs attributions respectives.
          </p>
          <p>
            Elles sont éventuellement transmises pour certaines tâches liées aux
            finalités, et dans la limite de leurs missions et habilitations
            respectives, aux destinataires suivants :
          </p>
          <ul>
            <li>
              Prestataires et sous-traitants auxquels nous faisons appel pour
              réaliser un ensemble d’opérations et tâches, en notre nom
              notamment dans le cadre de l’édition de nos logiciels métier ou
              pour la fourniture de service additionnel sur nos sites internet.
            </li>
            <li>
              Autorités publiques dûment habilitées (judiciaires, de contrôle…),
              dans le cadre de nos obligations légales et réglementaires ;
            </li>
            <li>
              Professions réglementées (avocats, huissiers, etc…) qui peuvent
              intervenir dans le cadre de la mise en œuvre des garanties, du
              recouvrement ou du contentieux ;
            </li>
            <li>
              Le Trésor public en cas d’impayés ou dans le cadre de
              vérifications.
            </li>
          </ul>
          <p>
            Lorsque vos Données sont communiquées à nos prestataires et
            sous-traitants, il leur est également demandé de ne pas utiliser les
            Données pour des finalités autres que celles initialement prévues.
          </p>
          <p>
            Nous mettons tout en œuvre pour nous assurer que ces Tiers
            préservent la confidentialité et la sécurité de vos Données.
          </p>
          <p>
            Dans tous les cas, seules les Données nécessaires sont fournies.
            Nous mettons tout en œuvre pour garantir une communication ou
            transmission sécurisée de vos Données. Nous ne vendons pas vos
            Données.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            6- Vos Données à caractère personnel sont-elles transférées vers des
            pays Tiers ?
          </h3>
          <p>
            La Société Tisio s’efforce de conserver les Données à caractère
            personnel en France, ou du moins au sein de l’Espace Économique
            Européen (EEE). Toutefois, il est possible que les Données que nous
            recueillons lorsque vous utilisez notre plateforme ou dans le cadre
            de nos services soient transférées dans d’autres pays. C’est par
            exemple le cas si certains de nos prestataires sont situés en dehors
            de l’Espace Economique Européen.
          </p>
          <p>
            En cas de Transfert de ce type, nous garantissons que celui-ci soit
            effectué :
          </p>
          <ul>
            <li>
              Vers un pays assurant un niveau de protection adéquat,
              c’est-à-dire un niveau de protection équivalent à ce que les
              Réglementations européennes exigent ;
            </li>
            <li>Dans le cadre de clauses contractuelles types ;</li>
            <li>Dans le cadre de règles internes d’entreprise.</li>
          </ul>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            7- Comment garantissons-nous la sécurité de vos Données à caractère
            personnel ?
          </h3>
          <p>
            La Société Tisio s’engage à protéger les Données à caractère
            personnel que nous collectons, ou ce que nous traitons, contre une
            perte, une destruction, altération, accès u divulgation non
            autorisée. Ainsi, nous mettons en œuvre toutes les mesures
            techniques et organisationnelles appropriées, selon la nature des
            Données et des risques que leur Traitement comporte. Ces mesures
            doivent permettre de préserver la sécurité et la confidentialité de
            vos Données à caractère personnel. Elles peuvent comprendre des
            pratiques telles que des accès limités aux Données à caractère
            personnel par des personnes habilitées, en raison de leurs
            fonctions, une pseudonymisation ou encore un chiffrement.
          </p>
          <p>
            De plus, nos pratiques et nos politiques et/ou des mesures de
            sécurité physiques et/ou logiques (accès sécurisé, procédé
            d’authentification, copie de sauvegarde, logiciel, etc) sont
            régulièrement vérifiées et mises à jour si nécessaire.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">8- Quels sont vos droits</h3>
          <p>
            Le RGPD met à la disposition des Personnes concernées des droits
            qu’elles peuvent exercer. Ainsi sont prévus :
          </p>
          <ul>
            <li>
              Droit à l’information : le droit d’avoir une information claire,
              précise et complète sur l’utilisation des Données à caractère
              personnel par la Société Tisio.
            </li>
            <li>
              Droit d’accès : le droit d’obtenir une copie des Données à
              caractère personnel que le Responsable de Traitement détient sur
              le demandeur.
            </li>
            <li>
              Droit de rectification : le droit de faire rectifier les Données à
              caractère personnel si elles sont inexactes ou obsolètes et/ou de
              les compléter si elles sont incomplètes.
            </li>
            <li>
              Droit à l’effacement / droit à l’oubli : le droit, dans certaines
              conditions, de faire effacer ou supprimer les Données, à moins que
              la Société Tisio ait un intérêt légitime à les conserver.
            </li>
            <li>
              Droit d’opposition : le droit de s’opposer au Traitement des
              Données à caractère personnel par la Société Tisio pour des
              raisons tenant à la situation particulière du demandeur (sous
              conditions).
            </li>
            <li>
              Droit de retirer son Consentement : le droit à tout moment de
              retirer le consentement lorsque le Traitement est fondé sur le
              consentement.{" "}
            </li>
            <li>
              Droit à la limitation du Traitement : le droit, dans certaines
              conditions, de demander que le Traitement des Données à caractère
              personnel soit momentanément suspendu.{" "}
            </li>
            <li>
              Droit à la portabilité des Données : le droit de demander que les
              Données à caractère personnel soient transmises dans un format
              réexploitable permettant de les utiliser dans une autre base de
              données.
            </li>
            <li>
              Droit de ne pas faire l’objet d’une décision automatisée : le
              droit pour le demandeur de refuser la prise de décision
              entièrement autorisée et/ou d’exercer les garanties
              supplémentaires offertes en la matière.
            </li>
            <li>
              Droit de définir des directives post-mortem : le droit pour le
              demandeur de définir des directives relatives au sort des Données
              à caractère personnel après sa mort.
            </li>
          </ul>
          <p>
            Des droits additionnels peuvent être octroyés par la Réglementation
            locale aux Personnes concernées. A cette fin, la Société Tisio a mis
            en œuvre une procédure de gestion des droits des Personnes conformes
            aux exigences de la Législation applicable. Cette procédure établit
            :
          </p>
          <ul>
            <li>
              Les standards à respecter pour assurer l’information transparente
              des Personnes concernées;
            </li>
            <li>Les exigences légales qui doivent être respectées ;</li>
            <li>
              Les moyens autorisés pour présenter une demande pour chaque droit,
              selon la catégorie de Personnes concernées ;
            </li>
            <li>
              Les processus opérationnels pour traiter ces demandes conformément
              aux exigences susmentionnées ;
            </li>
            <li>
              Les parties impliquées dans ces processus, leurs rôles et
              responsabilités. Pour exercer vos droits, vous pouvez contacter le
              référent DATA de la Société TISIO, à l’adresse suivante :
              amelie[a]tisio.fr.
            </li>
          </ul>

          <p>
            Lorsque vous nous transmettez une demande d’exercice de Droit, il
            vous est demandé de préciser autant que possible le périmètre de la
            demande, le type de droit exercé, le Traitement de Données à
            caractère personnel concerné, et tout autre élément utile, afin de
            faciliter l’examen de votre demande. En outre, en cas de doute
            raisonnable, il peut vous être demandé de justifier de votre
            identité. Vous disposez également du droit de saisir la Commission
            Nationale de l’Informatique et des Libertés (CNIL), par courrier
            postal à l’adresse suivante, 3 Place de Fontenoy – TSA 80715 – 75334
            PARIS CEDEX 07, ou via l’URL https://www.cnil.fr/fr/plaintes, de
            toute réclamation se rapportant à la manière dont la Société Tisio
            collecte et traite vos données.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            9- Mise à jour de la présente Politique
          </h3>
          <p>
            La présente Politique peut régulièrement être mise à jour afin de
            tenir compte des évolutions de la Réglementation relative aux
            Données à caractère personnel.Date de dernière mise à jour :
            novembre 2022.
          </p>
        </div>

        <div className="cblocks --s">
          <h3 className="paragraph_title">
            10- Utilisation des données Google Agenda
          </h3>
          <p>
            a. [EN] The application use and transfer of information received
            from Google APIs to any other app will adhere to Google API Services
            User Data Policy, including the Limited Use requirements.
            <br />
            b. [FR] L'utilisation et le transfert des données reçues de l'API
            Google lors du partage du calendrier google respectent la politique
            de Google concernant l'utilisation des données utilisateurs : Google
            API Services User Data Policy, ce qui implique le respect des
            exigences liées à l'utilisation limitée.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
