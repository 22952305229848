import Icon from "../../../../components/Icon";
import HowItWorksSteps from "./HowItWorksSteps";
import LogoAvanci from "../../../../assets/img/landings/avanci.svg";
import LogoElao from "../../../../assets/img/landings/elao.svg";
import LogoTrustfolio from "../../../../assets/img/landings/trustfolio.svg";
import LogoTheTribe from "../../../../assets/img/landings/logo-the-tribe.png";
import LogoDcPilot from "../../../../assets/img/landings/logo-dc-pilot.png";
import LogoClubFounding from "../../../../assets/img/landings/logo-clubfounding.png";
import LogoAvicom from "../../../../assets/img/landings/logo-avicom.png";
import LogoAxa from "../../../../assets/img/landings/logo-axa.png";
import LogoNeodev from "../../../../assets/img/landings/logo-neodev.png";
import LogoMonarqk from "../../../../assets/img/landings/logo-monarqk.png";
import LogoWalflow from "../../../../assets/img/landings/logo-walflow.png";
import LogoWindoo from "../../../../assets/img/landings/logo-windoo.png";
import LogoWitik from "../../../../assets/img/landings/logo-witik.png";
import ImgBenefit1 from "../../../../assets/img/landings/benefit-1.svg";
import ImgBenefit2 from "../../../../assets/img/landings/benefit-2.svg";
import ImgBenefit3 from "../../../../assets/img/landings/benefit-3.svg";
import ImgBenefit4 from "../../../../assets/img/landings/benefit-4.svg";
import GifTisioProspection from "../../../../assets/img/landings/tisio-prospection.gif";
import ImgNewClients from "../../../../assets/img/landings/new-clients.png";
import ImgPartners from "../../../../assets/img/landings/partners.png";
import Testimonials from "./Testimonials";
import Faq from "./FaqBusiness";
import SubscriptionBlock from "./SubscriptionBlock";
import Logo from "../../../../assets/img/logo-tisio-fluo.png";
import LogoAside from "../../../../assets/img/logo-tisio-blue.png";
import { Link } from "react-router-dom";
import { ScrollToAnchor } from "../../../../components/utilities/ScrollToAnchor";
import SiteFooter from "../../../../components/layouts/SiteFooter";
import { useState } from "react";
import ScrollToTopOnNav from "../../../../components/utilities/ScrollToTopOnNav";

function BusinessLandingPage() {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="landing">
      <ScrollToTopOnNav />
      <ScrollToAnchor />
      <div className="lp_header">
        <div className="lp_header_container">
          <Link to="/" className="logo">
            <img src={Logo} alt="Logo Tisio" />
          </Link>
          <nav className="header_nav">
            <Link className="link" to="#rewarding">
              Rémunérer ses prospects
            </Link>
            <Link className="link" to="#howitworks">
              Comment ça marche
            </Link>
            <Link className="link" to="#pricing">
              Tarif
            </Link>
            <Link className="link" to="#testimonials">
              Témoignages
            </Link>
            <Link className="link" to="#faq">
              FAQ
            </Link>
            <Link
              to="https://zcal.co/huberthomas/30min"
              target="_blank"
              className="btn--2 --s"
            >
              Demander une démo
            </Link>
          </nav>
          <button
            className="toggle-sidebar-btn icon--menu"
            onClick={() => setMenuOpen(true)}
          />
        </div>
      </div>
      {menuOpen && (
        <div className="lp_mobile-menu">
          <div className="lrow --right">
            <button
              className="close-sidebar-btn icon--close"
              onClick={() => setMenuOpen(false)}
            />
          </div>

          <Link to="/" className="logo">
            <img src={LogoAside} alt="Logo Tisio" />
          </Link>
          <nav className="aside_nav">
            <Link
              className="link"
              to="#rewarding"
              onClick={() => setMenuOpen(false)}
            >
              Rémunérer ses prospects
            </Link>
            <Link
              className="link"
              to="#howitworks"
              onClick={() => setMenuOpen(false)}
            >
              Comment ça marche
            </Link>
            <Link
              className="link"
              to="#pricing"
              onClick={() => setMenuOpen(false)}
            >
              Tarif
            </Link>
            <Link
              className="link"
              to="#testimonials"
              onClick={() => setMenuOpen(false)}
            >
              Témoignages
            </Link>
            <Link className="link" to="#faq" onClick={() => setMenuOpen(false)}>
              FAQ
            </Link>
            <Link
              to="https://zcal.co/huberthomas/30min"
              target="_blank"
              className="btn--2 --block lblock"
              onClick={() => setMenuOpen(false)}
            >
              Demander une démo
            </Link>
          </nav>
        </div>
      )}
      <div className="landing_head">
        <div className="container">
          <h1 className="head_title">
            Des <em>visios rémunérées</em>
            <br />
            pour des <span className="--txt-nowrap">rendez-vous</span>{" "}
            <em>ultra-qualifiés</em>
          </h1>
          <h2 className="head_tagline">
            Générez des échanges business de qualité pour vos équipes de vente
          </h2>
          <div className="head_cta">
            <Link className="btn--cta" to="#pricing">
              <span>En savoir plus</span>{" "}
              <Icon name="arrow-right" className="--blue" />
            </Link>
          </div>
        </div>
      </div>
      <div className="section --light lblocks">
        <h2 className="section_title --txt--center">
          Vous vous reconnaissez
          <br />
          dans une de ces situations ?
        </h2>
        <div className="lrow --center">
          <div className="icon-point">
            La prospection
            <br />
            c'est laborieux
          </div>
          <div className="icon-point">
            Les rendez-vous
            <br />
            ne sont pas assez qualifiés
          </div>
          <div className="icon-point">
            Les campagnes de prospection
            <br />
            coûtent trop cher
          </div>
        </div>
      </div>
      <div className="section --logos">
        <img src={LogoAvanci} alt="Avanci" />
        <img src={LogoTrustfolio} alt="Trustfolio" />
        <img src={LogoElao} alt="Elao" />
        <img src={LogoTheTribe} alt="The Tribe" />
        <img src={LogoDcPilot} alt="DC Pilot" />
        <img src={LogoClubFounding} alt="Club Founding" />
        <img src={LogoAvicom} alt="Avicom" />
        <img src={LogoAxa} alt="Axa" />
        <img src={LogoNeodev} alt="Neodev" />
        <img src={LogoMonarqk} alt="Monarqk" />
        <img src={LogoWalflow} alt="Walflow" />
        <img src={LogoWindoo} alt="Windoo" />
        <img src={LogoWitik} alt="Witik" />
      </div>
      <div className="section --light">
        <div className="container">
          <div className="l--img-content">
            <div>
              <img src={GifTisioProspection} alt="" />
            </div>
            <div className="lblocks --l">
              <div className="cblocks">
                <h2 className="section_title">
                  Découvrez Tisio, outil de prospection qui garantit des
                  échanges avec des prospects disponibles et à l’écoute !
                </h2>
                <p>
                  Sollicitez votre fichier en quelques clics et voyez les
                  rendez-vous se caler dans votre agenda.
                  <br />
                  <strong>Fixez le tarif et maîtrisez votre budget.</strong>
                </p>
              </div>

              <div>
                <Link className="btn--cta" to="#pricing">
                  <span>En savoir plus</span>{" "}
                  <Icon name="arrow-right" className="--blue" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section --blue --bg-1 lblocks" id="rewarding">
        <div className="container lblocks --xl">
          <h2 className="section_title --txt--center">
            Chez Tisio on pense qu'il faut
            <br />
            rémunérer ses prospects.
          </h2>
          <div className="benefits">
            <div className="benefit">
              <img src={ImgBenefit1} alt="Beaucoup de rendez-vous" />
              <div>
                <h3>
                  Génère beaucoup plus de
                  <br />
                  rendez-vous
                </h3>
                <p>
                  « Mon taux de transformation a été 10x supérieur à ma campagne
                  de prospection classique »
                </p>
              </div>
            </div>
            <div className="benefit">
              <img src={ImgBenefit2} alt="Disponibilité du prospect" />
              <div>
                <h3>
                  Assure la disponibilité
                  <br />
                  du prospect
                </h3>
                <p>
                  « Le prospect m’a même demandé s’il devait préparer quelque
                  chose pour notre rendez-vous »
                </p>
              </div>
            </div>
            <div className="benefit">
              <img src={ImgBenefit3} alt="Échanges de qualité" />
              <div>
                <h3>
                  Garantit des
                  <br />
                  échanges de qualité
                </h3>
                <p>
                  « Le feedback que m’a fait le prospect a été super utile pour
                  l’amélioration de notre solution »
                </p>
              </div>
            </div>
            <div className="benefit">
              <img src={ImgBenefit4} alt="Respect de vos futurs clients" />
              <div>
                <h3>
                  Respecte et valorise
                  <br />
                  vos futurs clients
                </h3>
                <p>
                  « Le prospect m’a remercié
                  <br />
                  de l’avoir sollicité ! »
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="section --blue --bg-2 lblocks --overlapped"
        id="howitworks"
      >
        <div className="container lblocks --xl">
          <h2 className="section_title --txt--center">Comment ça marche ?</h2>
          <HowItWorksSteps />
        </div>
      </div>
      <div className="section --light --wrapper">
        <div className="section --overlapping" id="pricing">
          <SubscriptionBlock />
        </div>
        <div className="section --white">
          <div className="lp_overlapping-blocks">
            <div>
              <div className="cblocks">
                <h2 className="section_title">Trouvez de nouveaux clients</h2>
                <p>
                  Demandez l’avis de clients potentiels sur votre solution pour
                  recevoir leur feedback et leur éventuel intérêt.
                </p>
              </div>
              <img src={ImgNewClients} alt="" />
            </div>
            <div>
              <img src={ImgPartners} alt="" />
              <div className="cblocks">
                <h2 className="section_title">
                  Rencontrez vos partenaires de demain
                </h2>
                <p>
                  Échangez avec des partenaires ou prescripteurs potentiels pour
                  leur présenter vos solutions et envisager des synergies.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section" id="testimonials">
          <h2 className="section_title">Témoignages clients</h2>
          <Testimonials />
        </div>
      </div>
      <div className="section --blue --sep" />
      <div className="section --light" id="faq">
        <h2 className="section_title">Questions et réponses</h2>
        <Faq />
      </div>
      <div className="lp_footer section --blue --bg-x">
        <div className="container">
          <SiteFooter />
        </div>
      </div>
    </div>
  );
}

export default BusinessLandingPage;
