import { baseAPI } from "../api";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { NewUserData, User } from "./userModel";

export function getUserRequest(userId: User["id"]): Promise<User> {
  return baseAPI.get(`/users/${userId}`).then(({ data }) => data);
}

export function getAllUsersRequest(): Promise<User[]> {
  return baseAPI.get("/users").then(({ data }) => data);
}

export function createUserRequest(newUserData: NewUserData): Promise<User> {
  return baseAPI.post("/users", newUserData);
}

export function updateUserRequest(user: User): Promise<User> {
  return baseAPI.put(`/users/${user.id}`, user).then(({ data }) => data);
}

export function sendDeleteUserRequestRequest(): Promise<void> {
  return baseAPI.post(`/users/delete-request`);
}

export function getUserCreditsRequest(userId: User["id"]): Promise<number> {
  return baseAPI.get(`/users/${userId}/credits`).then(({ data }) => data);
}

export function getDataForUserDashboardRequest(): Promise<{
  bookingRequestsToCome: BookingRequest[];
  bookingRequestsToClose: BookingRequest[];
  nbUnbookedBookingRequests: number;
}> {
  return baseAPI.get("/users/dashboard").then(({ data }) => data);
}
