import { Link } from "react-router-dom";
import Icon from "../../components/Icon";

const SupportWidgetComponent = () => {
  return (
    <div className="widget">
      <h2 className="widget_title">Une question ? Un problème ?</h2>
      <div className="widget_content --txt--center">
        <Icon name="phone" className="--l" />
        <div className="cblocks ">
          <p>Vous pouvez nous joindre :</p>
          <div className="body--30 cblocks --xs">
            <div>
              Par téléphone au{" "}
              <a className="link--accent" href="callto:+33757954068">
                07 57 95 40 68
              </a>
            </div>
            <div>
              Via le{" "}
              <Link to={"/contact"} className="link--accent" target="_blank">
                formulaire de contact
              </Link>
            </div>

            <div>OU</div>
            <a
              href="https://zcal.co/robinson/15min"
              className="link--accent"
              target="_blank"
              rel="noreferrer"
            >
              en prenant RDV avec Robinson
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportWidgetComponent;
