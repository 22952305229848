import { useCallback, useMemo, useState } from "react";
import { object, string } from "yup";
import { campaignService } from "../../../../../../services/campaign/campaignService";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Campaign } from "../../../../../../services/campaign/campaignModel";
import { toastsWithIntl } from "../../../../../../services/toastService";
import { User } from "../../../../../../services/user/userModel";
import ManualImport from "./ManualImport";
import CsvImport from "./CsvImport";
import useReload from "../../../../../../hooks/useReload";
import { randomUUID } from "../../../../../../react-helpers/crypto";

const { addContactsToCampaign } = campaignService();

export type ImportedUser = Pick<User, "firstname" | "lastname" | "email"> & {
  id: string;
};

const ContactsImportTab = () => {
  const { campaign } = useOutletContext<{
    campaign: Campaign;
  }>();

  const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);
  const navigate = useNavigate();
  const reload = useReload();

  const [manualEntry, setManualEntry] = useState(false);
  const [contacts, setContacts] = useState<
    (Pick<User, "firstname" | "lastname" | "email"> & { id: string })[]
  >([{ id: randomUUID() }, { id: randomUUID() }, { id: randomUUID() }]);

  const schema = object({
    firstname: string().label("Prénom").required(),
    lastname: string().label("Nom").required(),
    email: string().label("Email").email().required(),
  });

  const validContacts = useMemo(
    () => contacts.filter((contact) => schema.isValidSync(contact)),
    [contacts, schema],
  );

  const addValidContacts = useCallback(() => {
    return addContactsToCampaign(
      campaign.id,
      validContacts,
      campaign.type === "MANAGED" ? "send" : "dontSend",
    ).then(
      () => {
        toastSuccess("campaign:contacts-import.SUCCESS");
        reload();
        navigate("./..?import=1");
      },
      (err) => {
        switch (err?.response?.status) {
          case 402:
            toastError("campaign:contacts-import.ERROR_NO_CARD");
            break;
          case 412:
            toastError("campaign:contacts-import.ERROR_CARD_EXPIRED");
            break;
          default:
            toastError("campaign:contacts-import.ERROR");
        }
      },
    );
  }, [campaign, validContacts, toastSuccess, toastError, navigate, reload]);

  return (
    <div className="lblocks --l">
      <div className="switch-buttons --center--h">
        <button
          type="button"
          className={!manualEntry ? "active" : ""}
          onClick={() => setManualEntry(false)}
        >
          Import CSV
        </button>
        <button
          type="button"
          className={manualEntry ? "active" : ""}
          onClick={() => setManualEntry(true)}
        >
          Saisir
        </button>
      </div>

      {manualEntry && (
        <ManualImport
          addValidContacts={addValidContacts}
          validContacts={validContacts}
          campaign={campaign}
          schema={schema}
          setContacts={setContacts}
          contacts={contacts}
        />
      )}

      {!manualEntry && (
        <CsvImport
          addValidContacts={addValidContacts}
          validContacts={validContacts}
          campaign={campaign}
          schema={schema}
          setContacts={setContacts}
        />
      )}
    </div>
  );
};

export default ContactsImportTab;
