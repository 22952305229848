import {
  LoggedUserData,
  LoginData,
  ProRegisterData,
  RegisterData,
} from "./authModel";
import { loggerBuilder } from "../logger";
import {
  createPasswordRequest,
  loginRequest,
  logoutRequest,
  lostPasswordRequest,
  registerProRequest,
  registerRequest,
  resendValidationMailRequest,
  resetPasswordRequest,
  updateLoggedUserPasswordRequest,
  validateUserRequest,
} from "./authApi";
import { baseAPI } from "../api";
import { User } from "../user/userModel";
import { toastsWithIntl } from "../toastService";
import SubmitButton from "../../components/forms/SubmitButton";
import { t } from "i18next";
import toast from "react-hot-toast";
import {
  nativeGetLoggedUser,
  nativeSetLoggedUser,
} from "./authNativeInterface";
import { Link } from "react-router-dom";
import { LONG_TOAST_DURATION_IN_MS } from "../config";

const logger = loggerBuilder("auth-service");

export interface AuthService {
  isLoggedIn(): boolean;
  getLoggedUserId(): User["id"] | null;
  login(loginData: LoginData): Promise<void>;
  logout(): Promise<void>;
  register(registerData: RegisterData): Promise<void>;
  registerPro(registerData: ProRegisterData): Promise<void>;
  lostPassword(email: User["email"]): Promise<void>;
  resetPassword(
    uuid: string,
    newPassword: LoginData["password"],
  ): Promise<void>;
  createPassword(
    uuid: string,
    newPassword: LoginData["password"],
  ): Promise<void>;
  resendValidationMail(email: User["email"]): Promise<void>;
  validateUser(uuid: string): Promise<"validated" | "invalid-link" | "error">;
  updateLoggedUserPassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<void>;
}

export const authService = () => {
  const { toastError, toastSuccess } = toastsWithIntl(["global", "auth"]);

  function setLoggedUser(loggedUser: LoggedUserData | null) {
    if (loggedUser !== null) {
      nativeSetLoggedUser(loggedUser);
      baseAPI.defaults.headers.Authorization = `Bearer ${loggedUser.xsrfToken}`;
      logger.debug("User is authenticated");
    } else {
      nativeSetLoggedUser(null);
      delete baseAPI.defaults.headers.Authorization;

      logger.debug("User is disconnected");
    }
  }

  function isLoggedIn() {
    return nativeGetLoggedUser() !== null;
  }

  function getLoggedUserId() {
    return nativeGetLoggedUser()?.id ?? null;
  }

  const login: AuthService["login"] = (loginData) => {
    return loginRequest(loginData).then(
      (loggedUser) => {
        setLoggedUser(loggedUser);
      },
      (err) => {
        switch (err?.response.status) {
          case 401:
            toastError("auth:login.PASSWORD_MISMATCH", {
              id: "login-401",
            });
            break;
          case 404:
            toastError("auth:login.USER_NOT_FOUND", {
              id: "login-404",
            });
            break;
          case 424:
            break;
          case 412:
            toastError(
              (currentToast) => (
                <div>
                  <div>{t("auth:login.NOT_VALIDATED_MAIL")}</div>
                  <SubmitButton
                    type="button"
                    className="btn --s --block cblock--s"
                    onClick={() =>
                      resendValidationMail(loginData.email).then(() => {
                        toast.dismiss(currentToast.id);
                      })
                    }
                  >
                    {t("auth:register.RESEND_MAIL")}
                  </SubmitButton>
                </div>
              ),
              {
                id: "login-412",
              },
            );
            break;
          default:
            toastError("auth:login.ERROR", {
              id: "login-default",
            });
        }

        return Promise.reject(err);
      },
    );
  };

  const logout = () => {
    return logoutRequest().then(() => {
      setLoggedUser(null);
      if (/^\/app.*/.test(window.location.pathname)) window.location.href = "/";
    });
  };

  const resendValidationMail: AuthService["resendValidationMail"] = (email) => {
    return resendValidationMailRequest(email).then(
      () => {
        toastSuccess("auth:validate.ACTIVATION_MAIL_RESENT");
      },
      (err) => {
        switch (err?.response?.status) {
          case 409:
            toastError("auth:validate.ALREADY_VALIDATED", {
              id: "validate-409",
            });
            break;
          default:
            toastError("global:GENERIC_ERROR", {
              id: "error-generic",
            });
        }
      },
    );
  };

  const validateUser: AuthService["validateUser"] = (uuid) => {
    return validateUserRequest(uuid).then(
      (status) => {
        // toastSuccess("auth:validate.SUCCESS");
        switch (status) {
          case 208:
            return "invalid-link" as const;
          default:
            return "validated" as const;
        }
      },
      (err) => {
        switch (err?.response?.status) {
          case 404:
            // toastError("auth:validate.INVALID_LINK", {
            //   id: "validate-404",
            // });
            return "invalid-link" as const;
          default:
            return "error" as const;
          // toastError("global:GENERIC_ERROR", {
          //   id: "error-generic",
          // });
        }
      },
    );
  };

  const register: AuthService["register"] = (registerData) => {
    return registerRequest(registerData).then(
      () => {
        toastSuccess("auth:register.SUCCESS");
      },
      (err) => {
        switch (err?.response.status) {
          case 409:
            toastError("auth:register.ERROR_EMAIL_ALREADY_USED", {
              id: "register-409",
            });
            break;
          default:
            toastError("auth:register.ERROR", {
              id: "register-default",
            });
        }

        return Promise.reject(err);
      },
    );
  };

  const registerPro: AuthService["registerPro"] = (registerData) => {
    return registerProRequest(registerData).then(
      () => {
        toastSuccess("auth:register-pro.SUCCESS");
      },
      (err) => {
        switch (err?.response?.status) {
          case 409:
            toastError(
              () => (
                <div>
                  <div>
                    {t(
                      "auth:register-pro.ERROR_EMAIL_ALREADY_USED_WITH_PRO_ACCOUNT",
                    )}
                  </div>
                  <Link to="/login" className="btn --s --block cblock--s">
                    Se connecter
                  </Link>
                </div>
              ),
              {
                id: "register-409",
              },
            );
            break;
          case 412:
            toastError("auth:register-pro.INVALID_PROMO_CODE", {
              id: "register-pro-412",
            });
            break;
          default:
            toastError(
              () => (
                <div>
                  <div>{t("auth:register-pro.ERROR")}</div>
                  <Link to="/contact" className="btn --s --block cblock--s">
                    Nous contacter
                  </Link>
                </div>
              ),
              {
                id: "register-default",
              },
            );
        }

        return Promise.reject(err);
      },
    );
  };

  const lostPassword: AuthService["lostPassword"] = (email) =>
    lostPasswordRequest(email).then(
      () =>
        toastSuccess("auth:lost-password.SUCCESS", {
          duration: LONG_TOAST_DURATION_IN_MS,
          id: "lost-password-success",
        }),
      (e) => {
        switch (e?.response?.status) {
          case 404:
            toastError("auth:login.USER_NOT_FOUND", {
              id: "lost-password-404",
            });
            break;
          default:
            toastError("auth:lost-password.ERROR", {
              id: "lost-password-default",
            });
        }
      },
    );

  const resetPassword: AuthService["resetPassword"] = (uuid, newPassword) =>
    resetPasswordRequest(uuid, newPassword).then(
      () => {
        toastSuccess("auth:reset-password.SUCCESS");
      },
      (err) => {
        if (err.response.status === 400) {
          toastError("auth:reset-password.ERROR_INVALID_LINK");
        } else if (err.response.status === 406) {
          toastError("auth:reset-password.ERROR_LINK_ALREADY_USED");
        } else {
          toastError("global:GENERIC_ERROR");
        }
        return Promise.reject(err);
      },
    );

  const createPassword: AuthService["createPassword"] = (uuid, newPassword) =>
    createPasswordRequest(uuid, newPassword).then(
      () => {
        toastSuccess("auth:create-password.SUCCESS");
      },
      (err) => {
        switch (err?.response?.status) {
          case 400:
            toastError("auth:create-password.ERROR_INVALID_LINK");
            break;
          case 406:
            toastError("auth:create-password.ERROR_LINK_ALREADY_USED");
            break;
          default:
            toastError("global:GENERIC_ERROR");
        }

        return Promise.reject(err);
      },
    );

  const updateLoggedUserPassword: AuthService["updateLoggedUserPassword"] = (
    oldPassword,
    newPassword,
  ) => {
    return updateLoggedUserPasswordRequest(oldPassword, newPassword).then(
      () => {
        toastSuccess("auth:update-password.SUCCESS");
      },
      (err) => {
        if (err.response.status === 412) {
          toastError("auth:update-password.INVALID_PASSWORD");
        } else {
          toastError("global:GENERIC_ERROR");
        }
        return Promise.reject(err);
      },
    );
  };

  return {
    isLoggedIn,
    getLoggedUserId,
    login,
    logout,
    register,
    registerPro,
    lostPassword,
    resetPassword,
    createPassword,
    resendValidationMail,
    validateUser,
    updateLoggedUserPassword,
  };
};
