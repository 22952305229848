import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import {
  Campaign,
  CampaignContactStatus,
} from "../../../../../services/campaign/campaignModel";
import { orderByField, useSearch } from "../../../../../react-helpers/array";
import { ClientOnly } from "../../../../../react-helpers/react";
import { useMemo, useState } from "react";
import { downloadFile } from "../../../../../react-helpers/files";
import { useTranslation } from "react-i18next";
import { mapBookingRequestToContactStatus } from "../../../../../services/campaign/campaignService";
import Icon from "../../../../../components/Icon";
import CopyToClipboard from "../../../../../components/utilities/CopyToClipboard";
import slugify from "slugify";
import { formatDate } from "../../../../../react-helpers/date";

const ContactsListTab = () => {
  const { t } = useTranslation(["campaign"]);
  const { campaign } = useOutletContext<{
    campaign: Campaign;
  }>();
  const [searchParams] = useSearchParams();

  const [filterByStatus, setFilterByStatus] =
    useState<CampaignContactStatus | null>(null);

  const sortedContacts = useMemo(() => {
    return campaign.bookingRequests!.sort(orderByField("extEmail"));
  }, [campaign.bookingRequests]);

  const [searchedContacts, setSearch] = useSearch(
    sortedContacts,
    (bookingRequest) => [
      bookingRequest.extFirstname,
      bookingRequest.extLastname,
      bookingRequest.extEmail,
    ],
  );
  const filteredContacts = useMemo(
    () =>
      searchedContacts.filter(
        (contact) =>
          !filterByStatus ||
          mapBookingRequestToContactStatus(contact) === filterByStatus,
      ),
    [searchedContacts, filterByStatus],
  );

  const csv = useMemo(() => {
    let csvContent = "firstname;lastname;email;status;campaignlink\r\n";
    sortedContacts.forEach((bookingRequest) => {
      csvContent += `"${bookingRequest.extFirstname}";"${bookingRequest.extLastname}";"${bookingRequest.extEmail}";${mapBookingRequestToContactStatus(bookingRequest)};${import.meta.env.SSR ? null : location.origin}/ext/${bookingRequest.uuid}\r\n`;
    });
    return new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  }, [sortedContacts]);

  return (
    <div className="lblocks">
      {searchParams.get("import") && campaign.type === "TARGETED" && (
        <div className="info">
          Voici le lien individuel généré pour chacun de vos contacts.
          <br />
          Il vous suffit de copier/coller chaque lien individuel et l'envoyer
          par email ou via LinkedIn pour solliciter vos interlocuteurs.
        </div>
      )}
      {campaign.bookingRequests!.length > 0 ? (
        <div className="lblocks">
          <div className="lrow --bottom">
            <div className="--stretched">
              <input
                className="input"
                onChange={(ev) => setSearch(ev.target.value)}
                placeholder="Rechercher un contact"
              />
            </div>

            <div>
              <label className="field-label">Filter par statut</label>
              <select
                className="select"
                onChange={(ev) =>
                  setFilterByStatus(
                    ev.target.value.length > 0
                      ? (ev.target.value as CampaignContactStatus)
                      : null,
                  )
                }
              >
                <option value="">Tous</option>
                <option value="WAITING">
                  {t("campaign:contact-status.WAITING")}
                </option>
                <option value="SENT">
                  {t("campaign:contact-status.SENT")}
                </option>
                <option value="ACCEPTED">
                  {t("campaign:contact-status.ACCEPTED")}
                </option>
                <option value="DECLINED">
                  {t("campaign:contact-status.DECLINED")}
                </option>
              </select>
            </div>
          </div>

          <div className="cblocks --s">
            <div className="--txt--right">
              <button
                className="btn--soft --xs"
                type="button"
                onClick={() =>
                  downloadFile(
                    csv,
                    `contacts_${slugify(campaign.name, {
                      locale: "en",
                      trim: true,
                      lower: true,
                      strict: true,
                    })}_${formatDate(new Date(), "yyyy-MM-dd")}.csv`,
                  )
                }
              >
                Exporter la liste (CSV)
                <Icon name="download" />
              </button>
            </div>
            <div className="table-scroll">
              <table className="table">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Prénom</th>
                    <th>Nom</th>
                    {["TARGETED", "MANAGED"].includes(
                      campaign.type as string,
                    ) && <th>Lien de réservation</th>}
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredContacts.map((bookingRequest) => (
                    <tr key={bookingRequest.id}>
                      <td>
                        <strong>{bookingRequest.extEmail}</strong>
                      </td>
                      <td>{bookingRequest.extFirstname}</td>
                      <td>{bookingRequest.extLastname}</td>
                      {["TARGETED", "MANAGED"].includes(
                        campaign.type as string,
                      ) && (
                        <td>
                          <ClientOnly>
                            {() => (
                              <CopyToClipboard
                                value={`${location.origin}/ext/${bookingRequest.uuid}`}
                              />
                            )}
                          </ClientOnly>
                        </td>
                      )}
                      <td className="--shrink">
                        <span className="chip">
                          {t(
                            `campaign:contact-status.${mapBookingRequestToContactStatus(bookingRequest)}`,
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="info">
          <div className="cblocks">
            <div>Cette campagne ne contient encore aucun contact</div>
            <div>
              <Link to="import" className="btn --s">
                + Importer mes 1ers contacts
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactsListTab;
