import { useState } from "react";
import { cx } from "../../../../react-helpers/css";

function FaqBusiness() {
  const [item, setItem] = useState(0);
  return (
    <div className="faq">
      <div className="cblocks">
        <div className={cx(["faq_item", item === 1 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(1)}>
            Tisio c'est quoi ?
          </button>
          <div className="faq_answer">
            Tisio est une plateforme de visios rémunérées permettant de
            solliciter n'importe quel contact pour un échange professionnel 1:1
            confidentiel en visio.
          </div>
        </div>
        <div className={cx(["faq_item", item === 2 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(2)}>
            Combien de temps durent les échanges ?
          </button>
          <div className="faq_answer">
            Les temps d’échange en visio sont prévus pour durer 30 minutes !
            Mais sachez que la visio ne va pas se couper au bout de 30 minutes
            d’échange, libre à vous de faire plus si tout le monde est d’accord,
            mais les minutes additionnelles ne seront pas rémunérées.
          </div>
        </div>
        <div className={cx(["faq_item", item === 3 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(3)}>
            Comment s’assurer que mon interlocuteur sera qualitatif ?
          </button>
          <div className="faq_answer">
            En amont, la visio n’a lieu que s’il y a une acceptation mutuelle
            entre un demandeur et son interlocuteur. À l’issue de la visio, un
            système de notation croisé permet de valider la conformité et la
            qualité de l’échange.
          </div>
        </div>
      </div>
      <div className="cblocks">
        <div className={cx(["faq_item", item === 4 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(4)}>
            Dois-je préparer l’échange ?
          </button>
          <div className="faq_answer">
            Si vous sollicitez une personne via Tisio, il est essentiel de
            préparer votre échange ! 30 minutes, ça passe vite. Nous vous
            recommandons donc de préparer une courte introduction expliquant le
            contexte et ce que vous attendez concrètement de votre échange, puis
            lister les questions auxquelles vous souhaitez avoir des réponses.
          </div>
        </div>
        <div className={cx(["faq_item", item === 5 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(5)}>
            À quel moment dois-je payer ?
          </button>
          <div className="faq_answer">
            Pour solliciter quelqu'un, une empreinte de carte bancaire vous sera
            demandée au moment de la sollicitation. Le montant de l'échange ne
            sera débité qu'une fois la visio confirmée par votre interlocuteur.
          </div>
        </div>
        <div className={cx(["faq_item", item === 6 && "--open"])}>
          <button className="faq_question" onClick={() => setItem(6)}>
            Y a-t-il des frais de plateforme ?
          </button>
          <div className="faq_answer">
            Si vous souscrivez à l'abonnement pro, non, il n’y a pas de frais de
            plateforme : vous ne payez que l'abonnement mensuel ainsi que le
            montant proposé pour chaque visio effectuée. En revanche, vous
            pouvez tester le principe des visio rémunérées sans souscrire à
            l'abonnement, mais uniquement avec les personnes ayant créé leur
            profil. Dans ce cas, des frais de 20 % seront appliqués au tarif du
            profil.
          </div>
        </div>
      </div>
    </div>
  );
}

export default FaqBusiness;
