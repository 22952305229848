import BackButton from "../../../components/BackButton";

const BookingRequestNotFoundPage = () => {
  return (
    <div className="container">
      <div className="page_head">
        <div className="lblocks">
          <BackButton fallbackUrl="../" />
          <div>
            <div className="title--30">Requête de booking introuvable</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingRequestNotFoundPage;
