import { PropsWithChildren } from "react";
import { FallbackNs } from "react-i18next";
import {
  AppendKeyPrefix,
  InterpolationMap,
  KeyPrefix,
  Namespace,
  ParseKeys,
  TFunctionReturn,
  TOptions,
  t,
} from "i18next";
import { toast, ToastBar, Toaster, ToastOptions } from "react-hot-toast";
import { ClientOnly } from "../react-helpers/react";
import Icon from "../components/Icon";
import { LONG_TOAST_DURATION_IN_MS } from "./config";

type Message = Parameters<typeof toast>[0];
type SimplifiedToastOptions = Pick<
  ToastOptions,
  "duration" | "id" | "position"
>;
interface ToastService {
  toastSuccess(text: Message, options?: SimplifiedToastOptions): void;
  toastWarning(text: Message, options?: SimplifiedToastOptions): void;
  toastError(text: Message, options?: SimplifiedToastOptions): void;
}

const toastSuccess: ToastService["toastSuccess"] = (title, options) => {
  toast.success(title, {
    ...options,
    duration: options?.duration ?? 5000,
  });
};
const toastWarning: ToastService["toastWarning"] = (title, options) => {
  toast.success(title, {
    ...options,
    duration: options?.duration ?? 10000,
    icon: <Icon name="warning" className="--warning" />,
  });
};
const toastError: ToastService["toastError"] = (title, options) => {
  toast.error(title, {
    ...options,
    duration: options?.duration ?? LONG_TOAST_DURATION_IN_MS,
  });
};

export const ProvideToasts = ({ children }: PropsWithChildren) => {
  return (
    <>
      <ClientOnly>
        <Toaster position="top-right">
          {(t) => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => toast.dismiss(t.id)}
            >
              <ToastBar toast={t} />
            </div>
          )}
        </Toaster>
      </ClientOnly>
      {children}
    </>
  );
};

type ToastServiceWithIntl<
  N extends Namespace,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
> = Record<
  keyof ToastService,
  <
    const TOpt extends TOptions,
    const TKey extends ParseKeys<N, TOpt, KPrefix>,
    Ret extends TFunctionReturn<N, AppendKeyPrefix<TKey, KPrefix>, TOpt>,
    const ActualOptions extends TOpt & InterpolationMap<Ret> = TOpt &
      InterpolationMap<Ret>,
  >(
    text: TKey | TKey[] | Message,
    options?: ActualOptions & SimplifiedToastOptions,
    defaultValue?: string,
  ) => void
>;

// eslint-disable-next-line react-refresh/only-export-components
export function toastsWithIntl<
  N extends Namespace,
  KPrefix extends KeyPrefix<FallbackNs<N>>,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(_ns?: N): ToastServiceWithIntl<N, KPrefix> {
  return Object.fromEntries(
    Object.entries({
      toastSuccess,
      toastWarning,
      toastError,
    }).map(([key, service]) => [
      key,
      (text, options, defaultValue) => {
        return service(
          typeof text === "string"
            ? ((defaultValue
                ? t(text, defaultValue, options)
                : t(
                    // BUG: Cannot find a Typescript solution to solve this mapping without a any
                    text as any,
                    options,
                  )) as string)
            : (text as Message),
          options,
        );
      },
    ]),
  ) as ToastServiceWithIntl<N, KPrefix>;
}
