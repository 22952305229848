import ResetPasswordForm from "./ResetPasswordForm";

function ResetPasswordPage() {
  return (
    <>
      <div className="auth-card">
        <div className="card_head">
          <h1 className="card_title">Réinitialisation de mot de passe</h1>
          <p>Saisissez votre nouveau mot de passe</p>
        </div>
        <div className="card_body">
          <ResetPasswordForm />
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
