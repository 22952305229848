import { Link } from "react-router-dom";

function LegalGuidePage() {
  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">Guide : Statut, Fiscalité & Contrat</h1>
      </div>
      <div className="page-content lblocks">
        <h2 className="section_title">
          Ce qu’il y a à savoir concernant vos échanges sur Tisio !
        </h2>
        <div className="cblocks --s">
          <h3 className="paragraph_title">
            Dois-je être professionnel indépendant (à mon compte, dirigeant
            d’entreprise, …) pour créer un profil Tisio et être rémunéré ?
          </h3>
          <p>
            Non ! Tout le monde peut créer son profil sur Tisio.
            <br />
            Et tant que les recettes ne dépassent pas 1.500 € par an, il est
            possible d’opter pour un régime sans statut de travailleur
            indépendant/auto-entrepreneur. Il n’y a dans ce cas pas de formalité
            administrative de création d’entreprise. Il suffit ensuite de
            renseigner son RIB (ou celui de l’association de son choix) à
            l’issue d’une visio pour percevoir sa rémunération.
          </p>
        </div>
        <div className="cblocks --s">
          <h3 className="paragraph_title">
            Dois-je déclarer les sommes comme revenus ?
          </h3>
          <p>
            Oui, les sommes perçues sont à reporter sur votre déclaration
            d’impôts.
            <br />
            Dans la plupart des cas (recettes perçues inférieures à 77.700 €),
            vous pouvez déclarer les revenus Tisio en régime « micro » au moment
            de votre déclaration annuelle (dans la catégorie des « revenus non
            commerciaux non professionnels » ligne 5KU). Les revenus Tisio
            seront alors pris en compte dans le calcul de votre impôt avec un
            abattement automatique de 34%. Vous n’avez pas de TVA à appliquer
            tant que vos recettes annuelles sont inférieures à 36.800 € en 2023.
          </p>
          <p>
            Pour plus d’informations sur les obligations sociales et fiscales
            des personnes rémunérées sur la plateforme, nous vous invitons à
            vous rendre :<br />
          </p>
          <ul>
            <li>
              sur www.impots.gouv.fr, concernant les obligations fiscales, lien
              suivant :{" "}
              <Link
                className="link"
                to="https://www.impots.gouv.fr/portail/node/10841"
                target="_blank"
              >
                https://www.impots.gouv.fr/portail/node/10841
              </Link>
            </li>
            <li>
              sur www.urssaf.fr, concernant les obligations sociales, lien
              suivant :
              <Link
                className="link"
                to="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html"
                target="_blank"
              >
                https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html
              </Link>
            </li>
          </ul>
        </div>
        <div className="cblocks --s">
          <h3 className="paragraph_title">
            Mon employeur peut-il me reprocher de créer un profil Tisio pour y
            donner des conseils ou partager mon expérience/expertise ?
          </h3>
          <p>
            Non, tant que vous respectez vos obligations professionnelles
            (confidentialité, loyauté, éventuellement non-concurrence…)
            vis-à-vis de votre employeur et que votre contrat de travail ne
            prévoit pas d’interdictions ou limitations particulières (pensez à
            vérifier votre contrat ou à vous rapprocher de votre hiérarchie !).
          </p>
        </div>
      </div>
    </div>
  );
}

export default LegalGuidePage;
