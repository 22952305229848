import { cx } from "../../../react-helpers/css";

interface Section {
  title: string;
  content?: string | string[];
  sections?: Section[];
}

interface ContentSectionProps {
  section: Section;
  level?: number;
}

// Function to parse custom [b]...[/b] and [url:...]...[/url] format
function parseCustomFormat(content: string) {
  const parts = [];

  // Regex to find [b]...[/b] or [url:...]...[/url]
  const regex = /\[b\](.*?)\[\/b\]|\[url:(.*?)\](.*?)\[\/url\]/g;
  let lastIndex = 0;
  let match;

  while ((match = regex.exec(content)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      parts.push(content.substring(lastIndex, match.index));
    }

    if (match[1]) {
      // Handle [b]...[/b]
      parts.push(<b key={lastIndex}>{match[1]}</b>);
    } else if (match[2] && match[3]) {
      // Handle [url:...]...[/url]
      parts.push(
        <a
          key={lastIndex}
          href={match[2]}
          target="_blank"
          rel="noopener noreferrer"
        >
          {match[3]}
        </a>,
      );
    }

    lastIndex = regex.lastIndex;
  }

  // Add the remaining text after the last match
  if (lastIndex < content.length) {
    parts.push(content.substring(lastIndex));
  }

  return parts;
}

function ContentSection({ section, level = 1 }: ContentSectionProps) {
  const { title, content, sections } = section;

  return (
    <div className={cx(["cblocks", level > 1 && "--s"])}>
      {level === 1 && <h2 className="section_title">{title}</h2>}
      {level === 2 && <h3 className="paragraph_title">{title}</h3>}
      {level === 3 && <h4 className="block_title">{title}</h4>}
      {level > 3 && <h5>{title}</h5>}

      {/* Render simple text content */}
      {content && typeof content === "string" && (
        <p className="pre">{parseCustomFormat(content)}</p>
      )}

      {/* Render segmented (array) content */}
      {Array.isArray(content) &&
        content.map((item, index) => (
          <>
            {/* Render simple text item */}
            {typeof item === "string" && (
              <p key={index} className="pre">
                {parseCustomFormat(item)}
              </p>
            )}
            {/* Render list (if item is an array of strings */}
            {Array.isArray(item) && (
              <ul className="cblocks --xs" key={index}>
                {item.map((li, i) => (
                  <li key={i} className="pre">
                    {parseCustomFormat(li)}
                  </li>
                ))}
              </ul>
            )}
          </>
        ))}

      {/* Render nested sections if present */}
      {sections && sections.length > 0 && (
        <>
          {sections.map((section, index) => (
            <ContentSection key={index} section={section} level={level + 1} />
          ))}
        </>
      )}
    </div>
  );
}

export default ContentSection;
