import { useMemo } from "react";
import SxForm from "../../../forms/SxForm";
import Dialog from "../../../components/Dialog";
import { User } from "../../../services/user/userModel";
import { object, string } from "yup";
import { transformEmptyToNull } from "../../../react-helpers/yup";
import SxField from "../../../forms/fields/SxField";
import { Link } from "react-router-dom";
import { useField } from "formik";
import { supportService } from "../../../services/support/supportService";

const RDVLink = () => {
  const [emailField] = useField("email");
  const link = useMemo(
    () =>
      `https://zcal.co/robinson/15min${emailField.value ? "?email=" + emailField.value : ""}`,
    [emailField.value],
  );

  return (
    <Link to={link} className="btn --outlined" target="_blank">
      Prendre RDV avec Robinson
    </Link>
  );
};

export interface TargetedProfile {
  userId?: User["id"];
  description: string;
  email: string;
  tel: string | null;
}

const TargetingProfilesHelperDialog = ({
  loggedUser,
  onClose,
}: {
  loggedUser?: User;
  onClose: () => void;
}) => {
  const { targetingProfile } = supportService();

  const initialValues: TargetedProfile = useMemo(
    () => ({
      description:
        "Je cherche des profils correspondant aux critères suivants :\n\nMots clés :\nSecteur d'activité :\nFonction :\nEffectif :\nPosition :\n\nInformations additionnelles : N'hésitez-pas à nous en dire plus sur vos besoins !",
      email: loggedUser?.email ?? "",
      tel: loggedUser?.tel ?? null,
    }),
    [loggedUser?.email, loggedUser?.tel],
  );

  const schema = useMemo(
    () =>
      object({
        description: string()
          .required("Ce champ est obligatoire")
          .label(
            "Donnez-nous plus de détails sur les profils que souhaitez contacter, nous vous recontacterons au plus vite ! ",
          ),
        email: string()
          .email("Email invalide")
          .required("Ce champ est obligatoire")
          .label("L’adresse mail à laquelle on peut vous recontacter "),
        tel: string()
          .nullable()
          .transform(transformEmptyToNull)
          .label(
            "Le numéro de téléphone auquel on peut vous recontacter (optionnel)",
          ),
      }),
    [],
  );

  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <h2 className="popup_title">
            Besoin d'aide pour cibler des profils ?
          </h2>
          <div>
            Robinson vous aide à trouver l'interlocuteur qu'il vous faut !
          </div>
        </div>
        <div className="popup_body">
          <SxForm
            initialValues={initialValues}
            onSubmit={(values) =>
              targetingProfile({ ...values, userId: loggedUser?.id }).then(
                onClose,
              )
            }
            validationSchema={schema}
          >
            <SxField as="textarea" name="description" />
            {!loggedUser && <SxField name="email" as="email" />}
            <SxField name="tel" />
            <div className="cblock lrow --right --center--v">
              <button type="submit" className="btn --primary">
                Envoyer
              </button>
              ou
              <RDVLink />
            </div>
          </SxForm>
        </div>
      </>
    </Dialog>
  );
};

export default TargetingProfilesHelperDialog;
