import { Link, NavLink, Outlet, useLoaderData } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import { Campaign } from "../../../../../services/campaign/campaignModel";
import Tabs from "../../../../../components/Tabs";

const CampaignContactsPage = () => {
  const { campaign } = useLoaderData() as {
    campaign: Campaign;
  };

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton
            forcedUrl={`/app/pro/${campaign.businessAccountId}/campaigns/${campaign.id}`}
          />
          <h1 className="page_title">Contacts de la campagne</h1>
        </div>
        <Link className="btn --btn--icon --s --plus" to={`import`} />
      </div>
      <Tabs className="cblock">
        <NavLink className="tab_item" to={`.`} end>
          Tous les contacts
        </NavLink>
        <NavLink className="tab_item" to={`import`} end>
          Importer des contacts
        </NavLink>
      </Tabs>
      <div className="page-content lblocks">
        <Outlet context={{ campaign }} />
      </div>
    </div>
  );
};

export default CampaignContactsPage;
