import { Link, useLoaderData } from "react-router-dom";
import { Plan } from "../../../services/businessAccount/businessAccountModel";
import DisplayPrice from "../../../components/DisplayPrice";

const PlansPage = () => {
  const { plans } = useLoaderData() as {
    plans: Plan[];
  };

  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">Choix abonnement</h1>
      </div>
      <div className="page-content lblocks">
        {plans.map((plan) => (
          <Link to={`${plan.id}/subscribe`} key={plan.id}>
            <div>{plan.labelFr}</div>
            <div>
              <DisplayPrice amount={plan.monthlyPrice} taxFree currency="EUR" />{" "}
              / mois
            </div>
            <div>
              <DisplayPrice amount={plan.annualPrice} taxFree currency="EUR" />{" "}
              / an
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PlansPage;
