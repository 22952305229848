import { useLoaderData } from "react-router-dom";
import BackButton from "../../../../../components/BackButton";
import { Campaign } from "../../../../../services/campaign/campaignModel";
import { BusinessAccount } from "../../../../../services/businessAccount/businessAccountModel";
import { useMemo } from "react";
import { compareDate } from "../../../../../react-helpers/array";
import { isAfter, isBefore, parseISO } from "date-fns";
import BookingsTable from "./BookingsTable";

const CampaignBookingsPage = () => {
  const { campaign, businessAccount } = useLoaderData() as {
    campaign: Campaign;
    businessAccount: BusinessAccount;
  };

  const futureBookings = useMemo(() => {
    return (
      campaign.bookingRequests
        ?.filter(
          (bookingRequest) =>
            bookingRequest.booking?.startTime &&
            isBefore(new Date(), parseISO(bookingRequest.booking.startTime)),
        )
        .sort((a, b) =>
          compareDate(a.booking!.startTime, b.booking!.startTime),
        ) ?? []
    );
  }, [campaign]);

  const ongoingBookings = useMemo(() => {
    return (
      campaign.bookingRequests?.filter(
        (bookingRequest) =>
          bookingRequest.booking?.startTime &&
          isAfter(new Date(), parseISO(bookingRequest.booking.startTime)) &&
          bookingRequest.booking.endTime &&
          isBefore(new Date(), parseISO(bookingRequest.booking.endTime)),
      ) ?? []
    );
  }, [campaign]);

  const bookingsToClose = useMemo(() => {
    return (
      campaign.bookingRequests
        ?.filter(
          (bookingRequest) =>
            !!bookingRequest.booking?.endTime &&
            !bookingRequest.senderClosureDate &&
            isAfter(new Date(), parseISO(bookingRequest.booking.endTime)),
        )
        .sort((a, b) =>
          compareDate(b.booking!.startTime, a.booking!.startTime),
        ) ?? []
    );
  }, [campaign]);

  const closedBookings = useMemo(() => {
    return (
      campaign.bookingRequests
        ?.filter(
          (bookingRequest) =>
            !!bookingRequest.booking?.endTime &&
            !!bookingRequest.senderClosureDate &&
            isAfter(new Date(), parseISO(bookingRequest.booking.endTime)),
        )
        .sort((a, b) =>
          compareDate(b.booking!.startTime, a.booking!.startTime),
        ) ?? []
    );
  }, [campaign]);

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton
            forcedUrl={`/app/pro/${businessAccount.id}/campaigns/${campaign.id}`}
          />
          <h1 className="page_title">Les rendez-vous de votre campagne</h1>
        </div>
      </div>
      <div className="page-content lblocks">
        {futureBookings.length === 0 &&
          ongoingBookings.length === 0 &&
          bookingsToClose.length === 0 &&
          closedBookings.length === 0 && (
            <div className="info">
              Vous n'avez encore aucun rendez-vous dans le cadre de cette
              campagne.
            </div>
          )}

        {ongoingBookings.length > 0 && (
          <div className="lblocks">
            <h2 className="section_title">Vos rendez-vous en cours</h2>
            <BookingsTable
              showVisioLink
              bookingRequests={ongoingBookings}
              date="start"
            />
          </div>
        )}
        {futureBookings.length > 0 && (
          <div className="lblocks">
            <h2 className="section_title">Vos rendez-vous à venir</h2>
            <BookingsTable
              showVisioLink
              bookingRequests={futureBookings}
              date="start"
            />
          </div>
        )}
        {bookingsToClose.length > 0 && (
          <div className="lblocks">
            <h2 className="section_title">Vos rendez-vous à clôturer</h2>
            <BookingsTable bookingRequests={bookingsToClose} date="end" />
          </div>
        )}
        {closedBookings.length > 0 && (
          <div className="lblocks">
            <h2 className="section_title">Vos rendez-vous terminés</h2>
            <BookingsTable bookingRequests={closedBookings} date="end" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignBookingsPage;
