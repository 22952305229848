import { Link, useNavigate, useParams } from "react-router-dom";
import { authService } from "../../../services/auth/authService";
import SxForm from "../../../forms/SxForm";
import { boolean, object, string } from "yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxField from "../../../forms/fields/SxField";
import SxCheckbox from "../../../forms/fields/SxCheckbox";

const CreatePasswordForm = () => {
  const { uuid } = useParams();
  const auth = authService();
  const navigate = useNavigate();

  return (
    <SxForm
      initialValues={{
        newPassword: "",
        newPasswordConfirmation: "",
        cguAccepted: false,
      }}
      onSubmit={({ newPassword }) => {
        return auth.createPassword(uuid!, newPassword).then(() => {
          return navigate("/login");
        });
      }}
      validationSchema={object({
        newPassword: string().password().label("Mot de passe").required(),
        cguAccepted: boolean()
          .transform((v) => !!v)
          .oneOf([true]),
      })}
    >
      <SxField name="newPassword" placeholder="••••••••••••" />
      <div className="lblock">
        <SxCheckbox
          name="cguAccepted"
          label={
            <>
              J'ai lu et j'accepte les{" "}
              <Link to="/general-conditions-of-use" target="_blank">
                conditions générales d’utilisation
              </Link>{" "}
              de la plateforme.
            </>
          }
        />
      </div>

      <div className="form_footer">
        <SubmitButton className="submit-btn" type="submit">
          Créer
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default CreatePasswordForm;
