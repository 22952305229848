import PlanCard from "../../../../components/plans/PlanCard";

function SubscriptionBlock() {
  return (
    <div className="grid--2 --center--v --gap--l">
      <PlanCard />

      <div className="lblocks">
        <h1 className="head_title --condensed lblock --force-first">
          <em>Moins cher</em> que la prospection classique !
        </h1>
        <div className="body--50 --strong">
          Prix moyen du rendez-vous constaté sur Tisio actuellement :
          112€&nbsp;HT
        </div>
      </div>
    </div>
  );
}

export default SubscriptionBlock;
