import { Link } from "react-router-dom";
import {
  AppointmentsStatus,
  BookingRequest,
} from "../../services/bookingRequest/bookingRequestModel";
import Icon from "../../components/Icon";
import AppointmentsTable from "../../components/appointments/AppointmentTable";

const BookingRequestWidgets = ({
  bookingRequestsToCome,
  bookingRequestsToClose,
  nbUnbookedBookingRequests,
  loggedUserId,
}: {
  bookingRequestsToCome: BookingRequest[];
  bookingRequestsToClose: BookingRequest[];
  nbUnbookedBookingRequests: number;
  loggedUserId: number;
}) => {
  return (
    <div className="lblocks">
      {bookingRequestsToCome.length > 0 && (
        <AppointmentsTable
          bookingRequests={bookingRequestsToCome}
          loggedUserId={loggedUserId}
          status={AppointmentsStatus.TO_COME}
          minimal
        />
      )}
      {bookingRequestsToClose.length > 0 && (
        <AppointmentsTable
          bookingRequests={bookingRequestsToClose}
          loggedUserId={loggedUserId}
          status={AppointmentsStatus.TO_CLOSE}
          minimal
        />
      )}
      {nbUnbookedBookingRequests > 0 && (
        <div className="scorecard">
          <Icon name="clock" />
          <div>
            <div className="scorecard_label">
              Sollicitations en attente (
              <Link to={"/app/bookings/requests"} className="link --s">
                voir
              </Link>
              )
            </div>
            <div className="scorecard_value">{nbUnbookedBookingRequests} </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingRequestWidgets;
