import { cx } from "../../react-helpers/css";

const ProfilePic = ({
  src,
  className,
}: {
  src?: string | null;
  className?: string;
}) => {
  return (
    <div
      className={cx(["avatar", className])}
      style={
        src
          ? {
              backgroundImage: `url(${src})`,
            }
          : {}
      }
    />
  );
};

export default ProfilePic;
