import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import SxForm from "../../../../forms/SxForm";
import SubmitButton from "../../../../components/forms/SubmitButton";
import Dialog from "../../../../components/Dialog";
import { stripe } from "../../../../services/payment/stripeConfig";
import { businessAccountService } from "../../../../services/businessAccount/businessAccountService";
import { toastsWithIntl } from "../../../../services/toastService";
import useReload from "../../../../hooks/useReload";
import { BusinessAccount } from "../../../../services/businessAccount/businessAccountModel";

const { addPaymentMethodToBusinessAccount } = businessAccountService();

const BusinessAccountPaymentMethodForm = ({
  businessAccount,
  onClose,
}: {
  businessAccount: BusinessAccount;
  onClose: () => void;
}) => {
  const reload = useReload();
  const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (!error && paymentMethod) {
        // On envoie ici le paymentMethod.id via la route de back ci dessous
        // http://localhost:8888/api/business-accounts/1/add-payment-method
        await addPaymentMethodToBusinessAccount(
          businessAccount.id,
          paymentMethod.id,
        ).then(
          () => {
            toastSuccess("campaign:add-payment-method.SUCCESS");
            reload();
            onClose();
          },
          () => {
            toastError("campaign:add-payment-method.ERROR");
          },
        );
      }
    }
  };

  const card = businessAccount?.stripeCustomer?.card;

  return (
    <SxForm initialValues={{}} onSubmit={handleSubmit}>
      <div className="popup_body lblocks">
        {card && (
          <div className="info">
            Votre moyen de paiement enregistré
            <div className="body--20">
              {card.brand.toUpperCase()} XXXX-XXXX-XXXX-{card.last4} [
              {card.exp_month.toString().padStart(2, "0")}/{card.exp_year}]
            </div>
          </div>
        )}
        <div className="card-element-wrapper">
          <CardElement />
        </div>
      </div>

      <div className="popup_footer">
        <SubmitButton type="submit" className="btn">
          {card ? "Mettre à jour" : "Enregistrer"}
        </SubmitButton>
      </div>
    </SxForm>
  );
};

const BusinessAccountPaymentMethodDialog = ({
  onClose,
  businessAccount,
}: {
  onClose: () => void;
  businessAccount: BusinessAccount;
}) => {
  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <div className="popup_title">
            {businessAccount?.stripeCustomer?.card
              ? "Mettre à jour mon moyen de paiement"
              : "Enregistrer un nouveau moyen de paiement"}
          </div>
        </div>

        <Elements stripe={stripe}>
          <BusinessAccountPaymentMethodForm
            businessAccount={businessAccount}
            onClose={onClose}
          />
        </Elements>
      </>
    </Dialog>
  );
};

export default BusinessAccountPaymentMethodDialog;
