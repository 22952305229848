import { Field, useField, useFormikContext } from "formik";
import { ArrayElement } from "../../react-helpers/type";
import { SxFieldProps, SxFieldType } from "./SxField";
import { useEffect } from "react";
import { cx } from "../../react-helpers/css";

interface SxRadioProps {
  name: string;
  radio: ArrayElement<SxFieldProps<SxFieldType.Radio>["radios"]>;
  isDisabled?: boolean;
}

const SxRadio = ({
  name,
  radio: { value, label },
  isDisabled,
}: SxRadioProps) => {
  const formik = useFormikContext();
  const [field, , helpers] = useField(name);

  // this useEffect will not initialize the component before hydratation
  useEffect(() => {
    // Formik doesn't convert initialValue to string
    if (typeof field.value !== "string") {
      const normalizedValue = String(field.value);
      (formik.initialValues as any)[name] = normalizedValue;
      void helpers.setValue(normalizedValue);
    }

    // Run only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <label className={cx(["radio-btn", field.value === value && "selected"])}>
      <Field type="radio" name={name} value={value} disabled={isDisabled} />
      {label}
    </label>
  );
};

export default SxRadio;
