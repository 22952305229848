import i18n, { KeyPrefix, Namespace, ParseKeys, TOptions } from "i18next";
import { FallbackNs, initReactI18next } from "react-i18next";

import GLOBAL_FR from "./global/fr.json";
import GLOBAL_EN from "./global/en.json";
import AUTH_FR from "./auth/fr.json";
import AUTH_EN from "./auth/en.json";
import ACCOUNTS_FR from "./accounts/fr.json";
import ACCOUNTS_EN from "./accounts/en.json";
import SUPPORT_FR from "./support/fr.json";
import SUPPORT_EN from "./support/en.json";
import VALIDATION_FR from "./validation/fr.json";
import VALIDATION_EN from "./validation/en.json";
import BOOKING_PROFILE_FR from "./bookingProfile/fr.json";
import BOOKING_PROFILE_EN from "./bookingProfile/en.json";
import BOOKING_REQUEST_FR from "./bookingRequest/fr.json";
import BOOKING_REQUEST_EN from "./bookingRequest/en.json";
import PAYMENT_FR from "./payment/fr.json";
import PAYMENT_EN from "./payment/en.json";
import CAMPAIGN_FR from "./campaign/fr.json";
import CAMPAIGN_EN from "./campaign/en.json";
import BUSINESS_ACCOUNT_FR from "./businessAccount/fr.json";
import BUSINESS_ACCOUNT_EN from "./businessAccount/en.json";
import { setLocale } from "yup";
import { setDefaultOptions } from "date-fns";
import fr from "date-fns/locale/fr";

export const i18nResources = {
  fr: {
    global: GLOBAL_FR,
    auth: AUTH_FR,
    accounts: ACCOUNTS_FR,
    support: SUPPORT_FR,
    validation: VALIDATION_FR,
    bookingProfile: BOOKING_PROFILE_FR,
    bookingRequest: BOOKING_REQUEST_FR,
    payment: PAYMENT_FR,
    campaign: CAMPAIGN_FR,
    businessAccount: BUSINESS_ACCOUNT_FR,
  },
  en: {
    global: GLOBAL_EN,
    auth: AUTH_EN,
    accounts: ACCOUNTS_EN,
    support: SUPPORT_EN,
    validation: VALIDATION_EN,
    bookingProfile: BOOKING_PROFILE_EN,
    bookingRequest: BOOKING_REQUEST_EN,
    payment: PAYMENT_EN,
    campaign: CAMPAIGN_EN,
    businessAccount: BUSINESS_ACCOUNT_EN,
  },
  // NOTE: Add here all the other languages, the object format should match the French one
};

const namespaces = Object.keys(
  i18nResources.fr,
) as unknown as keyof (typeof i18nResources)["fr"];
void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: i18nResources,
    ns: namespaces,
    defaultNS: "auth",
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

// NOTE: Set date-fns default locale
setDefaultOptions({
  locale: fr,
});

setLocale({
  mixed: {
    default: translationWithParams("validation:INVALID_FIELD"),
    required: translationWithParams("validation:REQUIRED"),
  },
  string: {
    email: validationTranslation("validation:email.INVALID_EMAIL"),
    min: validationTranslation("validation:string.MIN"),
  },
});

// NOTE: Translation function to use only with setLocale
function translationWithParams<
  N extends Namespace,
  const TOpt extends TOptions,
  const TKey extends ParseKeys<N, TOpt, KPrefix>,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(key: TKey, namespace: Namespace = ["validation"]) {
  return (values: Record<string, unknown>) =>
    validationTranslation<N, TOpt, TKey, KPrefix>(key, values, namespace);
}

export function validationTranslation<
  N extends Namespace,
  const TOpt extends TOptions,
  const TKey extends ParseKeys<N, TOpt, KPrefix>,
  KPrefix extends KeyPrefix<FallbackNs<N>> = undefined,
>(
  key: TKey,
  values: Record<string, unknown> = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _namespace: Namespace = ["validation"],
): string {
  return i18n.t(key as string, values);
}

export default i18n;
