import { useOutletContext } from "react-router-dom";
import {
  AppointmentsStatus,
  BookingRequest,
} from "../../../services/bookingRequest/bookingRequestModel";
import { useCallback, useMemo } from "react";
import { compareDate, orderByField } from "../../../react-helpers/array";
import AppointmentsTable from "../../../components/appointments/AppointmentTable";
import { User } from "../../../services/user/userModel";

const AppointmentsSection = () => {
  const { paidAndBookedBookingRequests, loggedUserId } = useOutletContext<{
    paidAndBookedBookingRequests: BookingRequest[];
    loggedUserId: User["id"];
  }>();

  const compareBooking = orderByField<BookingRequest, "booking">(
    "booking",
    "desc",
    (a, b) => compareDate(new Date(a!.startTime), new Date(b!.startTime)),
  );

  const isSender = useCallback(
    (request: BookingRequest) => request.sender!.user.id === loggedUserId,
    [loggedUserId],
  );

  // an appointment is to come if the start date is in the future
  const appointmentToCome = useMemo(
    () =>
      paidAndBookedBookingRequests
        .filter((request) => new Date(request.booking!.startTime) > new Date())
        .sort(compareBooking),
    [paidAndBookedBookingRequests, compareBooking],
  );

  // an appointment is ongoing if the start date is in the past and the end date is in the future
  const onGoingAppointment = useMemo(
    () =>
      paidAndBookedBookingRequests
        .filter(
          (request) =>
            new Date(request.booking!.startTime) < new Date() &&
            new Date(request.booking!.endTime) > new Date(),
        )
        .sort(compareBooking),
    [paidAndBookedBookingRequests, compareBooking],
  );

  // an appointment is to close if the request is in past and the current user has not closed
  const appointmentToClose = useMemo(
    () =>
      paidAndBookedBookingRequests
        .filter(
          (request) =>
            new Date(request.booking!.endTime) < new Date() &&
            !["CLOSED", "CONFLICT"].includes(request.status!) &&
            (isSender(request)
              ? request.senderClosureDate === null
              : request.recipientClosureDate === null),
        )
        .sort(compareBooking),
    [paidAndBookedBookingRequests, compareBooking, isSender],
  );

  // an appointment is closed if the request status is "CLOSED"|"CONFLICT" or the current user has closed
  const appointmentClosed = useMemo(
    () =>
      paidAndBookedBookingRequests
        .filter(
          (request) =>
            ["CLOSED", "CONFLICT"].includes(request.status!) ||
            (isSender(request)
              ? request.senderClosureDate !== null
              : request.recipientClosureDate !== null),
        )
        .sort(compareBooking),
    [paidAndBookedBookingRequests, compareBooking, isSender],
  );

  return (
    <div>
      {paidAndBookedBookingRequests.length === 0 ? (
        <div className="info">Aucun RDV pour l'instant</div>
      ) : (
        <div className="lblocks">
          {appointmentToCome.length > 0 && (
            <AppointmentsTable
              bookingRequests={appointmentToCome}
              loggedUserId={loggedUserId}
              status={AppointmentsStatus.TO_COME}
            />
          )}
          {onGoingAppointment.length > 0 && (
            <AppointmentsTable
              bookingRequests={onGoingAppointment}
              loggedUserId={loggedUserId}
              status={AppointmentsStatus.ONGOING}
            />
          )}
          {appointmentToClose.length > 0 && (
            <AppointmentsTable
              bookingRequests={appointmentToClose}
              loggedUserId={loggedUserId}
              status={AppointmentsStatus.TO_CLOSE}
            />
          )}
          {appointmentClosed.length > 0 && (
            <AppointmentsTable
              bookingRequests={appointmentClosed}
              loggedUserId={loggedUserId}
              status={AppointmentsStatus.CLOSED}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default AppointmentsSection;
