import SxForm from "../../../forms/SxForm";
import useReload from "../../../hooks/useReload";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import ValidationErrors from "../../../forms/ValidationErrors";
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import { Association } from "../../../services/data/dataModel";
import { BookingProfileInterface } from "./bookingProfileManagementPage/BookingProfileManagementPage";
import {
  bookingProfilePreferencesSchema,
  bookingProfileService,
} from "../../../services/bookingProfile/bookingProfileService";
import { useMemo, useState } from "react";
import { useField } from "formik";
import SxCheckbox from "../../../forms/fields/SxCheckbox";
import BookingProfileCreateAssociationDialog from "./BookingProfileCreateAssociationDialog";
import {
  MAXIMUM_VISIO_PRICE,
  MINIMUM_VISIO_PRICE,
  TISIO_FEE_PERCENTAGE,
} from "../../../services/config";
import DisplayPrice from "../../../components/DisplayPrice";

function PriceInfo({ name }: { name: string }) {
  const [field] = useField(name);
  const [probono] = useField("probono");
  {
    /* TODO : centraliser le calcul */
  }
  const taxes = useMemo(
    () => Number(field.value) * parseInt(TISIO_FEE_PERCENTAGE, 10),
    [field.value],
  );

  return (
    field.value &&
    Number(field.value) > 0 && (
      <div className="info cblock--s">
        <div>
          Tarif affiché sur votre profil :{" "}
          <strong>
            <DisplayPrice amount={taxes + Number(field.value) * 100} taxFree />
          </strong>
        </div>
        <div className="body--20">
          dont frais de gestion Tisio ({TISIO_FEE_PERCENTAGE}%) :{" "}
          <strong>
            <DisplayPrice amount={taxes} />
          </strong>
        </div>
        {!probono.value && (
          <div>
            <br />
            Vous percevrez :{" "}
            <strong>
              {/* field.value is multiplied by 100 to convert it to cents */}
              <DisplayPrice amount={Number(field.value) * 100 * 0.9} />
            </strong>
            <br />
            Votre association recevra :{" "}
            <strong>
              <DisplayPrice amount={Number(field.value) * 100 * 0.1} />
            </strong>
          </div>
        )}
        {probono.value && (
          <div>
            <br />
            <div className="body--20">
              Vous avez choisi de reverser l'intégralité de vos gains à votre
              association.
            </div>
            <div>
              Votre association recevra donc : <strong>{field.value}€</strong>
            </div>
          </div>
        )}
      </div>
    )
  );
}

function BookingProfilePreferencesForm() {
  const reload = useReload();
  const navigate = useNavigate();

  const { associations } = useLoaderData() as {
    associations: Association[];
  };
  const profileInterface = useOutletContext<BookingProfileInterface>();
  const { updateBookingProfile } = bookingProfileService();

  const mappedAssociations = useMemo(
    () =>
      associations.map((m) => ({
        label: m.labelFr! + (!m.validated ? " (en cours de validation)" : ""),
        value: String(m.id),
      })),
    [associations],
  );

  const [showCreateAssociation, setShowCreateAssociation] = useState(false);

  return (
    <>
      <SxForm
        initialValues={profileInterface.profile}
        onSubmit={async (values) => {
          const updatedProfile = {
            ...profileInterface.profile,
            ...bookingProfilePreferencesSchema.cast(values),
            price: values.price * 100,
          };

          await updateBookingProfile({
            ...updatedProfile,
            languages: updatedProfile.languages?.map((l) => ({
              id: Number(l),
            })),
            expertises: updatedProfile.expertises?.map((e) => ({
              id: Number(e),
            })),
            skills: updatedProfile.skills?.map((s) => ({ id: Number(s) })),
          });
          reload();

          return profileInterface.isPositionComplete
            ? profileInterface.isDescriptionComplete
              ? []
              : navigate("../description")
            : navigate("../position");
        }}
        validationSchema={bookingProfilePreferencesSchema}
      >
        <div className="grid--2">
          <div className="lblocks">
            <div>
              <SxField
                as="autocomplete"
                placeholder="Sélectionnez une association"
                name="associationId"
                options={mappedAssociations}
                data-testid="association-id"
              />
              <div className="field-tip">
                10% de chaque visio sera reversés à cette association
              </div>
              <button
                type="button"
                className="btn--grey --s cblock"
                onClick={() => setShowCreateAssociation(true)}
              >
                + Proposer une nouvelle association
              </button>
            </div>
            <div>
              <SxField name="price" placeholder="eg. 100" data-testid="price" />
              <div className="field-tip">
                Montant minimum : {MINIMUM_VISIO_PRICE}€
              </div>
              <div className="field-tip">
                Montant maximum : {MAXIMUM_VISIO_PRICE}€
              </div>
              <PriceInfo name="price" />
            </div>
          </div>
          <div>
            <div className="card">
              <div className="card_body">
                <div className="checkboxes-group" role="group">
                  <SxCheckbox
                    name="probono"
                    className="--toggle"
                    tip="Vous choisissez de reverser l'ensemble de vos gains à votre association. Cette indication apparaîtra dans votre profil et pourra inciter des utilisateurs à vous solliciter."
                  />

                  <SxCheckbox
                    name="private"
                    className="--toggle"
                    tip="Ce profil n’apparaîtra plus dans les résultats de recherche, mais vous pourrez partager le lien de votre page personnelle de réservation à qui vous le souhaitez."
                  />
                </div>

                {/* NOTE: Disabled because the functionnality is confusing for the moment */}

                {/* {profileInterface.profile.published !== null && (
                  <div className="lblock --txt--right">
                    <SubmitButton
                      type="button"
                      className="link--danger --s"
                      onClick={() => {
                        return toggleBookingProfile(
                          profileInterface.profile.id,
                          !profileInterface.profile.published,
                        ).then(() => {
                          reload();
                        });
                      }}
                    >
                      {profileInterface.profile.published
                        ? "Désactiver"
                        : "Réactiver"}{" "}
                      mon profil
                    </SubmitButton>
                    <div className="field-tip">
                      Désactivez votre profil pour ne plus du tout être
                      sollicité(e), ni dans les résultats de recherche, ni via
                      votre page personnelle de réservation.
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>

        <div className="form_footer">
          <ValidationErrors />
          <div className="cblock">
            <SubmitButton
              className="btn--1"
              type="submit"
              data-testid="booking-profile-preferences-submit"
            >
              Enregistrer
            </SubmitButton>
          </div>
        </div>
      </SxForm>
      {showCreateAssociation && (
        <BookingProfileCreateAssociationDialog
          onClose={() => setShowCreateAssociation(false)}
        />
      )}
    </>
  );
}

export default BookingProfilePreferencesForm;
