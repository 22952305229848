import BookingCalendarComponent from "../../../components/cal/BookingCalendarComponent";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";

const CampaignBookingDialogContent = ({
  nextStep,
  bookingRequest,
}: {
  nextStep(): void;
  bookingRequest: BookingRequest;
}) => {
  return (
    <>
      <div className="popup_head">
        <h1 className="popup_title">Date de l'interview</h1>
      </div>
      <div className="popup_body">
        <BookingCalendarComponent
          calLink={`${bookingRequest.sender?.user.username}/${bookingRequest.sender?.community.slug}-30-minutes?tisioBookingId=${bookingRequest.uuid}&email=${encodeURIComponent(bookingRequest.extEmail!)}&name=${encodeURIComponent(`${bookingRequest.extFirstname} ${bookingRequest.extLastname}`)}`}
          bookedCallback={nextStep}
        />
      </div>
    </>
  );
};

export default CampaignBookingDialogContent;
