import { object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import { BusinessAccount } from "../../../services/businessAccount/businessAccountModel";
import { transformEmptyToNull } from "../../../react-helpers/yup";
import { useMemo } from "react";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import { orderByField } from "../../../react-helpers/array";
import { Country } from "../../../services/country/countryModel";
import { businessAccountService } from "../../../services/businessAccount/businessAccountService";
import ValidationErrors from "../../../forms/ValidationErrors";

// eslint-disable-next-line react-refresh/only-export-components
export const proAddressSchema = (required: boolean) =>
  object({
    addressLine1: string()
      .label("Adresse ligne 1")
      .transform(transformEmptyToNull)
      .nullable()
      [required ? "required" : "notRequired"](),
    addressLine2: string()
      .label("Adresse ligne 2")
      .transform(transformEmptyToNull)
      .nullable(),
    postalCode: string()
      .label("Code postal")
      .transform(transformEmptyToNull)
      .nullable()
      [required ? "required" : "notRequired"](),
    city: string()
      .label("Ville")
      .transform(transformEmptyToNull)
      .nullable()
      [required ? "required" : "notRequired"](),
    countryCode: string()
      .label("Pays")
      .transform(transformEmptyToNull)
      .nullable()
      [required ? "required" : "notRequired"](),
  });

export const ProAddressFields = ({ countries }: { countries: Country[] }) => {
  const mappedCountries = useMemo(() => {
    return countries.sort(orderByField("countryName")).map((country) => ({
      value: country.countryCode,
      label: country.countryName,
    }));
  }, [countries]);

  return (
    <div className="cblocks">
      <SxField
        name="addressLine1"
        placeholder={"Rue, voie, boîte postale..."}
      />
      <SxField
        name="addressLine2"
        placeholder={"Complément d'adresse. Bâtiment, étage..."}
      />

      <div className="grid">
        <div>
          <SxField name="postalCode" />
        </div>
        <div>
          <SxField name="city" />
        </div>
      </div>
      <SxField
        name="countryCode"
        as="autocomplete"
        options={mappedCountries}
        placeholder={"Sélectionnez un pays"}
      />
    </div>
  );
};

export const ProAddressForm = ({
  businessAccount,
  countries,
  notRequired,
}: {
  businessAccount: BusinessAccount;
  countries: Country[];
  notRequired?: boolean;
}) => {
  const schema = useMemo(() => proAddressSchema(!notRequired), [notRequired]);
  const { updateBusinessAccount } = businessAccountService();

  return (
    <SxForm
      initialValues={businessAccount}
      onSubmit={(values) => {
        return updateBusinessAccount(businessAccount.id, values);
      }}
      validationSchema={schema}
    >
      <ProAddressFields countries={countries} />

      <div className="form_footer lblocks">
        <ValidationErrors />
        <SubmitButton className="btn" type="submit">
          Enregistrer
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default ProAddressForm;
