import { useState } from "react";
import { cx } from "../../../../react-helpers/css";
import Img1 from "../../../../assets/img/landings/how-it-works-1.png";
import Img2 from "../../../../assets/img/landings/how-it-works-2.png";
import Img3 from "../../../../assets/img/landings/how-it-works-3.png";
import Img4 from "../../../../assets/img/landings/how-it-works-4.png";
import Img5 from "../../../../assets/img/landings/how-it-works-5.png";

// TODO REFACTO : more dynamic behaviour (based on data object)
function HowItWorksSteps() {
  const [step, setStep] = useState(1);

  return (
    <>
      <div className="landing_steps">
        <button
          className={cx(["step_item", step === 1 && "--active"])}
          onClick={() => setStep(1)}
        >
          <span>1</span>
        </button>
        <button
          className={cx(["step_item", step === 2 && "--active"])}
          onClick={() => setStep(2)}
        >
          <span>2</span>
        </button>
        <button
          className={cx(["step_item", step === 3 && "--active"])}
          onClick={() => setStep(3)}
        >
          <span>3</span>
        </button>
        <button
          className={cx(["step_item", step === 4 && "--active"])}
          onClick={() => setStep(4)}
        >
          <span>4</span>
        </button>
        <button
          className={cx(["step_item", step === 5 && "--active"])}
          onClick={() => setStep(5)}
        >
          <span>5</span>
        </button>
      </div>
      <div className="container --wrap--s">
        {step === 1 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Choisissez le type de campagne souhaitée.
              </h3>
              <p>
                Campagne ciblée individuellement, envoyée par Tisio ou par
                vous-même, ou campagne publique ouverte à tous, choisissez le
                mode de sollicitation de vos contacts.
              </p>
            </div>
            <div className="card --200 rotate--m10">
              <img src={Img1} alt="" />
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Définissez votre demande et le tarif.
              </h3>
              <p>
                Précisez la nature de votre sollicitation (pourquoi vous
                souhaitez rencontrer votre interlocuteur) et déterminez
                librement le montant de la rémunération que vous proposez.
              </p>
            </div>
            <div className="card --200 rotate--10">
              <img src={Img3} alt="" />
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">Insérez vos contacts.</h3>
              <p>
                Préparez un fichier comprenant nom, prénom et adresse mail
                professionnel des personnes que vous souhaitez interviewer et
                importez-le en 2 clics.
              </p>
            </div>
            <div className="card --200 rotate--m10">
              <img src={Img2} alt="" />
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Les visios se calent directement dans votre agenda.
              </h3>
              <p>
                Les interlocuteurs qui acceptent la sollicitation choisissent un
                créneau dans votre agenda, vous n'avez rien à faire.
              </p>
            </div>
            <div className="card --200 rotate--10">
              <img src={Img4} alt="" />
            </div>
          </div>
        )}
        {step === 5 && (
          <div className="l--content-img fade-in">
            <div className="cblocks --xs">
              <h3 className="paragraph_title">
                Bénéficiez d'échanges ultra-qualitatifs.
              </h3>
              <p>
                Vos interlocuteurs répondent à vos questions, partagent leurs
                retours sur votre solution, et vous engagez un échange en toute
                confiance !
              </p>
            </div>
            <div className="card --200 rotate--m10">
              <img src={Img5} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default HowItWorksSteps;
