import Icon from "../../../components/Icon";

function HomeReinsuranceSection() {
  return (
    <div className="section --l --bg">
      <div className="container">
        <div className="lblock flex-grid --3 --center">
          <div className="--txt--center cblocks">
            <Icon className="--xxl --blue" name="relation" />
            <div className="--txt--blue">
              Mise en contact facilitée
              <br />
              grâce à un tiers de <strong>confiance</strong>
            </div>
          </div>
          <div className="--txt--center cblocks">
            <Icon className="--xxl --blue" name="rating" />
            <div className="--txt--blue">
              Système de <strong>notation</strong>
              <br />
              des interlocuteurs
            </div>
          </div>

          <div className="--txt--center cblocks">
            <Icon className="--xxl --blue" name="securedpayment" />
            <div className="--txt--blue">
              Paiement
              <br />
              <strong>sécurisé</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeReinsuranceSection;
