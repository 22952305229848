import { useState, useEffect } from "react";
import ContentSection from "./ContentSection";

interface PublicContentPageProps {
  data: any;
}

function PublicContentPage({ data }: PublicContentPageProps) {
  const [content, setContent] = useState<any>(null);

  useEffect(() => {
    setContent(data);
  }, [data]);

  if (!content) {
    return (
      <div className="container --wrap">
        <div className="page-content">Chargement...</div>
      </div>
    );
  }

  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">{content.page_title}</h1>
      </div>
      <div className="page-content lblocks --l">
        {content.sections.map((section: any, index: number) => (
          <ContentSection section={section} key={index} />
        ))}
      </div>
    </div>
  );
}

export default PublicContentPage;
