import { Link } from "react-router-dom";
import { cx } from "../../react-helpers/css";
import Icon from "../Icon";
import ImgFeature1 from "../../assets/img/plans/plan-1-1.png";
import ImgFeature2 from "../../assets/img/plans/plan-1-2.png";
import ImgFeature3 from "../../assets/img/plans/plan-1-3.png";
import ImgFeature4 from "../../assets/img/plans/plan-1-4.png";
import ImgFeature5 from "../../assets/img/plans/plan-1-5.png";
import ImgFeature6 from "../../assets/img/plans/plan-1-6.png";
import ImgTagYearlyDiscount from "../../assets/img/plans/tag25p.png";
import { useEffect, useState } from "react";

function PlanCard({
  displayCta = true,
  yearly,
  onChange,
}: {
  displayCta?: boolean;
  yearly?: boolean;
  onChange?(value: 1 | 2): void;
}) {
  const [frequency, setFrequency] = useState(2);

  useEffect(() => {
    setFrequency(yearly === false ? 1 : 2);
  }, [yearly]);

  const toggleFrequency = () => {
    const newFrequency = frequency === 1 ? 2 : 1;
    setFrequency(newFrequency);
    onChange?.(newFrequency);
  };

  return (
    <div className="plan_block lblocks">
      <div className="plan_frequency">
        <div className="toggle-switch">
          <div className={cx(["switch_opt", frequency === 1 && "--active"])}>
            Mensuel
          </div>
          <button
            type="button"
            className={`btn--toggle --opt${frequency}`}
            onClick={toggleFrequency}
          />
          <div className={cx(["switch_opt", frequency === 2 && "--active"])}>
            Annuel <img src={ImgTagYearlyDiscount} className="discount" />
          </div>
        </div>
      </div>
      <div className="card --plan">
        <div className="card_head">
          <h3 className="plan_price">
            <strong>{frequency === 1 ? "199" : "149"}€</strong>
            <small>HT par mois</small>
          </h3>

          {!!displayCta && (
            <div className="head_cta">
              <Link
                className="btn--cta"
                to={`/plans/1/subscribe${frequency === 2 ? "?y" : ""}`}
              >
                <span>Je commence !</span>{" "}
                <Icon name="arrow-right" className="--blue" />
              </Link>
            </div>
          )}
        </div>
        <div className="card_body">
          <div className="plan_features">
            <div className="feature_item">
              <img src={ImgFeature1} />
              <div>
                Import de fichiers de prospection et nombre de visios illimités
              </div>
            </div>
            <div className="feature_item">
              <img src={ImgFeature2} />
              <div>
                Accès à l’option “Campagne ciblée” & “Campagne publique”
              </div>
            </div>
            <div className="feature_item">
              <img src={ImgFeature3} />
              <div>
                Accès à l’option “Envoi par Tisio” ou “Envoi par vos soins”
              </div>
            </div>
            <div className="feature_item">
              <img src={ImgFeature4} />
              <div>0% de commission prélevé sur le tarif des visios</div>
            </div>
            <div className="feature_item">
              <img src={ImgFeature5} />
              <div>Tableau de bord de vos campagnes actives</div>
            </div>
            <div className="feature_item">
              <img src={ImgFeature6} />
              <div>
                Support Premium ”humain” accessible 5&nbsp;jours&nbsp;/&nbsp;7
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanCard;
