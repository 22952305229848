import { useLoaderData } from "react-router-dom";
import { ClientOnly } from "../../../../react-helpers/react";
import BookingRequestPaymentComponent from "./BookingRequestPaymentComponent";
import { BookingRequest } from "../../../../services/bookingRequest/bookingRequestModel";
import DisplayPrice from "../../../../components/DisplayPrice";
import BackButton from "../../../../components/BackButton";
import Icon from "../../../../components/Icon";

const BookingProfilePaymentPage = () => {
  const { bookingRequest } = useLoaderData() as {
    bookingRequest: BookingRequest;
  };

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton forcedUrl={`/app/bookings/${bookingRequest.uuid}`} />
          <div className="page_title">Paiement de la sollicitation</div>
        </div>
      </div>
      <div className="section">
        <div className="scorecard --bg">
          <Icon name="label" />
          <div>
            <div className="scorecard_label">Montant à payer</div>
            <div className="scorecard_value">
              <DisplayPrice amount={bookingRequest.paidPrice!} />
            </div>
          </div>
        </div>

        <ClientOnly>
          {/* We use a function so the props are only evaluated when on client */}
          {(bookingRequest.paidPrice ?? 0) > 0
            ? () => (
                <div className="cblock card --bg">
                  <div className="card_body">
                    <BookingRequestPaymentComponent
                      clientSecret={bookingRequest.paymentSecret!}
                      amount={Math.round(bookingRequest.paidPrice! * 100)}
                      returnUrl={`${location.protocol}//${location.host}/app/bookings/${bookingRequest.uuid}`}
                    />
                  </div>
                </div>
              )
            : null}
        </ClientOnly>
      </div>
    </div>
  );
};

export default BookingProfilePaymentPage;
