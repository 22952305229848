import { useMemo, useState } from "react";
import Dialog from "../../../components/Dialog";
import SxForm from "../../../forms/SxForm";
import { object, string } from "yup";
import {
  checkIban,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import { User } from "../../../services/user/userModel";
import { userService } from "../../../services/user/userService";
import { paymentService } from "../../../services/payment/paymentService";
import { deepEqual } from "../../../react-helpers/any";
import useReload from "../../../hooks/useReload";

const EWalletRefundDialog = ({
  onClose,
  loggedUser,
}: {
  onClose(): void;
  loggedUser: User;
}) => {
  const reload = useReload();
  const { updateUserById } = userService();
  const { cashoutForCurrentUser } = paymentService();

  const [readOnly, setReadOnly] = useState(
    !!loggedUser.iban && !!loggedUser.bic,
  );

  const initialValues = useMemo(
    () => ({
      iban: loggedUser.iban ?? "",
      bic: loggedUser.bic ?? "",
    }),
    [loggedUser],
  );

  return (
    <Dialog onClose={onClose}>
      <>
        <div className="popup_head">
          <div className="popup_title">Retrait des crédits</div>
          <div className="body--30">
            Demandez le versement de vos crédits sur votre compte
          </div>
        </div>
        <SxForm
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values) => {
            if (!deepEqual(initialValues, values)) {
              await updateUserById({
                ...loggedUser,
                iban: values.iban,
                bic: values.bic,
              });
            }

            return cashoutForCurrentUser().then(() => {
              reload();
              onClose();
            });
          }}
          validationSchema={object({
            iban: string()
              .label("IBAN")
              .transform(transformEmptyToUndefined)
              .test("checkIbanFormat", "IBAN invalide", checkIban)
              .required(),
            bic: string()
              .label("BIC")
              .transform(transformEmptyToUndefined)
              .required(),
          })}
        >
          <>
            <div className="popup_body">
              <SxField disabled={readOnly} name="iban" />
              <SxField disabled={readOnly} name="bic" />

              {readOnly && (
                <div className="cblock">
                  <button
                    type="button"
                    className="btn--grey --s"
                    onClick={() => setReadOnly(false)}
                  >
                    mettre à jour mon compte bancaire
                  </button>
                </div>
              )}
            </div>
            <div className="popup_footer">
              <button type="button" className="btn--grey" onClick={onClose}>
                Annuler
              </button>
              <SubmitButton type="submit" className="btn">
                Confirmer
              </SubmitButton>
            </div>
          </>
        </SxForm>
      </>
    </Dialog>
  );
};

export default EWalletRefundDialog;
