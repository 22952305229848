import { contactRequest, targetingProfileRequest } from "./supportApi";
import { toastsWithIntl } from "../toastService";
import { Contact } from "../../views/contact/ContactForm";
import { TargetedProfile } from "../../views/profilesList/NoProfileFound/TargetingProfilesHelperDialog";

interface SupportService {
  contact(contact: Contact): Promise<void>;
  targetingProfile: (targetedProfile: TargetedProfile) => Promise<void>;
}

export const supportService = () => {
  const { toastError, toastSuccess } = toastsWithIntl(["global", "support"]);

  const contact: SupportService["contact"] = (contact) =>
    contactRequest(contact).then(
      () => {
        toastSuccess("support:contact.SUCCESS");
      },
      (err) => {
        toastError("global:GENERIC_ERROR", {
          id: "support-contact-error",
        });
        return Promise.reject(err);
      },
    );

  const targetingProfile: SupportService["targetingProfile"] = (
    targetedProfile,
  ) =>
    targetingProfileRequest(targetedProfile).then(
      () => {
        toastSuccess("support:targetingProfile.SUCCESS");
      },
      (err) => {
        toastError("global:GENERIC_ERROR", {
          id: "support-targeting-profile-error",
        });
        return Promise.reject(err);
      },
    );

  return {
    contact,
    targetingProfile,
  };
};
