import { Link, useLoaderData } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";
import {
  BookingRequest,
  BookingRequestAttendanceDeclaration,
} from "../../../services/bookingRequest/bookingRequestModel";
import { useMemo, useState } from "react";
import { isAfter, isBefore, parseISO } from "date-fns";
import BookingClosureBookingHappenedForm from "./BookingClosureBookingHappenedForm";
import BookingClosureBookingNotHappenedForm from "./BookingClosureBookingNotHappenedForm";
import { cx } from "../../../react-helpers/css";
import BookingRequestAside from "../../../components/bookings/BookingRequestAside";

const BookingClosurePage = () => {
  const { bookingRequest, currentUserBookingProfile } = useLoaderData() as {
    bookingRequest: BookingRequest;
    currentUserBookingProfile: BookingProfile;
  };

  const isCurrentUserSender =
    bookingRequest.senderId === currentUserBookingProfile.id;

  const isBookingRequestFinal = useMemo(
    () =>
      bookingRequest.status === "CLOSED" ||
      bookingRequest.status === "CONFLICT",
    [bookingRequest],
  );

  const isAlreadyCompleted = useMemo(
    () =>
      (isCurrentUserSender && !!bookingRequest.senderClosureDate) ||
      (!isCurrentUserSender && !!bookingRequest.recipientClosureDate),
    [bookingRequest, isCurrentUserSender],
  );

  const bookingAttendanceStatus = useMemo(
    () =>
      bookingRequest.recipientAttendanceDeclaration ??
      bookingRequest.senderAttendanceDeclaration ??
      BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE,
    [bookingRequest],
  );

  const [bookingHappened, setBookingHappened] = useState<null | boolean>(
    isCurrentUserSender
      ? bookingRequest.senderAttendanceDeclaration
        ? bookingRequest.senderAttendanceDeclaration ===
          BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE
        : null
      : bookingRequest.recipientAttendanceDeclaration
        ? bookingRequest.recipientAttendanceDeclaration ===
          BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE
        : null,
  );

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton />
          <div className="page_title">Clôturer le rendez-vous</div>
        </div>
      </div>

      <div className="section">
        <div className="grid--3-2">
          <div>
            {/* *** BOOKING IS NOT PASSED YET */}
            {bookingRequest.status === "BOOKED" &&
              isBefore(
                new Date(),
                parseISO(bookingRequest.booking!.startTime),
              ) && (
                <div className="info">Le RDV n'a pas encore été réalisé</div>
              )}

            {!isAlreadyCompleted &&
              isAfter(
                new Date(),
                parseISO(bookingRequest.booking!.startTime),
              ) && (
                <div className="lblocks">
                  {!(isBookingRequestFinal && bookingHappened === null) ? (
                    <div className="card --bg">
                      <div className="card_body --txt--center cblocks">
                        <h3 className="section_title">
                          Le rendez-vous a-t-il bien eu lieu ?
                        </h3>
                        <div className="switch-buttons --block">
                          <button
                            className={cx([bookingHappened && "--active"])}
                            type="button"
                            disabled={isAlreadyCompleted}
                            onClick={() => setBookingHappened(true)}
                          >
                            Oui
                          </button>
                          <button
                            className={cx([
                              bookingHappened === false && "--active",
                            ])}
                            type="button"
                            disabled={isAlreadyCompleted}
                            onClick={() => setBookingHappened(false)}
                          >
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="info">
                      <div>
                        Le rendez-vous a été clôturé automatiquement car il est
                        terminé depuis plus de 48 heures.
                      </div>
                      <div className="cblocks">
                        Statut :{" "}
                        <strong>
                          {bookingAttendanceStatus ===
                          BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE
                            ? "Le rendez-vous a bien eu lieu"
                            : "Le rendez-vous n'a pas eu lieu"}
                        </strong>
                      </div>
                      <div className="cblocks">
                        Ce n’est pas le cas ?{" "}
                        <Link to="/contact">Contactez-nous !</Link>
                      </div>
                    </div>
                  )}
                  {(bookingHappened === true ||
                    (bookingHappened === null &&
                      isBookingRequestFinal &&
                      bookingAttendanceStatus ===
                        BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE)) && (
                    <BookingClosureBookingHappenedForm
                      isCurrentUserSender={isCurrentUserSender}
                      bookingRequest={bookingRequest}
                      isAlreadyCompleted={isAlreadyCompleted}
                    />
                  )}
                  {bookingHappened === false && (
                    <BookingClosureBookingNotHappenedForm
                      isCurrentUserSender={isCurrentUserSender}
                      bookingRequest={bookingRequest}
                      isAlreadyCompleted={isAlreadyCompleted}
                    />
                  )}
                </div>
              )}
          </div>
          <BookingRequestAside
            bookingRequest={bookingRequest}
            currentUser={currentUserBookingProfile.user}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingClosurePage;
