import { Link, useLoaderData } from "react-router-dom";
import { User } from "../../../../services/user/userModel";
import { BookingRequest } from "../../../../services/bookingRequest/bookingRequestModel";
import useReload from "../../../../hooks/useReload";
import { useState, useMemo, useEffect } from "react";
import BookingRequestPaymentRecapForm from "../BookingRequestPaymentRecapForm";
import { CAL_BASE_URL } from "../../../../services/config";
import BackButton from "../../../../components/BackButton";
import Icon from "../../../../components/Icon";
import BookingDateTimeDisplay from "../../../../components/bookings/BookingDateTimeDisplay";
import { isAfter, parseISO } from "date-fns";
import BookingRequestAside from "../../../../components/bookings/BookingRequestAside";
import BookingCalendarComponent from "../../../../components/cal/BookingCalendarComponent";

const BookingRequestPage = () => {
  const { bookingRequest, currentUser, currentUserCredits } =
    useLoaderData() as {
      bookingRequest: BookingRequest;
      currentUser: User;
      currentUserCredits: {
        eCredit: User["eCredit"];
        vouchers: User["vouchers"];
      };
    };

  const reload = useReload();

  const otherPartyBookingProfile = useMemo(() => {
    return bookingRequest.sender?.userId === currentUser.id
      ? bookingRequest.recipient
      : bookingRequest.sender!;
  }, [bookingRequest, currentUser]);

  const isCurrentUserSender = currentUser.id === bookingRequest.sender?.userId;

  const [hasBooked, setBooked] = useState(
    (bookingRequest.status === "BOOKED" || !!bookingRequest.booking) &&
      bookingRequest.status !== "CLOSED",
  );

  const closureStartingTime = useMemo(() => {
    if (!bookingRequest.booking) return null;
    const bookingStartTime = new Date(bookingRequest.booking.startTime);
    bookingStartTime.setMinutes(bookingStartTime.getMinutes() + 15);
    return bookingStartTime;
  }, [bookingRequest.booking]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (bookingRequest.status === "PENDING") timeout = setTimeout(reload, 5000);

    return () => {
      if (timeout) clearTimeout(timeout);
      timeout = null;
    };
  }, [bookingRequest, reload]);

  const isAlreadyCompleted = useMemo(
    () =>
      (isCurrentUserSender && !!bookingRequest.senderClosureDate) ||
      (!isCurrentUserSender && !!bookingRequest.recipientClosureDate),
    [bookingRequest, isCurrentUserSender],
  );

  const rescheduleBookingProfile = useMemo(() => {
    return bookingRequest.external
      ? bookingRequest.sender
      : bookingRequest.recipient;
  }, [bookingRequest]);

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/bookings" />
          <h1 className="page_title">
            {bookingRequest.status === "CREATED" && "Récapitulatif & paiement"}
            {bookingRequest.status === "BOOKED" && "Votre rendez-vous"}
            {bookingRequest.status === "CLOSED" && "Rendez-vous terminé"}
            {bookingRequest.status === "PAID" &&
              isCurrentUserSender &&
              !hasBooked &&
              "Choix du créneau"}
          </h1>
        </div>
      </div>

      <div className="page-content">
        <div className="grid--3-1">
          {/* *** MAIN COL *** */}
          <div>
            {/* TODO: change when new update + launch payement intent route  is created */}
            {bookingRequest.status === "CREATED" && isCurrentUserSender && (
              <BookingRequestPaymentRecapForm
                bookingRequest={bookingRequest}
                currentUserCredits={currentUserCredits}
              />
            )}
            {bookingRequest.status === "PAID" &&
              isCurrentUserSender &&
              !hasBooked &&
              otherPartyBookingProfile && (
                <BookingCalendarComponent
                  bookedCallback={() => {
                    setBooked(true);
                    reload();
                  }}
                  calLink={`${otherPartyBookingProfile.user.username}/${otherPartyBookingProfile.community.slug}-30-minutes?tisioBookingId=${bookingRequest.uuid}&email=${encodeURIComponent(currentUser.email!)}&name=${encodeURIComponent(`${currentUser.firstname} ${currentUser.lastname}`)}`}
                />
              )}
            {bookingRequest.status === "PENDING" && (
              <div className="info --warning">
                Le paiement est en cours de traitement, veuillez patienter
                quelques instants...
              </div>
            )}

            {bookingRequest.booking && (
              <div className="lblocks">
                <div className="cblocks">
                  <div>
                    <BookingDateTimeDisplay booking={bookingRequest.booking} />

                    {isAfter(
                      parseISO(bookingRequest.booking?.startTime),
                      new Date(),
                    ) &&
                      rescheduleBookingProfile && (
                        <div className="--txt--right">
                          <a
                            href={`${CAL_BASE_URL}/${rescheduleBookingProfile.user.username}/${rescheduleBookingProfile.community.slug}-30-minutes?rescheduleUid=${bookingRequest.booking.uid}`}
                            target="_blank"
                            className="link --s --with-icon"
                            rel="noreferrer"
                          >
                            <Icon name="clock" />
                            replanifier le rendez-vous
                          </a>
                        </div>
                      )}
                  </div>
                </div>

                {closureStartingTime &&
                  new Date() >= closureStartingTime &&
                  bookingRequest.status !== "CLOSED" &&
                  (bookingRequest.sender!.userId === currentUser.id
                    ? bookingRequest.senderClosureDate === null
                    : bookingRequest.recipientClosureDate === null) && (
                    <div className="card --bg">
                      <div className="card_body --txt--center cblocks">
                        <div className="info">
                          À l'issue du rendez-vous, les participants ont 48h
                          pour le clôturer, au delà de quoi il sera considéré
                          comme s'étant bien déroulé, et les paiements seront
                          effectués.
                        </div>
                        <Link
                          to={`/app/bookings/${bookingRequest.uuid}/closure`}
                          className="btn"
                        >
                          Clôturer le RDV
                        </Link>
                      </div>
                    </div>
                  )}

                {/* *** BOOKING HAS ALREADY BEEN CLOSED BY CURRENT USER */}
                {/* TODO afficher les infos de clôture */}
                {isAlreadyCompleted && bookingRequest.status !== "CLOSED" && (
                  <div className="lblocks">
                    <div className="info">
                      Mission accomplie ! Vous avez clôturé ce rendez-vous.
                      <br />
                      Il est désormais en attente de la réponse de votre
                      interlocuteur, ou du délai de 48h pour être définitivement
                      clôturé.
                    </div>
                  </div>
                )}

                {bookingRequest.status === "CLOSED" && (
                  <div className="lblocks">
                    <div className="info --success">
                      Ce rendez-vous est clôturé
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          <BookingRequestAside
            bookingRequest={bookingRequest}
            currentUser={currentUser}
            displayDate={false}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingRequestPage;
