import { Link, NavLink, Outlet, useLoaderData } from "react-router-dom";
import BackButton from "../../../../components/BackButton";
import Tabs from "../../../../components/Tabs";
import Icon from "../../../../components/Icon";
import { cx } from "../../../../react-helpers/css";
import {
  BookingProfile,
  EditingBookingProfile,
} from "../../../../services/bookingProfile/bookingProfileModel";
import { useMemo } from "react";
import { User } from "../../../../services/user/userModel";

import {
  bookingProfileDescriptionSchema,
  bookingProfilePositionSchema,
  bookingProfilePreferencesSchema,
} from "../../../../services/bookingProfile/bookingProfileService";

import { ClientOnly } from "../../../../react-helpers/react";
import CopyToClipboard from "../../../../components/utilities/CopyToClipboard";

import ProfileDisplay from "./ProfileDisplay";

// REVIEW JP : ne pourrait-on pas créer un seul type avec des types variables pour ces 3 propriétés ?
export interface BookingProfileInterface {
  profile: EditingBookingProfile;
  isPositionComplete: boolean;
  isDescriptionComplete: boolean;
  isPreferencesComplete: boolean;
}

function BookingProfileManagementPage() {
  const { profile, user } = useLoaderData() as {
    profile: BookingProfile;
    user: User;
  };

  // Mapped profile to ease usage in forms
  const editingProfile = useMemo(
    () => ({
      ...profile,
      price: profile.price ? profile.price / 100 : undefined,
      languages: profile.languages.map((l) => String(l.id)),
      skills: profile.skills.map((s) => String(s.id)),
      expertises: profile.expertises.map((e) => String(e.id)),
    }),
    [profile],
  );

  const isPositionComplete = useMemo(
    () => bookingProfilePositionSchema.isValidSync(editingProfile),
    [editingProfile],
  );
  const isDescriptionComplete = useMemo(
    () => bookingProfileDescriptionSchema.isValidSync(editingProfile),
    [editingProfile],
  );
  const isPreferencesComplete = useMemo(
    () =>
      bookingProfilePreferencesSchema.isValidSync({
        ...editingProfile,
        price: editingProfile.price ? editingProfile.price : undefined,
      }),
    [editingProfile],
  );

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton />
          <h1 className="page_title">Votre profil Tisio</h1>
        </div>
      </div>
      <div className="section lblocks">
        <ProfileDisplay user={user} profile={profile} />

        <div className="section--s cblocks">
          {(!isPositionComplete ||
            !isDescriptionComplete ||
            !isPreferencesComplete) &&
            !user.migrated && (
              <div className="info --warning">
                Votre profil n’est pas encore visible car il est incomplet.
                <br />
                Complétez vite les informations manquantes ci-dessous pour
                pouvoir être sollicité !
              </div>
            )}
          {(!isPositionComplete ||
            !isDescriptionComplete ||
            !isPreferencesComplete) &&
            !!user.migrated && (
              <div className="info --warning">
                Suite à la migration de votre compte, votre profil est désormais
                en ligne, mais il n'est pas encore associé à vos domaines
                d'expertise et compétences clés. Renseignez-les pour augmenter
                vos chances d'apparaître dans les résultats de recherche et
                d'être sollicité !
              </div>
            )}
          {profile.published === true && !user.completedOnboarding && (
            <div className="info --warning">
              Votre profil est complet, mais vous devez{" "}
              <Link to="/app/my-calendar">paramétrer votre calendrier</Link>{" "}
              pour apparaître dans la liste de profils Tisio et pouvoir être
              sollicité pour un RDV.
            </div>
          )}
          {profile.published === true && user.completedOnboarding && (
            <ClientOnly>
              {() => {
                const profileUrl = `${window.location.origin}/c/${profile.community.slug}/${profile.user.username}`;

                return (
                  <CopyToClipboard value={profileUrl}>
                    Partagez le {""}
                    <a
                      className="link--accent --s"
                      href={profileUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      lien de votre page de réservation
                    </a>
                  </CopyToClipboard>
                );
              }}
            </ClientOnly>
          )}
        </div>
        <Tabs>
          <NavLink className="tab_item" to="position">
            <div className="lrow --gap--xxs">
              <Icon
                name="check-round"
                className={cx([isPositionComplete ? "--success" : "--grey"])}
              />
              Poste
            </div>
          </NavLink>
          <NavLink className="tab_item" to="description">
            <div className="lrow --gap--xxs">
              <Icon
                name="check-round"
                className={cx([isDescriptionComplete ? "--success" : "--grey"])}
              />
              Description
            </div>
          </NavLink>
          <NavLink className="tab_item" to="preferences">
            <div className="lrow --gap--xxs">
              <Icon
                name="check-round"
                className={cx([isPreferencesComplete ? "--success" : "--grey"])}
              />
              Tarif & préférences
            </div>
          </NavLink>
        </Tabs>

        <Outlet
          context={{
            profile: editingProfile,
            isPositionComplete,
            isDescriptionComplete,
            isPreferencesComplete,
          }}
        />
      </div>
    </div>
  );
}

export default BookingProfileManagementPage;
