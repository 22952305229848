import { useNavigate } from "react-router-dom";
import SxForm from "../../../forms/SxForm";
import useReload from "../../../hooks/useReload";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { User } from "../../../services/user/userModel";
import BookingProfilePaymentTableComponent from "../bookingProfile/BookingProfilePaymentTableComponent";
import { TISIO_FEE_PERCENTAGE } from "../../../services/config";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import { number, object } from "yup";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import Icon from "../../../components/Icon";

const BookingRequestPaymentRecapForm = ({
  bookingRequest,
  currentUserCredits,
}: {
  bookingRequest: BookingRequest;
  currentUserCredits: {
    eCredit: User["eCredit"];
    vouchers: User["vouchers"];
  };
}) => {
  const { updateBookingRequestById } = bookingRequestService();
  const reload = useReload();
  const navigate = useNavigate();
  const { confirm } = useConfirmationWithIntl("bookingRequest");
  return (
    <SxForm
      initialValues={{
        eCredit: bookingRequest.eCredit,
        voucherId: bookingRequest.voucherId,
      }}
      onSubmit={(values) => {
        const resolve = (bookingRequest: BookingRequest) =>
          bookingRequest.status === "PAID" ? reload() : navigate(`payments`);
        const selectedVoucherAmount =
          currentUserCredits.vouchers?.find(
            (voucher) => voucher.id === values.voucherId,
          )?.amount ?? null;
        const bookingPaidPrice =
          bookingRequest.price! *
          (1 + (bookingRequest.fee ?? TISIO_FEE_PERCENTAGE) / 100);

        if (selectedVoucherAmount && selectedVoucherAmount > bookingPaidPrice) {
          // If the voucher covers the total price, we displayed a confirmation dialog
          confirm(
            "payment.CONFIRM",
            () =>
              updateBookingRequestById(bookingRequest.id, {
                ...bookingRequest,
                voucherId: values.voucherId ?? null,
              }).then(resolve),
            true,
          );
          return Promise.resolve();
        } else
          return updateBookingRequestById(bookingRequest.id, {
            ...bookingRequest,
            voucherId: values.voucherId ?? null,
          }).then(resolve);
      }}
      validationSchema={object({
        eCredit: number().min(0).nullable(),
        voucherId: number().nullable().transform(transformEmptyToUndefined),
      })}
    >
      <div className="cblock">
        <BookingProfilePaymentTableComponent
          bookingPrice={bookingRequest.price!}
          vouchersFieldName="voucherId"
          eCreditFieldName="eCredit"
          bookingRequestFee={bookingRequest.fee}
          userCredits={currentUserCredits}
        />
      </div>

      <div className="lblock--l --txt--right">
        <SubmitButton className="btn--2" type="submit">
          Valider
          <Icon name="arrow-right" />
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default BookingRequestPaymentRecapForm;
