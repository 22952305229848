import SxForm from "../../../forms/SxForm";
import useReload from "../../../hooks/useReload";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import ValidationErrors from "../../../forms/ValidationErrors";
import { useLoaderData, useNavigate, useOutletContext } from "react-router-dom";
import {
  CompanyActivity,
  CompanyHeadcount,
  HierarchyLevel,
} from "../../../services/data/dataModel";
import { useMemo } from "react";
import {
  bookingProfilePositionSchema,
  bookingProfileService,
} from "../../../services/bookingProfile/bookingProfileService";
import { BookingProfileInterface } from "./bookingProfileManagementPage/BookingProfileManagementPage";

function BookingProfilePositionForm() {
  const reload = useReload();
  const navigate = useNavigate();
  const { companyActivities, companyHeadcounts, hierarchyLevels } =
    useLoaderData() as {
      companyActivities: CompanyActivity[];
      companyHeadcounts: CompanyHeadcount[];
      hierarchyLevels: HierarchyLevel[];
    };
  const profileInterface = useOutletContext<BookingProfileInterface>();
  const { updateBookingProfile } = bookingProfileService();

  const mappedCompanyActivities = useMemo(
    () =>
      companyActivities.map((ca) => ({
        label: ca.labelFr,
        value: String(ca.id),
      })),
    [companyActivities],
  );

  return (
    <SxForm
      enableReinitialize
      initialValues={profileInterface.profile}
      onSubmit={async (values) => {
        const updatedProfile = {
          ...profileInterface.profile,
          ...bookingProfilePositionSchema.cast(values),
          price: profileInterface.profile.price * 100,
        };

        await updateBookingProfile({
          ...updatedProfile,
          languages: updatedProfile.languages?.map((l) => ({ id: Number(l) })),
          expertises: updatedProfile.expertises?.map((e) => ({
            id: Number(e),
          })),
          skills: updatedProfile.skills?.map((s) => ({ id: Number(s) })),
        });
        reload();

        return profileInterface.isDescriptionComplete
          ? profileInterface.isPreferencesComplete
            ? []
            : navigate("../preferences")
          : navigate("../description");
      }}
      validationSchema={bookingProfilePositionSchema}
      data-testid="booking-profile-position-form"
    >
      <div className="grid--2">
        <div>
          <SxField
            name="position"
            placeholder="eg. Directeur commercial"
            data-testid="position"
          />
          <div className="field-tip">max. 100 caractères</div>
        </div>
        <SxField
          name="hierarchyLevelId"
          as="select"
          data-testid="hierarchy-level-id"
        >
          <option value="">- Sélectionnez un niveau -</option>
          {hierarchyLevels.map((hierarchyLevel) => (
            <option key={hierarchyLevel.id} value={hierarchyLevel.id}>
              {hierarchyLevel.labelFr}
            </option>
          ))}
        </SxField>
        <SxField
          name="companyName"
          placeholder="eg. Tesla"
          data-testid="company-name"
        />
        <SxField
          name={"companyActivityId"}
          as={"autocomplete"}
          options={mappedCompanyActivities}
          placeholder="- Sélectionnez un secteur -"
          data-testid="company-activity-id"
        />
        <SxField
          name="companyHeadcountId"
          as="select"
          data-testid="company-headcount-id"
        >
          <option value="">- Sélectionnez un effectif -</option>
          {companyHeadcounts.map((companyHeadcount) => (
            <option key={companyHeadcount.id} value={companyHeadcount.id}>
              {companyHeadcount.labelFr}
            </option>
          ))}
        </SxField>
      </div>

      <div className="form_footer">
        <ValidationErrors />
        <div className="cblock">
          <SubmitButton
            className="btn--1"
            type="submit"
            data-testid="booking-profile-position-submit"
          >
            Enregistrer
          </SubmitButton>
        </div>
      </div>
    </SxForm>
  );
}

export default BookingProfilePositionForm;
