import CopyToClipboard from "../components/utilities/CopyToClipboard";
import Icon from "../components/Icon";

const icons = Object.keys(
  import.meta.glob(
    ["../assets/icons/*.svg", "../assets/icons/sx-icons/*.svg"],
    { eager: true },
  ),
).map((path) => {
  const match = path.match(/icon-(.*?)\.svg/);
  return match?.[1];
});

function SxUIIcons() {
  return (
    <div className="container page-content">
      <h1>Icons</h1>
      <br />
      <div>
        <p>
          Une icône inline <Icon name={"close"} className="--inline --red" /> au
          milieu d'un paragraphe
        </p>
        <div className="lrow">
          {icons.map((name) => (
            <div key={name}>
              <div className={`icon--${name}`}></div>
              <CopyToClipboard value={`.icon--${name}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SxUIIcons;
