import Cal, { getCalApi } from "@calcom/embed-react";
import { CAL_BASE_URL } from "../../services/config";
import { useEffect } from "react";

const BookingCalendarComponent = ({
  calLink,
  bookedCallback,
}: {
  calLink: string;
  bookedCallback(): void;
}) => {
  useEffect(() => {
    void (async function () {
      const cal = await getCalApi();
      cal("ui", {
        styles: { branding: { brandColor: "#20547a" } },
        hideEventTypeDetails: false,
        layout: "month_view",
        theme: "light",
      });
      cal("on", {
        action: "bookingSuccessful",
        callback: () => {
          bookedCallback();
        },
      });
    })();
  }, [bookedCallback]);

  return (
    <div>
      <h2 className="section_title">
        Prenez rendez-vous dans l'agenda de votre interlocuteur
      </h2>
      <div className="cblock card --bg">
        <div className="card_body">
          <Cal
            calLink={calLink}
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
            config={{ layout: "month_view" }}
            calOrigin={CAL_BASE_URL}
            embedJsUrl={`${CAL_BASE_URL}/embed/embed.js`}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingCalendarComponent;
