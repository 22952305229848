import Img1 from "../../../../assets/img/landings/testimonial1.png";
import Logo1 from "../../../../assets/img/landings/logo-trustfolio-blue.svg";
import Img2 from "../../../../assets/img/landings/testimonial2.png";
import Logo2 from "../../../../assets/img/landings/logo-elao-blue.svg";
import Img3 from "../../../../assets/img/landings/testimonial3.png";
import Logo3 from "../../../../assets/img/landings/logo-avanci-blue.svg";
import { useState } from "react";

function Testimonials() {
  const [item, setItem] = useState(1);

  return (
    <div className="testimonials">
      <div className="bg-card">
        {item === 1 && (
          <div className="card">
            <div className="testimonial_content">
              <img src={Img1} className="pic" />
              <p>
                Tisio est une plateforme innovante et maline pour prospecter et
                entrer en contact avec ses cibles.
                <br />
                <br />
                Chez Trustfolio, on a utilisé la plateforme pour{" "}
                <em>entrer en contact avec des Directions Marketing</em> et on a
                eu un <em>taux de retour</em>
                vraiment <em>excellent</em> (6% de taux de rendez-vous),
                largement supérieur aux autres solutions qu'on utilise pour
                l'outbound.
                <br />
                <br />
                Bonus : l'accompagnement de l'équipe est top. Ils sont réactifs
                et bien cablés pour créer des campagnes aux oignons !
              </p>
              <div className="testimonial_footer">
                <div className="testimonial_name">
                  Thomas Nanterme - Co-fondateur Trustfolio
                </div>

                <img src={Logo1} alt="" />
              </div>
            </div>
          </div>
        )}
        {/* 2 */}
        {item === 2 && (
          <div className="card">
            <div className="testimonial_content">
              <img src={Img2} className="pic" />
              <p>
                Dans le cadre du test et du{" "}
                <em>lancement de ma plateforme d'IA</em>, j'avais besoin de
                contacter des profils expérimentés et très implantés dans le
                digital, pas évident à prospecter donc ! <br />
                <br />
                Passer par Tisio m'a permis de leur montrer que j'estimais leur
                temps et leur expertise, que je voulais investir sur nos
                échanges.
                <br />
                <br />
                Bingo ! 8 rendez-vous de grande qualité avec de super profils,
                et de belles collaborations à venir.
                <br />
                <br />
                Certains d'entre eux ont même choisi de reverser leur
                rémunération à des projets associatifs, comme le propose la
                plateforme, c'est aussi ça la magie de Tisio !
              </p>
              <div className="testimonial_footer">
                <div className="testimonial_name">Xavier Gorse - CEO Elao</div>

                <img src={Logo2} alt="" />
              </div>
            </div>
          </div>
        )}
        {/* 3 */}
        {item === 3 && (
          <div className="card">
            <div className="testimonial_content">
              <img src={Img3} className="pic" />
              <p>
                J'ai utilisé Tisio pour échanger avec des Directeurs Marketing
                dans le E-Commerce, afin de leur présenter la méthode de
                valorisation de la data que propose Avanci, et de{" "}
                <em>recueillir leur feedback et leur analyse des enjeux</em>. La
                méthode est simple et rapide.
                <br />
                <br />
                Les échanges que j'ai eus ont été conviviaux, efficaces et
                surtout utiles.
                <br />
                <br />
                Plusieurs d'entre eux ont été suivis de discussions.
              </p>
              <div className="testimonial_footer">
                <div className="testimonial_name">
                  Benjamin Huau - Head of Partnerships Avanci
                </div>

                <img src={Logo3} alt="" />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="bullet-nav">
        <button
          onClick={() => setItem(1)}
          className={item === 1 ? "--active" : ""}
        />
        <button
          onClick={() => setItem(2)}
          className={item === 2 ? "--active" : ""}
        />
        <button
          onClick={() => setItem(3)}
          className={item === 3 ? "--active" : ""}
        />
      </div>
    </div>
  );
}

export default Testimonials;
