import { isAfter, isBefore, parseISO } from "date-fns";
import BackButton from "../../../components/BackButton";
import ExtClosureAside from "./ExtClosureAside";
import {
  BookingRequest,
  BookingRequestAttendanceDeclaration,
} from "../../../services/bookingRequest/bookingRequestModel";
import { useMemo, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { cx } from "../../../react-helpers/css";
import { Association } from "../../../services/data/dataModel";
import ExtClosureBookingHappenedForm from "./ExtClosureBookingHappenedForm";
import BookingClosureBookingNotHappenedForm from "../../solicitation/closure/BookingClosureBookingNotHappenedForm";

const ExtRequestClosurePage = () => {
  const { bookingRequest, associations } = useLoaderData() as {
    bookingRequest: BookingRequest;
    associations: Association[];
  };

  const senderBookingProfile = bookingRequest.sender!;

  const isBookingRequestFinal = useMemo(
    () =>
      bookingRequest.status === "CLOSED" ||
      bookingRequest.status === "CONFLICT",
    [bookingRequest],
  );

  const isAlreadyCompleted = !!bookingRequest.recipientClosureDate;

  const bookingAttendanceStatus = useMemo(
    () =>
      bookingRequest.recipientAttendanceDeclaration ??
      bookingRequest.senderAttendanceDeclaration ??
      BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE,
    [bookingRequest],
  );

  const [bookingHappened, setBookingHappened] = useState<null | boolean>(
    bookingRequest.recipientAttendanceDeclaration
      ? bookingRequest.recipientAttendanceDeclaration ===
          BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE
      : null,
  );

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <div className="page_title">Clôturer le rendez-vous</div>
        </div>
      </div>
      <div className="page-content">
        <div className="grid--3-2">
          <div>
            {/* *** BOOKING IS NOT PASSED YET */}
            {bookingRequest.status === "BOOKED" &&
              isBefore(
                new Date(),
                parseISO(bookingRequest.booking!.startTime),
              ) && (
                <div className="info">Le RDV n'a pas encore été réalisé</div>
              )}
            {/* *** BOOKING HAS ALREADY BEEN CLOSED BY CURRENT USER */}
            {/* TODO afficher les infos de clôture + mutualiser avec page booking request detail */}
            {isAlreadyCompleted && (
              <div className="lblocks">
                <div className="info">
                  Vous avez déjà clôturé ce RDV
                  <div>
                    <BackButton />
                  </div>
                </div>
              </div>
            )}

            {!isAlreadyCompleted &&
              isAfter(
                new Date(),
                parseISO(bookingRequest.booking!.startTime),
              ) && (
                <div className="lblocks">
                  {!(isBookingRequestFinal && bookingHappened === null) ? (
                    <div className="card --bg">
                      <div className="card_body --txt--center cblocks">
                        <h3 className="section_title">
                          Le rendez-vous a-t-il bien eu lieu ?
                        </h3>
                        <div className="switch-buttons --block">
                          <button
                            className={cx([bookingHappened && "--active"])}
                            type="button"
                            disabled={isAlreadyCompleted}
                            onClick={() => setBookingHappened(true)}
                          >
                            Oui
                          </button>
                          <button
                            className={cx([
                              bookingHappened === false && "--active",
                            ])}
                            type="button"
                            disabled={isAlreadyCompleted}
                            onClick={() => setBookingHappened(false)}
                          >
                            Non
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="info">
                      <div>
                        Le rendez-vous a été clôturé automatiquement car il est
                        terminé depuis plus de 48 heures.
                      </div>
                      <div className="cblocks">
                        Statut :{" "}
                        <strong>
                          {bookingAttendanceStatus ===
                          BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE
                            ? "Le rendez-vous a bien eu lieu"
                            : "Le rendez-vous n'a pas eu lieu"}
                        </strong>
                      </div>
                      <div className="cblocks">
                        Ce n’est pas le cas ?{" "}
                        <Link to="/contact">Contactez-nous !</Link>
                      </div>
                    </div>
                  )}
                  {(bookingHappened === true ||
                    (bookingHappened === null &&
                      isBookingRequestFinal &&
                      bookingAttendanceStatus ===
                        BookingRequestAttendanceDeclaration.BOTH_WHERE_HERE)) && (
                    <ExtClosureBookingHappenedForm
                      associations={associations}
                      bookingRequest={bookingRequest}
                      isAlreadyCompleted={isAlreadyCompleted}
                    />
                  )}
                  {bookingHappened === false && (
                    <BookingClosureBookingNotHappenedForm
                      associations={associations}
                      isCurrentUserSender={false}
                      bookingRequest={bookingRequest}
                      isAlreadyCompleted={isAlreadyCompleted}
                    />
                  )}
                </div>
              )}
          </div>
          <ExtClosureAside
            bookingRequest={bookingRequest}
            senderBookingProfile={senderBookingProfile}
          />
        </div>
      </div>
    </div>
  );
};

export default ExtRequestClosurePage;
