import { object } from "yup";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import SxField from "../../../forms/fields/SxField";
import { string } from "yup";
import { authService } from "../../../services/auth/authService";
import useReload from "../../../hooks/useReload";

const UserPasswordForm = () => {
  const { updateLoggedUserPassword } = authService();
  const reload = useReload();

  return (
    <SxForm
      initialValues={{
        oldPassword: "",
        newPassword: "",
      }}
      onSubmit={(values) => {
        return updateLoggedUserPassword(
          values.oldPassword,
          values.newPassword,
        ).then(() => reload());
      }}
      validationSchema={object({
        oldPassword: string().label("Mot de passe actuel").required(),
        newPassword: string()
          .required()
          .password()
          .label("Nouveau mot de passe"),
      })}
    >
      <div className="grid--3">
        <div>
          <SxField
            name="oldPassword"
            as="password"
            placeholder="*******"
            data-testid="old-password"
          />
          <SxField
            name="newPassword"
            placeholder="********"
            data-testid="new-password"
          />
        </div>
      </div>
      <div className="form_footer --left">
        <SubmitButton
          className="btn"
          type="submit"
          data-testid="user-password-submit"
        >
          Enregistrer
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default UserPasswordForm;
