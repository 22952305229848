import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import { TISIO_FEE_PERCENTAGE } from "../../../services/config";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import DisplayPrice from "../../../components/DisplayPrice";
import { useConfirmationWithIntl } from "../../../components/ConfirmationDialog";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import useReload from "../../../hooks/useReload";

const RequestsTable = ({
  bookingRequests,
  // TODO: uncomment when other communities will be create
  // loggedUserId,
}: {
  bookingRequests: BookingRequest[];
  // TODO: uncomment when other communities will be create
  // loggedUserId: User["id"];
}) => {
  const addFee = useCallback(
    (price: BookingRequest["price"], fee: BookingRequest["fee"]) =>
      Number(price) * (1 + (fee ?? parseInt(TISIO_FEE_PERCENTAGE, 10)) / 100),
    [],
  );
  const requestStatus = {
    CREATED: "En attente de paiement",
    PENDING: "Payement en cours de validation",
    PAID: "En attente de réservation",
  };
  const { confirm } = useConfirmationWithIntl("bookingRequest");
  const { deleteBookingRequest } = bookingRequestService();
  const reload = useReload();
  return (
    <div>
      <div className="section_title">
        Vos sollicitations en attente de finalisation
      </div>
      {bookingRequests.length === 0 && (
        <div className="cblock">
          <div className="info">
            Vous n’avez aucune sollicitation en attente
          </div>
        </div>
      )}
      {bookingRequests.length > 0 && (
        <div className="cblock">
          <table className="table">
            <thead>
              <tr>
                <th>Avec</th>
                <th>Statut</th>
                <th>Prix&nbsp;(HT)</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {bookingRequests.map((request) => (
                <tr key={request.id}>
                  <td>
                    {request.recipient?.user.displayName ??
                      `${request.extFirstname} ${request.extLastname?.[0]}.`}
                  </td>
                  <td className="--shrink">
                    <div className="ui-row --nowrap">
                      <span className="tag--3">
                        {
                          requestStatus[
                            request.status as keyof typeof requestStatus
                          ]
                        }
                      </span>
                      {/* TODO: uncomment when other communities will be create */}
                      {/* <span className="tag">
                      {request.sender.user.id === loggedUserId
                        ? request.sender.community.labelFr
                        : request.recipient?.community.labelFr}
                    </span> */}
                    </div>
                  </td>
                  <td className="--shrink --txt--center">
                    <strong>
                      <DisplayPrice
                        amount={addFee(request.price, request.fee)}
                      />
                    </strong>
                  </td>
                  <td className="--shrink">
                    <div className="ui-row --nowrap --right">
                      {(request.status === "CREATED" ||
                        request.status === "PAID") && (
                        <button
                          className="btn--error --btn--icon --delete"
                          onClick={() => {
                            confirm(
                              "delete.CONFIRM",
                              () => {
                                return deleteBookingRequest(request.id).then(
                                  reload,
                                );
                              },
                              true,
                              { title: "Confirmation" },
                            );
                          }}
                        ></button>
                      )}
                      <Link
                        to={`/app/bookings/${request.uuid}`}
                        className="btn --btn--icon --chevron-right"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RequestsTable;
