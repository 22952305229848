import { useRef, ReactNode, useEffect, useState } from "react";
import { cx } from "../react-helpers/css";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";

// TODO : Revoir toute l'implémentation, c'est nul !! xD

// Tabs component to handle rendering of tab items and detect overflow
function Tabs({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  // Reference to the tabs container
  const tabsRef = useRef<HTMLDivElement>(null);
  // State to track if the tabs are overflowing the container
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    // compute and store initial total width of children (tab items)
    let childrenWidth = 0;
    if (tabsRef.current) {
      // Get all the children of the parent container
      const tabElements = tabsRef.current.children ?? [];

      // Calculate the total width of all children
      Array.from(tabElements).forEach((tab) => {
        const tabElement = tab as HTMLElement;
        childrenWidth += tabElement.offsetWidth;
      });
    }
    // Function to check if the children are overflowing the parent container
    const checkOverflow = () => {
      if (tabsRef.current) {
        // Get the width of the parent container
        const parentWidth = tabsRef.current.clientWidth;

        // Update the state to indicate if the children are overflowing the parent container
        // with a 20% margin
        setIsOverflowing(parentWidth < childrenWidth * 1.2);
      }
    };
    // Perform an initial check for overflow
    checkOverflow();

    // Create a ResizeObserver to watch for size changes
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });

    // Observe the tabs container for size changes
    if (tabsRef.current) {
      resizeObserver.observe(tabsRef.current);
    }

    // Clean up the observer when the component is unmounted
    return () => {
      resizeObserver.disconnect();
    };
  }, []); // Re-run effect when children change

  return (
    <div
      className={cx(["tabs", className, isOverflowing && "popover"])}
      ref={tabsRef}
    >
      {!isOverflowing ? (
        children
      ) : (
        <Popover className="popover-menu">
          <PopoverButton className="popover-button">{children}</PopoverButton>
          <PopoverPanel
            className="popover-panel"
            anchor={{ to: "bottom end", gap: "4px" }}
          >
            {({ close }) => (
              <nav className="lcol --gap--xs" onClick={() => close()}>
                {children}
              </nav>
            )}
          </PopoverPanel>
        </Popover>
      )}
    </div>
  );
}

export default Tabs;
