import { useLoaderData } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import EWalletTotalComponent from "./EWalletTotalComponent";
import {
  Voucher,
  WalletTransaction,
} from "../../../services/payment/paymentModel";
import EWalletTransactionsTableComponent from "./EWalletTransactionsTableComponent";
import EWalletVouchersColumnComponent from "./EWalletVouchersColumnComponent";
import { User } from "../../../services/user/userModel";

const EWalletPage = () => {
  const { vouchers, walletTransactions, loggedUser } = useLoaderData() as {
    vouchers: Voucher[];
    walletTransactions: WalletTransaction[];
    loggedUser: User;
  };

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton />
          <h1 className="page_title">Crédits &amp; bons d’achat</h1>
        </div>
      </div>

      <div className="page-content">
        <div className="grid--3-2">
          <div className="lblocks">
            <EWalletTotalComponent
              loggedUser={loggedUser}
              walletTransactions={walletTransactions}
            />

            <EWalletTransactionsTableComponent
              walletTransactions={walletTransactions}
            />
          </div>
          <EWalletVouchersColumnComponent vouchers={vouchers} />
        </div>
      </div>
    </div>
  );
};

export default EWalletPage;
