import { useConfirmationWithIntl } from "../../../../../../components/ConfirmationDialog";
import SubmitButton from "../../../../../../components/forms/SubmitButton";
import SxForm from "../../../../../../forms/SxForm";
import { Campaign } from "../../../../../../services/campaign/campaignModel";
import {
  replaceInArray,
  spliceReturn,
} from "../../../../../../react-helpers/array";
import FormAutoSubmit from "../../../../../../forms/FormAutoSubmit";
import SxField from "../../../../../../forms/fields/SxField";
import { AnySchema } from "yup";
import Icon from "../../../../../../components/Icon";
import { randomUUID } from "../../../../../../react-helpers/crypto";
import { ImportedUser } from "./ContactsImportTab";

const ManualImport = <S extends AnySchema>({
  contacts,
  setContacts,
  schema,
  campaign,
  validContacts,
  addValidContacts,
}: {
  contacts: ImportedUser[];
  setContacts: (prev: (prev: ImportedUser[]) => ImportedUser[]) => void;
  schema: S;
  campaign: Campaign;
  validContacts: ImportedUser[];
  addValidContacts: () => Promise<void>;
}) => {
  const { confirm } = useConfirmationWithIntl(["campaign"]);

  return (
    <div>
      <div className="cblocks">
        {contacts.map((contact, index) => (
          <SxForm
            key={contact.id}
            initialValues={{
              firstname: contact.firstname,
              lastname: contact.lastname,
              email: contact.email,
              id: contact.id,
            }}
            onSubmit={(values) => {
              setContacts((prev) =>
                replaceInArray(prev, index, values as unknown as ImportedUser),
              );
            }}
            validationSchema={schema}
          >
            <FormAutoSubmit />
            <div className="lrow --center--v --gap--h--s --gap--v--xxs">
              <div className="card --bg --stretched">
                <div className="card_body ui-row --stretched --stretch">
                  <div>
                    <SxField name="firstname" />
                  </div>
                  <div>
                    <SxField name="lastname" />
                  </div>
                  <div>
                    <SxField name="email" />
                  </div>
                </div>
              </div>

              <div className="--shrink --pos--r">
                <button
                  className="btn--warning --btn--icon --delete"
                  type="button"
                  onClick={() =>
                    setContacts((prev) => spliceReturn(prev, index))
                  }
                />
              </div>
            </div>
          </SxForm>
        ))}
      </div>

      <div className="cblock">
        <button
          className="btn--soft --s"
          type="button"
          onClick={() => setContacts((prev) => [...prev, { id: randomUUID() }])}
        >
          <Icon name="plus" />
          ajouter un contact
        </button>
      </div>
      {validContacts.length > 0 && (
        <div className="lblock --txt--center">
          <SubmitButton
            className="btn"
            type="button"
            onClick={() => {
              if (campaign.type === "TARGETED") return addValidContacts();

              confirm(
                "campaign:contacts-import.CONFIRM",
                addValidContacts,
                true,
                {
                  title: "Confirmer l’envoi des sollicitations",
                  confirmText: "Confirmer et envoyer",
                },
              );
            }}
          >
            Ajouter {campaign.type === "MANAGED" && "et solliciter "}
            {validContacts.length} contact(s)
          </SubmitButton>
        </div>
      )}
    </div>
  );
};

export default ManualImport;
