import { EditorContent, useEditor, Editor } from "@tiptap/react";
import { useField } from "formik";
import StarterKit from "@tiptap/starter-kit";
import { cx } from "../../react-helpers/css";

const extensions = [StarterKit];

const Toolbar = ({ editor }: { editor: Editor }) => {
  return (
    <div className="rte_toolbar">
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        disabled={
          !editor.can().chain().focus().toggleHeading({ level: 1 }).run()
        }
        className={cx([editor.isActive("heading", { level: 1 }) && "--active"])}
      >
        Titre 1
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        disabled={
          !editor.can().chain().focus().toggleHeading({ level: 2 }).run()
        }
        className={cx([editor.isActive("heading", { level: 2 }) && "--active"])}
      >
        Titre 2
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().setParagraph().run()}
        disabled={
          !editor.can().chain().focus().toggleHeading({ level: 2 }).run()
        }
        className={cx([editor.isActive("paragraph") && "--active"])}
      >
        Paragraphe
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={cx([editor.isActive("bold") && "--active"])}
      >
        Gras
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        disabled={!editor.can().chain().focus().toggleBulletList().run()}
        className={cx([editor.isActive("bulletList") && "--active"])}
      >
        Liste
      </button>
    </div>
  );
};

const SxRTE = ({
  name,
  placeholder,
  "data-testid": dataTestId,
}: {
  name: string;
  placeholder?: string;
  "data-testid"?: string;
}) => {
  const [field, , helpers] = useField(name);
  const editor = useEditor({
    content: field.value,
    extensions,
    editorProps: {
      attributes: {
        testid: dataTestId ?? "",
      },
    },
    onUpdate({ editor }) {
      void helpers.setValue(
        editor.getText().length > 0 ? editor.getHTML() : "",
      );
    },
  });

  return (
    <div className="rte">
      {editor && <Toolbar editor={editor} />}
      <EditorContent
        name={name}
        placeholder={placeholder}
        editor={editor}
        className="rte_editor"
        data-testid={dataTestId}
      />
    </div>
  );
};

export default SxRTE;
