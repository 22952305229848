import LostPasswordForm from "./LostPasswordForm";

function LostPasswordPage() {
  return (
    <div className="auth-card">
      <div className="card_head">
        <h1 className="card_title">Mot de passe oublié ?</h1>
        <p>Pas de panique !</p>
      </div>
      <div className="card_body">
        <LostPasswordForm />
      </div>
    </div>
  );
}

export default LostPasswordPage;
