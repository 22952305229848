import Icon from "../../../components/Icon";

function HowToWriteCampaignContextPage() {
  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">
          Comment rédiger une demande de sollicitation efficace ?
        </h1>
      </div>
      <div className="page-content lblocks --l">
        <div className="cblocks">
          <h2 className="section_title">Bonnes pratiques</h2>
          <ul className="checklist">
            <li>
              Soyez précis : le texte que vous rédigez c’est ce que votre
              interlocuteur lira.
            </li>
            <li>
              Présentez-vous : votre fonction et l’entreprise pour laquelle vous
              travaillez.
            </li>
            <li>
              Précisez le profil des personnes avec qui vous souhaitez vous
              entretenir.
            </li>
            <li>
              Expliquez le contexte de votre sollicitation et ce que vous
              attendez de l’échange.
            </li>
          </ul>
        </div>
        <div className="cblocks">
          <h2 className="section_title">Exemple</h2>
          <div className="card --bg">
            <div className="card_body cblocks">
              <Icon name="quote" className="--blue" />
              <p>
                Je suis Sales Manager chez ABC. Je veux échanger avec des
                directeurs marketing du e-Commerce pour présenter une nouvelle
                offre dans l’objectif de recueillir du feedback qualitatif et
                proposer un abonnement.
              </p>
            </div>
          </div>
        </div>
        <div className="cblocks">
          <h2 className="section_title">Conseil</h2>
          <div className="card">
            <div className="card_body">
              Soyez toujours consultatif dans votre approche : demandez à
              recueillir un feedback sur vos offres, un avis spontanée sur votre
              démo produit, un retour d'expérience sur votre type de
              prestations, etc.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowToWriteCampaignContextPage;
