import { NavLink, Outlet, useLoaderData } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import { useMemo } from "react";
import { User } from "../../../services/user/userModel";
import Tabs from "../../../components/Tabs";
import { compareDate, orderByField } from "../../../react-helpers/array";

const RequestsAndBookingsPage = () => {
  const { currentUserBookingRequests, loggedUser } = useLoaderData() as {
    currentUserBookingRequests: BookingRequest[];
    loggedUser: User;
  };

  // this array regroups all the booking requests that are not finished (created but not paid or paid but not booked)
  const unfinishedBookingRequests = useMemo(
    () =>
      currentUserBookingRequests
        .filter(
          (request) =>
            !request.booking &&
            request.sender!.user.id === loggedUser.id &&
            !request.external,
        )
        .sort(
          orderByField("createdAt", "desc", (a, b) =>
            compareDate(new Date(a!), new Date(b!)),
          ),
        ),
    [currentUserBookingRequests, loggedUser],
  );

  // this array regroups all the booking requests that are paid and booked
  const paidAndBookedBookingRequests = useMemo(
    () => currentUserBookingRequests.filter((request) => !!request.booking),
    [currentUserBookingRequests],
  );

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton />
          <div className="page_title">Sollicitations et rendez-vous</div>
        </div>
      </div>
      {unfinishedBookingRequests.length !== 0 && (
        <Tabs>
          <NavLink className="tab_item" to="appointments">
            Rendez-vous
          </NavLink>
          <NavLink className="tab_item" to="requests">
            Sollicitations en attente{" "}
            <div className="chip --s --warning">
              {unfinishedBookingRequests.length}
            </div>
          </NavLink>
        </Tabs>
      )}
      <div className="page-content">
        <Outlet
          context={{
            paidAndBookedBookingRequests,
            unfinishedBookingRequests,
            loggedUserId: loggedUser.id,
          }}
        />
      </div>
    </div>
  );
};

export default RequestsAndBookingsPage;
