import { createRef, useMemo, useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import { MappedConfigData } from "../../services/data/dataModel";
import BookingProfileCard from "../../components/profiles/BookingProfileCard";
import PaginatedList from "../../components/PaginatedList";
import BookingProfileSearch from "./BookingProfileSearch";
import BackButton from "../../components/BackButton";
import { User } from "../../services/user/userModel";
import NoProfileFoundBlock from "./NoProfileFound/NoProfileFoundBlock";
import Icon from "../../components/Icon";
import TargetingProfilesHelperDialog from "./NoProfileFound/TargetingProfilesHelperDialog";

const ProfilesListPage = () => {
  const { bookingProfiles, totalProfilesNb, configData, loggedUser } =
    useLoaderData() as {
      bookingProfiles: BookingProfile[];
      configData: MappedConfigData;
      totalProfilesNb: number;
      loggedUser?: User;
    };

  const [searchParams, setSearchParams] = useSearchParams();
  const scrollAnchor = createRef<HTMLDivElement>();

  const [
    showTargetingProfilesHelperDialog,
    setShowTargetingProfilesHelperDialog,
  ] = useState(false);

  const sort = useMemo(() => {
    return searchParams.get("sort") ?? "rating:desc";
  }, [searchParams]);

  return (
    <div className="container">
      <div ref={scrollAnchor} />
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
          <h1 className="page_title">Liste des profils</h1>
        </div>
      </div>
      <div className="page-content">
        {bookingProfiles.length === 0 &&
        Array.of(searchParams.entries()).length === 0 ? (
          <div className="info">
            <div className="info_title">
              Aucun profil n'est encore enregistré sur la plateforme.
            </div>
          </div>
        ) : (
          <>
            <BookingProfileSearch filtersOptions={configData} />

            <div className="lblock--l">
              {bookingProfiles.length > 0 ? (
                <>
                  <div className="--txt--right">
                    <select
                      value={sort}
                      className="select --inline --s"
                      onChange={(evt) => {
                        const field = evt.target.value;
                        setSearchParams((prev) => {
                          prev.set("sort", field);
                          return prev;
                        });
                      }}
                    >
                      {/* TODO: added mark in BookingProfile model */}
                      {/* <option value="mark:asc">Les mieux notés</option> */}
                      <option value="price:asc">Les moins chers</option>
                      <option value="price:desc">Les plus chers</option>
                      <option value="rating:desc">Les mieux notés</option>
                    </select>
                  </div>
                  <div className="cblock">
                    <PaginatedList
                      items={bookingProfiles}
                      render={(bookingProfile) => (
                        <BookingProfileCard
                          key={bookingProfile.id}
                          bookingProfile={bookingProfile}
                        />
                      )}
                      totalItems={totalProfilesNb}
                      pageSizes={[20, 40, 60]}
                      defaultPageSize={40}
                      className="booking-profiles-list"
                      scrollToAnchor={scrollAnchor}
                    />
                  </div>
                  <NoProfileFoundBlock loggedUser={loggedUser} />
                </>
              ) : (
                <div className="callout --light">
                  <Icon name="search" />
                  <div>
                    <strong>AUCUN RÉSULTAT</strong>
                    <br />
                    ne correspond à votre recherche.
                  </div>
                  <div className="cblocks --xs">
                    <button
                      className="btn--2"
                      onClick={() => setShowTargetingProfilesHelperDialog(true)}
                    >
                      Faites appel à nous
                    </button>
                    <div className="body--20">
                      pour trouver les meilleurs profils
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {showTargetingProfilesHelperDialog && (
        <TargetingProfilesHelperDialog
          onClose={() => setShowTargetingProfilesHelperDialog(false)}
          loggedUser={loggedUser}
        />
      )}
    </div>
  );
};

export default ProfilesListPage;
