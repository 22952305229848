import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../../../services/auth/authService";
import SxForm from "../../../forms/SxForm";
import { object, string } from "yup";
import SxField from "../../../forms/fields/SxField";
import SubmitButton from "../../../components/forms/SubmitButton";
import BackButton from "../../../components/BackButton";

function ResetPasswordForm() {
  const { uuid } = useParams();
  const auth = authService();
  const navigate = useNavigate();

  return (
    <SxForm
      initialValues={{
        newPassword: "",
        newPasswordConfirmation: "",
      }}
      onSubmit={({ newPassword }) => {
        return auth.resetPassword(uuid!, newPassword).then(() => {
          return navigate("/login");
        });
      }}
      validationSchema={object({
        newPassword: string()
          .password()
          .label("Nouveau mot de passe")
          .required(),
      })}
    >
      <SxField
        name="newPassword"
        placeholder="••••••••••••"
        data-testid="new-password"
      />

      <div className="form_footer">
        <SubmitButton
          className="submit-btn"
          type="submit"
          data-testid="reset-password-submit"
        >
          Envoyer
        </SubmitButton>
        <div className="lblock">
          <BackButton fallbackUrl="/login" />
        </div>
      </div>
    </SxForm>
  );
}

export default ResetPasswordForm;
