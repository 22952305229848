import { object, string } from "yup";
import SxForm from "../../../forms/SxForm";
import { BusinessAccount } from "../../../services/businessAccount/businessAccountModel";
import {
  checkSiret,
  transformEmptyToNull,
  transformEmptyToUndefined,
} from "../../../react-helpers/yup";
import SxField from "../../../forms/fields/SxField";
import ValidationErrors from "../../../forms/ValidationErrors";
import SubmitButton from "../../../components/forms/SubmitButton";
import { businessAccountService } from "../../../services/businessAccount/businessAccountService";

const ProAccountForm = ({
  businessAccount,
}: {
  businessAccount: BusinessAccount;
}) => {
  const { updateBusinessAccount } = businessAccountService();
  const schema = object({
    email: string()
      .label("Adresse e-mail du gestionnaire")
      .email()
      .transform(transformEmptyToUndefined)
      .required(),
    name: string()
      .label("Nom de l'entreprise")
      .transform(transformEmptyToUndefined)
      .required(),
    phone: string()
      .label("Numéro de téléphone")
      .transform(transformEmptyToNull)
      .phoneNumber()
      .nullable(),
    siret: string()
      .label("SIRET")
      .test("Le SIRET saisi n’est pas valide", checkSiret)
      .transform(transformEmptyToUndefined)
      .required(),
  });

  return (
    <SxForm
      initialValues={businessAccount}
      onSubmit={(values) => {
        return updateBusinessAccount(businessAccount.id, schema.cast(values));
      }}
      validationSchema={schema}
    >
      <div className="grid--2">
        <SxField name="email" />
        <SxField name="phone" />
        <SxField name="name" />
        <SxField name="siret" />
      </div>

      <div className="form_footer lblocks">
        <ValidationErrors />
        <SubmitButton className="btn" type="submit">
          Enregistrer
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default ProAccountForm;
