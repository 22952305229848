import {
  IndexRouteObject,
  LoaderFunctionArgs,
  NonIndexRouteObject,
  RouteObject,
  redirect,
} from "react-router-dom";
import { loggerBuilder } from "../services/logger";

const logger = loggerBuilder("routerConditions");

export type RouteObjectWithCondition = (
  | (NonIndexRouteObject & {
      sxRedirectTo?: (
        args: LoaderFunctionArgs<any>,
        resolvedPath?: string,
      ) => Promise<string | null> | string | null;
      children?: RouteObjectWithCondition[];
    })
  | IndexRouteObject
) & {
  resolvedPath?: string;
  // Internal variable used to store the the conditions and the inherited ones
  __sxRedirectLoader?: (
    args: LoaderFunctionArgs<any>,
  ) => Promise<[string, string | null] | null>;
  // Debug variable used to log the inheritances
  __sxdebugInherits?: string;
};

// Normalisation function to transform a resolvedPath to a realPath
// E.G ///app//role2/dashboard -> /app/dashboard
// Each empty section (between double slashes) represent a condition

function normalizeResolvedPath(resolvedPath?: string) {
  return resolvedPath?.replace(/\/{2,}/g, "/").replace(/\/$/, "") ?? "";
}

export function redirectAdapter(
  routes: RouteObjectWithCondition | RouteObjectWithCondition[],
  redirectTo?: (
    args: LoaderFunctionArgs<any>,
  ) => Promise<[string, string | null] | null>,
  parentResolvedPath = "",
): RouteObject[] {
  const mappedRoutes = Array.isArray(routes) ? routes : [routes];

  mappedRoutes.forEach((r) => {
    // If we have no redirectTo, we are not inheriting conditions from a parent
    if (!redirectTo) {
      // The resolved path is a join using slashes of the parent resolved path and the current route path
      // if the current route path is empty, we still add a slash because it is a virtual step in the routing process
      r.resolvedPath = [
        parentResolvedPath,
        (r.path ?? "").replace(/:[^/]+/, "[^/]+"),
      ].join("/");

      // Initialize the resolvedPath of our childrens and then their conditions
      if (r.children) redirectAdapter(r.children, undefined, r.resolvedPath);

      // If we have conditions in this route
      if ("sxRedirectTo" in r) {
        // Store the previous loader if defined
        const previousLoader = r.loader;

        // Initialize our internal conditions with only those of the current route
        const redirectLoader: typeof r.__sxRedirectLoader = async (args) => {
          const redirectTo = await Promise.resolve(
            r.sxRedirectTo!(
              args,
              // The second params is a shorthand to have the current destination in the conditions function
              normalizeResolvedPath(new URL(args.request.url).pathname),
            ),
          );
          // We return the route.resolvedPath so we can know which sxRedirectTo caused the redirection
          return redirectTo ? [redirectTo, r.resolvedPath ?? null] : null;
        };
        r.__sxRedirectLoader = redirectLoader;

        // We store the resolvedPath of this route as the base of the inheritance
        r.__sxdebugInherits = r.resolvedPath;

        // We override the loader with the one that use the conditions
        r.loader = async (args: LoaderFunctionArgs<any>) => {
          let needToRedirect, pathThatRedirected;
          // If we have our conditions merge with the inherited ones, we call them
          if ("__sxRedirectLoader" in r) {
            const sxRedirectTo = await Promise.resolve(
              r.__sxRedirectLoader?.(args),
            );
            if (sxRedirectTo)
              [needToRedirect, pathThatRedirected] = sxRedirectTo;
          }

          // If needToRedirect is different from null
          if (needToRedirect) {
            logger.debug(
              `Route that redirected: ${r.resolvedPath} -> to: ${needToRedirect} (because of: ${pathThatRedirected})`,
            );

            // Then we can redirect
            return redirect(needToRedirect);
            // Else we can run the route loader if present
          } else if (previousLoader && typeof previousLoader === "function")
            return previousLoader(args);
          // Else we can simply render the route
          else return Promise.resolve(null);
        };

        // If we have childrens and we have some redirect conditions we forward them
        if (r.children && r.sxRedirectTo)
          redirectAdapter(r.children, redirectLoader, r.resolvedPath);
      }
    } else {
      // Store the resolvedPath that we are inheriting from
      r.__sxdebugInherits = parentResolvedPath + " -> " + r.__sxdebugInherits;

      // Store the previous conditions to we can use them later
      const previousRedirectLoader = r.__sxRedirectLoader;

      // Override the route conditions with the ones we are inheriting
      r.__sxRedirectLoader = async (args) => {
        const parentsRedirect = await Promise.resolve(redirectTo(args));

        // If the inherited conditions require a redirect
        if (parentsRedirect) return parentsRedirect;
        // Else we can check the previous stored conditions
        else
          return Promise.resolve(
            (await previousRedirectLoader?.(args)) ?? null,
          );
      };

      // Propagate the inherited conditions to our childrens
      if (r.children)
        redirectAdapter(r.children, redirectTo, parentResolvedPath);
    }
  });

  return mappedRoutes;
}
