import { ReactNode } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Icon from "./Icon";
import { useTranslation } from "react-i18next";

function BackButton({
  forcedUrl,
  fallbackUrl = "/",
  children,
}: {
  forcedUrl?: string;
  fallbackUrl?: string;
  children?: ReactNode;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(["global"]);

  const goBack = () => {
    forcedUrl
      ? navigate(forcedUrl)
      : location.key !== "default"
        ? navigate(-1)
        : navigate(fallbackUrl);
  };

  return (
    <button type="button" className="link--back" onClick={goBack}>
      <Icon name="chevron-back" />
      {children ?? t("global:GO_BACK")}
    </button>
  );
}

export default BackButton;
