import { AnySchema } from "yup";
import { downloadFile, readFile } from "../../../../../../react-helpers/files";
import { Campaign } from "../../../../../../services/campaign/campaignModel";
import SubmitButton from "../../../../../../components/forms/SubmitButton";
import { useConfirmationWithIntl } from "../../../../../../components/ConfirmationDialog";
import SxFileInput from "../../../../../../forms/fields/SxFileInput";
import { useCallback } from "react";
import { toastsWithIntl } from "../../../../../../services/toastService";
import Icon from "../../../../../../components/Icon";
import { ImportedUser } from "./ContactsImportTab";
import { randomUUID } from "../../../../../../react-helpers/crypto";
import chardet from "chardet";

const CsvImport = <S extends AnySchema>({
  campaign,
  setContacts,
  schema,
  validContacts,
  addValidContacts,
}: {
  campaign: Campaign;
  setContacts: (prev: (prev: ImportedUser[]) => ImportedUser[]) => void;
  schema: S;
  validContacts: ImportedUser[];
  addValidContacts: () => Promise<void>;
}) => {
  const MAX_CONTACTS_IMPORT = 300;

  const template = new Blob(
    ["firstname;lastname;email\r\nJohn;Doe;example@example.com\r\n"],
    { type: "text/csv;charset=utf-8" },
  );
  const { confirm } = useConfirmationWithIntl(["campaign"]);
  const { toastSuccess, toastError, toastWarning } = toastsWithIntl([
    "campaign",
  ]);

  const readFiles = useCallback(
    (files: (File | string)[]) => {
      try {
        void readFile(files[0] as File, "binary").then((rawCsv) => {
          const bytes = new Uint8Array(rawCsv);
          const encoding = chardet.detect(bytes);
          const decoder = new TextDecoder(encoding ?? undefined);
          const csv = decoder.decode(bytes);

          const cleanedCsv = csv.replace(/\r\n/g, "\n");
          const lines = cleanedCsv.split("\n");
          const header = lines[0];

          const matches = header.match(
            /^(?:([|\t,;])?"?(?:lastname|firstname|email)"?)+/,
          );
          if (!matches) {
            toastError("campaign:csv-import.ERROR");
            return;
          }
          const [, separator] = matches;
          const data = lines
            .slice(1)
            .filter((line) => line.length > 0)
            .map((line) => line.split(separator));

          const headers = header.split(separator);
          if (
            ["firstname", "lastname", "email"].some((h) => !headers.includes(h))
          ) {
            toastError("campaign:csv-import.ERROR_BAD_HEADERS");
            return;
          }

          const indexedHeaders = headers.reduce(
            (acc, h, index) => {
              if (["firstname", "lastname", "email"].includes(h))
                acc = { ...acc, [h]: index };
              return acc;
            },
            {} as Record<string, number>,
          );

          const contacts = data.map((line) =>
            Object.fromEntries(
              Object.entries(indexedHeaders).map(([header, index]) => [
                header,
                line[index]?.match(/"(.*)"/)?.at(1) ?? line[index] ?? "",
              ]),
            ),
          );
          const validContacts = contacts.filter((contact) =>
            schema.isValidSync(contact),
          );

          if (contacts.length !== validContacts.length) {
            toastWarning("campaign:csv-import.WARNING", {
              count: validContacts.length,
              maxCount: contacts.length,
            });
          } else if (validContacts.length > MAX_CONTACTS_IMPORT) {
            toastWarning("campaign:csv-import.TOO_MUCH", {
              count: MAX_CONTACTS_IMPORT,
              maxCount: contacts.length,
            });
          } else {
            toastSuccess("campaign:csv-import.SUCCESS", {
              count: validContacts.length,
            });
          }

          setContacts(
            () =>
              validContacts
                .slice(0, MAX_CONTACTS_IMPORT)
                .map((u) => ({ ...u, id: randomUUID() })) as ImportedUser[],
          );
        });
      } catch (e) {
        return toastError("campaign:csv-import.ERROR");
      }
    },
    [setContacts, toastError, toastSuccess, toastWarning, schema],
  );

  return (
    <div className="lblocks">
      <div className="info">
        <div>Importer un fichier CSV contenant vos contacts :</div>
        <ul className="body--30">
          <li>1 contact par ligne</li>
          <li>séparateur : « ; » (point virgule)</li>
          <li>En-tête des colonnes : firstname | lastname | email</li>
        </ul>
        <div className="cblock">
          <button
            className="link--accent --s --with-icon"
            type="button"
            onClick={() => downloadFile(template, "template.csv")}
          >
            <Icon name="download" />
            télécharger le modèle
          </button>
        </div>
      </div>

      <div>
        <SxFileInput accept="text/csv" onChange={readFiles}></SxFileInput>
      </div>

      {validContacts.length > 0 && (
        <div className="card">
          <div className="card_body --txt--center cblocks">
            <div>
              <div className="ui-row --center --txt--blue">
                <Icon name="users" className="--l" />
                <span className="title--70 --strong">
                  {validContacts.length}
                </span>
              </div>
              <div> contacts importés</div>
            </div>
            {campaign.type === "TARGETED" ? (
              <SubmitButton
                type="button"
                className="btn"
                onClick={addValidContacts}
              >
                Ajouter les {validContacts.length} contacts
              </SubmitButton>
            ) : (
              <SubmitButton
                type="button"
                className="btn"
                onClick={() => {
                  return confirm(
                    "campaign:contacts-import.CONFIRM",
                    addValidContacts,
                    true,
                    {
                      title: "Confirmer l’envoi des sollicitations",
                      confirmText: "Confirmer et envoyer",
                    },
                  );
                }}
              >
                Solliciter les {validContacts.length} contacts
              </SubmitButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CsvImport;
