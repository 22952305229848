import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";

const ProfileDescription = ({
  profile,
  publicDescription = false,
}: {
  profile: BookingProfile;
  publicDescription?: boolean;
}) => {
  if (!profile.description) return;

  return (
    <div className="profile_description">
      <div
        className={
          publicDescription ? "description_full" : "description_preview"
        }
        dangerouslySetInnerHTML={{
          __html: profile.description,
        }}
      />
    </div>
  );
};

export default ProfileDescription;
