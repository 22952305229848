import { useNavigate } from "react-router-dom";
import Icon from "./Icon";
import { authService } from "../services/auth/authService";
import SxForm from "../forms/SxForm";
import SxField from "../forms/fields/SxField";
import SubmitButton from "./forms/SubmitButton";

const ProfileSearch = () => {
  const navigate = useNavigate();
  const { isLoggedIn } = authService();

  return (
    <SxForm
      initialValues={{ search: "" }}
      onSubmit={(values) => {
        return navigate(
          `${isLoggedIn() ? "/app" : ""}/profiles-list${values.search.length > 0 ? `?search=${values.search}` : ""}`,
        );
      }}
    >
      <div className="input--search">
        <SxField name="search" placeholder="Rechercher un profil" as="text" />
        <SubmitButton className="--addon btn--2">
          <Icon name="search" />
        </SubmitButton>
      </div>
    </SxForm>
  );
};

export default ProfileSearch;
