import { useField } from "formik";
import SxField from "../../../forms/fields/SxField";
import { TISIO_FEE_PERCENTAGE } from "../../../services/config";
import { User } from "../../../services/user/userModel";
import { useEffect, useMemo } from "react";
import DisplayPrice from "../../../components/DisplayPrice";
import { formatDate } from "../../../react-helpers/date";

const BookingProfilePaymentTableComponent = ({
  bookingPrice,
  vouchersFieldName,
  eCreditFieldName,
  bookingRequestFee,
  userCredits,
}: {
  bookingPrice: number;
  vouchersFieldName: string;
  eCreditFieldName: string;
  bookingRequestFee?: number;
  // userCredits can be undefined when user did public request
  userCredits?: {
    eCredit: User["eCredit"] | null;
    vouchers: User["vouchers"] | null;
  };
}) => {
  const [voucherField] = useField(vouchersFieldName);
  const [eCreditField, , eCreditHelpers] = useField(eCreditFieldName);
  const selectedVoucherAmount = useMemo(() => {
    return (
      userCredits?.vouchers?.find(
        (voucher) => voucher.id === Number(voucherField.value),
      )?.amount ?? null
    );
  }, [voucherField.value, userCredits]);

  const fee = bookingRequestFee ?? TISIO_FEE_PERCENTAGE;
  const totalAfterPlatformFees = bookingPrice * (fee / 100 + 1);
  const totalAfterVoucher = selectedVoucherAmount
    ? Number(selectedVoucherAmount) >= totalAfterPlatformFees
      ? 0
      : totalAfterPlatformFees - Number(selectedVoucherAmount)
    : totalAfterPlatformFees;

  const usedCredits = useMemo(() => {
    return userCredits?.eCredit
      ? totalAfterVoucher < userCredits.eCredit
        ? userCredits.eCredit - (userCredits.eCredit - totalAfterVoucher)
        : userCredits.eCredit
      : 0;
  }, [totalAfterVoucher, userCredits]);

  const totalAfterCredits = totalAfterVoucher - usedCredits;

  useEffect(() => {
    if (
      (!eCreditField.value && usedCredits > 0) ||
      eCreditField.value !== usedCredits
    )
      void eCreditHelpers.setValue(usedCredits);
  }, [usedCredits, eCreditHelpers, eCreditField.value]);
  return (
    <div className="table-scroll">
      <table className="table --framed">
        <tbody>
          <tr>
            <td className="row-title">Tarif de la visio</td>
            <td className="row-title --shrink --txt--right">
              <DisplayPrice
                amount={totalAfterPlatformFees}
                taxFree
                addon="/ 30 minutes"
              />
            </td>
          </tr>
          <tr>
            <td>dont frais de gestion Tisio ({fee}%)</td>
            <td className="--shrink --txt--right">
              <DisplayPrice
                amount={totalAfterPlatformFees - bookingPrice}
                taxFree
              />
            </td>
          </tr>
          {/* tdis test is here because userCredits can be undefined when user did public request */}
          {userCredits?.vouchers && userCredits.vouchers.length > 0 && (
            <tr>
              <td className="row-title --shrink">Utiliser un bon d'achat</td>
              <td>
                <SxField name={vouchersFieldName} as="select">
                  <option value={""}>Selectionner un bon d'achat</option>
                  {userCredits.vouchers.map((voucher) => (
                    <option key={voucher.id} value={voucher.id}>
                      {/* 'voucher.amount / 100' because amount is in euro cents */}
                      {voucher.label} - {voucher.amount / 100}€ (expire le{" "}
                      {formatDate(new Date(voucher.expirationDate), "P")}){" "}
                    </option>
                  ))}
                </SxField>
              </td>
            </tr>
          )}
          {usedCredits > 0 && (
            <tr>
              <td className="row-title">Crédits utilisés</td>
              <td className="row-title --shrink --txt--right">
                -<DisplayPrice amount={usedCredits} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <table className="table cblock">
        <tbody>
          <tr className="table_subtotal-row">
            <td className="--txt--right">À PAYER</td>
            <td className="--txt--right --shrink">
              <DisplayPrice amount={totalAfterCredits} taxFree />
            </td>
          </tr>
          <tr>
            <td className="--txt--right">TVA (20%)</td>
            <td className="--txt--right --shrink">
              <DisplayPrice amount={totalAfterCredits * 0.2} />
            </td>
          </tr>
          <tr className="table_total-row">
            <td className="--txt--right">TOTAL TTC</td>
            <td className="row-value --txt--right --shrink">
              <DisplayPrice amount={totalAfterCredits * 1.2} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default BookingProfilePaymentTableComponent;
