import { Link } from "react-router-dom";

function FaqPage() {
  return (
    <div className="container --wrap">
      <div className="page_head">
        <h1 className="page_title">FAQ</h1>
      </div>
      <div className="page-content lblocks">
        {/* Section : Tisio c'est quoi ? */}
        <div className="cblocks">
          <h2 className="section_title">Tisio c'est quoi ?</h2>
          <p>
            Tisio est une plateforme de mise en relation entre personnes
            souhaitant vendre ou acheter un temps d’échange en visio pour du
            conseil, un témoignage, un avis ou un partage d’expérience et/ou
            d’expertise.
          </p>
        </div>

        {/* Section : À quoi peut me servir Tisio ? */}
        <div className="cblocks">
          <h2 className="section_title">À quoi peut me servir Tisio ?</h2>
          <p>
            À plein de choses ! Par exemple… recueillir des conseils pratiques
            sur un sujet, contacter des prospects, échanger avec un expert,
            envisager une reconversion, solliciter un avis avant de lancer un
            projet, et tout ce que vous pourrez imaginer d’autre !
          </p>
          <p>
            Mais aussi, Tisio est un puissant outil de développement commercial
            B2B. Il permet d’organiser des rendez-vous qualifiés avec des
            prospects intéressés et disponibles, grâce à un système de visios
            rémunérées.
            <br />
            <Link to="/business" className="link--accent">
              En savoir plus sur la prospection avec Tisio
            </Link>
          </p>
        </div>

        {/* Section : Qui est-ce que je peux solliciter ? */}
        <div className="cblocks">
          <h2 className="section_title">Qui est-ce que je peux solliciter ?</h2>
          <p>
            Tout le monde ! À partir du moment où ils ont une adresse mail pro
            ou un profil LinkedIn. Vous avez la possibilité de :
          </p>
          <ul>
            <li>séléctionner des profils dans notre base</li>
            <li>
              nous transmettre vos critères de ciblage : nous irons les chercher
              pour vous !
            </li>
          </ul>
        </div>

        {/* Section : Combien de temps durent les échanges ? */}
        <div className="cblocks">
          <h2 className="section_title">
            Combien de temps durent les échanges ?
          </h2>
          <p>
            Les temps d’échange en visio durent environ 30 minutes ! Mais sachez
            que la visio ne va pas se couper au bout de 30 minutes d’échange.
            Libre à vous de faire plus si tout le monde est d’accord, mais les
            minutes additionnelles ne seront pas rémunérées.
          </p>
        </div>

        {/* Section : Dois-je être professionnel indépendant pour créer un profil Tisio ? */}
        <div className="cblocks">
          <h2 className="section_title">
            Dois-je être professionnel indépendant pour créer un profil Tisio ?
          </h2>
          <p>
            Non ! Tout le monde peut créer son profil sur Tisio. Et tant que les
            gains ne dépassent pas 1.500 € par an, il est possible d’opter pour
            un régime sans statut de travailleur indépendant/ auto-entrepreneur.
            Il n’y a dans ce cas pas de formalité administrative de création
            d’entreprise. Il suffit ensuite de renseigner son RIB (ou le nom de
            l’association de son choix) à l’issue d’une visio pour percevoir sa
            rémunération.
          </p>
        </div>

        {/* Section : Dois-je déclarer les sommes comme des revenus ? */}
        <div className="cblocks">
          <h2 className="section_title">
            Dois-je déclarer les sommes comme des revenus ?
          </h2>
          <p>
            Oui, les sommes perçues sont à reporter sur votre déclaration
            d’impôts. Dans la plupart des cas (recettes perçues inférieures à
            77.700 €), vous pouvez déclarer les revenus Tisio en régime « micro
            » au moment de votre déclaration annuelle (dans la catégorie des «
            revenus non commerciaux non professionnels » à la ligne 5KU). Les
            revenus Tisio seront alors pris en compte dans le calcul de votre
            impôt avec un abattement automatique de 34%. Vous n’avez pas de TVA
            à appliquer tant que vos recettes annuelles sont inférieures à
            36.800 € en 2023.
          </p>
          <p>
            Pour plus d’informations sur les obligations sociales et fiscales
            des personnes rémunérées sur la plateforme, nous vous invitons à
            vous rendre :
          </p>
          <ul>
            <li>
              sur <a href="http://www.impots.gouv.fr/">www.impots.gouv.fr</a>,
              concernant les obligations fiscales, lien suivant :{" "}
              <a href="https://www.impots.gouv.fr/portail/node/10841">
                lien impots
              </a>
            </li>
            <li>
              sur <a href="http://www.urssaf.fr/">www.urssaf.fr</a>, concernant
              les obligations sociales, lien suivant :{" "}
              <a href="https://www.urssaf.fr/portail/home/espaces-dedies/activites-relevant-de-leconomie.html">
                lien urssaf
              </a>
              .
            </li>
          </ul>
        </div>

        {/* Section : Mon employeur peut-il me reprocher de créer un profil Tisio ? */}
        <div className="cblocks">
          <h2 className="section_title">
            Mon employeur peut-il me reprocher de créer un profil Tisio ?
          </h2>
          <p>
            Non, tant que vous respectez vos obligations professionnelles
            (confidentialité, loyauté, éventuellement non-concurrence…)
            vis-à-vis de votre employeur et que votre contrat de travail ne
            prévoit pas d’interdictions ou limitations particulières (pensez à
            vérifier votre contrat !)
          </p>
        </div>

        {/* Section : Comment s’assurer que mon interlocuteur sera qualitatif ? */}
        <div className="cblocks">
          <h2 className="section_title">
            Comment s’assurer que mon interlocuteur sera qualitatif ?
          </h2>
          <p>
            En amont, la vision n’a lieu que s’il y a une acceptation mutuelle
            entre une demande et un profil. À l’issue de la visio, un système de
            notation croisé permet de valider la conformité de l’échange.
          </p>
        </div>

        {/* Section : Dois-je préparer l’échange ? */}
        <div className="cblocks">
          <h2 className="section_title">Dois-je préparer l’échange ?</h2>
          <p>
            Si une personne vous sollicite pour une visio, il peut être
            difficile de préparer l’échange puisque, dans la plupart des cas,
            vous ne connaîtrez pas le détail des sujets qui seront abordés. La
            personne qui vous sollicite veut avoir votre point de vue, votre
            avis d’expert, votre feedback… Vous aurez donc tout en tête !
          </p>
          <p>
            En revanche, si c’est vous qui sollicitez un profil Tisio, alors, en
            effet, il est essentiel de préparer votre échange ! 30 minutes ça
            passe vite. Nous vous recommandons donc de préparer une courte
            introduction expliquant le contexte et ce que vous attendez
            concrètement de votre échange, puis lister les questions auxquelles
            vous souhaitez avoir des réponses.
          </p>
        </div>

        {/* Section : À quel moment dois-je payer ? */}
        <div className="cblocks">
          <h2 className="section_title">À quel moment dois-je payer ?</h2>
          <p>
            Pour créer son profil Tisio, c'est gratuit ! Pour solliciter
            quelqu'un, une empreinte de carte bancaire vous sera demandée au
            moment de la sollicitation. Le montant de l'échange sera débité
            qu'une fois la visio confirmée par votre interlocuteur.
          </p>
        </div>

        {/* Section : Comment fixer mon tarif ? */}
        <div className="cblocks">
          <h2 className="section_title">Comment fixer mon tarif ?</h2>
          <p>
            Pour définir votre tarif pour 30 minutes, on vous encourage à
            regarder les profils similaires aux vôtres déjà présents sur la
            plateforme. Mais surtout, fixez un prix que vous estimez être la
            valeur de vos expériences et compétences professionnelles.
          </p>
        </div>

        {/* Section : À quel moment suis-je rémunéré ou comment en faire bénéficier une association ? */}
        <div className="cblocks">
          <h2 className="section_title">
            À quel moment suis-je rémunéré ou comment en faire bénéficier une
            association ?
          </h2>
          <p>
            À l’issue de la visio réalisée, vous recevez un mail pour valider
            votre rémunération. C’est à ce moment-là que vous choisissez de
            verser cette rémunération à une des associations que nous avons
            présélectionnées, à l’association de votre choix ou directement sur
            votre compte.
          </p>
        </div>

        {/* Section : La rémunération est-elle garantie ? */}
        <div className="cblocks">
          <h2 className="section_title">La rémunération est-elle garantie ?</h2>
          <p>
            Oui, à partir du moment où la visio a été réalisée, Tisio assure
            directement le règlement selon le choix que vous aurez effectué (cf.
            question précédente).
          </p>
        </div>

        <div className="cblocks">
          <h2 className="section_title">Y a-t-il des frais de plateforme ?</h2>
          <p>
            Oui, Tisio facture des frais de plateforme à hauteur de 20% à la
            personne ayant sollicité la visio, lorsque celle-ci a été réalisée.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FaqPage;
