import { authService } from "../auth/authService";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { BusinessAccount } from "../businessAccount/businessAccountModel";
import { User } from "../user/userModel";
import {
  addContactsToCampaignRequest,
  createBookingRequestRequest,
  createCampaignRequest,
  getCampaignByIdRequest,
  getPublicCampaignByUuidRequest,
  getCampaignsByUserAndBaIdRequest,
  updateCampaignRequest,
} from "./campaignApi";
import { Campaign, CampaignContactStatus } from "./campaignModel";

interface CampaignService {
  getCampaign(
    campaignUuid: Campaign["id"] | Campaign["uuid"],
  ): Promise<Campaign>;
  getPublicCampaign(campaignUuid: string): Promise<Campaign>;
  createCampaign(campaign: Campaign): Promise<Campaign>;
  updateCampaign(
    campaign: Partial<Campaign> & Pick<Campaign, "id">,
  ): Promise<Campaign>;
  getAllCampaigns(
    businessAccountId: BusinessAccount["id"],
  ): Promise<Campaign[]>;
  createBookingRequest(
    campaignUuid: Campaign["uuid"],
    externalUser: Pick<
      BookingRequest,
      "extFirstname" | "extLastname" | "extConsent" | "extEmail"
    >,
  ): Promise<BookingRequest>;
  addContactsToCampaign(
    campaignId: Campaign["id"],
    contacts: Pick<User, "firstname" | "lastname" | "email">[],
    sendMail: "send" | "dontSend",
  ): Promise<Campaign>;
}

const { getLoggedUserId } = authService();

export function campaignService(): CampaignService {
  const getAllCampaigns: CampaignService["getAllCampaigns"] = (
    businessAccountId,
  ) => {
    return getCampaignsByUserAndBaIdRequest(
      getLoggedUserId()!,
      businessAccountId,
    );
  };

  const getCampaign: CampaignService["getCampaign"] = (campaignId) => {
    return getCampaignByIdRequest(campaignId);
  };

  const getPublicCampaign: CampaignService["getPublicCampaign"] = (
    campaignUuid,
  ) => {
    return getPublicCampaignByUuidRequest(campaignUuid);
  };

  const updateCampaign: CampaignService["updateCampaign"] = (campaign) => {
    return updateCampaignRequest(campaign);
  };

  const createCampaign: CampaignService["createCampaign"] = (campaign) => {
    return createCampaignRequest(campaign);
  };

  const createBookingRequest: CampaignService["createBookingRequest"] = (
    campaignUuidd,
    externalUser,
  ) => {
    return createBookingRequestRequest(campaignUuidd, externalUser);
  };

  const addContactsToCampaign: CampaignService["addContactsToCampaign"] = (
    campaignId,
    contacts,
    sendMail,
  ) => {
    return addContactsToCampaignRequest(campaignId, contacts, sendMail);
  };

  return {
    getAllCampaigns,
    getCampaign,
    getPublicCampaign,
    updateCampaign,
    createCampaign,
    createBookingRequest,
    addContactsToCampaign,
  };
}

export function isUserAdminOfCampaign(user: User, campaign?: Campaign) {
  return (
    user.businessAccounts?.some(
      (ba) =>
        ba.BusinessAccountUser?.admin && campaign?.businessAccountId === ba.id,
    ) ?? null
  );
}

export function mapBookingRequestToContactStatus(
  bookingRequest: BookingRequest,
): CampaignContactStatus {
  if (bookingRequest.status === "BOOKED") return "ACCEPTED";
  else if (bookingRequest.status === "DECLINED") return "DECLINED";
  else if (bookingRequest.requestMailSent) return "SENT";
  else return "WAITING";
}
