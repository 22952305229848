import { useState } from "react";
import Dialog from "../../../components/Dialog";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "../../../components/forms/SubmitButton";
import SxForm from "../../../forms/SxForm";
import { number, object, string } from "yup";
import { bookingRequestService } from "../../../services/bookingRequest/bookingRequestService";
import { authService } from "../../../services/auth/authService";
import SxField from "../../../forms/fields/SxField";
import { transformEmptyToUndefined } from "../../../react-helpers/yup";
import { DeclineReason } from "../../../services/data/dataModel";
import { toastsWithIntl } from "../../../services/toastService";

enum Step {
  Reason,
  Subscribe,
}

const { isLoggedIn } = authService();
const { updateBookingRequestById } = bookingRequestService();

const RejectedDialog = ({
  onClose,
  bookingRequest,
  declineReasons,
}: {
  onClose(): void;
  bookingRequest: BookingRequest;
  declineReasons: DeclineReason[];
}) => {
  const { toastSuccess, toastError } = toastsWithIntl(["campaign"]);
  const navigate = useNavigate();
  const [step, setStep] = useState(Step.Reason);

  return (
    <Dialog onClose={onClose}>
      <>
        {step === Step.Reason && (
          <SxForm
            initialValues={{
              declineReasonId: "",
              otherReason: "",
            }}
            onSubmit={async (values) => {
              await updateBookingRequestById(
                isLoggedIn() ? bookingRequest.id : bookingRequest.uuid,
                {
                  ...bookingRequest,
                  declineReasonId: Number(values.declineReasonId),
                  otherReason: values.otherReason,
                },
              ).then(
                () => {
                  toastSuccess("campaign:decline.SUCCESS");
                  if (isLoggedIn()) navigate("/");
                  else setStep(Step.Subscribe);
                },
                () => {
                  toastError("campaign:decline.ERROR");
                },
              );
            }}
            validationSchema={object({
              declineReasonId: number()
                .oneOf(declineReasons.map((d) => d.id))
                .transform(transformEmptyToUndefined)
                .required(),
              otherReason: string().multiline(),
            })}
          >
            <div className="popup_head">
              <div className="popup_title">
                Quelle est la raison qui vous pousse à refuser ?
              </div>
            </div>
            <div className="popup_body">
              <SxField name="declineReasonId" as="select">
                <option value="">-</option>
                {declineReasons.map((reason) => (
                  <option key={reason.id} value={reason.id}>
                    {reason.labelFr}
                  </option>
                ))}
              </SxField>
              <SxField name="otherReason" placeholder="Dites-nous en plus !" />
            </div>
            <div className="popup_footer">
              <SubmitButton className="btn" type="submit">
                Envoyer
              </SubmitButton>
            </div>
          </SxForm>
        )}
        {step === Step.Subscribe && (
          <>
            <div className="popup_body lblocks --l">
              <p className="section_title --txt--center">
                Souhaitez-vous recevoir d’autres sollicitations
                <br />
                plus pertinentes ?
              </p>
              <div className="lblock ui-row --center">
                <Link to="/register" className="btn--2">
                  Oui, je crée mon profil Tisio
                </Link>
                <Link to="/" className="btn--grey">
                  Non merci
                </Link>
              </div>
            </div>
          </>
        )}
      </>
    </Dialog>
  );
};

export default RejectedDialog;
