import { FormikState, useFormikContext } from "formik";
import { useMemo } from "react";
import SubmitButton from "./SubmitButton";

type FormResetButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  "onClick"
> & {
  onClick?(
    ev: MouseEvent,
    helpers: {
      resetForm: (nextState?: Partial<FormikState<any>> | undefined) => void;
      submitForm: () => Promise<void>;
    },
  ): void | Promise<void>;
  ignoredFields?: string[];
};

const FormResetButton = ({
  ignoredFields,
  onClick,
  ...props
}: FormResetButtonProps) => {
  const formik = useFormikContext<any>();

  const isFormValuesEmpty = useMemo(() => {
    const isNullOrUndefined = (value: any) =>
      value === null || value === undefined;

    return (
      Object.entries(formik.values)
        // we filter out the ignored fields ...
        .filter(([key]) =>
          ignoredFields ? !ignoredFields.includes(key) : true,
        )
        // and transform the array of key-value pairs into an array of values
        .map(([, value]) => value)
        .every(
          (value) =>
            isNullOrUndefined(value) ||
            (typeof value === "string"
              ? value === ""
              : typeof value === "number"
                ? value === 0
                : typeof value === "boolean"
                  ? value === false
                  : Array.isArray(value) && value.length === 0),
        )
    );
  }, [formik.values, ignoredFields]);

  return (
    <>
      {!isFormValuesEmpty && (
        <SubmitButton
          type="button"
          {...props}
          onClick={(ev) => {
            if (onClick) {
              ev.stopPropagation();
              // we pass the resetForm function to the onClick handler to reset the form after the custom click handler if needed
              return onClick(ev as any, {
                resetForm: formik.resetForm,
                submitForm: formik.submitForm,
              });
            } else {
              formik.resetForm();
            }
          }}
        >
          {props.children}
        </SubmitButton>
      )}
    </>
  );
};

export default FormResetButton;
