import { isAfter, parseISO } from "date-fns";
import Icon from "../Icon";
import { formatDate } from "../../react-helpers/date";
import { Booking } from "../../services/bookingRequest/bookingRequestModel";
import { CAL_BASE_URL } from "../../services/config";
import { cx } from "../../react-helpers/css";
import { useMemo } from "react";

function BookingDateTimeDisplay({ booking }: { booking: Booking }) {
  const bookingStartTime = new Date(booking.startTime);

  const isPassed = useMemo(
    () => isAfter(new Date(), parseISO(booking.endTime)),
    [booking],
  );

  return (
    <div className={cx(["datetime-display", isPassed && "--passed"])}>
      <div className="display_date">
        <div>
          <div className="date_day">{formatDate(bookingStartTime, "dd")}</div>
          <div className="date_month">
            {formatDate(bookingStartTime, "MMMM")}
          </div>
          <div className="date_year">
            {formatDate(bookingStartTime, "yyyy")}
          </div>
        </div>
      </div>
      <div className="display_content">
        {!isPassed && <span className="chip">Visio planifiée</span>}
        {isPassed && <span className="chip --cancel">Visio terminée</span>}
        <div className="display_time ui-row --center--v cblock--xs">
          {formatDate(bookingStartTime, "p")}

          <small className="ui-row --center">
            <Icon name="arrow-right" className="--s" />
            {formatDate(new Date(booking.endTime), "p")}
          </small>
        </div>
        {!isPassed && (
          <div className="ui-row --center--v ">
            <Icon className="--l" name="visio" />
            <div className="">
              <a
                href={`${CAL_BASE_URL}/video/${booking.uid}`}
                target="_blank"
                rel="noreferrer"
                className="link --s"
              >
                rejoindre la visio
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default BookingDateTimeDisplay;
