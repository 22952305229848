import { Link } from "react-router-dom";
import { BookingProfile } from "../../services/bookingProfile/bookingProfileModel";
import Icon from "../Icon";

const LinkedinLink = ({ profile }: { profile: BookingProfile }) => {
  if (!profile.linkedin) return;
  return (
    <Link
      to={profile.linkedin}
      target="_blank"
      className="profile_linkedin-link"
    >
      <Icon name="linkedin" className="--linkedin-blue" />
    </Link>
  );
};

export default LinkedinLink;
