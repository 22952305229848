import { ErrorMessage } from "formik";
import { randomUUID } from "../react-helpers/crypto";

const SxError = ({
  name,
  firstErrorOnly,
}: {
  name: string;
  firstErrorOnly?: boolean;
}) => {
  return (
    <ErrorMessage
      name={name}
      render={(err: any) => (
        <div>
          {(Array.isArray(err)
            ? firstErrorOnly
              ? err.slice(0, 1)
              : err
            : [{ type: randomUUID(), message: err }]
          ).map((err) => (
            <div key={`${name}-${err.type}`} className="field-error">
              {err.message}
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default SxError;
