import { useState } from "react";
import LoginDialog from "../../../components/LoginDialog";
import RegisterDialog from "../../../components/RegisterDialog";
import { Request } from "./BookingProfilePage";
import { authService } from "../../../services/auth/authService";
import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";
import Dialog from "../../../components/Dialog";
import Icon from "../../../components/Icon";

const BookingProfilePublicAuthDialog = ({
  request,
  bookingProfileId,
  onClose,
}: {
  request: Request;
  bookingProfileId: BookingProfile["id"];
  onClose: () => void;
}) => {
  const auth = authService();
  const [displayedPopup, setDisplayedPopup] = useState<
    "login" | "register" | "afterRegister"
  >("register");
  return (
    <>
      {displayedPopup === "register" && (
        <RegisterDialog
          onClose={() => {
            setDisplayedPopup("register");
            onClose();
          }}
          initialValues={{
            email: request.email!,
            firstname: request.firstname!,
            lastname: request.lastname!,
          }}
          onRedirectToLogin={() => setDisplayedPopup("login")}
          onRegister={async (registerData) => {
            return auth
              .register({
                ...registerData,
                cguAccepted: registerData.cguAccepted,
                request: {
                  context: request.context,
                  eCredit: request.eCredit,
                  recipientId: bookingProfileId,
                },
              })
              .then(() => setDisplayedPopup("afterRegister"));
          }}
        />
      )}
      {displayedPopup === "login" && (
        <LoginDialog
          onClose={() => {
            setDisplayedPopup("register");
            onClose();
          }}
          initialValues={{ email: request.email! }}
          onRedirectToRegister={() => setDisplayedPopup("register")}
        />
      )}
      {displayedPopup === "afterRegister" && (
        <Dialog modal={true} onClose={() => null}>
          <div className="popup_body cblocks">
            <div className="--txt--center">
              <Icon name="check" className="--success --xl" />
            </div>
            <div>
              Vous allez recevoir un mail contenant un lien vous permettant de
              valider votre compte et finaliser votre solllicitation.
            </div>

            <div className="info">
              <strong>Vous pouvez désormais fermer cette page.</strong>
              Vous serez automatiquement redirigé(e) vers la page de prise de
              rendez-vous après avoir cliqué sur le lien reçu par mail.
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default BookingProfilePublicAuthDialog;
