import { useEffect, useState } from "react";
import { cx } from "../react-helpers/css";
import logger from "../services/logger";

const icons = import.meta.glob(
  ["../assets/icons/*.svg", "../assets/icons/sx-icons/*.svg"],
  { eager: true },
);

function Icon({ name, className }: { name: string; className?: string }) {
  const [svgContent, setSvgContent] = useState<string | null | TrustedHTML>(
    null,
  );

  const icon = Object.entries(icons).filter(([path]) =>
    path.includes(`icon-${name}`),
  )[0][1] as Record<string, any>;

  useEffect(() => {
    void fetch(icon.default)
      .then((res) => res.text())
      .then((res) => setSvgContent(res))
      .catch((error) => logger("Error fetching SVG content:", error));
  }, [icon]);

  return svgContent ? (
    <span
      className={cx(["icon", className])}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  ) : (
    <img src={icon.default} className={cx(["icon", className])} />
  );
}

export default Icon;
