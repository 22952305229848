import { useEffect, useState, useRef } from "react";
import { useNavigation } from "react-router-dom";
import loaderImg from "../assets/img/loader.svg";

const RouteLoadingIndicator = () => {
  const navigation = useNavigation();
  const [isLoading, setLoading] = useState(false);
  const loadingTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (navigation.state === "loading") {
      // Set a timer to delay the loading indicator
      loadingTimer.current = setTimeout(() => {
        setLoading(true);
      }, 300);
    } else {
      // Clear the timer and reset the loading state
      if (loadingTimer.current) {
        clearTimeout(loadingTimer.current);
        loadingTimer.current = null;
      }
      setTimeout(() => setLoading(false), 100); // avoid flickering by imposing min 100ms duration
    }
  }, [navigation.state]);

  return isLoading ? (
    <div className="loading-indicator">
      <img src={loaderImg} alt="Loading..." />
    </div>
  ) : null;
};

export default RouteLoadingIndicator;
