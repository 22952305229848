import { ErrorResponse, Link, Navigate, useRouteError } from "react-router-dom";
import { loggerBuilder } from "../../services/logger";
import GlobalError from "./GlobalError";
import NotFound from "./NotFound";
import { ClientOnly } from "../../react-helpers/react";
import AccessDenied from "./AccessDenied";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const logger = loggerBuilder("navigation-error");

function ErrorPage() {
  const error = useRouteError() as ErrorResponse & { message?: string };
  logger.error(error);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ClientOnly>
      <div className="full-screen --txt--center lblocks">
        {error.status === 404 || error.message?.includes("status code 404") ? (
          <NotFound />
        ) : error.status === 403 ||
          error.message?.includes("status code 403") ? (
          <AccessDenied />
        ) : error.message?.includes("Not logged in") ? (
          <Navigate to="/login" />
        ) : (
          <GlobalError errorMessage={error.data ?? error.message} />
        )}
        <Link to="/app/" className="btn">
          <div className="icon--chevron-back --white svg"></div> Retour
        </Link>
      </div>
    </ClientOnly>
  );
}

export default ErrorPage;
