import { useCallback, useMemo } from "react";
import { BookingRequest } from "../../services/bookingRequest/bookingRequestModel";
import { User } from "../../services/user/userModel";
import DisplayPrice from "../DisplayPrice";
import Icon from "../Icon";
import BookingProfileCard from "../profiles/BookingProfileCard";
import { TISIO_FEE_PERCENTAGE } from "../../services/config";
import BookingDateTimeDisplay from "./BookingDateTimeDisplay";
import ProfilePic from "../profileDisplay/ProfilePic";
import { Link } from "react-router-dom";

function BookingRequestAside({
  bookingRequest,
  currentUser,
  displayDate = true,
}: {
  bookingRequest: BookingRequest;
  currentUser: User;
  displayDate?: boolean;
}) {
  const otherPartyBookingProfile = useMemo(() => {
    return bookingRequest.sender?.userId === currentUser.id
      ? bookingRequest.recipient
      : bookingRequest.sender!;
  }, [bookingRequest, currentUser]);

  const isCurrentUserSender = bookingRequest.sender?.userId === currentUser.id;

  const addFee = useCallback(
    (price: BookingRequest["price"], fee: BookingRequest["fee"]) =>
      Number(price) * (1 + (fee ?? parseInt(TISIO_FEE_PERCENTAGE, 10)) / 100),
    [],
  );

  return (
    <div className="lblocks">
      {displayDate && (
        <BookingDateTimeDisplay booking={bookingRequest.booking!} />
      )}
      {bookingRequest.external && isCurrentUserSender && (
        <div className="cblocks">
          <h2 className="section_title">Campagne</h2>
          <div className="card">
            <div className="card_body lrow">
              <Icon name="campaigns" />
              <Link
                to={`/app/pro/${bookingRequest.businessAccountId}/campaigns/${bookingRequest.campaign?.id}`}
                className="link"
              >
                {bookingRequest.campaign?.name}
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="cblocks --s">
        <h2 className="section_title">Votre interlocuteur</h2>
        {otherPartyBookingProfile ? (
          <BookingProfileCard
            bookingProfile={otherPartyBookingProfile}
            minimal={true}
          />
        ) : (
          <div className="booking-profile-list_item">
            <div className="profile_row">
              <div className="profile_info-col">
                <div>
                  <div className="lrow --center--v">
                    <ProfilePic className="--s" />
                    <div>
                      <div className="profile_name">
                        {bookingRequest.extFirstname}{" "}
                        {bookingRequest.extLastname?.[0]}.
                      </div>
                      <div className="profile_company-name">
                        (Contact externe de campagne)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="cblocks --s">
        <h2 className="section_title">Le contexte</h2>
        <div className="card --bg">
          <pre className="card_body body--30">{bookingRequest.context}</pre>
        </div>
      </div>
      {/* TODO : créer un helper qui splitt le prix en toutes ses composantes :
    remu dest, remu asso, coût sender, fees, tva, etc...
    VOIR enrichir dans le service l'objet reçu par le back, pour ne pas avoir à recalculer
    toutes ces valeurs à chaque fois  */}
      {bookingRequest.price && (
        <>
          {isCurrentUserSender && (
            <div className="scorecard --bg">
              <Icon name="label" />
              <div>
                <div className="scorecard_label">Prix</div>
                <div className="scorecard_value">
                  <DisplayPrice
                    amount={addFee(bookingRequest.price, bookingRequest.fee)}
                    taxFree
                  />
                </div>
              </div>
            </div>
          )}
          {!isCurrentUserSender && !bookingRequest.recipientProbono && (
            <div className="scorecard --bg">
              <Icon name="wallet" />
              <div>
                <div className="scorecard_label">Votre rémunération</div>
                <div className="scorecard_value">
                  <DisplayPrice amount={bookingRequest.price * 0.9} />
                </div>
                <div className="body--30">
                  +<DisplayPrice amount={bookingRequest.price * 0.1} /> à votre
                  association
                </div>
              </div>
            </div>
          )}
          {!isCurrentUserSender && !!bookingRequest.recipientProbono && (
            <div className="scorecard --bg">
              <Icon name="wallet" />
              <div>
                <div className="scorecard_label">Pour votre association</div>
                <div className="scorecard_value">
                  <DisplayPrice amount={bookingRequest.price} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default BookingRequestAside;
