import { useFormikContext } from "formik";
import { useYupFields } from "./validation/useYupField";
import { useTranslation } from "react-i18next";

const ValidationErrors = ({
  errorsStyle,
}: {
  errorsStyle?: "detailed" | "global";
}) => {
  const formik = useFormikContext();
  const getYupFields = useYupFields();
  const { t } = useTranslation(["validation"]);

  const fields = getYupFields(Object.keys(formik.errors));

  // Object.keys(formik.errors).every((k) => Object.keys(formik.touched).includes(k)) => when a user interact with a field it's added to formik.touched (when submitted they are all added). formik.errors contains all the fields
  // Object.values(formik.errors).flat().length === 0 => check if no errors reported by formik
  if (
    !Object.keys(formik.errors).every((k) =>
      Object.keys(formik.touched).includes(k),
    ) ||
    Object.values(formik.errors).flat().length === 0
  )
    return null;

  return !errorsStyle || errorsStyle === "global" ? (
    <div className="info --error">
      <div>{t("validation:ERRORS_IN_FORM")}</div>
    </div>
  ) : (
    <div>
      {Object.entries(formik.errors).map(([field, errors]) => (
        <div key={field} className="field-error">
          {fields[field]?.spec.label}
          {(errors as any[]).map((err) => (
            <div key={err.type}>{err.message}</div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ValidationErrors;
