import { boolean, object, string } from "yup";
import SxForm from "../../../../forms/SxForm";
import SxField from "../../../../forms/fields/SxField";
import SxCheckbox from "../../../../forms/fields/SxCheckbox";
import { Link } from "react-router-dom";
import ValidationErrors from "../../../../forms/ValidationErrors";
import SubmitButton from "../../../../components/forms/SubmitButton";
import { Plan } from "../../../../services/businessAccount/businessAccountModel";
import DisplayPrice from "../../../../components/DisplayPrice";
import { ProRegisterData } from "../../../../services/auth/authModel";
import PlanCard from "../../../../components/plans/PlanCard";
import {
  transformEmptyToNull,
  transformEmptyToUndefined,
} from "../../../../react-helpers/yup";
import { isValidCouponRequest } from "../../../../services/coupon/couponApi";
import { useCallback, useMemo } from "react";
import { useField, useFormikContext } from "formik";
import Icon from "../../../../components/Icon";

const CouponField = ({ name }: { name: string }) => {
  const formik = useFormikContext();
  const [field, meta] = useField(name);
  return (
    <div>
      <SxField name={name} />
      <div className="field-tip">
        {(field.value?.length ?? 0) > 0 &&
        !meta.error &&
        !formik.isValidating ? (
          <div className="ui-row --center--v">
            <Icon name="check-round" className="--s --green" />
            30 jours gratuits
          </div>
        ) : (
          // empty field-tip to avoid flickering
          <span>&nbsp;</span>
        )}
      </div>
    </div>
  );
};

const PlanRegisterForm = ({
  onRegister,
  plan,
  yearly,
}: {
  onRegister: (data: ProRegisterData) => Promise<void>;
  plan: Plan;
  yearly: boolean;
}) => {
  const isValidCoupon = useCallback(async (coupon: string | null) => {
    if (!coupon) {
      return null;
    } else {
      return isValidCouponRequest(coupon).then(
        () => null,
        (err) => {
          switch (err.response?.status) {
            case 404:
              return "Ce code n'existe pas";
            case 417:
              return "Ce code n'est pas encore actif";
            case 412:
              return "Ce code n'est plus actif";
          }
          return "Une erreur est survenue";
        },
      );
    }
  }, []);

  const schema = useMemo(
    () =>
      object({
        firstname: string().label("Prénom").required().min(2).trim(),
        lastname: string().label("Nom").required().min(2).trim(),
        email: string().label("Adresse email").email().required(),
        tel: string()
          .label("Numéro de téléphone")
          .phoneNumber("Le numéro de téléphone est invalide")
          .transform(transformEmptyToUndefined)
          .required("Le numéro de téléphone est requis"),
        password: string().label("Mot de passe").password().required(),
        cguAccepted: boolean()
          .transform((v) => !!v)
          .oneOf([true]),
        companyName: string().label("Nom de l'entreprise").required().trim(),
        siret: string().label("Siret").required().trim(),
        stripePlanId: string().label("Formule").required().trim(),
        couponCode: string()
          .label("Code promo")
          .trim()
          .transform(transformEmptyToNull)
          .nullable()
          .test("couponIsValid", (value, ctx) =>
            isValidCoupon(value ?? null).then(
              (e) => e === null || ctx.createError({ message: e }),
            ),
          ),
      }),
    [isValidCoupon],
  );

  return (
    <SxForm
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        tel: "",
        password: "",
        cguAccepted: false,
        companyName: "",
        stripePlanId: yearly
          ? plan.annualStripePriceId
          : plan.monthlyStripePriceId,
        siret: "",
        couponCode: null,
      }}
      onSubmit={async (values) => onRegister({ ...values, planId: plan.id })}
      validationSchema={schema}
    >
      {({ values, setFieldValue }) => (
        <div className="grid--1-2">
          <div className="card --bg">
            <div className="card_body">
              <PlanCard
                displayCta={false}
                yearly={values.stripePlanId === plan.annualStripePriceId}
                onChange={(p) =>
                  void setFieldValue(
                    "stripePlanId",
                    p === 1
                      ? plan.monthlyStripePriceId
                      : plan.annualStripePriceId,
                    false,
                  )
                }
              />
            </div>
          </div>
          <div className="cblocks">
            <div className="card">
              <div className="card_body cblocks">
                <h2 className="section_title">Compte utilisateur</h2>
                <div className="grid--2">
                  <SxField
                    name="firstname"
                    placeholder="Saisissez votre prénom"
                  />
                  <SxField
                    name="lastname"
                    placeholder="Saisissez votre nom de famille"
                  />
                </div>

                <SxField name="email" placeholder="me@mail.com" />
                <SxField name="password" placeholder="************" />
              </div>
            </div>
            <div className="card">
              <div className="card_body cblocks">
                <h2 className="section_title">Entreprise</h2>
                <div className="grid--2">
                  <SxField
                    name="companyName"
                    placeholder="Saisissez le nom de l'entreprise"
                  />
                  <SxField
                    name="siret"
                    placeholder="Saisissez le siret de l'entreprise"
                  />
                </div>
              </div>
            </div>
            <div className="card --bg">
              <div className="card_body cblocks">
                <div className="cblocks grid--2">
                  <SxField name="stripePlanId" as="select">
                    <option value={plan.annualStripePriceId}>
                      Abonnement annuel (
                      <DisplayPrice
                        amount={plan.annualPrice}
                        taxFree
                        currency="EUR"
                        textOnly
                      />{" "}
                      / an)
                    </option>
                    <option value={plan.monthlyStripePriceId}>
                      Abonnement mensuel (
                      <DisplayPrice
                        amount={plan.monthlyPrice}
                        taxFree
                        currency="EUR"
                        textOnly
                      />{" "}
                      / mois)
                    </option>
                  </SxField>
                  <CouponField name="couponCode" />
                </div>

                <SxCheckbox
                  name="cguAccepted"
                  label={
                    <>
                      J'ai lu et j'accepte les{" "}
                      <Link to="/general-conditions-of-use" target="_blank">
                        conditions générales d’utilisation
                      </Link>{" "}
                      de la plateforme.
                    </>
                  }
                />
              </div>
            </div>

            <div className="form_footer">
              <ValidationErrors />
              <div className="cblock">
                <SubmitButton className="btn" type="submit">
                  Souscrire
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </SxForm>
  );
};

export default PlanRegisterForm;
