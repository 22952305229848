import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";
import { BookingRequest } from "../../../services/bookingRequest/bookingRequestModel";
import DisplayPrice from "../../../components/DisplayPrice";
import ProfilePic from "../../../components/profileDisplay/ProfilePic";
import BookingDateTimeDisplay from "../../../components/bookings/BookingDateTimeDisplay";
import Icon from "../../../components/Icon";

// TODO : mutualize with INT requests aside
const ExtClosureAside = ({
  bookingRequest,
  senderBookingProfile,
}: {
  bookingRequest: BookingRequest;
  senderBookingProfile: BookingProfile;
}) => {
  return (
    <div className="lblocks">
      <BookingDateTimeDisplay booking={bookingRequest.booking!} />
      <div className="cblocks">
        <h2 className="section_title">Votre interlocuteur</h2>
        <div className="booking-profile-list_item">
          <div className="profile_row">
            <ProfilePic src={senderBookingProfile.user.profilePicture} />
            <div>
              <div className="profile_name">
                {senderBookingProfile.user.displayName}
              </div>
              <div className="cblock--s profile_position">
                {senderBookingProfile.position}
              </div>
              <div className="profile_company-name">
                {senderBookingProfile.companyName}
                {senderBookingProfile.companyActivity && (
                  <small>
                    {" "}
                    ({senderBookingProfile.companyActivity.labelFr})
                  </small>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scorecard --bg">
        <Icon name="wallet" />
        <div>
          <div className="scorecard_label">Votre rémunération</div>
          <div className="scorecard_value">
            <DisplayPrice amount={bookingRequest.price!} />
          </div>
        </div>
      </div>
      <div className="cblocks --s">
        <h2 className="section_title">Le contexte</h2>
        <div className="card --bg">
          <pre className="card_body body--30">{bookingRequest.context}</pre>
        </div>
      </div>
    </div>
  );
};

export default ExtClosureAside;
