import { useMemo } from "react";
import { compareDate, orderByField } from "../../../react-helpers/array";
import { WalletTransaction } from "../../../services/payment/paymentModel";
import { authService } from "../../../services/auth/authService";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../react-helpers/date";
import { Link } from "react-router-dom";
import DisplayPrice from "../../../components/DisplayPrice";
import { cx } from "../../../react-helpers/css";

const EWalletTransactionsTableComponent = ({
  walletTransactions,
}: {
  walletTransactions: WalletTransaction[];
}) => {
  const { getLoggedUserId } = authService();
  const { t } = useTranslation(["payment"]);

  const sortedWalletTransactions = useMemo(() => {
    const loggedUserId = getLoggedUserId();
    return walletTransactions
      .sort(orderByField("createdAt", "asc", compareDate))
      .map((walletTransaction) => {
        return {
          ...walletTransaction,
          isSender: walletTransaction.bookingRequest
            ? walletTransaction.bookingRequest.sender!.userId === loggedUserId
            : null,
        };
      });
  }, [walletTransactions, getLoggedUserId]);

  return (
    <div className="table-scroll">
      <table className="table --framed">
        <tbody>
          {sortedWalletTransactions.map((walletTransaction) => (
            <tr key={walletTransaction.id}>
              <td className="row-title --shrink">
                {formatDate(walletTransaction.createdAt, "P")}
              </td>
              <td className="--shrink">
                <span className="tag--3">
                  {t(
                    `payment:wallet-transaction.type.${walletTransaction.type}`,
                  )}
                </span>
              </td>
              <td>
                {walletTransaction.bookingRequest && (
                  <Link
                    to={`/app/bookings/${walletTransaction.bookingRequest.uuid}`}
                    className="link --s"
                  >
                    {walletTransaction.isSender
                      ? walletTransaction.bookingRequest.recipient!.user
                          .displayName
                      : walletTransaction.bookingRequest.sender!.user
                          .displayName}{" "}
                    {walletTransaction.bookingRequest.booking &&
                      `- ${formatDate(
                        walletTransaction.bookingRequest.booking.startTime,
                        "Pp",
                      )}`}
                  </Link>
                )}
              </td>
              <td className="row-value --txt--right --shrink">
                <div className="ui-row --nowrap --center--v">
                  {!walletTransaction.validated && (
                    <span className="chip --pending">En attente</span>
                  )}
                  <div
                    className={cx([
                      !walletTransaction.validated && "--txt--pending",
                    ])}
                  >
                    <DisplayPrice amount={walletTransaction.amount} withSign />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EWalletTransactionsTableComponent;
