import React, { useEffect, useMemo } from "react";
import useYupField from "../validation/useYupField";
import { Field, FieldProps, useField } from "formik";
import { AnySchema } from "yup";
import { loggerBuilder } from "../../services/logger";
import SxRadio from "./SxRadio";
import SxCheckbox from "./SxCheckbox";
import { cx } from "../../react-helpers/css";
import SxPassword from "./SxPassword";
import { SxAutocomplete, SxAutocompleteProps } from "./SxAutocomplete";
import SxRTE from "./SxRTE";
import Icon from "../../components/Icon";
import { formatDate } from "../../react-helpers/date";
import { isValid, parseISO } from "date-fns";
import SxError from "../SxError";

const logger = loggerBuilder("forms-sxfield");

// NOTE: You can add here props that will be added to each SxField component
export interface SxFieldCommonProps {
  // The name of the field in Formik and Yup
  name: string;
  // The label of the field default the the Yup label if present
  label?: string | null;
  // The placeholder for fields that support it
  placeholder?: string;
  // Follow the Formik spec for as, change the type of field. Default to inferred from yup
  as?: `${SxFieldType}`;
  // Hide errors under the field
  noErrors?: boolean;
  // When multiple errors are present in the validation only show the first one
  firstErrorOnly?: boolean;
  children?: ((props: FieldProps<any>) => React.ReactNode) | React.ReactNode;
  disabled?: boolean;
  clearable?: boolean;
  hookOnUpdate?: (value: any) => void;
  onReset?: () => void;
  "data-testid"?: string;
}

// NOTE: You can add here props that will be added to specifics SxField components
export type SxFieldProps<T extends `${SxFieldType}` = `${SxFieldType}`> = Omit<
  SxFieldCommonProps,
  "as"
> &
  (T extends `${SxFieldType.Radio}`
    ? {
        // Only on as="radio" fields
        as: `${SxFieldType.Radio}`;
        radios?: {
          value: string | number;
          label: string;
        }[];
      }
    : T extends `${SxFieldType.Checkbox}`
      ? {
          // Only on as="checkbox" fields
          as: `${SxFieldType.Checkbox}`;
          checkboxes?: {
            value: string | number;
            label: string | React.ReactNode;
          }[];
        }
      : T extends `${SxFieldType.Autocomplete}`
        ? {
            // Only on as="autocomplete" fields
            as: `${SxFieldType.Autocomplete}`;
          } & SxAutocompleteProps
        : {
            as?: `${T}`;
          });

// eslint-disable-next-line react-refresh/only-export-components
export enum SxFieldType {
  Text = "text",
  Email = "email",
  Password = "password",
  Number = "number",
  Textarea = "textarea",
  Select = "select",
  Date = "date",
  DateTime = "dateTime",
  Radio = "radio",
  Checkbox = "checkbox",
  Autocomplete = "autocomplete",
  RichText = "rte",
  // NOTE: Add new custom field types here
}
const FORMIK_AS_FIELDS: (SxFieldType | null)[] = [
  SxFieldType.Select,
  SxFieldType.Textarea,
];
const FORMIK_TYPE_FIELDS: (SxFieldType | null)[] = [
  SxFieldType.Text,
  SxFieldType.Number,
  SxFieldType.Email,
  SxFieldType.Date,
];

function getFieldType(fieldSchema: AnySchema): SxFieldType | null {
  if (fieldSchema.tests.some((t) => t.OPTIONS?.name === "email" ?? false)) {
    return SxFieldType.Email;
  }
  if (fieldSchema.meta()?.password) {
    return SxFieldType.Password;
  }
  if (fieldSchema.meta()?.multiline) {
    return SxFieldType.Textarea;
  }
  if (fieldSchema.type === "number") {
    return SxFieldType.Number;
  }
  if (fieldSchema.type === "date") {
    return SxFieldType.Date;
  }
  if (fieldSchema.type === "string") {
    return SxFieldType.Text;
  }

  // NOTE: Add new field type inference from Yup here before the default return
  logger.info("No field type detected from Yup");
  return null;
}

function SxField({
  name,
  label,
  as,
  children,
  noErrors,
  firstErrorOnly = true,
  placeholder,
  disabled,
  clearable,
  hookOnUpdate,
  onReset,
  ...rawProps
}: SxFieldProps) {
  const props = rawProps as any;
  const [field, meta, helpers] = useField(name);
  const fieldSchema = useYupField(name);
  const asType: SxFieldType | null = useMemo(() => {
    if (as) return (as as SxFieldType) ?? null;
    return fieldSchema ? getFieldType(fieldSchema) : null;
  }, [as, fieldSchema]);

  const isRequired = useMemo(
    () => !fieldSchema?.describe().optional ?? false,
    [fieldSchema],
  );

  const isDisabled = useMemo(
    () => disabled ?? fieldSchema?.meta()?.disabled ?? false,
    [disabled, fieldSchema],
  );

  const isClearable = useMemo(
    () => clearable ?? fieldSchema?.meta()?.clearable ?? false,
    [clearable, fieldSchema],
  );

  const hasMin = useMemo(
    () =>
      fieldSchema?.describe().tests.find((t) => t.name === "min")?.params?.min,
    [fieldSchema],
  );

  const hasMax = useMemo(
    () =>
      fieldSchema?.describe().tests.find((t) => t.name === "max")?.params?.max,
    [fieldSchema],
  );

  const fixedFieldValue = useMemo(() => {
    // Date field only accepts yyyy-MM-dd
    if (asType === SxFieldType.Date) {
      const date =
        typeof field.value === "string" ? parseISO(field.value) : field.value;
      if (isValid(date)) return formatDate(field.value, "yyyy-MM-dd");
    }

    return field.value;
  }, [field.value, asType]);

  useEffect(() => {
    hookOnUpdate?.(field.value);
  }, [hookOnUpdate, field.value]);

  if (fieldSchema) {
    if (fieldSchema.meta()?.notVisible) return null;
  }

  if (asType === null) logger.warn(`No field type found for ${name}`);
  return (
    <div className="form-block">
      {label !== null && (label ?? fieldSchema?.spec.label) ? (
        <label htmlFor={name} className="field-label">
          {label ?? fieldSchema?.spec.label}
          {isRequired ? "*" : ""}
        </label>
      ) : null}
      {[...FORMIK_AS_FIELDS, ...FORMIK_TYPE_FIELDS].includes(asType) && (
        <Field
          data-testid={rawProps["data-testid"]}
          name={name}
          as={FORMIK_AS_FIELDS.includes(asType) ? asType : undefined}
          type={FORMIK_TYPE_FIELDS.includes(asType) ? asType : undefined}
          disabled={isDisabled}
          placeholder={placeholder}
          value={fixedFieldValue ?? ""}
          className={cx([
            asType === SxFieldType.Textarea
              ? "textarea"
              : asType === SxFieldType.Select
                ? "select"
                : "input",
            meta.touched && meta.error && "--error",
          ])}
          min={hasMin}
          max={hasMax}
        >
          {children}
        </Field>
      )}
      {asType === SxFieldType.Password && (
        <SxPassword
          name={name}
          data-testid={rawProps["data-testid"]}
          showTogglePassword
          disabled={isDisabled}
          placeholder={placeholder}
          errored={meta.touched && meta.error}
          className={cx([meta.touched && meta.error && "--error"])}
        />
      )}
      {asType === SxFieldType.Checkbox && (
        <div className="checkboxes-group" role="group">
          {(props.checkboxes ?? []).map((checkbox: any) => (
            <SxCheckbox
              key={checkbox.value}
              name={name}
              checkbox={checkbox}
              isDisabled={isDisabled}
            />
          ))}
        </div>
      )}
      {asType === SxFieldType.Radio && (
        <div className="radio-group" role="group">
          {(props.radios ?? []).map((radio: any) => (
            <SxRadio
              key={radio.value}
              name={name}
              radio={radio}
              isDisabled={isDisabled}
            />
          ))}
        </div>
      )}
      {asType === SxFieldType.Autocomplete && (
        <SxAutocomplete
          className={cx([
            "react-select",
            meta.touched && meta.error && "--error",
          ])}
          classNamePrefix="react-select"
          closeMenuOnSelect={!props.isMulti}
          placeholder={placeholder}
          {...props}
          name={name}
          data-testid={rawProps["data-testid"]}
        />
      )}
      {asType === SxFieldType.RichText && (
        <SxRTE
          name={name}
          placeholder={placeholder}
          data-testid={rawProps["data-testid"]}
        />
      )}
      {isClearable && asType === SxFieldType.Text && !!meta.value && (
        <button
          type="button"
          className="input_clear-btn"
          onClick={() => {
            void helpers.setValue("");
            onReset?.();
          }}
        >
          <Icon name="close" />
        </button>
      )}
      {/* NOTE: If you have a custom field add it here */}
      {!noErrors && !fieldSchema?.meta()?.passwordConfig && (
        <SxError name={name} firstErrorOnly={firstErrorOnly} />
      )}
    </div>
  );
}

export default SxField;
