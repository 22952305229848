import { baseAPI } from "../api";
import { BookingRequest } from "../bookingRequest/bookingRequestModel";
import { User } from "../user/userModel";
import { BookingProfile } from "./bookingProfileModel";

export function getBookingProfileRequest(
  communitySlug: string,
  userId: User["id"],
): Promise<BookingProfile> {
  return baseAPI
    .get(`/booking-profiles/users/${userId}/communities/${communitySlug}`)
    .then(({ data }) => data);
}

export function getBookingProfileByUsernameRequest(
  username: NonNullable<User["username"]>,
  communitySlug: string,
): Promise<BookingProfile> {
  return baseAPI
    .get(`/booking-profiles/communities/${communitySlug}/users/${username}`)
    .then(({ data }) => data);
}

export function updateBookingProfileRequest(
  bookingProfile: BookingProfile,
): Promise<BookingProfile> {
  return baseAPI
    .put(`/booking-profiles/${bookingProfile.id}`, bookingProfile)
    .then(({ data }) => data);
}

export function toggleBookingProfilePublishedRequest(
  bookingProfileId: BookingProfile["id"],
  published?: boolean,
): Promise<BookingProfile> {
  return baseAPI
    .post(`/booking-profiles/${bookingProfileId}/published`, {
      published,
    })
    .then(({ data }) => data);
}

export function getBookingProfilesRequest(
  params: Record<string, string>,
): Promise<[BookingProfile[], number]> {
  return baseAPI
    .get(`/booking-profiles/`, {
      params,
    })
    .then(({ data }) => data);
}

export function getBookingProfileByIdRequest(
  bookingProfileId: BookingProfile["id"],
): Promise<BookingProfile> {
  return baseAPI
    .get(`/booking-profiles/${bookingProfileId}`)
    .then(({ data }) => data);
}

export function getBookingProfileByRequestUuidRequest(
  bookingRequestUuid: BookingRequest["uuid"],
): Promise<BookingProfile> {
  return baseAPI
    .get(`/booking-profiles/requests/${bookingRequestUuid}`)
    .then(({ data }) => data);
}
