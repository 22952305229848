import { object, string, InferType } from "yup";
import SxForm from "../../forms/SxForm";
import SxField from "../../forms/fields/SxField";
import SubmitButton from "../../components/forms/SubmitButton";
import { supportService } from "../../services/support/supportService";
import { transformEmptyToUndefined } from "../../react-helpers/yup";

enum Reason {
  QUESTION = "Une question",
  PROBLEM = "Un problème",
  SUGGESTION = "Une suggestion, un retour utilisateur",
}

const schema = object({
  firstname: string()
    .transform(transformEmptyToUndefined)
    .label("Prénom")
    .required(),
  lastname: string()
    .transform(transformEmptyToUndefined)
    .label("Nom")
    .required(),
  email: string()
    .transform(transformEmptyToUndefined)
    .label("Adresse email")
    .email()
    .required(),
  reason: string()
    .label("Motif")
    .oneOf(Object.keys(Reason) as (keyof typeof Reason)[])
    .required(),
  object: string()
    .label("Objet")
    .transform(transformEmptyToUndefined)
    .required(),
  message: string()
    .label("Message")
    .transform(transformEmptyToUndefined)
    .required(),
});
export type Contact = Omit<InferType<typeof schema>, "reason"> & {
  reason: string;
};

function ContactForm({ initialValues }: { initialValues: Partial<Contact> }) {
  const { contact } = supportService();

  return (
    <SxForm
      initialValues={{
        reason: "QUESTION",
        email: "",
        object: "",
        message: "",
        lastname: "",
        firstname: "",
        ...initialValues,
      }}
      onSubmit={(values, { resetForm }) => {
        return contact({
          ...values,
          reason: Reason[values.reason],
        }).then(() => {
          resetForm();
        });
      }}
      validationSchema={schema}
    >
      <SxField
        name="email"
        placeholder="me@mail.com"
        data-testid="contact-email"
      />
      <SxField
        name="firstname"
        placeholder="Votre prénom"
        data-testid="contact-firstname"
      />
      <SxField
        name="lastname"
        placeholder="Votre nom de famille"
        data-testid="contact-lastname"
      />
      <SxField name="reason" as="select" data-testid="contact-reason">
        {Object.entries(Reason).map(([key, reason]) => (
          <option key={key} value={key}>
            {reason}
          </option>
        ))}
      </SxField>
      <SxField
        name="object"
        placeholder="L'objet de votre message"
        data-testid="contact-object"
      />
      <SxField
        name="message"
        placeholder="Votre message"
        as="textarea"
        data-testid="contact-message"
      />

      <div className="form_footer --txt--center">
        <SubmitButton
          className="btn"
          type="submit"
          data-testid="contact-submit"
        >
          Envoyer
        </SubmitButton>
      </div>
    </SxForm>
  );
}

export default ContactForm;
