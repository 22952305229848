import { baseAPI } from "../api";
import { Contact } from "../../views/contact/ContactForm";
import { User } from "../user/userModel";
import {
  LoggedUserData,
  LoginData,
  ProRegisterData,
  RegisterData,
} from "./authModel";

export function loginRequest(
  loginData: LoginData,
): Promise<User & LoggedUserData> {
  return baseAPI
    .post("/auth/login", loginData, {
      withCredentials: true,
    })
    .then(({ data }) => data);
}

export function logoutRequest(): Promise<void> {
  return baseAPI.post("/auth/logout", undefined, {
    withCredentials: true,
  });
}

export function registerRequest(user: RegisterData): Promise<void> {
  return baseAPI.post("/auth/register", user);
}

export function resendValidationMailRequest(
  email: User["email"],
): Promise<void> {
  return baseAPI.post("/auth/resend-validation", { email });
}

export function lostPasswordRequest(email: User["email"]): Promise<void> {
  return baseAPI.post("/auth/lost-password", { email });
}

export function resetPasswordRequest(
  uuid: string,
  newPassword: LoginData["password"],
): Promise<void> {
  return baseAPI.post("/auth/reset-password", { uuid, newPassword });
}

export function validateUserRequest(uuid: string): Promise<number> {
  return baseAPI.post("/auth/validate/" + uuid).then(({ status }) => status);
}

export function createPasswordRequest(
  uuid: string,
  newPassword: LoginData["password"],
): Promise<void> {
  return baseAPI.post("/auth/create-password", { uuid, newPassword });
}

export function updateLoggedUserPasswordRequest(
  oldPassword: string,
  newPassword: string,
): Promise<void> {
  return baseAPI.put(`/auth/update-password`, { oldPassword, newPassword });
}

export function contactRequest(contact: Contact) {
  return baseAPI.post("/contact", contact);
}

export function registerProRequest(user: ProRegisterData): Promise<void> {
  return baseAPI.post("/auth/register-pro", user);
}
