import { BookingProfile } from "../../../services/bookingProfile/bookingProfileModel";

type MappedBookingProfile = Omit<
  BookingProfile,
  "languages" | "skills" | "expertises"
> & {
  languages: string[];
  skills: string[];
  expertises: string[];
};

function BookingProfileStatuses({
  profile,
}: {
  profile: BookingProfile | MappedBookingProfile;
}) {
  return (
    <div className="ui-row --stretch">
      {profile.published === false && (
        <span className="status --danger">Désactivé</span>
      )}
      {profile.private && <span className="status --warning">Privé</span>}
      {profile.probono && <span className="status">100% don</span>}
    </div>
  );
}

export default BookingProfileStatuses;
