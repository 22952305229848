import CopyToClipboard from "../../../components/utilities/CopyToClipboard";

const BookingProfileContextExempleSection = () => {
  return (
    <div>
      <div className="block_title">Exemples</div>

      <div className="cblock--s context-examples">
        <div className="examples_row">
          <CopyToClipboard
            value={`Je suis Product Manager chez ABC. Je veux échanger avec des directeurs marketing du e-Commerce pour présenter une nouvelle offre dans l’objectif de recueillir du feedback qualitatif et proposer un abonnement.`}
          />
          <CopyToClipboard
            value={`Je suis fondateur de MyStartup. Je souhaite échanger avec des DSIs pour présenter une nouvelle solution de monitoring, la confronter à leurs besoins, et reccueillir leur avis d'expert.`}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingProfileContextExempleSection;
