import { PropsWithChildren } from "react";
import { toastsWithIntl } from "../../services/toastService";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

const { toastError } = toastsWithIntl(["global"]);

function CopyToClipboard({
  value,
  children,
}: PropsWithChildren & { value: string }) {
  const { t } = useTranslation(["global"]);
  const copy = () => {
    void navigator.clipboard
      .writeText(value)
      .then(() => {
        //toastSuccess("global:copy-to-clipboard.SUCCESS");
      })
      .catch(() => toastError("global:copy-to-clipboard.ERROR"));
  };

  return (
    // TODO : integration
    <div className="copy-to-clipboard">
      <div className="ctc_content">{children ?? value}</div>
      <button
        data-tooltip-id="ctc-tooltip"
        type="button"
        className="ctc_button"
        onClick={() => copy()}
      ></button>
      <Tooltip
        id="ctc-tooltip"
        content={t("global:copy-to-clipboard.SUCCESS")}
        events={["click"]}
        delayHide={1000}
      />
    </div>
  );
}

export default CopyToClipboard;
