import { RegisterData } from "../services/auth/authModel";
import RegisterForm from "../views/auth/register/RegisterForm";
import Dialog from "./Dialog";

const RegisterDialog = ({
  initialValues,
  onClose,
  onRegister,
  onRedirectToLogin,
}: {
  initialValues?: Pick<RegisterData, "firstname" | "lastname" | "email">;
  onClose: () => void;
  onRegister: (values: RegisterData) => Promise<void> | void;
  onRedirectToLogin: () => void;
}) => {
  return (
    <Dialog onClose={onClose} className="auth-card">
      <>
        <div className="popup_head">
          <div className="popup_title">Inscription</div>
        </div>
        <div className="popup_body">
          <RegisterForm
            onRegister={onRegister}
            initialValues={initialValues}
            onRedirectToLogin={onRedirectToLogin}
          />
        </div>
      </>
    </Dialog>
  );
};

export default RegisterDialog;
