import { useRouteError } from "react-router-dom";
import BackButton from "../../../components/BackButton";

const CampaignsErrorPage = () => {
  const error: any = useRouteError();

  return (
    <div className="container">
      <div className="page_head">
        <div>
          <BackButton fallbackUrl="/app/dashboard" />
        </div>
      </div>
      <div className="page-content">
        <div className="info --warning">
          {String(error).includes("403")
            ? "Vous n'êtes pas rattachés à un compte professionnel"
            : "Une erreur est survenue."}
        </div>
      </div>
    </div>
  );
};

export default CampaignsErrorPage;
